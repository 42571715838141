import CancelIcon from '@mui/icons-material/Cancel'
import ChairIcon from '@mui/icons-material/Chair'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import SearchIcon from '@mui/icons-material/Search'
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Select as MuiSelect,
  Paper,
} from '@mui/material'
import axios from 'axios'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap'
import { components } from 'react-select'
import { AsyncPaginate } from 'react-select-async-paginate'
import { toast } from 'react-toastify'
import {
  clinicApiURL,
  commonHeadersCPWeb,
  getCPDataWithoutParams,
  postCPDataWithoutParams,
  postCPDataWithParams,
  putCPDataWithoutParams,
  putCPDataWithParams,
} from 'src/clinic/services'
import {
  allAvailableEventTypes,
  APP_CONSTANTS,
  BLANK_PATIENT_DATA,
  BOOKED_EVENT_STEP_STATUSES,
  BOOKING_REQUEST_STATUS,
  currency_list,
  displayNamesOfEventTypes,
  GENDER_LIST_OFFLINE,
  MOMENT_FORMATS,
  OFFLINE_PAYMENT_METHODS_LIST,
  PATIENT_VALIDATION_FIELDS,
  PAY_IN_FUTURE_ALLOWED_MENTOR_IDS,
  platformOptions,
  RELATIONSHIP_OPTIONS,
  SESSION_PLATFORM_OFFLINE,
  SESSION_PLATFORM_ONLINE,
} from 'src/consumer/constants'
import { getAgeFromDobWithTime, getDOBWithTime } from 'src/consumer/helpers/profilePageHelper'
import {
  areStringsEqualIgnoreCase,
  removePlusFromBeginning,
  validateEmail,
  validateName,
  validateOnlyNumbers,
} from 'src/consumer/helpers/utilFunctions'
import { platformWeb } from 'src/consumer/services'
import {
  NewEventTypeButton,
  StyledEventsRefreshIcon,
} from 'src/mentor/components/MentorEventsPage/MentorEventsPageStyles'
import {
  getDataMapFromCommonResponse,
  getDefaultScheduleFromSchedules,
  getSubscriptionPlanById,
  isCommonResponseSuccessful,
  isCommonResponseSuccessfulV2,
} from 'src/mentor/helpers/utilityFunctions'
import { GenericDisplayFlex } from 'src/mentor/layout/Styles'
import { v4 as uuidv4 } from 'uuid'
import {
  getInitiallySelectedEventTypeData,
  getCorrectScreeningReasonMessageObject as getScreeningReasonMessageObject,
} from './ClinicManageBookingsHelper'
import {
  BookingRequestContainer,
  BookingRequestItemsParent,
  CashDenominationInput,
  CashDenominationText,
  EDVCBookNewSlotSelect,
  EDVCEventsContainer,
  EDVCNewBookingContainer,
  EDVCPhoneInput,
  EDVCRectangleButton,
  PIParent,
  PISection,
  PISectionInput,
  PISectionItem,
  PISectionTitle,
  PISectionValidation,
  RRBContainer,
  RRBItem,
  SearchSectionParent,
} from './ClinicManageBookingsPageStyles'

import { Error } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Button as MuiButton } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { styled as muiStyled } from '@mui/material/styles'
import dayjs from 'dayjs'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { GenericEventBookingComponent } from 'src/consumer/components/EventBooking/BookingComponents'
import { CustomHTMLSelect, GenericLoadingOverlay } from 'src/consumer/components/helperComponents'
import { cancelBookingRequestFuncForAssistant } from 'src/consumer/services/featureServices'
import './LoaderComponent.css'
import { CompositeBookingRequestCreation } from './TherapyRequestBookingComponents'
import NewBookingDialogReasonForNotDoingScreeningForm from './newBookingDialog/NewBookingDialogReasonForNotDoingScreeningForm'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import DoneIcon from '@mui/icons-material/Done'
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront'
import { PinEntryVarOne } from 'src/chamberScreen/layout/PinEntryVarOne'
import { addOrUpdatePatientsInIdb, searchPatientsInIdb } from 'src/emr/database/indexedDbDao'
import {
  convertToCelsiusFromFahrenheit,
  convertToFahrenheitFromCelsius,
  VitalShowingComponent,
} from 'src/mentor/components/VitalsListingPage'
import styled from 'styled-components'
import { getVitalsListForAPatientInClinic as getVitalsListForAPatientInAClinic } from './utils'
import EventSelectorWithVisitEventTypesSelection from './newBookingDialog/eventSelector/EventSelectorWithVisitEventTypesSelection'
import { PastBookingsHorizontalComponent } from './PastBookingsHorizonal'

const utcPlugin = require('dayjs/plugin/utc')
dayjs.extend(utcPlugin)

const BootstrapDialog = muiStyled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

const BootStrapDialogPaperComponent = muiStyled(Paper)(
  ({ theme }) => `
    max-width: 1000px !important;
    @media (max-height: 980px) {
      position: absolute !important;
      top: 16px;
    }
    `,
)

// new stuff components:

export const TherapyBookingDialogComponent = ({
  open,
  clinicLocationId,
  clinicBrandId,
  clinicLocationData,
  handleCloseDialog,
  data,
  bookingRequestsData,
  bookingRequestsDataLoading,
  setFetchDataTriggerForBookingRequests,
}) => {
  return (
    <BootstrapDialog
      onClose={handleCloseDialog}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperComponent={BootStrapDialogPaperComponent}
    >
      <div style={{ width: '90vw', height: '90vh', padding: '20px' }}>
        <CompositeBookingRequestCreation
          clinicLocationId={clinicLocationId}
          clinicBrandId={clinicBrandId}
          clinicLocationData={clinicLocationData}
          bookedSlot={data?.bookedSlot}
          bookingRequestsData={bookingRequestsData}
          bookingRequestsDataLoading={bookingRequestsDataLoading}
          neededTherapyBookingDataForThisEvent={data?.therapyBookingDataForThisEvent}
          handleCloseDialog={handleCloseDialog}
          setFetchDataTriggerForBookingRequests={setFetchDataTriggerForBookingRequests}
        />
      </div>
    </BootstrapDialog>
  )
}

export const NewBookingDialogComponent = ({
  dialogTitle,
  dialogSecondaryTitle,
  open,
  handleCloseDialog,
  setNewBookingDialogOpen,
  clinicId,
  clinicBrandId,
  clinicData,
  clinicBrandData,
  bookedSlotsInDay,
  updateBookingsInCalendar,
  forMentor,
  mentorProfileData,
  mentorAuthorisedToSaveAndGetPatientsUsingIdb,
  chambersData,
  assistantProfileInThisClinic,
  selectedEventTypeId,
  eventDataForSelectedEventType,
  planDataForSelectedEventId,
  eventTypesVisible,
  setSelectedEventTypeId,
  clickedWantedSlotData,
  setClickedWantedSlotData,
  setEventDataForReceiptPrinting,
  setEventIdForReceiptPrinting,
  openReceiptDialog,
  selectedVisitEventType,
  setSelectedVisitEventType,
}) => {
  // states:
  const [isNewPatient, setIsNewPatient] = useState(false)
  const [relatedToPatient, setRelatedToPatient] = useState()
  const [relatedPatientLoading, setRelatedPatientLoading] = useState(false)
  const [selectedPatient, setSelectedPatient] = useState(null)
  const [selectedPatientInputDataValidations, setSelectedPatientInputDataValidations] =
    useState(PATIENT_VALIDATION_FIELDS)
  const [selectedPatientChangesDisabled, setSelectedPatientChangesDisabled] = useState(true)
  const [tryBookingScreening, setTryBookingScreening] = useState(true)

  const [newPatientSubmissionLoading, setNewPatientSubmissionLoading] = useState(false)
  const [bookingLoading, setBookingLoading] = useState(false)
  const [patientUpdateLoading, setPatientUpdateLoading] = useState(false)

  const [selectedRelatedConsultationForTherapy, setSelectedRelatedConsultationForTherapy] =
    useState('')
  const [pastConsultationsOfPatient, setPastConsultationsOfPatient] = useState([])

  const [newPatientInputData, setNewPatientInputData] = useState({ ...BLANK_PATIENT_DATA })
  const [newPatientInputDataValidations, setNewPatientInputDataValidations] =
    useState(PATIENT_VALIDATION_FIELDS)

  const [paymentDetail, setPaymentDetail] = useState({
    feePaid: undefined,
    feePaymentMode: 'cash',
    feePaidTxnId: '',
    currency:
      currency_list.find((item) =>
        areStringsEqualIgnoreCase(item.countryCode, localStorage.getItem('countryCode')),
      )?.code || 'usd',
  })

  const [autoFillStuffForNewPatientString, setAutoFillStuffForNewPatientString] = useState('')

  const [chosenChamberRoomId, setChosenChamberRoomId] = useState('')

  const [showPastBookings, setShowPastBookings] = useState(false)

  const [
    chamberRoomsForMentorAssistantClinicLocation,
    setChamberRoomsForMentorAssistantClinicLocation,
  ] = useState([])

  const [reasonForNotDoingScreening, setReasonForNotDoingScreening] = useState('')
  const [reasonIdForNotDoingScreening, setReasonIdForNotDoingScreening] = useState('')
  const [screenerAvailable, setScreenerAvailable] = useState(false)

  const [relatedConsultationsLoading, setRelatedConsultationsLoading] = useState(false)

  const [printReceipt, setPrintReceipt] = useState(false)

  // is time enough to book?
  let isEnoughTimeToBook

  const hasValidAvailableEvents = eventTypesVisible && eventTypesVisible?.length > 0

  let thereIsOverlap = [] // with booked slots
  bookedSlotsInDay?.forEach((nonAvailableSlot) => {
    const UnavailableTimeSlotStart = moment(nonAvailableSlot?.startTimeForDayCal)
    const UnavailableTimeSlotEnd = moment(nonAvailableSlot?.endTimeForDayCal)

    if (
      moment(clickedWantedSlotData?.startTime)?.isSameOrAfter(UnavailableTimeSlotEnd) ||
      moment(clickedWantedSlotData?.endTime)?.isSameOrBefore(UnavailableTimeSlotStart)
    ) {
      thereIsOverlap?.push(false)
    } else {
      thereIsOverlap?.push(true)
    }
  })
  isEnoughTimeToBook = !thereIsOverlap?.some((element) => Boolean(element))
  ////////////

  // ask payment?
  const askPayment =
    true ||
    Boolean(Boolean(planDataForSelectedEventId?.finalPrice) && !eventDataForSelectedEventType?.free)

  async function selectPatientFunction(data) {
    const dataHere = { ...data }
    setIsNewPatient(false)
    const toSetSelectedPatientData = {
      ...dataHere,
      age: getAgeFromDobWithTime(dataHere?.dateOfBirth),
    }
    if (Array.isArray(data?.attendants) && data?.attendants?.length > 0) {
    } else {
      toSetSelectedPatientData['attendants'] = [
        {
          name: '',
          primary: true,
          type: 'relative',
        },
      ]
    }
    if (!data?.communicationsToAttendant) {
      toSetSelectedPatientData['communicationToAttendant'] = false
    }
    setSelectedPatient(toSetSelectedPatientData)
    setRelatedToPatient(null)
    if (dataHere?.primaryPatientProfile?.patientId) {
      setRelatedPatientLoading(true)
      const response = await getCPDataWithoutParams(
        `/api/secure/patient/list?patientId=${dataHere?.primaryPatientProfile?.patientId}&clinicId=${clinicId}`,
      )
      setRelatedPatientLoading(false)
      if (response.status === 200 && response?.data && response?.data?.[0]) {
        setRelatedToPatient(response?.data?.[0])
      } else {
        toast.error('Error finding related patient data. Contact support.')
      }
    }
    if (dataHere?.id) {
      setSelectedRelatedConsultationForTherapy('')
      setPastConsultationsOfPatient([])
      if (forMentor) {
      } else {
        if (eventDataForSelectedEventType?.type === allAvailableEventTypes.therapySession) {
          setRelatedConsultationsLoading(true)
          const response = await getCPDataWithoutParams(
            `/api/secure/clinic-assistant/booked-slots/patient?patientId=${dataHere?.id}&clinicId=${clinicId}&sortDescendingOnStartTime=true`,
          )
          setRelatedConsultationsLoading(false)
          if (response.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
            let arrayOfPastBookings = response?.data?.data?.['booked-event-info']
            arrayOfPastBookings = arrayOfPastBookings?.filter(
              (item) => item?.eventType === allAvailableEventTypes.consultation,
            )
            setPastConsultationsOfPatient(arrayOfPastBookings)
            if (arrayOfPastBookings?.length > 0) {
              if (isWithinLast24Hours(arrayOfPastBookings?.[0]?.startTime)) {
                setSelectedRelatedConsultationForTherapy(arrayOfPastBookings?.[0]?.id)
              }
            }
          } else {
            toast.error('Error finding related patient data. Contact support.')
          }
        }
      }
    }
  }

  function selectRelatedPatient(switchToPatientData) {
    const switchToPatientDataHere = { ...switchToPatientData }
    setSelectedPatient(switchToPatientDataHere)
    setRelatedToPatient(false)
  }

  function handleAddNewButtonClick(autoFillStuffForNewPatientString) {
    setSelectedPatient(null)
    setIsNewPatient(true)
    if (identifyStringUseForAutoFill(autoFillStuffForNewPatientString).isValid) {
      if (identifyStringUseForAutoFill(autoFillStuffForNewPatientString).type === 'email') {
        setNewPatientInputData({
          ...BLANK_PATIENT_DATA,
          email: autoFillStuffForNewPatientString,
        })
      } else if (identifyStringUseForAutoFill(autoFillStuffForNewPatientString).type === 'mobile') {
        if (isValidPhoneNumber(`+${autoFillStuffForNewPatientString}`)) {
          setNewPatientInputData({
            ...BLANK_PATIENT_DATA,
            mobile: autoFillStuffForNewPatientString,
          })
        } else {
          setNewPatientInputData({
            ...BLANK_PATIENT_DATA,
            mobile: `91${autoFillStuffForNewPatientString}`,
          })
        }
      } else if (identifyStringUseForAutoFill(autoFillStuffForNewPatientString).type === 'name') {
        setNewPatientInputData({
          ...BLANK_PATIENT_DATA,
          name: autoFillStuffForNewPatientString,
        })
      } else {
        setNewPatientInputData({ ...BLANK_PATIENT_DATA })
      }
    } else {
      setNewPatientInputData({ ...BLANK_PATIENT_DATA })
    }
  }

  function handleAddNewRelatedPatient(existingPatient) {
    const existingPatientHere = { ...existingPatient }
    setIsNewPatient(true)
    setRelatedToPatient(null)
    setSelectedPatient(null)
    setNewPatientInputData({
      ...BLANK_PATIENT_DATA,
      mobile: existingPatient?.mobile,
      email: existingPatient?.email,
      dependentRelation: 'relative',
      relatedToExistingPatient: existingPatientHere,
    })
  }

  async function bookNowHandler() {
    const createdEvent = await makeBookingInDayViewCalendar(
      forMentor,
      clinicId,
      clinicBrandId,
      mentorProfileData?.id,
      askPayment,
      clickedWantedSlotData,
      eventDataForSelectedEventType,
      selectedPatient,
      updateBookingsInCalendar,
      setNewBookingDialogOpen,
      {
        feePaid: paymentDetail?.feePaid,
        feePaymentMode: paymentDetail?.feePaymentMode,
        feePaidTxnId: paymentDetail?.feePaidTxnId,
        currency: paymentDetail?.currency,
      },
      setBookingLoading,
      setSelectedPatient,
      setIsNewPatient,
      setNewPatientInputData,
      chosenChamberRoomId,
      tryBookingScreening,
      false,
      getScreeningReasonMessageObject({
        eventType: eventDataForSelectedEventType?.type,
        isScreenerAvailable: screenerAvailable,
        isTryingToBookScreening: tryBookingScreening,
        reasonIdForNotDoingScreening: reasonIdForNotDoingScreening,
        reasonForNotDoingScreening: reasonForNotDoingScreening,
      }),
      null,
      selectedRelatedConsultationForTherapy,
    )
    if (printReceipt && !!createdEvent) {
      setEventDataForReceiptPrinting(createdEvent)
      openReceiptDialog(true)
    }
  }

  useEffect(() => {
    setNewPatientInputDataValidations({
      ...PATIENT_VALIDATION_FIELDS,
      name: Boolean(newPatientInputData?.name),
      email: validateEmail(newPatientInputData?.email),
      mobile: isValidPhoneNumber(`+${removePlusFromBeginning(newPatientInputData?.mobile)}`),
      gender: Boolean(newPatientInputData?.gender),
      age: Boolean(newPatientInputData?.age),
    })
  }, [newPatientInputData])

  useEffect(() => {
    setSelectedPatientInputDataValidations({
      ...PATIENT_VALIDATION_FIELDS,
      name: Boolean(selectedPatient?.name),
      email: validateEmail(selectedPatient?.email),
      mobile: isValidPhoneNumber(`+${removePlusFromBeginning(selectedPatient?.mobile)}`),
      gender: Boolean(selectedPatient?.gender),
      age: Boolean(selectedPatient?.age),
    })
  }, [selectedPatient])

  useEffect(() => {
    if (
      Array.isArray(chambersData) &&
      eventDataForSelectedEventType?.meetingSrc === 'offline' &&
      !forMentor
    ) {
      const items = chambersData?.filter(
        (item) =>
          item?.clinicLocationId === clinicId &&
          item?.inPersonMentorsIds?.includes(mentorProfileData?.id) &&
          item?.clinicAssistantsIds?.includes(assistantProfileInThisClinic?.id),
      )
      if (items?.length > 0) {
        setChosenChamberRoomId(items?.[0]?.id)
        setChamberRoomsForMentorAssistantClinicLocation(items)
      } else {
        setChosenChamberRoomId('')
        setChamberRoomsForMentorAssistantClinicLocation([])
      }
    } else {
      setChosenChamberRoomId('')
      setChamberRoomsForMentorAssistantClinicLocation([])
    }
  }, [chambersData, mentorProfileData, eventDataForSelectedEventType, clinicId])

  useEffect(() => {
    setClickedWantedSlotData((prev) => {
      return {
        ...prev,
        endTime: dayjs(prev?.startTime)
          .add(eventDataForSelectedEventType?.durationInMins, 'minute')
          .format('YYYY-MM-DDTHH:mm:ssZ'),
      }
    })
  }, [selectedEventTypeId, eventDataForSelectedEventType])

  useEffect(() => {
    const sessionPrintReceipt = sessionStorage.getItem('printReceipt')

    setPrintReceipt(sessionPrintReceipt === 'true' ? true : false)
  }, [])

  return (
    <>
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperComponent={BootStrapDialogPaperComponent}
        sx={{ width: '700px', margin: 'auto' }}
      >
        <IconButton
          aria-label="close"
          onClick={() => {
            setSelectedPatient(null)
            setIsNewPatient(false)
            setNewPatientInputData({ ...BLANK_PATIENT_DATA })
            handleCloseDialog()
          }}
          sx={{
            position: 'absolute',
            top: '8px',
            right: '8px',
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '16px',
          }}
        >
          <div>
            {dialogTitle}
            {dialogSecondaryTitle}
          </div>
        </DialogTitle>

        <div style={{ padding: '0 16px 16px 16px' }}>
          <EventSelectorWithVisitEventTypesSelection
            eventTypesVisible={eventTypesVisible}
            selectedEventTypeId={selectedEventTypeId}
            setSelectedEventTypeId={setSelectedEventTypeId}
            clinicLocationId={clinicId}
            mentorProfileData={mentorProfileData}
            selectedVisitType={selectedVisitEventType}
            setSelectedVisitType={setSelectedVisitEventType}
          />
        </div>

        {!!selectedEventTypeId && (
          <div style={{ padding: '0 16px 16px 16px' }}>
            {' '}
            <div className="d-flex gap-2" style={{ fontSize: '12px', fontWeight: 500 }}>
              <div>
                Session: {eventDataForSelectedEventType?.title} (
                {displayNamesOfEventTypes?.[eventDataForSelectedEventType?.type] || ''})
              </div>
              {!forMentor && clinicData && (
                <div
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${APP_CONSTANTS.LIVE_APP_URL}/mentor/${mentorProfileData?.slug}/events/${selectedEventTypeId}`,
                    )
                    toast.success(
                      <div style={{ textAlign: 'center', fontSize: '12px' }}>
                        Self-Booking link for this session copied to your clipboard. You can share
                        this with the patient.
                      </div>,
                    )
                  }}
                >
                  <u>Copy Self-Booking Link</u>
                </div>
              )}
            </div>
            <div style={{ fontSize: '12px', fontWeight: 500 }}>
              {eventDataForSelectedEventType?.meetingSrc === SESSION_PLATFORM_OFFLINE ? (
                <>
                  Starts:{' '}
                  {moment(clickedWantedSlotData?.startTime).local().format('DD/MM/YYYY, h:mma')} -{' '}
                </>
              ) : (
                <>
                  For:{' '}
                  {moment(clickedWantedSlotData?.startTime).local().format('DD/MM/YYYY, h:mma')} -{' '}
                  {moment(clickedWantedSlotData?.endTime).local().format('h:mma')} -{' '}
                </>
              )}
              {
                platformOptions.find(
                  (item) => item.id === eventDataForSelectedEventType?.meetingSrc,
                )?.name
              }
            </div>{' '}
          </div>
        )}

        <EDVCNewBookingContainer>
          {clinicId ? (
            <>
              {isEnoughTimeToBook ? (
                // main stuff here
                <>
                  {/* section one */}
                  <SearchSectionParent>
                    <GenericDisplayFlex
                      style={{
                        justifyContent: 'space-between',
                        gap: '15px',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div style={{ width: '100%' }}>
                        <AsyncPaginate
                          value={selectedPatient}
                          isClearable={true}
                          isDisabled={!hasValidAvailableEvents}
                          placeholder="Search Patient"
                          loadOptions={loadOptionsForExistingPatient}
                          components={{
                            NoOptionsMessage: CustomNoOptionsMessage,
                            Placeholder: CustomPlaceholder,
                            Option: CustomOption,
                          }}
                          styles={{
                            menuList: (provided, state) => ({
                              ...provided,
                              maxHeight: '200px', // Set the desired height here
                              overflowY: 'scroll',
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              display: 'none', // Hides the dropdown arrow
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: 'none', // Optionally hides the separator line
                            }),
                          }}
                          getOptionValue={(option) => option.id}
                          getOptionLabel={(option) => getLabelForAlreadyPatientOption(option)}
                          onChange={selectPatientFunction}
                          debounceTimeout={mentorAuthorisedToSaveAndGetPatientsUsingIdb ? 200 : 500}
                          additional={{
                            page: 1,
                            clinicId,
                            clinicBrandId,
                            setAutoFillStuffForNewPatientString,
                            mentorAuthorisedToSaveAndGetPatientsUsingIdb,
                          }}
                        />
                        <div style={{ fontSize: '14px' }}>
                          <i>Use Patient No./Name/Mobile/Email</i>
                        </div>
                      </div>
                      <span style={{ marginTop: '6px' }}>{' or '}</span>
                      {!isNewPatient && !Boolean(selectedPatient) && (
                        <MuiButton
                          variant="contained"
                          disabled={!hasValidAvailableEvents}
                          sx={{ flexShrink: 0 }}
                          onClick={() => {
                            handleAddNewButtonClick(autoFillStuffForNewPatientString)
                          }}
                        >
                          Add New
                        </MuiButton>
                      )}
                      <MuiButton
                        variant="text"
                        onClick={() => {
                          setSelectedPatient(null)
                          setRelatedToPatient(null)
                          setIsNewPatient(false)
                          setNewPatientInputData({ ...BLANK_PATIENT_DATA })
                        }}
                      >
                        Reset
                      </MuiButton>
                    </GenericDisplayFlex>
                  </SearchSectionParent>
                  <hr />

                  {/* isNewPatient is marked true and there is no selected patient, patient creation possible */}
                  {isNewPatient && !Boolean(selectedPatient) && (
                    <>
                      {newPatientInputData?.relatedToExistingPatient &&
                        newPatientInputData?.dependentRelation && (
                          <div style={{ marginBottom: '1rem' }}>
                            Choose Relationship:{' '}
                            <b>{newPatientInputData?.relatedToExistingPatient?.name}&apos;s</b>
                            <CustomHTMLSelect
                              value={newPatientInputData?.dependentRelation}
                              onChange={(e) => {
                                setNewPatientInputData((prev) => ({
                                  ...prev,
                                  dependentRelation: e.target.value,
                                }))
                              }}
                              getOptionLabel={(data) => data?.label}
                              getOptionValue={(data) => data?.value}
                              options={RELATIONSHIP_OPTIONS}
                            />
                          </div>
                        )}
                      <div
                        style={{ marginBottom: '10px', display: 'flex', justifyContent: 'right' }}
                      >
                        {newPatientSubmissionLoading ? (
                          <Spinner animation="border" variant="dark" size="md" />
                        ) : (
                          <MuiButton
                            variant="contained"
                            disabled={
                              !(
                                newPatientInputDataValidations?.name &&
                                (newPatientInputDataValidations?.email ||
                                  newPatientInputDataValidations?.mobile) &&
                                newPatientInputDataValidations?.gender &&
                                newPatientInputDataValidations?.age
                              ) || newPatientSubmissionLoading
                            }
                            onClick={() => {
                              createNewPatient(
                                clinicId,
                                clinicBrandId,
                                mentorProfileData,
                                newPatientInputData,
                                setNewPatientSubmissionLoading,
                                forMentor,
                                setSelectedPatient,
                                setNewPatientInputData,
                                setIsNewPatient,
                                setRelatedToPatient,
                                setRelatedPatientLoading,
                              )
                            }}
                          >
                            Add Patient
                          </MuiButton>
                        )}
                      </div>
                      <PIParent>
                        <PISection>
                          {/* Name */}
                          <PISectionItem>
                            <PISectionTitle>Name*</PISectionTitle>
                            <div>
                              <PISectionInput
                                type="text"
                                value={newPatientInputData?.name}
                                onChange={(e) =>
                                  setNewPatientInputData((prev) => ({
                                    ...prev,
                                    name: e.target.value,
                                  }))
                                }
                              />
                              {!newPatientInputDataValidations?.name && (
                                <PISectionValidation>Required</PISectionValidation>
                              )}
                            </div>
                          </PISectionItem>
                          {/* Gender */}
                          <PISectionItem>
                            <PISectionTitle>Gender*</PISectionTitle>
                            <div>
                              <RectangleRadioButton
                                options={[
                                  { label: 'Male', id: 'male' },
                                  { label: 'Female', id: 'female' },
                                  { label: 'Other', id: 'nonBinary' },
                                ]}
                                selectedId={newPatientInputData?.gender}
                                selectionFunction={(id) =>
                                  setNewPatientInputData((prev) => ({ ...prev, gender: id }))
                                }
                              />
                              {!newPatientInputDataValidations?.gender && (
                                <PISectionValidation>Choose one</PISectionValidation>
                              )}
                            </div>
                          </PISectionItem>
                          {/* Email */}
                          <PISectionItem>
                            <PISectionTitle>Email</PISectionTitle>
                            <div>
                              <PISectionInput
                                disabled={
                                  newPatientInputData?.relatedToExistingPatient &&
                                  newPatientInputData?.dependentRelation
                                }
                                type="text"
                                value={newPatientInputData?.email}
                                onChange={(e) =>
                                  setNewPatientInputData((prev) => ({
                                    ...prev,
                                    email: e.target.value.trim(),
                                  }))
                                }
                              />
                              {!newPatientInputDataValidations?.email &&
                                !newPatientInputDataValidations?.mobile && (
                                  <PISectionValidation>
                                    Mobile or Email required
                                  </PISectionValidation>
                                )}
                            </div>
                          </PISectionItem>
                          {/* Address */}
                          <PISectionItem>
                            <PISectionTitle>Address</PISectionTitle>
                            <PISectionInput
                              type="text"
                              value={newPatientInputData?.address}
                              onChange={(e) =>
                                setNewPatientInputData((prev) => ({
                                  ...prev,
                                  address: e.target.value,
                                }))
                              }
                            />
                          </PISectionItem>
                          {/* Attendants */}
                          <PISectionItem className="flex-column">
                            <PISectionTitle className="d-flex gap-1" style={{ width: 'auto' }}>
                              Attendants{' '}
                              <AddCircleOutlineIcon
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  setNewPatientInputData((prev) => ({
                                    ...prev,
                                    attendants: Array.isArray(prev?.attendants)
                                      ? [
                                          ...prev?.attendants,
                                          {
                                            name: '',
                                            primary: false,
                                            type: RELATIONSHIP_OPTIONS[0].value,
                                          },
                                        ]
                                      : [
                                          {
                                            name: '',
                                            primary: true,
                                            type: RELATIONSHIP_OPTIONS[0].value,
                                          },
                                        ],
                                  }))
                                }}
                              />
                            </PISectionTitle>
                            {(newPatientInputData?.attendants || [])?.map((item, idx) => {
                              return (
                                <div key={idx} className="d-flex">
                                  <input
                                    style={{ border: '1px solid #cecece', borderRadius: '4px' }}
                                    value={item?.name || ''}
                                    onChange={(e) => {
                                      setNewPatientInputData((prev) => ({
                                        ...prev,
                                        attendants: prev?.attendants?.map((attendant, idxn) =>
                                          idx === idxn
                                            ? { ...attendant, name: e.target.value }
                                            : attendant,
                                        ),
                                      }))
                                    }}
                                  />
                                  <select
                                    value={item?.type}
                                    style={{
                                      border: '1px solid #cecece',
                                      borderRadius: '4px',
                                      width: '80px',
                                    }}
                                    onChange={(e) => {
                                      setNewPatientInputData((prev) => ({
                                        ...prev,
                                        attendants: prev?.attendants?.map((attendant, idxn) =>
                                          idx === idxn
                                            ? { ...attendant, type: e.target.value }
                                            : attendant,
                                        ),
                                      }))
                                    }}
                                  >
                                    {RELATIONSHIP_OPTIONS.map((item, index) => {
                                      return (
                                        <option key={index} value={item.value}>
                                          {item.label}
                                        </option>
                                      )
                                    })}
                                  </select>
                                  <DeleteOutlineIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      setNewPatientInputData((prev) => ({
                                        ...prev,
                                        attendants: prev?.attendants?.filter(
                                          (_, index) => index !== idx,
                                        ),
                                      }))
                                    }}
                                  />
                                </div>
                              )
                            })}
                          </PISectionItem>
                          {/* /Attendants */}
                          {/* communicationToAttendant */}
                          {Array.isArray(newPatientInputData?.attendants) &&
                            newPatientInputData?.attendants?.length > 0 && (
                              <PISectionItem className="flex-column">
                                <PISectionTitle
                                  className="d-flex gap-1 flex-row align-items-center"
                                  style={{ width: 'auto' }}
                                >
                                  Communication to Attendant
                                  <Checkbox
                                    checked={!!newPatientInputData?.communicationToAttendant}
                                    onChange={(event) => {
                                      setNewPatientInputData((prev) => ({
                                        ...prev,
                                        communicationToAttendant: event.target.checked,
                                      }))
                                    }}
                                    size="small"
                                  />
                                </PISectionTitle>
                              </PISectionItem>
                            )}
                          {/* /communicationToAttendant */}
                        </PISection>
                        <PISection>
                          {/* Mobile */}
                          <PISectionItem>
                            <PISectionTitle>Mobile*</PISectionTitle>
                            <div>
                              <EDVCPhoneInput
                                disabled={
                                  newPatientInputData?.relatedToExistingPatient &&
                                  newPatientInputData?.dependentRelation
                                }
                                specialLabel=""
                                placeholder="Mobile Number"
                                country={localStorage.getItem('countryCode')?.toLowerCase() || 'in'}
                                value={newPatientInputData?.mobile}
                                onChange={(value) =>
                                  setNewPatientInputData((prev) => ({ ...prev, mobile: value }))
                                }
                              />
                              {!newPatientInputDataValidations?.email &&
                                !newPatientInputDataValidations?.mobile && (
                                  <PISectionValidation>
                                    Mobile or Email required
                                  </PISectionValidation>
                                )}
                            </div>
                          </PISectionItem>
                          {/* Age */}
                          <PISectionItem>
                            <PISectionTitle>Age*</PISectionTitle>
                            <div>
                              <PISectionInput
                                type="number"
                                value={newPatientInputData?.age}
                                onChange={(e) =>
                                  setNewPatientInputData((prev) => ({
                                    ...prev,
                                    age: e.target.value,
                                  }))
                                }
                              />
                              {!newPatientInputDataValidations?.age && (
                                <PISectionValidation>Required</PISectionValidation>
                              )}
                            </div>
                          </PISectionItem>
                          {/* City */}
                          <PISectionItem>
                            <PISectionTitle>City</PISectionTitle>
                            <PISectionInput
                              type="text"
                              value={newPatientInputData?.city}
                              onChange={(e) =>
                                setNewPatientInputData((prev) => ({
                                  ...prev,
                                  city: e.target.value,
                                }))
                              }
                            />
                          </PISectionItem>
                          {/* Pincode */}
                          <PISectionItem>
                            <PISectionTitle>Pincode</PISectionTitle>
                            <PISectionInput
                              type="number"
                              value={newPatientInputData?.pincode}
                              onChange={(e) =>
                                setNewPatientInputData((prev) => ({
                                  ...prev,
                                  pincode: e.target.value,
                                }))
                              }
                            />
                          </PISectionItem>
                          {/* Note */}
                          <PISectionItem>
                            <PISectionTitle>Note</PISectionTitle>
                            <PISectionInput
                              type="text"
                              value={newPatientInputData?.note}
                              onChange={(e) =>
                                setNewPatientInputData((prev) => ({
                                  ...prev,
                                  note: e.target.value,
                                }))
                              }
                            />
                          </PISectionItem>
                          {/* Referred By */}
                          <PISectionItem>
                            <PISectionTitle>Referred By</PISectionTitle>
                            <PISectionInput
                              type="text"
                              value={newPatientInputData?.referredBy}
                              onChange={(e) =>
                                setNewPatientInputData((prev) => ({
                                  ...prev,
                                  referredBy: e.target.value,
                                }))
                              }
                            />
                          </PISectionItem>
                        </PISection>
                      </PIParent>
                    </>
                  )}

                  {/* patient is selected and either below code is used to either update the details of the patient or create a booking for him */}
                  {selectedPatient && (
                    <>
                      {/* edit patient/add related buttons */}

                      <div
                        style={{
                          margin: '1rem 0',
                          display: 'flex',
                          gap: '16px',
                          justifyContent: 'right',
                        }}
                      >
                        {selectedPatientChangesDisabled && (
                          <MuiButton
                            sx={{ textDecoration: 'underline' }}
                            startIcon={<EditIcon />}
                            variant="text"
                            onClick={() => {
                              setSelectedPatientChangesDisabled(false)
                            }}
                          >
                            Edit Patient
                          </MuiButton>
                        )}
                        {/* add related patient button */}
                        {selectedPatient &&
                          selectedPatientChangesDisabled &&
                          !Boolean(selectedPatient?.primaryPatientProfile?.patientId) && (
                            <MuiButton
                              sx={{ textDecoration: 'underline' }}
                              endIcon={<AddIcon />}
                              variant="text"
                              onClick={() => handleAddNewRelatedPatient(selectedPatient)}
                            >
                              Add Related Patient
                            </MuiButton>
                          )}
                      </div>

                      {/* related patient section */}
                      {selectedPatient?.primaryPatientProfile?.patientId &&
                        (relatedPatientLoading ? (
                          <div>
                            <Spinner animation="border" variant="dark" size="sm" />
                          </div>
                        ) : (
                          <div
                            style={{ padding: '1rem', background: '#E7F3FF', marginBottom: '1rem' }}
                          >
                            <div>
                              <b>{`${relatedToPatient?.name}'s ${selectedPatient?.primaryPatientProfile?.dependentRelation}`}</b>
                            </div>
                            <div
                              style={{ fontSize: '12px', cursor: 'pointer' }}
                              onClick={() => selectRelatedPatient(relatedToPatient)}
                            >
                              <u>
                                Select {relatedToPatient?.name} (
                                {relatedToPatient?.dateOfBirth &&
                                  getAgeFromDobWithTime(relatedToPatient?.dateOfBirth)}
                                /{relatedToPatient?.gender})?
                              </u>
                            </div>
                          </div>
                        ))}

                      {!Boolean(selectedPatientChangesDisabled) && (
                        <div
                          style={{
                            marginBottom: '8px',
                            display: 'flex',
                            justifyContent: 'right',
                          }}
                        >
                          {patientUpdateLoading ? (
                            <Spinner animation="border" variant="dark" size="sm" />
                          ) : (
                            <MuiButton
                              variant="contained"
                              disabled={(() => {
                                if (!selectedPatientChangesDisabled) {
                                  return (
                                    !(
                                      selectedPatientInputDataValidations?.name &&
                                      (selectedPatientInputDataValidations?.email ||
                                        selectedPatientInputDataValidations?.mobile) &&
                                      selectedPatientInputDataValidations?.gender &&
                                      selectedPatientInputDataValidations?.age
                                    ) || patientUpdateLoading
                                  )
                                }
                                return false
                              })()}
                              onClick={() => {
                                handleUpdatePatientDetails(
                                  selectedPatient?.id,
                                  clinicId,
                                  clinicBrandId,
                                  mentorProfileData,
                                  selectedPatient,
                                  setPatientUpdateLoading,
                                  setSelectedPatientChangesDisabled,
                                )
                              }}
                            >
                              {!selectedPatientChangesDisabled && 'Update Patient'}
                            </MuiButton>
                          )}
                        </div>
                      )}

                      <PIParent>
                        {selectedPatientChangesDisabled ? (
                          <div className="d-flex flex-column w-100">
                            <div>
                              <span>
                                <b>Patient Details: </b>
                              </span>
                              <span>
                                {selectedPatient?.name} ({selectedPatient?.age}/
                                {GENDER_LIST_OFFLINE.find(
                                  (item) => item.value === selectedPatient?.gender,
                                )?.label || '?'}
                                )
                              </span>
                            </div>
                            <div>
                              <span>
                                <b>Patient Number: </b>
                              </span>
                              <span>{selectedPatient?.patientNumber || '--'}</span>
                            </div>
                            <div>
                              <VitalsComponentInsideNewBooking
                                patientId={selectedPatient?.id}
                                clinicId={selectedPatient?.clinicId}
                                withCreateButton={true}
                              />
                            </div>
                            {showPastBookings ? (
                              <div style={{ width: 'calc(100% - 32px)' }}>
                                <PastBookingsHorizontalComponent
                                  patientId={selectedPatient?.id}
                                  clinicId={selectedPatient?.clinicId}
                                />
                              </div>
                            ) : (
                              <div
                                style={{ fontSize: '12px', cursor: 'pointer' }}
                                onClick={() => setShowPastBookings(true)}
                              >
                                <u>Show Past Bookings</u>
                              </div>
                            )}
                          </div>
                        ) : (
                          <>
                            <PISection>
                              {/* Name */}
                              <PISectionItem>
                                <PISectionTitle>Name*</PISectionTitle>
                                <div>
                                  <PISectionInput
                                    disabled={selectedPatientChangesDisabled}
                                    type="text"
                                    value={selectedPatient?.name}
                                    onChange={(e) =>
                                      setSelectedPatient((prev) => ({
                                        ...prev,
                                        name: e.target.value,
                                      }))
                                    }
                                  />
                                  {!selectedPatientInputDataValidations?.name && (
                                    <PISectionValidation>Required</PISectionValidation>
                                  )}
                                </div>
                              </PISectionItem>
                              {/* Gender */}
                              <PISectionItem>
                                <PISectionTitle>Gender*</PISectionTitle>
                                <div>
                                  <RectangleRadioButton
                                    disabled={selectedPatientChangesDisabled}
                                    options={[
                                      { label: 'Male', id: 'male' },
                                      { label: 'Female', id: 'female' },
                                      { label: 'Other', id: 'nonBinary' },
                                    ]}
                                    selectedId={selectedPatient?.gender}
                                    selectionFunction={(id) =>
                                      setSelectedPatient((prev) => ({ ...prev, gender: id }))
                                    }
                                  />
                                  {!selectedPatientInputDataValidations?.gender && (
                                    <PISectionValidation>Choose one</PISectionValidation>
                                  )}
                                </div>
                              </PISectionItem>
                              {/* Email */}
                              <PISectionItem>
                                <PISectionTitle>Email</PISectionTitle>
                                <div>
                                  <PISectionInput
                                    type="text"
                                    disabled={selectedPatientChangesDisabled}
                                    value={selectedPatient?.email}
                                    onChange={(e) => {
                                      setSelectedPatient((prev) => ({
                                        ...prev,
                                        email: e.target.value.trim(),
                                      }))
                                    }}
                                  />
                                  {!selectedPatientInputDataValidations?.email &&
                                    !selectedPatientInputDataValidations?.mobile && (
                                      <PISectionValidation>
                                        Mobile or Email required
                                      </PISectionValidation>
                                    )}
                                </div>
                              </PISectionItem>
                              {/* Address */}
                              <PISectionItem>
                                <PISectionTitle>Address</PISectionTitle>
                                <PISectionInput
                                  type="text"
                                  disabled={selectedPatientChangesDisabled}
                                  value={selectedPatient?.address}
                                  onChange={(e) =>
                                    setSelectedPatient((prev) => ({
                                      ...prev,
                                      address: e.target.value,
                                    }))
                                  }
                                />
                              </PISectionItem>
                              {/* Address */}
                              {/* Attendants */}
                              <PISectionItem className="flex-column">
                                <PISectionTitle className="d-flex gap-1" style={{ width: 'auto' }}>
                                  Attendants{' '}
                                  <AddCircleOutlineIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      setSelectedPatient((prev) => ({
                                        ...prev,
                                        attendants: Array.isArray(prev?.attendants)
                                          ? [
                                              ...prev?.attendants,
                                              {
                                                name: '',
                                                primary: false,
                                                type: RELATIONSHIP_OPTIONS[0].value,
                                              },
                                            ]
                                          : [
                                              {
                                                name: '',
                                                primary: true,
                                                type: RELATIONSHIP_OPTIONS[0].value,
                                              },
                                            ],
                                      }))
                                    }}
                                  />
                                </PISectionTitle>
                                {(selectedPatient?.attendants || [])?.map((item, idx) => {
                                  return (
                                    <div key={idx} className="d-flex">
                                      <input
                                        style={{ border: '1px solid #cecece', borderRadius: '4px' }}
                                        value={item?.name || ''}
                                        onChange={(e) => {
                                          setSelectedPatient((prev) => ({
                                            ...prev,
                                            attendants: prev?.attendants?.map((attendant, idxn) =>
                                              idx === idxn
                                                ? { ...attendant, name: e.target.value }
                                                : attendant,
                                            ),
                                          }))
                                        }}
                                      />
                                      <select
                                        value={item?.type}
                                        style={{
                                          border: '1px solid #cecece',
                                          borderRadius: '4px',
                                          width: '80px',
                                        }}
                                        onChange={(e) => {
                                          setSelectedPatient((prev) => ({
                                            ...prev,
                                            attendants: prev?.attendants?.map((attendant, idxn) =>
                                              idx === idxn
                                                ? { ...attendant, type: e.target.value }
                                                : attendant,
                                            ),
                                          }))
                                        }}
                                      >
                                        {RELATIONSHIP_OPTIONS.map((item, index) => {
                                          return (
                                            <option key={index} value={item.value}>
                                              {item.label}
                                            </option>
                                          )
                                        })}
                                      </select>
                                      <DeleteOutlineIcon
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          setSelectedPatient((prev) => ({
                                            ...prev,
                                            attendants: prev?.attendants?.filter(
                                              (_, index) => index !== idx,
                                            ),
                                          }))
                                        }}
                                      />
                                    </div>
                                  )
                                })}
                              </PISectionItem>
                              {/* /Attendants */}
                              {/* communicationToAttendant */}
                              {Array.isArray(selectedPatient?.attendants) &&
                                selectedPatient?.attendants?.length > 0 && (
                                  <PISectionItem className="flex-column">
                                    <PISectionTitle
                                      className="d-flex gap-1 flex-row align-items-center"
                                      style={{ width: 'auto' }}
                                    >
                                      Communication to Attendant
                                      <Checkbox
                                        checked={!!selectedPatient?.communicationToAttendant}
                                        onChange={(event) => {
                                          setSelectedPatient((prev) => ({
                                            ...prev,
                                            communicationToAttendant: event.target.checked,
                                          }))
                                        }}
                                        size="small"
                                      />
                                    </PISectionTitle>
                                  </PISectionItem>
                                )}
                              {/* /communicationToAttendant */}
                            </PISection>
                            <PISection>
                              {/* Mobile */}
                              <PISectionItem>
                                <PISectionTitle>Mobile*</PISectionTitle>
                                <div>
                                  <EDVCPhoneInput
                                    disabled={selectedPatientChangesDisabled}
                                    specialLabel=""
                                    placeholder="Mobile Number"
                                    country={
                                      localStorage.getItem('countryCode')?.toLowerCase() || 'in'
                                    }
                                    value={removePlusFromBeginning(selectedPatient?.mobile)}
                                    onChange={(value) => {
                                      setSelectedPatient((prev) => ({ ...prev, mobile: value }))
                                    }}
                                  />
                                  {!selectedPatientInputDataValidations?.email &&
                                    !selectedPatientInputDataValidations?.mobile && (
                                      <PISectionValidation>
                                        Mobile or Email required
                                      </PISectionValidation>
                                    )}
                                </div>
                              </PISectionItem>
                              {/* Age */}
                              <PISectionItem>
                                <PISectionTitle>Age*</PISectionTitle>
                                <div>
                                  <PISectionInput
                                    disabled={selectedPatientChangesDisabled}
                                    type="number"
                                    value={selectedPatient?.age}
                                    onChange={(e) =>
                                      setSelectedPatient((prev) => ({
                                        ...prev,
                                        age: e.target.value,
                                      }))
                                    }
                                  />
                                  {!selectedPatientInputDataValidations?.age && (
                                    <PISectionValidation>Required</PISectionValidation>
                                  )}
                                </div>
                              </PISectionItem>
                              {/* City */}
                              <PISectionItem>
                                <PISectionTitle>City</PISectionTitle>
                                <PISectionInput
                                  type="text"
                                  disabled={selectedPatientChangesDisabled}
                                  value={selectedPatient?.city}
                                  onChange={(e) =>
                                    setSelectedPatient((prev) => ({
                                      ...prev,
                                      city: e.target.value,
                                    }))
                                  }
                                />
                              </PISectionItem>
                              {/* Pincode */}
                              <PISectionItem>
                                <PISectionTitle>Pincode</PISectionTitle>
                                <PISectionInput
                                  type="number"
                                  disabled={selectedPatientChangesDisabled}
                                  value={selectedPatient?.pincode}
                                  onChange={(e) =>
                                    setSelectedPatient((prev) => ({
                                      ...prev,
                                      pincode: e.target.value,
                                    }))
                                  }
                                />
                              </PISectionItem>
                              {/* Note */}
                              <PISectionItem>
                                <PISectionTitle>Note</PISectionTitle>
                                <PISectionInput
                                  type="text"
                                  disabled={selectedPatientChangesDisabled}
                                  value={selectedPatient?.note}
                                  onChange={(e) =>
                                    setSelectedPatient((prev) => ({
                                      ...prev,
                                      note: e.target.value,
                                    }))
                                  }
                                />
                              </PISectionItem>
                              {/* Referred By */}
                              <PISectionItem>
                                <PISectionTitle>Referred By</PISectionTitle>
                                <PISectionInput
                                  type="text"
                                  disabled={selectedPatientChangesDisabled}
                                  value={selectedPatient?.referredBy}
                                  onChange={(e) =>
                                    setSelectedPatient((prev) => ({
                                      ...prev,
                                      referredBy: e.target.value,
                                    }))
                                  }
                                />
                              </PISectionItem>
                            </PISection>
                          </>
                        )}
                      </PIParent>

                      {selectedPatientChangesDisabled && (
                        <>
                          <hr />
                          {bookingLoading ? (
                            <Spinner animation="border" variant="dark" size="md" />
                          ) : (
                            <div
                              style={{ display: 'flex', gap: '16px', flexDirection: 'row-reverse' }}
                            >
                              <MuiButton
                                sx={{ marginBottom: '8px', display: 'block' }}
                                variant="contained"
                                disabled={(() => {
                                  let toReturn = false
                                  if (askPayment) {
                                    if (paymentDetail?.feePaid && paymentDetail?.feePaymentMode) {
                                      if (paymentDetail?.feePaymentMode === 'cash') {
                                        toReturn = false
                                      } else if (paymentDetail?.feePaymentMode !== 'cash') {
                                        toReturn = false
                                      } else {
                                        toReturn = true
                                      }
                                    } else {
                                      toReturn = true
                                    }
                                  } else {
                                    toReturn = false
                                  }
                                  if (
                                    eventDataForSelectedEventType?.type ===
                                      allAvailableEventTypes.consultation &&
                                    !forMentor
                                  ) {
                                    if (!tryBookingScreening) {
                                      if (!reasonForNotDoingScreening) {
                                        toReturn = true
                                      }
                                    }
                                  }

                                  return toReturn
                                })()}
                                onClick={bookNowHandler}
                              >
                                Book Now
                              </MuiButton>
                              {forMentor && (
                                <MuiButton
                                  sx={{ marginBottom: '8px', display: 'block' }}
                                  variant="outlined"
                                  disabled={(() => {
                                    if (askPayment) {
                                      if (paymentDetail?.feePaid && paymentDetail?.feePaymentMode) {
                                        if (paymentDetail?.feePaymentMode === 'cash') {
                                          return false
                                        } else if (paymentDetail?.feePaymentMode !== 'cash') {
                                          return false
                                        } else {
                                          return true
                                        }
                                      } else {
                                        return true
                                      }
                                    } else {
                                      return false
                                    }
                                  })()}
                                  onClick={() => {
                                    makeBookingInDayViewCalendar(
                                      forMentor,
                                      clinicId,
                                      clinicBrandId,
                                      mentorProfileData?.id,
                                      askPayment,
                                      clickedWantedSlotData,
                                      eventDataForSelectedEventType,
                                      selectedPatient,
                                      updateBookingsInCalendar,
                                      setNewBookingDialogOpen,
                                      {
                                        feePaid: paymentDetail?.feePaid,
                                        feePaymentMode: paymentDetail?.feePaymentMode,
                                        feePaidTxnId: paymentDetail?.feePaidTxnId,
                                        currency: paymentDetail?.currency,
                                      },
                                      setBookingLoading,
                                      setSelectedPatient,
                                      setIsNewPatient,
                                      setNewPatientInputData,
                                      chosenChamberRoomId,
                                      tryBookingScreening,
                                      true,
                                      null,
                                      null,
                                      selectedRelatedConsultationForTherapy,
                                    )
                                  }}
                                >
                                  Hold Slot & send Link to Pay
                                </MuiButton>
                              )}
                              {forMentor &&
                                PAY_IN_FUTURE_ALLOWED_MENTOR_IDS.includes(
                                  mentorProfileData?.id,
                                ) && (
                                  <MuiButton
                                    sx={{ marginBottom: '8px', display: 'block' }}
                                    variant="outlined"
                                    disabled={false}
                                    onClick={() => {
                                      makeBookingInDayViewCalendar(
                                        forMentor,
                                        clinicId,
                                        clinicBrandId,
                                        mentorProfileData?.id,
                                        askPayment,
                                        clickedWantedSlotData,
                                        eventDataForSelectedEventType,
                                        selectedPatient,
                                        updateBookingsInCalendar,
                                        setNewBookingDialogOpen,
                                        {
                                          feePaid: paymentDetail?.feePaid,
                                          feePaymentMode: paymentDetail?.feePaymentMode,
                                          feePaidTxnId: paymentDetail?.feePaidTxnId,
                                          currency: paymentDetail?.currency,
                                        },
                                        setBookingLoading,
                                        setSelectedPatient,
                                        setIsNewPatient,
                                        setNewPatientInputData,
                                        chosenChamberRoomId,
                                        tryBookingScreening,
                                        false,
                                        null,
                                        'payInFuture',
                                        selectedRelatedConsultationForTherapy,
                                      )
                                    }}
                                  >
                                    Auto Deduct Credits in Future (Beta)
                                  </MuiButton>
                                )}
                            </div>
                          )}
                          {true && (
                            <div>
                              {/* {chamberRoomsForMentorAssistantClinicLocation?.length > 0 &&
                                eventDataForSelectedEventType?.meetingSrc === 'offline' && (
                                  <>
                                    <div style={{ marginTop: '5px' }}>Chamber Room</div>
                                    <EDVCBookNewSlotSelect
                                      value={chosenChamberRoomId}
                                      onChange={(e) => {
                                        setChosenChamberRoomId(e.target.value.id)
                                      }}
                                    >
                                      {chamberRoomsForMentorAssistantClinicLocation?.map(
                                        (room, roomIndex) => (
                                          <option value={room.id} key={roomIndex}>
                                            {room?.title || room?.id}
                                          </option>
                                        ),
                                      )}
                                    </EDVCBookNewSlotSelect>
                                  </>
                                )} */}
                              {eventDataForSelectedEventType?.type ===
                                allAvailableEventTypes.therapySession && (
                                <>
                                  {relatedConsultationsLoading ? (
                                    <Spinner size="sm" variant="dark" />
                                  ) : (
                                    <>
                                      <div>Select related consultation:</div>
                                      <div style={{ fontSize: '12px' }}>
                                        (Which doctor recommended therapy, and in which session?)
                                      </div>
                                      <select
                                        value={selectedRelatedConsultationForTherapy}
                                        style={{
                                          border: '1px solid #cecece',
                                          borderRadius: '4px',
                                        }}
                                        onChange={(e) =>
                                          setSelectedRelatedConsultationForTherapy(e.target.value)
                                        }
                                      >
                                        <option value={''}>None/Other</option>
                                        {pastConsultationsOfPatient?.map(
                                          (pastConsultation, pastConsultationIdx) => {
                                            return (
                                              <option
                                                key={pastConsultationIdx}
                                                value={pastConsultation?.id}
                                              >
                                                {pastConsultation?.mentorName} (
                                                {giveTodayYesterdayElseFormattedStringPerLocal(
                                                  pastConsultation?.startTime,
                                                  null,
                                                  true,
                                                )}
                                                )
                                              </option>
                                            )
                                          },
                                        )}
                                      </select>
                                    </>
                                  )}
                                </>
                              )}
                              <>
                                <div className="d-flex gap-2">
                                  <div className="w-100">
                                    <div style={{ marginTop: '5px' }}>
                                      Fee Collected (Default:{' '}
                                      {(planDataForSelectedEventId?.currency || '')?.toUpperCase()}{' '}
                                      {planDataForSelectedEventId?.finalPrice})
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                      <EDVCBookNewSlotSelect
                                        style={{ width: '70px', borderRadius: '4px 0 0 4px' }}
                                        value={paymentDetail?.currency}
                                        onChange={(e) =>
                                          setPaymentDetail((prev) => ({
                                            ...prev,
                                            currency: e.target.value,
                                          }))
                                        }
                                      >
                                        {currency_list?.map((currencyData, currencyDataIndex) => (
                                          <option value={currencyData.code} key={currencyDataIndex}>
                                            {currencyData.code?.toUpperCase()}
                                          </option>
                                        ))}
                                      </EDVCBookNewSlotSelect>
                                      <PISectionInput
                                        style={{ borderRadius: '0 4px 4px 0' }}
                                        value={paymentDetail?.feePaid}
                                        onChange={(e) =>
                                          setPaymentDetail((prev) => ({
                                            ...prev,
                                            feePaid: e.target.value,
                                          }))
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="w-100">
                                    <>
                                      <div style={{ marginTop: '5px' }}>Payment Mode</div>
                                      <EDVCBookNewSlotSelect
                                        value={paymentDetail?.feePaymentMode}
                                        onChange={(e) =>
                                          setPaymentDetail((prev) => ({
                                            ...prev,
                                            feePaymentMode: e.target.value,
                                          }))
                                        }
                                      >
                                        {OFFLINE_PAYMENT_METHODS_LIST?.map(
                                          (paymentMethod, paymentMethodIndex) => (
                                            <option
                                              value={paymentMethod.id}
                                              key={paymentMethodIndex}
                                            >
                                              {paymentMethod.title}
                                            </option>
                                          ),
                                        )}
                                      </EDVCBookNewSlotSelect>
                                    </>
                                  </div>
                                </div>
                                <div className="w-100">
                                  {paymentDetail?.feePaymentMode !== 'cash' && (
                                    <>
                                      <div style={{ marginTop: '5px' }}>Transaction ID</div>
                                      <PISectionInput
                                        value={paymentDetail?.feePaidTxnId}
                                        onChange={(e) =>
                                          setPaymentDetail((prev) => ({
                                            ...prev,
                                            feePaidTxnId: e.target.value,
                                          }))
                                        }
                                      />
                                    </>
                                  )}
                                </div>
                              </>
                            </div>
                          )}
                          {(eventDataForSelectedEventType?.type ===
                            allAvailableEventTypes.consultation ||
                            eventDataForSelectedEventType?.type ===
                              allAvailableEventTypes.therapySession) &&
                            !forMentor && (
                              <div className="d-flex flex-column w-100">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={printReceipt}
                                      onChange={(e) => {
                                        setPrintReceipt(e.target.checked)
                                        sessionStorage.setItem('printReceipt', e.target.checked)
                                      }}
                                    />
                                  }
                                  label="Print Receipt for this booking"
                                />
                              </div>
                            )}
                          {eventDataForSelectedEventType?.type ===
                            allAvailableEventTypes.consultation &&
                            !forMentor && (
                              <div className="d-flex flex-column w-100">
                                <div className="d-flex align-items-center w-100">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={tryBookingScreening}
                                        onChange={(e) => {
                                          setTryBookingScreening(e.target.checked)
                                        }}
                                        icon={<CancelIcon style={{ color: 'red' }} />}
                                        checkedIcon={
                                          screenerAvailable ? (
                                            <CheckCircleIcon style={{ color: 'green' }} />
                                          ) : (
                                            <Error sx={{ color: '#ffaa09' }} />
                                          )
                                        }
                                      />
                                    }
                                    color="secondary"
                                    label="Book with Screening"
                                  />
                                  <AvailableScreenerComponent
                                    eventBeingBooked={{
                                      apiParam: 'booked-event-info',
                                      mentorId: mentorProfileData?.id,
                                      userId: '',
                                      startTime: moment
                                        ?.utc(clickedWantedSlotData?.startTime)
                                        ?.format('YYYY-MM-DD HH:mm:ss'),
                                      endTime: moment
                                        ?.utc(clickedWantedSlotData?.endTime)
                                        ?.format('YYYY-MM-DD HH:mm:ss'),
                                      eventType: eventDataForSelectedEventType?.type,
                                      chamberRoomId: chosenChamberRoomId,
                                      eventTypeId: eventDataForSelectedEventType?.uid,
                                      subscriptionPlanId:
                                        eventDataForSelectedEventType?.subscriptionPlanId,
                                      patientId: selectedPatient?.id,
                                      id: '',
                                      feePaid: askPayment ? paymentDetail?.feePaid : '',
                                      currency: paymentDetail?.currency || 'inr',
                                      feePaymentMode: askPayment
                                        ? paymentDetail?.feePaymentMode
                                        : '',
                                      feePaidTxnId: askPayment ? paymentDetail?.feePaidTxnId : '',
                                      clinicId: clinicId || '',
                                      clinicBrandId: clinicBrandId || '',
                                    }}
                                    setScreenerAvailable={setScreenerAvailable}
                                  />
                                </div>
                                {!tryBookingScreening && (
                                  <NewBookingDialogReasonForNotDoingScreeningForm
                                    reasonForNotBooking={reasonForNotDoingScreening}
                                    setReasonIdForNotDoingScreening={
                                      setReasonIdForNotDoingScreening
                                    }
                                    setReasonForNotBooking={setReasonForNotDoingScreening}
                                    screenerAvailable={screenerAvailable}
                                    reasonForNotBookingScreeningId={reasonIdForNotDoingScreening}
                                  />
                                )}
                              </div>
                            )}
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <div
                  style={{
                    fontWeight: 500,
                    textAlign: 'center',
                    color: '#b73c30',
                    marginBottom: '20px',
                  }}
                >
                  Not enough time to book this event
                </div>
              )}
            </>
          ) : (
            <div style={{ fontWeight: 700, textAlign: 'center', color: '#b73c30' }}>
              The event needs to be associated with a clinic for booking slots from here.
            </div>
          )}
        </EDVCNewBookingContainer>
      </BootstrapDialog>
    </>
  )
}

export const EditBookingDialogComponent = ({
  open,
  forMentor,
  bookedEvent,
  closeFunction,
  callbackFunctionAfterSave,
}) => {
  const PAGE_STATE_PIN = 'pin'
  const PAGE_STATE_EDIT = 'edit'

  const [pageState, setPageState] = useState(PAGE_STATE_PIN)
  const [newFee, setNewFee] = useState(bookedEvent?.feePaid || 0)
  const [updating, setUpdating] = useState(false)

  async function updateNow(newStatus) {
    const dataToSubmit = {
      apiParam: 'booked-event-info',
      id: bookedEvent?.id,
      feePaid: newFee,
    }
    if (newStatus) {
      dataToSubmit['status'] = newStatus
    }
    setUpdating(true)
    const response = await putCPDataWithoutParams(`/api/secure/meeting/edit`, dataToSubmit)
    setUpdating(false)
    if (response?.status === 200 && isCommonResponseSuccessfulV2(response)) {
      toast.success('Booking updated')
      callbackFunctionAfterSave()
    } else {
      toast.error('Error updating booking')
    }
  }

  return (
    <>
      <BootstrapDialog
        onClose={closeFunction}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperComponent={BootStrapDialogPaperComponent}
        sx={{ width: 'auto', margin: 'auto' }}
      >
        <IconButton
          aria-label="close"
          onClick={() => {
            closeFunction()
          }}
          sx={{
            position: 'absolute',
            top: '0px',
            right: '0px',
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '8px',
          }}
        >
          Edit Booking
        </DialogTitle>

        <div style={{ padding: '8px' }}>
          <div>
            <b>Editing for</b> {bookedEvent?.userName}
          </div>
        </div>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minWidth: '400px' }}
        >
          {pageState === PAGE_STATE_PIN && (
            <PinEntryVarOne
              verifyPinWithApi={true}
              onSuccessFunc={() => {
                setPageState(PAGE_STATE_EDIT)
              }}
            />
          )}
          {pageState === PAGE_STATE_EDIT && (
            <div className="w-100" style={{ padding: '8px' }}>
              <div className="d-flex justify-content-center align-items-center">
                <span style={{ minWidth: '120px' }}>New Fee:</span>
                <span>
                  {currency_list?.find((item) => item?.code === bookedEvent?.currency)?.symbol}
                </span>
                <input
                  style={{
                    border: '1px solid #cecece',
                    borderRadius: '4px',
                    padding: '4px',
                    width: '80px',
                  }}
                  value={newFee}
                  onChange={(e) => setNewFee(e.target.value)}
                />
              </div>
              <Button
                color="emrPrimary"
                variant="contained"
                onClick={updateNow}
                style={{ margin: '16px 0' }}
                disabled={updating}
              >
                <span style={{ marginRight: '8px' }}>Update</span>
              </Button>
              <Button
                color="error"
                variant="contained"
                onClick={() => updateNow('cancelled')}
                style={{ margin: '16px' }}
                disabled={updating}
              >
                <span style={{ marginRight: '8px' }}>Cancel</span>
              </Button>
              {updating && <Spinner size="sm" variant="dark" />}
            </div>
          )}
        </div>
      </BootstrapDialog>
    </>
  )
}

export const EditPatientDialogComponent = ({
  dialogTitle,
  open,
  handleCloseDialog,
  patientId,
  clinicId,
  clinicBrandId,
  mentorProfileData,
}) => {
  return (
    <>
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperComponent={BootStrapDialogPaperComponent}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {dialogTitle}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <PatientForm
          newPatient={false}
          patientId={patientId}
          showSeparateDialogActions={true}
          clinicId={clinicId}
          clinicBrandId={clinicBrandId}
          mentorProfileData={mentorProfileData}
        />
        <div className="w-100" style={{ padding: '0 12px' }}>
          <PastBookingsHorizontalComponent patientId={patientId} clinicId={clinicId} />
        </div>
      </BootstrapDialog>
    </>
  )
}

export const PendingActionsDCDialogComponent = ({
  open,
  handleCloseDialog,
  thisBookedSlotData,
  pendingActionsOfThisPatient,
  refreshPendingActions,

  patientId,
  clinicId,
  professionalDataForNextVisit,
  scheduleForNextVisit,
  eventDataForNextVisit,

  allProfessionalsDataForAssistant,
  selectedClinic,
}) => {
  const thisBookingPendingActions = pendingActionsOfThisPatient?.filter(
    (item) => item?.bookedEventId === thisBookedSlotData?.id,
  )
  const otherBookingPendingActions = pendingActionsOfThisPatient?.filter(
    (item) => item?.bookedEventId !== thisBookedSlotData?.id,
  )

  return (
    <>
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperComponent={BootStrapDialogPaperComponent}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Pending Actions for {thisBookedSlotData?.userName}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <div style={{ padding: '0 20px 20px', width: '700px' }}>
          <hr />
          <h6>This Booking&apos;s pending actions:</h6>
          <div>
            <b>{thisBookingPendingActions?.length === 0 && 'No pending actions'}</b>
          </div>
          {thisBookingPendingActions?.map((thisBookingActionItem, thisBookingActionItemIndex) => {
            return (
              <div
                key={thisBookingActionItemIndex}
                style={{ border: '1px solid #CECECE', borderRadius: '5px', marginBottom: '5px' }}
              >
                <RenderAction
                  actionItem={thisBookingActionItem}
                  actionItemIndex={thisBookingActionItemIndex}
                  refreshPendingActions={refreshPendingActions}
                  patientId={patientId}
                  clinicId={clinicId}
                  eventIdForNextBooking={eventDataForNextVisit?.uid}
                  eventDataForNextBooking={eventDataForNextVisit}
                  scheduleForThisEventNextBooking={scheduleForNextVisit}
                  professionalDataForNextVisit={professionalDataForNextVisit}
                  allProfessionalsDataForAssistant={allProfessionalsDataForAssistant}
                  selectedClinic={selectedClinic}
                />
              </div>
            )
          })}

          <hr />
          <h6>Other Bookings&apos; pending actions:</h6>
          <div>
            <b>{otherBookingPendingActions?.length === 0 && 'No pending actions'}</b>
          </div>
          {otherBookingPendingActions?.map(
            (otherBookingActionItem, otherBookingActionItemIndex) => {
              return (
                <div
                  key={otherBookingActionItemIndex}
                  style={{ border: '1px solid #CECECE', borderRadius: '5px', marginBottom: '5px' }}
                >
                  <RenderAction
                    actionItem={otherBookingActionItem}
                    actionItemIndex={otherBookingActionItemIndex}
                    refreshPendingActions={refreshPendingActions}
                    patientId={patientId}
                    clinicId={clinicId}
                    eventIdForNextBooking={eventDataForNextVisit?.uid}
                    eventDataForNextBooking={eventDataForNextVisit}
                    scheduleForThisEventNextBooking={scheduleForNextVisit}
                    professionalDataForNextVisit={professionalDataForNextVisit}
                    allProfessionalsDataForAssistant={allProfessionalsDataForAssistant}
                    selectedClinic={selectedClinic}
                  />
                </div>
              )
            },
          )}
        </div>
      </BootstrapDialog>
    </>
  )
}

export const PatientForm = ({
  newPatient,
  patientId,
  showSeparateDialogActions,
  clinicId,
  clinicBrandId,
  mentorProfileData,
}) => {
  const [patientDetailsLocal, setPatientDetailsLocal] = useState({ ...BLANK_PATIENT_DATA })
  const [loading, setLoading] = useState(false)
  const [editMode, setEditMode] = useState(false)

  async function getAndSetExistingPatientDetails() {
    setLoading(true)
    const response = await getCPDataWithoutParams(
      `/api/secure/patient/list?patientId=${patientId}&clinicId=${clinicId}`,
    )
    if (response.status === 200 && response?.data?.[0]) {
      const receivedPatientData = response.data[0]
      let age =
        receivedPatientData?.dateOfBirth && getAgeFromDobWithTime(receivedPatientData?.dateOfBirth)

      setPatientDetailsLocal((prev) => {
        let dataHere = {
          ...prev,
          patientNumber: receivedPatientData?.patientNumber, // added 22 jan - hw because appointment number was getting removed on edit
          name: receivedPatientData?.name ?? prev.name,
          email: receivedPatientData?.email ?? prev.email,
          mobile:
            removePlusFromBeginning(receivedPatientData?.mobile) ??
            removePlusFromBeginning(prev.mobile),
          gender: receivedPatientData?.gender ?? prev.gender,
          age: age ?? prev.age,
          note: receivedPatientData?.note ?? prev.note,
          referredBy: receivedPatientData?.referredBy ?? prev.referredBy,
          address: receivedPatientData?.location?.address ?? prev.address,
          pincode: receivedPatientData?.location?.pincode ?? prev.pincode,
          city: receivedPatientData?.location?.city ?? prev.city,
        }

        if (
          !Array.isArray(receivedPatientData?.attendants) ||
          receivedPatientData?.attendants?.length === 0
        ) {
          dataHere['attendants'] = [
            {
              name: '',
              primary: true,
              type: 'relative',
            },
          ]
        } else {
          dataHere['attendants'] = receivedPatientData.attendants
        }

        if (!receivedPatientData?.communicationsToAttendant) {
          dataHere['communicationToAttendant'] = true
        }

        return dataHere
      })
    } else {
      toast.error("Couldn't find patient details")
    }
    setLoading(false)
  }

  async function handleEditPatientDetails() {
    const dataToSubmit = {
      apiParam: 'PatientInfo',
      id: patientId,
      clinicId: clinicId,
      clinicBrandId: clinicBrandId,
      mentorId: mentorProfileData?.id,
      name: patientDetailsLocal?.name,
      email: patientDetailsLocal?.email,
      mobile: patientDetailsLocal?.mobile && `+${patientDetailsLocal?.mobile}`,
      dateOfBirth: getDOBWithTime(patientDetailsLocal?.age),
      gender: patientDetailsLocal?.gender,
      note: patientDetailsLocal?.note,
      patientNumber: patientDetailsLocal?.patientNumber,
      referredBy: patientDetailsLocal?.referredBy,
      location: {
        address: patientDetailsLocal?.address,
        pincode: patientDetailsLocal?.pincode,
        city: patientDetailsLocal?.city,
      },
      attendants: cleanAttendantsArray(patientDetailsLocal?.attendants),
      communicationToAttendant: patientDetailsLocal?.communicationToAttendant,
    }
    setLoading(true)
    let response = await postCPDataWithoutParams('/api/secure/patient/save', dataToSubmit)
    if (response.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
      addOrUpdatePatientsInIdb([response?.data?.data?.patient])
      toast.success('Patient Details updated')
    } else {
      toast.error('Error creating new patient')
    }
    setLoading(false)
  }

  async function setup() {
    getAndSetExistingPatientDetails()
  }

  useEffect(() => {
    setup()
  }, [])

  return (
    <>
      {showSeparateDialogActions && (
        <DialogActions>
          {!newPatient && (
            <MuiButton autoFocus onClick={() => setEditMode((prev) => !prev)}>
              {editMode ? 'View Mode' : 'Edit'}
            </MuiButton>
          )}
          {!newPatient && editMode && (
            <MuiButton
              color="success"
              autoFocus
              onClick={handleEditPatientDetails}
              disabled={
                !(
                  Boolean(patientDetailsLocal?.name) &&
                  (validateEmail(patientDetailsLocal?.email) ||
                    isValidPhoneNumber(`+${patientDetailsLocal?.mobile}`)) &&
                  Boolean(patientDetailsLocal?.gender) &&
                  Boolean(patientDetailsLocal?.age)
                ) || loading
              }
            >
              Save changes
            </MuiButton>
          )}
        </DialogActions>
      )}
      <DialogContent dividers>
        <PIParent>
          <PISection>
            {/* Name */}
            <PISectionItem>
              <PISectionTitle>Name*</PISectionTitle>
              <PISectionInput
                disabled={!editMode}
                type="text"
                value={patientDetailsLocal?.name}
                onChange={(e) =>
                  setPatientDetailsLocal((prev) => ({ ...prev, name: e.target.value }))
                }
              />
            </PISectionItem>
            {/* Gender */}
            <PISectionItem>
              <PISectionTitle>Gender*</PISectionTitle>
              <RectangleRadioButton
                disabled={!editMode}
                options={[
                  { label: 'Male', id: 'male' },
                  { label: 'Female', id: 'female' },
                  { label: 'Other', id: 'nonBinary' },
                ]}
                selectedId={patientDetailsLocal?.gender}
                selectionFunction={(id) =>
                  setPatientDetailsLocal((prev) => ({ ...prev, gender: id }))
                }
              />
            </PISectionItem>

            {/* Email */}
            <PISectionItem>
              <PISectionTitle>Email</PISectionTitle>
              <PISectionInput
                type="text"
                disabled={!editMode}
                value={patientDetailsLocal?.email}
                onChange={(e) =>
                  setPatientDetailsLocal((prev) => ({ ...prev, email: e.target.value.trim() }))
                }
              />
            </PISectionItem>
            {/* Address */}
            <PISectionItem>
              <PISectionTitle>Address</PISectionTitle>
              <PISectionInput
                type="text"
                disabled={!editMode}
                value={patientDetailsLocal?.address}
                onChange={(e) =>
                  setPatientDetailsLocal((prev) => ({ ...prev, address: e.target.value }))
                }
              />
            </PISectionItem>

            {/* Attendants */}
            <PISectionItem className="flex-column">
              <PISectionTitle className="d-flex gap-1" style={{ width: 'auto' }}>
                Attendants{' '}
                <AddCircleOutlineIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (editMode) {
                      setPatientDetailsLocal((prev) => ({
                        ...prev,
                        attendants: Array.isArray(prev?.attendants)
                          ? [
                              ...prev?.attendants,
                              {
                                name: '',
                                primary: false,
                                type: RELATIONSHIP_OPTIONS[0].value,
                              },
                            ]
                          : [
                              {
                                name: '',
                                primary: true,
                                type: RELATIONSHIP_OPTIONS[0].value,
                              },
                            ],
                      }))
                    }
                  }}
                />
              </PISectionTitle>
              {(patientDetailsLocal?.attendants || [])?.map((item, idx) => {
                return (
                  <div key={idx} className="d-flex">
                    <input
                      style={{ border: '1px solid #cecece', borderRadius: '4px' }}
                      value={item?.name || ''}
                      onChange={(e) => {
                        if (editMode) {
                          setPatientDetailsLocal((prev) => ({
                            ...prev,
                            attendants: prev?.attendants?.map((attendant, idxn) =>
                              idx === idxn ? { ...attendant, name: e.target.value } : attendant,
                            ),
                          }))
                        }
                      }}
                    />
                    <select
                      value={item?.type}
                      style={{
                        border: '1px solid #cecece',
                        borderRadius: '4px',
                        width: '80px',
                      }}
                      onChange={(e) => {
                        if (editMode) {
                          setPatientDetailsLocal((prev) => ({
                            ...prev,
                            attendants: prev?.attendants?.map((attendant, idxn) =>
                              idx === idxn ? { ...attendant, type: e.target.value } : attendant,
                            ),
                          }))
                        }
                      }}
                    >
                      {RELATIONSHIP_OPTIONS.map((item, index) => {
                        return (
                          <option key={index} value={item.value}>
                            {item.label}
                          </option>
                        )
                      })}
                    </select>
                    <DeleteOutlineIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (editMode) {
                          setPatientDetailsLocal((prev) => ({
                            ...prev,
                            attendants: prev?.attendants?.filter((_, index) => index !== idx),
                          }))
                        }
                      }}
                    />
                  </div>
                )
              })}
            </PISectionItem>

            {/* communicationToAttendant */}
            {Array.isArray(patientDetailsLocal?.attendants) &&
              patientDetailsLocal?.attendants?.length > 0 && (
                <PISectionItem className="flex-column">
                  <PISectionTitle
                    className="d-flex gap-1 flex-row align-items-center"
                    style={{ width: 'auto' }}
                  >
                    Communication to Attendant
                    <Checkbox
                      checked={!!patientDetailsLocal?.communicationToAttendant}
                      onChange={(event) => {
                        if (editMode) {
                          setPatientDetailsLocal((prev) => ({
                            ...prev,
                            communicationToAttendant: event.target.checked,
                          }))
                        }
                      }}
                      size="small"
                    />
                  </PISectionTitle>
                </PISectionItem>
              )}
          </PISection>
          <PISection>
            {/* Mobile */}
            <PISectionItem>
              <PISectionTitle>Mobile*</PISectionTitle>
              <EDVCPhoneInput
                disabled={!editMode}
                specialLabel=""
                placeholder="Mobile Number"
                country={localStorage.getItem('countryCode')?.toLowerCase() || 'in'}
                value={patientDetailsLocal?.mobile}
                onChange={(value) => setPatientDetailsLocal((prev) => ({ ...prev, mobile: value }))}
              />
            </PISectionItem>
            {/* Age */}
            <PISectionItem>
              <PISectionTitle>Age*</PISectionTitle>
              <PISectionInput
                disabled={!editMode}
                type="number"
                value={patientDetailsLocal?.age}
                onChange={(e) =>
                  setPatientDetailsLocal((prev) => ({ ...prev, age: e.target.value }))
                }
              />
            </PISectionItem>
            {/* City */}
            <PISectionItem>
              <PISectionTitle>City</PISectionTitle>
              <PISectionInput
                type="text"
                disabled={!editMode}
                value={patientDetailsLocal?.city}
                onChange={(e) =>
                  setPatientDetailsLocal((prev) => ({ ...prev, city: e.target.value }))
                }
              />
            </PISectionItem>
            {/* Pincode */}
            <PISectionItem>
              <PISectionTitle>Pincode</PISectionTitle>
              <PISectionInput
                type="number"
                disabled={!editMode}
                value={patientDetailsLocal?.pincode}
                onChange={(e) =>
                  setPatientDetailsLocal((prev) => ({ ...prev, pincode: e.target.value }))
                }
              />
            </PISectionItem>
            {/* Note */}
            <PISectionItem>
              <PISectionTitle>Note</PISectionTitle>
              <PISectionInput
                type="text"
                disabled={!editMode}
                value={patientDetailsLocal?.note}
                onChange={(e) =>
                  setPatientDetailsLocal((prev) => ({ ...prev, note: e.target.value }))
                }
              />
            </PISectionItem>
            {/* Referred By */}
            <PISectionItem>
              <PISectionTitle>Referred By</PISectionTitle>
              <PISectionInput
                type="text"
                disabled={!editMode}
                value={patientDetailsLocal?.referredBy}
                onChange={(e) =>
                  setPatientDetailsLocal((prev) => ({ ...prev, referredBy: e.target.value }))
                }
              />
            </PISectionItem>
          </PISection>
        </PIParent>
      </DialogContent>
    </>
  )
}

export const RectangleRadioButton = ({ options, selectedId, selectionFunction, disabled }) => {
  return (
    <RRBContainer>
      {options?.map((option) => (
        <RRBItem
          key={option?.id}
          selected={option?.id === selectedId}
          onClick={() => {
            if (!disabled) {
              selectionFunction(option?.id)
            }
          }}
        >
          {option?.label}
        </RRBItem>
      ))}
    </RRBContainer>
  )
}

export const RenderAction = ({
  actionItem,
  actionItemIndex,
  refreshPendingActions,

  patientId,
  clinicId,
  eventIdForNextBooking,
  eventDataForNextBooking,
  scheduleForThisEventNextBooking,
  professionalDataForNextVisit,

  allProfessionalsDataForAssistant,
  selectedClinic,
}) => {
  if (actionItem?.type === 'refund') {
    return (
      <PaymentAction
        actionItem={actionItem}
        actionItemIndex={actionItemIndex}
        refreshPendingActions={refreshPendingActions}
      />
    )
  } else if (actionItem?.type === 'schedule-next-visit') {
    return (
      <NextVisitAction
        actionItem={actionItem}
        actionItemIndex={actionItemIndex}
        refreshPendingActions={refreshPendingActions}
        patientId={patientId}
        clinicId={clinicId}
        eventIdForNextBooking={eventIdForNextBooking}
        eventDataForNextBooking={eventDataForNextBooking}
        scheduleForThisEventNextBooking={scheduleForThisEventNextBooking}
        professionalDataForNextVisit={professionalDataForNextVisit}
      />
    )
  } else if (actionItem?.type === 'schedule-therapy') {
    return (
      <ScheduleTherapyAction
        actionItem={actionItem}
        actionItemIndex={actionItemIndex}
        refreshPendingActions={refreshPendingActions}
        allProfessionalsDataForAssistant={allProfessionalsDataForAssistant}
        clinicId={clinicId}
        patientId={patientId}
        selectedClinic={selectedClinic}
      />
    )
  }
}

export const PaymentAction = ({ actionItem, actionItemIndex, refreshPendingActions }) => {
  const orignalPaidFee = actionItem?.actionQData?.data?.originalPrice
  const newFee = actionItem?.actionQData?.data?.discountedPrice
  const differenceAmountPaid = newFee - orignalPaidFee
  const [submitLoading, setSubmitLoading] = useState(false)

  const [apiSubmissionBody, setApiSubmissionBody] = useState({
    paymentMode: 'cash',
    currency: 'inr',
    feePaid: differenceAmountPaid,
    feePaymentMode: 'cash',
    feePaidTxnId: '',
    cashBreakUp: { 500: 0, 200: 0, 100: 0, 50: 0, 20: 0, 10: 0, 5: 0, 2: 0, 1: 0 },
  })

  async function handlePaymentActionCompleted() {
    setSubmitLoading(true)
    await putCPDataWithoutParams(
      `/api/secure/clinic-assistant/booked-event/discount-refund-complete?actionQId=${actionItem?.id}`,
      removeInvalidValuesCash(apiSubmissionBody),
    )
    await refreshPendingActions()
    setSubmitLoading(false)
  }

  const total =
    apiSubmissionBody?.cashBreakUp?.['500'] * 500 +
    apiSubmissionBody?.cashBreakUp?.['200'] * 200 +
    apiSubmissionBody?.cashBreakUp?.['100'] * 100 +
    apiSubmissionBody?.cashBreakUp?.['50'] * 50 +
    apiSubmissionBody?.cashBreakUp?.['20'] * 20 +
    apiSubmissionBody?.cashBreakUp?.['10'] * 10 +
    apiSubmissionBody?.cashBreakUp?.['5'] * 5 +
    apiSubmissionBody?.cashBreakUp?.['2'] * 2 +
    apiSubmissionBody?.cashBreakUp?.['1'] * 1

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px' }}>
      <div>
        <b>{actionItemIndex + 1}. Payment Action</b>
      </div>
      <div>
        Fee Paid: {actionItem?.actionQData?.data?.originalPrice} / New Fee:{' '}
        {actionItem?.actionQData?.data?.discountedPrice}
      </div>
      <div>
        <u>
          <span>{differenceAmountPaid < 0 ? 'Return Amount: ' : 'Collect Amount: '}</span>
          <span>{differenceAmountPaid < 0 ? differenceAmountPaid * -1 : differenceAmountPaid}</span>
        </u>
      </div>
      <div>
        <span>Payment Mode:</span>
        <span>
          <EDVCBookNewSlotSelect
            style={{ maxWidth: '200px', marginLeft: '5px', padding: '3px' }}
            value={apiSubmissionBody?.feePaymentMode}
            onChange={(e) =>
              setApiSubmissionBody((prev) => ({
                ...prev,
                paymentMode: e.target.value,
                feePaymentMode: e.target.value,
              }))
            }
          >
            {OFFLINE_PAYMENT_METHODS_LIST?.map((paymentMethod, paymentMethodIndex) => (
              <option value={paymentMethod.id} key={paymentMethodIndex}>
                {paymentMethod.title}
              </option>
            ))}
          </EDVCBookNewSlotSelect>
        </span>
      </div>
      {apiSubmissionBody?.feePaymentMode === 'cash' && (
        <div>
          <div>Enter Denomination: </div>

          {['500', '200', '100', '50', '20', '10', '5', '2', '1'].map((denomination) =>
            renderDenominationInput(
              denomination,
              apiSubmissionBody?.cashBreakUp?.[denomination],
              (newValue) =>
                setApiSubmissionBody((prev) => ({
                  ...prev,
                  cashBreakUp: { ...prev?.cashBreakUp, [denomination]: newValue },
                })),
            ),
          )}

          <GenericDisplayFlex>
            <CashDenominationText>Total: </CashDenominationText>
            <span
              style={{
                fontWeight: '500',
                color: `${
                  total === differenceAmountPaid || total === differenceAmountPaid * -1
                    ? 'green'
                    : 'red'
                }`,
              }}
            >
              {Boolean(total) ? total : ''}
            </span>
          </GenericDisplayFlex>
        </div>
      )}
      {apiSubmissionBody?.feePaymentMode !== 'cash' && (
        <div>
          <span>Transaction ID: </span>
          <CashDenominationInput
            style={{ width: '200px' }}
            value={apiSubmissionBody?.feePaidTxnId}
            onChange={(e) =>
              setApiSubmissionBody((prev) => ({ ...prev, feePaidTxnId: e.target.value }))
            }
          />
        </div>
      )}
      {submitLoading ? (
        <Spinner animation="border" variant="dark" size="sm" />
      ) : (
        <div style={{ display: 'flex', gap: '10px' }}>
          {/* <EDVCRectangleButton>Cancel</EDVCRectangleButton> */}
          <EDVCRectangleButton action onClick={handlePaymentActionCompleted}>
            Mark Action Completed
          </EDVCRectangleButton>
        </div>
      )}
    </div>
  )
}

export const NextVisitAction = ({
  actionItem,
  actionItemIndex,
  refreshPendingActions,
  patientId,
  clinicId,
  eventIdForNextBooking,
  eventDataForNextBooking,
  scheduleForThisEventNextBooking,
  professionalDataForNextVisit,
}) => {
  const [bookingSlotSelected, setBookingSlotSelected] = useState()
  const [planDataForSelectedEventId, setPlanDataForSelectedEventId] = useState()
  const [paymentDetail, setPaymentDetail] = useState({
    feePaid: undefined,
    feePaymentMode: 'cash',
    feePaidTxnId: '',
  })
  const [bookingLoading, setBookingLoading] = useState(false)

  useEffect(() => {
    ;(async () => {
      const eventDataForSelectedEventType = professionalDataForNextVisit?.eventTypes?.find(
        (eventType) => eventType?.uid === eventIdForNextBooking,
      )
      if (eventDataForSelectedEventType && !eventDataForSelectedEventType?.free) {
        const response = await getSubscriptionPlanById(
          eventDataForSelectedEventType?.subscriptionPlanId,
        )
        if (response.status === 200 && response?.data) {
          setPlanDataForSelectedEventId(response?.data)
        }
      } else {
        setPlanDataForSelectedEventId(null)
      }
    })()
  }, [])

  const askPayment = Boolean(
    Boolean(planDataForSelectedEventId?.finalPrice) && !eventDataForNextBooking?.free,
  )

  return (
    <div style={{ padding: '10px' }}>
      <div>
        <b>{actionItemIndex + 1}. Next Visit Action</b>
      </div>
      <div style={{ marginBottom: '20px' }}>
        Suggested Next Visit: {moment(actionItem?.actionQData?.nextVisitDate)?.format('DD-MM-YYYY')}
      </div>
      <GenericEventBookingComponent
        key={eventIdForNextBooking}
        eventId={eventIdForNextBooking}
        scheduleForThisEvent={scheduleForThisEventNextBooking}
        professionalData={professionalDataForNextVisit}
        getSelectionDetailFunction={(data) => setBookingSlotSelected(data)}
      />
      {bookingSlotSelected && (
        <div style={{ marginTop: '30px' }}>
          {askPayment && (
            <div>
              <div>Default Fee: {planDataForSelectedEventId?.finalPrice}</div>
              <>
                <div style={{ marginTop: '5px' }}>Fee Collected</div>
                <PISectionInput
                  value={paymentDetail?.feePaid}
                  onChange={(e) =>
                    setPaymentDetail((prev) => ({ ...prev, feePaid: e.target.value }))
                  }
                />
              </>
              <>
                <div style={{ marginTop: '5px' }}>Payment Mode</div>
                <EDVCBookNewSlotSelect
                  value={paymentDetail?.feePaymentMode}
                  onChange={(e) =>
                    setPaymentDetail((prev) => ({ ...prev, feePaymentMode: e.target.value }))
                  }
                >
                  {OFFLINE_PAYMENT_METHODS_LIST?.map((paymentMethod, paymentMethodIndex) => (
                    <option value={paymentMethod.id} key={paymentMethodIndex}>
                      {paymentMethod.title}
                    </option>
                  ))}
                </EDVCBookNewSlotSelect>
              </>
              {paymentDetail?.feePaymentMode !== 'cash' && (
                <>
                  <div style={{ marginTop: '5px' }}>Transaction ID</div>
                  <PISectionInput
                    value={paymentDetail?.feePaidTxnId}
                    onChange={(e) =>
                      setPaymentDetail((prev) => ({ ...prev, feePaidTxnId: e.target.value }))
                    }
                  />
                </>
              )}
            </div>
          )}

          {bookingLoading ? (
            <Spinner animation="border" variant="dark" size="md" />
          ) : (
            <MuiButton
              sx={{ marginTop: '10px', display: 'block' }}
              variant="contained"
              disabled={(() => {
                if (askPayment) {
                  if (paymentDetail?.feePaid && paymentDetail?.feePaymentMode) {
                    if (paymentDetail?.feePaymentMode === 'cash') {
                      return false
                    } else if (
                      paymentDetail?.feePaymentMode !== 'cash' &&
                      paymentDetail?.feePaidTxnId
                    ) {
                      return false
                    } else {
                      return true
                    }
                  } else {
                    return true
                  }
                } else {
                  return false
                }
              })()}
              onClick={() =>
                makeBookingAssistant(
                  askPayment,
                  professionalDataForNextVisit,
                  bookingSlotSelected,
                  eventDataForNextBooking,
                  patientId,
                  clinicId,
                  actionItem,
                  paymentDetail,
                  setBookingLoading,
                  refreshPendingActions,
                )
              }
            >
              Book Now
            </MuiButton>
          )}
        </div>
      )}
    </div>
  )
}

export const ScheduleTherapyAction = ({
  actionItem,
  actionItemIndex,
  refreshPendingActions,

  allProfessionalsDataForAssistant,
  clinicId,
  patientId,
  selectedClinic,
}) => {
  const [eventTypesVisible, setEventTypesVisible] = useState([])
  const [selectedEventTypeId, setSelectedEventTypeId] = useState()

  const [bookingSlotSelected, setBookingSlotSelected] = useState()
  const [planDataForSelectedEventId, setPlanDataForSelectedEventId] = useState()
  const [paymentDetail, setPaymentDetail] = useState({
    feePaid: undefined,
    feePaymentMode: 'cash',
    feePaidTxnId: '',
  })
  const [bookingLoading, setBookingLoading] = useState(false)

  const thisProfessionalData = allProfessionalsDataForAssistant?.find(
    (item) => item?.id === actionItem?.actionQData?.requestedTherapist?.id,
  )

  useEffect(() => {
    const arrayToInsert = []
    thisProfessionalData?.eventTypes?.forEach((eventTypeItem) => {
      if (eventTypeItem?.contextId === clinicId) {
        arrayToInsert.push(eventTypeItem)
      }
    })
    setEventTypesVisible(arrayToInsert)
    setSelectedEventTypeId(arrayToInsert?.[0]?.uid)
  }, [thisProfessionalData])

  const eventDataForSelectedEventType = eventTypesVisible?.find(
    (eventType) => eventType?.uid === selectedEventTypeId,
  )

  const scheduleForSelectedEventType = useMemo(() => {
    if (eventDataForSelectedEventType?.contextId === clinicId) {
      if (
        thisProfessionalData?.schedules?.some((schedule) =>
          schedule?.scopeSchedules?.includes(clinicId),
        )
      ) {
        const arrayOfAllSchedulesAssoicatedWithThisClinic = thisProfessionalData?.schedules?.filter(
          (schedule) => schedule?.scopeSchedules?.includes(clinicId),
        )
        const clinicAssociatedScheduleWithThisEventId =
          arrayOfAllSchedulesAssoicatedWithThisClinic?.find((schedule) =>
            schedule?.eventIds?.includes(clinicId),
          )
        if (clinicAssociatedScheduleWithThisEventId) {
          return clinicAssociatedScheduleWithThisEventId
        } else if (arrayOfAllSchedulesAssoicatedWithThisClinic?.length > 0) {
          return arrayOfAllSchedulesAssoicatedWithThisClinic?.[0]
        }
      } else {
        const defaultClinicSchedule = getDefaultScheduleFromSchedules(selectedClinic?.schedules)
        if (defaultClinicSchedule) {
          return defaultClinicSchedule
        }
      }
    } else {
      const scheduleWithRequestedEventId = thisProfessionalData?.schedules?.find((schedule) =>
        schedule?.eventIds?.includes(selectedEventTypeId),
      )
      if (scheduleWithRequestedEventId) {
        return scheduleWithRequestedEventId
      }
    }
  }, [thisProfessionalData, selectedEventTypeId])

  useEffect(() => {
    ;(async () => {
      const eventDataForSelectedEventType = thisProfessionalData?.eventTypes?.find(
        (eventType) => eventType?.uid === selectedEventTypeId,
      )
      if (eventDataForSelectedEventType && !eventDataForSelectedEventType?.free) {
        const response = await getSubscriptionPlanById(
          eventDataForSelectedEventType?.subscriptionPlanId,
        )
        if (response.status === 200) {
          setPlanDataForSelectedEventId(response?.data)
        }
      } else {
        setPlanDataForSelectedEventId(null)
      }
    })()
  }, [selectedEventTypeId])

  const askPayment = Boolean(
    Boolean(planDataForSelectedEventId?.finalPrice) && !eventDataForSelectedEventType?.free,
  )

  if (Boolean(thisProfessionalData)) {
    return (
      <div style={{ padding: '10px' }}>
        <div>
          <b>{actionItemIndex + 1}. Schedule Therapy Action</b>
        </div>
        <div style={{ marginTop: '20px' }}>With {thisProfessionalData?.name}</div>
        <div>Choose Event:</div>
        <EDVCEventsContainer>
          {eventTypesVisible?.map((eventTypeItem, eventTypeIndex) => {
            return (
              <NewEventTypeButton
                key={eventTypeIndex}
                selected={selectedEventTypeId === eventTypeItem?.uid}
                onClick={() => setSelectedEventTypeId(eventTypeItem?.uid)}
              >
                {eventTypeItem?.title}
              </NewEventTypeButton>
            )
          })}
        </EDVCEventsContainer>
        <GenericEventBookingComponent
          key={selectedEventTypeId}
          eventId={selectedEventTypeId}
          scheduleForThisEvent={scheduleForSelectedEventType}
          professionalData={thisProfessionalData}
          getSelectionDetailFunction={(data) => setBookingSlotSelected(data)}
        />
        {bookingSlotSelected && (
          <div style={{ marginTop: '30px' }}>
            {askPayment && (
              <div>
                <div>Default Fee: {planDataForSelectedEventId?.finalPrice}</div>
                <>
                  <div style={{ marginTop: '5px' }}>Fee Collected</div>
                  <PISectionInput
                    value={paymentDetail?.feePaid}
                    onChange={(e) =>
                      setPaymentDetail((prev) => ({ ...prev, feePaid: e.target.value }))
                    }
                  />
                </>
                <>
                  <div style={{ marginTop: '5px' }}>Payment Mode</div>
                  <EDVCBookNewSlotSelect
                    value={paymentDetail?.feePaymentMode}
                    onChange={(e) =>
                      setPaymentDetail((prev) => ({ ...prev, feePaymentMode: e.target.value }))
                    }
                  >
                    {OFFLINE_PAYMENT_METHODS_LIST?.map((paymentMethod, paymentMethodIndex) => (
                      <option value={paymentMethod.id} key={paymentMethodIndex}>
                        {paymentMethod.title}
                      </option>
                    ))}
                  </EDVCBookNewSlotSelect>
                </>
                {paymentDetail?.feePaymentMode !== 'cash' && (
                  <>
                    <div style={{ marginTop: '5px' }}>Transaction ID</div>
                    <PISectionInput
                      value={paymentDetail?.feePaidTxnId}
                      onChange={(e) =>
                        setPaymentDetail((prev) => ({ ...prev, feePaidTxnId: e.target.value }))
                      }
                    />
                  </>
                )}
              </div>
            )}
            {bookingLoading ? (
              <Spinner animation="border" variant="dark" size="md" />
            ) : (
              <MuiButton
                sx={{ marginTop: '10px', display: 'block' }}
                variant="contained"
                disabled={(() => {
                  if (askPayment) {
                    if (paymentDetail?.feePaid && paymentDetail?.feePaymentMode) {
                      if (paymentDetail?.feePaymentMode === 'cash') {
                        return false
                      } else if (
                        paymentDetail?.feePaymentMode !== 'cash' &&
                        paymentDetail?.feePaidTxnId
                      ) {
                        return false
                      } else {
                        return true
                      }
                    } else {
                      return true
                    }
                  } else {
                    return false
                  }
                })()}
                onClick={() =>
                  makeBookingAssistant(
                    askPayment,
                    thisProfessionalData,
                    bookingSlotSelected,
                    eventDataForSelectedEventType,
                    patientId,
                    clinicId,
                    actionItem,
                    paymentDetail,
                    setBookingLoading,
                    refreshPendingActions,
                  )
                }
              >
                Book Now
              </MuiButton>
            )}
          </div>
        )}
      </div>
    )
  } else {
    return (
      <div style={{ padding: '10px' }}>
        <div>
          <b>{actionItemIndex + 1}. Schedule Therapy Action</b>
        </div>
        <div>Error: No Mentor Data</div>
      </div>
    )
  }
}

export const renderDenominationInput = (denomination, value, onChange) => {
  return (
    <GenericDisplayFlex>
      <CashDenominationText>{`${parseInt(denomination)} x `}</CashDenominationText>
      <CashDenominationInput
        type="number"
        value={value}
        onChange={(e) => onChange(parseInt(e.target.value))}
      />
      <span>
        {' '}
        = {Boolean(value * parseFloat(denomination)) ? value * parseFloat(denomination) : ''}
      </span>
    </GenericDisplayFlex>
  )
}

export const MoreOptionsMenuForStrip = ({
  handleNewTherapyRequest,
  therapyBookingDataForThisEvent,
  bookedSlot,
  setClickedBookedSlotData,
  setEditPatientDialogOpen,
  forMentor,
  setAddVitalsDialogOpen,
  setEditBookingDialogOpen,
  setEventDataForReceiptPrinting,
  openReceiptDialog,
}) => {
  const [moreOptionsAnchor, setMoreOptionsAnchor] = useState(null)
  const open = Boolean(moreOptionsAnchor)
  const handleClick = (event) => {
    event.stopPropagation()
    setMoreOptionsAnchor(event.currentTarget)
  }
  const handleClose = () => {
    setMoreOptionsAnchor(null)
  }

  async function bookScreeningForThisBooking() {
    const response = await putCPDataWithoutParams(`/api/secure/meeting/screening/book`, {
      ...bookedSlot,
      apiParam: 'booked-event-info',
    })
    if (
      response?.status === 200 &&
      isCommonResponseSuccessful(response?.data?.code) &&
      response?.data?.data?.screening
    ) {
      toast.success(
        `Screening booked for ${response?.data?.data?.screening?.userName} with ${response?.data?.data?.screening?.mentorName}`,
      )
    } else {
      toast.error('Error booking screening')
    }
  }

  async function raiseReassignScreeningsRequest() {
    const response = await putCPDataWithoutParams(
      `/api/secure/meeting/screening/auto-reassign-eligible`,
      {
        ...bookedSlot,
        apiParam: 'booked-event-info',
      },
    )
    if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
      toast.success('Requested')
    } else {
      toast.error('Error')
    }
  }

  return (
    <div>
      <MoreVertIcon
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      />
      <Menu
        id="basic-menu"
        anchorEl={moreOptionsAnchor}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {(!Boolean(bookedSlot?.previousSteps) || bookedSlot?.previousSteps?.length === 0) && (
          <MenuItem
            onClick={() => {
              bookScreeningForThisBooking()
              handleClose()
            }}
          >
            Book Screening
          </MenuItem>
        )}
        {Boolean(bookedSlot?.previousSteps) &&
          bookedSlot?.previousSteps?.length === 1 &&
          bookedSlot?.previousSteps?.[0]?.status ===
            BOOKED_EVENT_STEP_STATUSES.HANDLER_NOT_ASSIGNED && (
            <MenuItem
              onClick={() => {
                raiseReassignScreeningsRequest()
                handleClose()
              }}
            >
              Request Reassign
            </MenuItem>
          )}
        {!forMentor && (
          <>
            <MenuItem
              onClick={() => {
                handleNewTherapyRequest(therapyBookingDataForThisEvent, bookedSlot)
                handleClose()
              }}
            >
              Book Therapy
            </MenuItem>
          </>
        )}

        <MenuItem
          onClick={() => {
            setClickedBookedSlotData(bookedSlot)
            setEditPatientDialogOpen(true)
            handleClose()
          }}
        >
          Patient Details
        </MenuItem>

        <MenuItem
          onClick={() => {
            setClickedBookedSlotData(bookedSlot)
            setAddVitalsDialogOpen(true)
            handleClose()
          }}
        >
          Add Vitals
        </MenuItem>
        <MenuItem
          onClick={() => {
            setClickedBookedSlotData(bookedSlot)
            setEditBookingDialogOpen(true)
            handleClose()
          }}
        >
          Edit Booking
        </MenuItem>
        {bookedSlot?.meetingSrc === SESSION_PLATFORM_ONLINE && (
          <MenuItem
            onClick={() => {
              navigator.clipboard.writeText(`${APP_CONSTANTS.LIVE_APP_URL}/l/${bookedSlot?.slug}`)
              toast.success('Video call link copied')
              handleClose()
            }}
          >
            Copy video call link
          </MenuItem>
        )}
      </Menu>
    </div>
  )
}

// helpers :

async function makeBookingInDayViewCalendar(
  forMentor,
  clinicId,
  clinicBrandId,
  teacherId,
  askPayment = false,
  timingData,
  eventDetails,
  patientDetails,
  updateBookingsInCalendar,
  setNewBookingDialogOpen,
  offlinePaymentDetails,
  setBookingLoading,
  setSelectedPatient,
  setIsNewPatient,
  setNewPatientInputData,
  chosenChamberRoomId,
  tryBookingScreening,
  holdSlotAndSendPaymentLink,
  reasonDataForNotDoingScreening,
  overridePaymentGateway,
  selectedRelatedConsultationForTherapy,
) {
  let paymentGateway
  if (askPayment) {
    if (holdSlotAndSendPaymentLink) {
      paymentGateway = 'paymentLinkInitiatedByMentor'
    } else {
      paymentGateway = 'paidOffline'
    }
  } else {
    paymentGateway = 'free'
  }
  if (overridePaymentGateway) {
    paymentGateway = overridePaymentGateway
  }

  const clientTransactionId = uuidv4()

  const dataToSubmit = {
    apiParam: 'booked-event-info',
    mentorId: teacherId,
    userId: '',
    startTime: moment?.utc(timingData?.startTime)?.format('YYYY-MM-DD HH:mm:ss'),
    endTime: moment?.utc(timingData?.endTime)?.format('YYYY-MM-DD HH:mm:ss'),
    eventType: eventDetails?.type,
    chamberRoomId: chosenChamberRoomId,
    eventTypeId: eventDetails?.uid,
    subscriptionPlanId: eventDetails?.subscriptionPlanId,
    patientId: patientDetails?.id,
    id: '',
    feePaid: askPayment ? offlinePaymentDetails?.feePaid : '',
    currency: offlinePaymentDetails?.currency || 'inr',
    feePaymentMode: askPayment ? offlinePaymentDetails?.feePaymentMode : '',
    feePaidTxnId: askPayment ? offlinePaymentDetails?.feePaidTxnId : '',
    clinicId: clinicId || '',
    clinicBrandId: clinicBrandId || '',
    bookingTime: moment()?.format(MOMENT_FORMATS.javaInstantFormat),
    screeningRequest: {
      requested: tryBookingScreening,
      reasonId: reasonDataForNotDoingScreening?.id || '',
      reason: reasonDataForNotDoingScreening?.title || '',
    },
  }

  setBookingLoading(true)
  let response
  let createdBookedEventData = null
  // distinction between clinic assistant api and mentor api

  if (forMentor) {
    response = await putCPDataWithParams(`/api/secure/meeting/book`, dataToSubmit, {
      teacherId: teacherId,
      paymentGateway: paymentGateway,
      clientTransactionId: clientTransactionId,
      platform: platformWeb,
      paymentMethod: 'upi',
      cancelUrl: encodeURIComponent(window.location.href),
      successUrl: encodeURIComponent(window.location.href),
    })
  } else {
    const params = {
      teacherId: teacherId,
      paymentGateway: paymentGateway,
      clientTransactionId: clientTransactionId,
      platform: platformWeb,
      paymentMethod: 'upi',
      cancelUrl: encodeURIComponent(window.location.href),
      successUrl: encodeURIComponent(window.location.href),
      bookScreeningAutomatically: tryBookingScreening,
      referenceConsultationId: selectedRelatedConsultationForTherapy,
    }
    response = await putCPDataWithParams(`/api/secure/meeting/book`, dataToSubmit, params)
  }
  setBookingLoading(false)
  if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
    toast.success('Booked session')
    setSelectedPatient(null)
    setIsNewPatient(false)
    setNewPatientInputData({ ...BLANK_PATIENT_DATA })
    updateBookingsInCalendar()
    setNewBookingDialogOpen(false)
    createdBookedEventData = getDataMapFromCommonResponse(response)?.bookedEvent
    if (response?.data?.data?.userPaymentLink?.paymentLink) {
      navigator.clipboard.writeText(response?.data?.data?.userPaymentLink?.paymentLink)
      toast.info('Payment Link sent to client')
      toast.info('Payment Link copied to clipboard')
    }
  } else {
    toast.error('Error Booking Session')
  }
  return createdBookedEventData
}

async function createNewPatient(
  clinicId,
  clinicBrandId,
  mentorProfileData,
  newPatientInputData,
  setNewPatientSubmissionLoading,
  forMentor,
  setSelectedPatient,
  setNewPatientInputData,
  setIsNewPatient,
  setRelatedToPatient,
  setRelatedPatientLoading,
) {
  console.log(newPatientInputData)
  let dataToSubmit = {
    apiParam: 'PatientInfo',
    clinicId: clinicId,
    clinicBrandId: clinicBrandId,
    mentorId: mentorProfileData?.id,
    name: newPatientInputData?.name,
    email: newPatientInputData?.email,
    mobile: newPatientInputData?.mobile ? `+${newPatientInputData?.mobile}` : null,
    dateOfBirth: getDOBWithTime(newPatientInputData?.age),
    gender: newPatientInputData?.gender,
    note: newPatientInputData?.note,
    referredBy: newPatientInputData?.referredBy,
    attendants: cleanAttendantsArray(newPatientInputData?.attendants),
    communicationToAttendant: newPatientInputData?.communicationToAttendant,
  }
  let addAddToRelatedPatientStateAfterCreation = null
  if (
    newPatientInputData?.dependentRelation &&
    newPatientInputData?.relatedToExistingPatient &&
    newPatientInputData?.relatedToExistingPatient?.id
  ) {
    dataToSubmit = {
      ...dataToSubmit,
      primaryPatientProfile: {
        apiParam: 'primary-patient-profile',
        patientId: newPatientInputData?.relatedToExistingPatient?.id,
        mobile: newPatientInputData?.relatedToExistingPatient?.mobile,
        email: newPatientInputData?.relatedToExistingPatient?.email,
        userId: newPatientInputData?.relatedToExistingPatient?.userId,
        dependentRelation: newPatientInputData?.dependentRelation,
      },
    }
    addAddToRelatedPatientStateAfterCreation = newPatientInputData?.relatedToExistingPatient?.id
  }
  setNewPatientSubmissionLoading(true)
  // distinction between clinic assistant api and mentor api
  let response
  if (forMentor) {
    response = await postCPDataWithoutParams('/api/secure/patient/new', dataToSubmit)
  } else {
    response = await postCPDataWithoutParams('/api/secure/patient/new', dataToSubmit)
  }
  if (response.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
    setSelectedPatient(response?.data?.data?.patient)
    setNewPatientInputData(BLANK_PATIENT_DATA)
    setIsNewPatient(false)
    if (addAddToRelatedPatientStateAfterCreation) {
      setRelatedToPatient(null)
      setRelatedPatientLoading(true)
      const responseTwo = await getCPDataWithoutParams(
        `/api/secure/patient/list?patientId=${addAddToRelatedPatientStateAfterCreation}&clinicId=${clinicId}`,
      )
      setRelatedPatientLoading(false)
      if (responseTwo.status === 200 && responseTwo?.data && responseTwo?.data?.[0]) {
        setRelatedToPatient(responseTwo?.data?.[0])
      } else {
        toast.error('Error finding related patient data. Contact support.')
      }
    }
  } else {
    toast.error('Error creating new patient')
  }
  setNewPatientSubmissionLoading(false)
}

export async function loadOptionsForExistingPatient(
  search,
  loadedOptions,
  {
    page,
    clinicId,
    clinicBrandId,
    setAutoFillStuffForNewPatientString,
    mentorAuthorisedToSaveAndGetPatientsUsingIdb,
  },
) {
  setAutoFillStuffForNewPatientString(search)

  var optionsArray = []
  if (mentorAuthorisedToSaveAndGetPatientsUsingIdb) {
    const patientsFromIdb = await searchPatientsInIdb(search)
    optionsArray = (patientsFromIdb || [])
      ?.filter((item) => item?.clinicBrandId === clinicBrandId || item?.clinicId === clinicId)
      ?.map((option, index) => {
        return {
          ...option,
          id: option?.id,
          name: option?.name || option?.firstName,
        }
      })
  }

  var hasMore = true

  if (optionsArray.length === 0) {
    // distinction between clinic assistant api and mentor api
    const response = await axios.get(
      `${clinicApiURL}/api/secure/patient/list?search=${search}&clinicId=${clinicId}&clinicBrandId=${clinicBrandId}&page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          ...commonHeadersCPWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    if (response.status === 200 && Array.isArray(response.data) && response.data.length > 0) {
      if (mentorAuthorisedToSaveAndGetPatientsUsingIdb) {
        addOrUpdatePatientsInIdb(response?.data || [])
      }
      optionsArray = response?.data?.map((option, index) => {
        return {
          ...option,
          id: option?.id,
          name: option?.name || option?.firstName,
        }
      })
    } else {
      hasMore = false
    }
  } else {
    hasMore = false
  }

  return {
    options: optionsArray,
    hasMore,
    additional: {
      page: page + 1,
      clinicId,
      setAutoFillStuffForNewPatientString,
    },
  }
}

export function getLabelForAlreadyPatientOption(option) {
  let label = option.name
  if (option?.dateOfBirth || option?.gender) {
    label += ' ('
  }
  if (option?.dateOfBirth) {
    label += getAgeFromDobWithTime(option?.dateOfBirth)
  }
  if (option?.dateOfBirth && option?.gender) {
    label += '/'
  }
  if (option?.gender) {
    label += GENDER_LIST_OFFLINE?.find((item) => item.value === option?.gender)?.label || '?'
  }
  if (option?.dateOfBirth || option?.gender) {
    label += ')'
  }
  if (option?.mobile) {
    label += `, ${option?.mobile}`
  }
  if (!option?.mobile && option?.email) {
    label += `, ${option?.email}`
  }
  return label
}

function identifyStringUseForAutoFill(inputString) {
  if (inputString && inputString.length > 0) {
    if (validateEmail(inputString)) {
      return { isValid: true, type: 'email' }
    } else if (validateOnlyNumbers(inputString)) {
      return { isValid: true, type: 'mobile' }
    } else if (validateName(inputString)) {
      return { isValid: true, type: 'name' }
    } else {
      return { isValid: false, type: '' }
    }
  } else {
    return { isValid: false, type: '' }
  }
}

async function handleUpdatePatientDetails(
  patientId,
  clinicId,
  clinicBrandId,
  mentorProfileData,
  patientDetailsLocal,
  setPatientUpdateLoading,
  setSelectedPatientChangesDisabled,
) {
  const dataToSubmit = {
    ...patientDetailsLocal,
    apiParam: 'PatientInfo',
    id: patientId,
    clinicId: clinicId,
    clinicBrandId: clinicBrandId,
    mentorId: mentorProfileData?.id,
    name: patientDetailsLocal?.name,
    email: patientDetailsLocal?.email,
    mobile:
      patientDetailsLocal?.mobile && `+${removePlusFromBeginning(patientDetailsLocal?.mobile)}`,
    dateOfBirth: getDOBWithTime(patientDetailsLocal?.age),
    gender: patientDetailsLocal?.gender,
    note: patientDetailsLocal?.note,
    referredBy: patientDetailsLocal?.referredBy,
    attendants: cleanAttendantsArray(patientDetailsLocal?.attendants),
    communicationToAttendant: patientDetailsLocal?.communicationToAttendant,
    location: {
      address: patientDetailsLocal?.address,
      pincode: patientDetailsLocal?.pincode,
      city: patientDetailsLocal?.city,
    },
  }
  setPatientUpdateLoading(true)
  let response = await postCPDataWithoutParams('/api/secure/patient/save', dataToSubmit)
  if (response.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
    addOrUpdatePatientsInIdb([response?.data?.data?.patient])
    toast.success('Patient Details updated')
  } else {
    toast.error('Error creating new patient')
  }
  setPatientUpdateLoading(false)
  setSelectedPatientChangesDisabled(true)
}

function removeInvalidValuesCash(apiSubmissionBody) {
  const updatedCashBreakUp = {}

  for (const denomination in apiSubmissionBody.cashBreakUp) {
    const value = apiSubmissionBody.cashBreakUp[denomination]
    updatedCashBreakUp[denomination] = typeof value === 'number' && !isNaN(value) ? value : 0
  }

  return {
    ...apiSubmissionBody,
    cashBreakUp: updatedCashBreakUp,
  }
}

async function makeBookingAssistant(
  askPayment,
  professionalData,
  bookingSlotSelected,
  eventData,
  patientId,
  clinicId,
  actionItem,
  paymentDetail,
  setBookingLoading,
  refreshPendingActions,
) {
  let paymentGateway
  if (askPayment) {
    paymentGateway = 'paidOffline'
  } else {
    paymentGateway = 'free'
  }
  const clientTransactionId = uuidv4()
  const dataToSubmit = {
    apiParam: 'booked-event-info',
    mentorId: professionalData?.id,
    userId: '',
    startTime: moment
      ?.utc(bookingSlotSelected?.timeSlotItem?.startTime)
      ?.add(bookingSlotSelected?.preBufferMins, 'minutes')
      ?.format('YYYY-MM-DD HH:mm:ss'),
    endTime: moment
      ?.utc(bookingSlotSelected?.timeSlotItem?.endTime)
      ?.subtract(bookingSlotSelected?.postBufferMins, 'minutes')
      ?.format('YYYY-MM-DD HH:mm:ss'),
    eventType: eventData?.type,
    eventTypeId: eventData?.uid,
    subscriptionPlanId: eventData?.subscriptionPlanId,
    patientId: patientId,
    id: '',
    feePaid: askPayment ? paymentDetail?.feePaid : '',
    currency: paymentDetail?.currency || 'inr',
    feePaymentMode: askPayment ? paymentDetail?.feePaymentMode : '',
    feePaidTxnId: askPayment ? paymentDetail?.feePaidTxnId : '',
    clinicId: clinicId || '',
  }
  setBookingLoading(true)
  let response
  // distinction between clinic assistant api and mentor api
  response = await putCPDataWithoutParams(
    `/api/secure/meeting/book?teacherId=${
      professionalData?.id
    }&paymentGateway=${paymentGateway}&clientTransactionId=${clientTransactionId}&platform=${platformWeb}&paymentMethod=upi&actionQId=${
      actionItem?.id
    }&cancelUrl=${encodeURIComponent(window.location.href)}&successUrl=${encodeURIComponent(
      window.location.href,
    )}`,
    dataToSubmit,
  )
  setBookingLoading(false)
  if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
    toast.success('Booked session')
    refreshPendingActions()
  } else {
    toast.error('Error Booking Session')
  }
}

export function getOverlapDataWithPreviousSlots(bookedSlotItem, bookedSlotsPerUtc) {
  // 1 part : RN only checking if there are any slots before this booked slot,
  // having same starttime and endtime as this slot, and then using adding duration in this slot
  // 2 part : the other thing is shifting of other slots, very basic case, we can shift all slots that are below instead of checking if they need to be shifted, doing this
  // 3 part: there will be slots that will need to be shifted as they are not same starttime, endtime, but there has already been an overlap and downshift above. so we will need to push these too
  // possible bug: exact same second bookings might not show up

  const allBookingsBeforeThisBooking = bookedSlotsPerUtc?.filter((item) =>
    moment(item?.createdTime)?.isBefore(bookedSlotItem?.createdTime),
  )

  const sameTimeOverlapsAsThisBooking = allBookingsBeforeThisBooking?.filter(
    (item) =>
      item?.startTime === bookedSlotItem?.startTime && item?.endTime === bookedSlotItem?.endTime,
  )?.length

  let minutesToAddToThisEvent = 0

  const countMap = new Map() // Map to store counts of items with same start and end times and Iterate through the array
  allBookingsBeforeThisBooking?.forEach((item) => {
    const key = `${item.startTime}_${item.endTime}` // Create a unique key using start and end times
    if (countMap.has(key)) {
      // If the key already exists, increment the count
      countMap.set(key, countMap.get(key) + 1)
    } else {
      // If the key doesn't exist, initialize the count to 1
      countMap.set(key, 1)
    }
  })
  countMap.forEach((value, key) => {
    let additionAmount = 0
    if (`${bookedSlotItem.startTime}_${bookedSlotItem.endTime}`) {
    } else {
      if (value > 1) {
        additionAmount = value - 1
      }
      minutesToAddToThisEvent += additionAmount * bookedSlotItem?.eventDuration
    }
  })

  if (sameTimeOverlapsAsThisBooking > 0) {
    minutesToAddToThisEvent += sameTimeOverlapsAsThisBooking * bookedSlotItem?.eventDuration
  }
  return { minutesToAddToThisEvent: minutesToAddToThisEvent }
}

export const customSortForDayCalendar = (a, b) => {
  // Check if both items have 'createdTime'
  if (a?.createdTime && b?.createdTime) {
    return moment(a?.createdTime).diff(moment(b?.createdTime))
  }
  // Check if both items have 'modifiedTime'
  else if (a?.modifiedTime && b?.modifiedTime) {
    return moment(a?.modifiedTime).diff(moment(b?.modifiedTime))
  }
  // If 'createdTime' or 'modifiedTime' is missing, sort based on 'appointmentNumber'
  else {
    return a?.appointmentNumber - b?.appointmentNumber
  }
}

const BookingRequestStrip = ({ bookingRequestId, cancelClickFunction, bookingRequestData }) => {
  return (
    <BookingRequestContainer>
      {/* looking status loader */}
      {bookingRequestData?.status === BOOKING_REQUEST_STATUS.BOOKING_INITIATED && (
        <div style={{ display: 'flex', gap: '8px' }}>
          <div>Looking for therapists in {bookingRequestData?.clinicLocationName}</div>
          <div className="loader"></div>
        </div>
      )}

      {/* patient details */}
      <div>
        Patient Details:{' '}
        <b>
          {bookingRequestData?.patientName || bookingRequestData?.patientId},{' '}
          {
            GENDER_LIST_OFFLINE?.find((item) => item?.value === bookingRequestData?.patientGender)
              ?.label
          }
          ,{' '}
          {moment()?.diff(
            moment.utc(bookingRequestData?.patientDateOfBirth, 'YYYY-MM-DD HH:mm:ss'),
            'years',
          )}
          {Array.isArray(bookingRequestData?.patientLanguages) &&
            bookingRequestData?.patientLanguages?.length > 0 &&
            `, speaks ${bookingRequestData?.patientLanguages?.join(', ')}`}
        </b>
      </div>

      {/* mentor */}
      <div>Suggested by {bookingRequestData?.suggestedByMentorName}</div>

      {/* started looking */}
      <div>
        Started Looking:{' '}
        {moment
          .utc(bookingRequestData?.createdTime, 'YYYY-MM-DD HH:mm:ss')
          ?.local()
          ?.format('DD MMM, h:mma')}
      </div>

      {/* session start time */}
      <div>
        Session Starts:{' '}
        {moment
          .utc(bookingRequestData?.startTime, 'YYYY-MM-DD HH:mm:ss')
          ?.local()
          ?.format('DD MMM, h:mma')}
      </div>

      {/* finalised fee data */}
      {bookingRequestData?.fee && bookingRequestData?.currency && (
        <div>
          Collect Fee:{' '}
          {(
            currency_list?.find((item) => item?.code === bookingRequestData?.currency)?.code || ''
          ).toUpperCase()}{' '}
          {bookingRequestData?.fee}
        </div>
      )}

      {/* status and action buttons */}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div></div>
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          {bookingRequestData?.status === BOOKING_REQUEST_STATUS.BOOKING_INITIATED && (
            <button
              style={{
                background: 'red',
                color: '#fff',
                padding: '4px 8px',
                borderRadius: '4px',
              }}
              onClick={() => cancelClickFunction(bookingRequestId)}
            >
              Cancel
            </button>
          )}

          {bookingRequestData?.status === BOOKING_REQUEST_STATUS.BOOKING_CANCELLED && (
            <div style={{ fontWeight: 600, color: 'red' }}>Cancelled</div>
          )}

          {bookingRequestData?.status === BOOKING_REQUEST_STATUS.BOOKING_EXPIRED && (
            <div style={{ fontWeight: 600, color: 'red' }}>Expired</div>
          )}

          {bookingRequestData?.status === BOOKING_REQUEST_STATUS.BOOKING_ACCEPTED && (
            <div style={{ fontWeight: 600, color: 'green' }}>
              Accepted by {bookingRequestData?.acceptedByName}
            </div>
          )}
          {bookingRequestData?.status === BOOKING_REQUEST_STATUS.BOOKING_BOOKED && (
            <div className="d-flex flex-column align-items-end">
              <div style={{ fontWeight: 600, color: 'green' }}>
                Booking created with {bookingRequestData?.acceptedByName}
              </div>
              <div>
                ({platformOptions.find((item) => item.id === bookingRequestData?.mode)?.name})
              </div>
            </div>
          )}
        </div>
      </div>
    </BookingRequestContainer>
  )
}

export const AllPastBookingRequestListSection = ({
  bookingRequestsData,
  clinicLocationData,
  bookingRequestsDataLoading,
  setFetchDataTriggerForBookingRequests,
}) => {
  const [dataLoading, setDataLoading] = useState(false)

  async function cancelBookingRequest(bookingRequestId) {
    setDataLoading(true)
    await cancelBookingRequestFuncForAssistant(bookingRequestId)
    setDataLoading(false)
    setFetchDataTriggerForBookingRequests((prev) => !prev)
  }

  const tooltipComp = (
    <Tooltip id="tooltip">
      <strong>Update Therapy Booking Requests</strong>
    </Tooltip>
  )

  useEffect(() => {
    const interval = setInterval(() => {
      setFetchDataTriggerForBookingRequests((prev) => !prev)
    }, 60000) // 1 minute

    // setFetchDataTriggerForBookingRequests((prev) => !prev) // already called outside - Call initially

    return () => clearInterval(interval) // Cleanup function to clear the interval
  }, [])

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '32px' }}>
        <div>
          <div>
            <b>All Therapy Booking Requests</b>
          </div>
          <div style={{ fontSize: '12px' }}>
            (where start time of session is after{' '}
            {moment()?.subtract(24, 'hours')?.format('DD-MM-YYYY h:mm A')})
          </div>
        </div>
        {dataLoading ? (
          <Spinner animation="border" variant="dark" size="sm" />
        ) : (
          <OverlayTrigger placement="bottom" overlay={tooltipComp}>
            <StyledEventsRefreshIcon
              onClick={() => setFetchDataTriggerForBookingRequests((prev) => !prev)}
            />
          </OverlayTrigger>
        )}
      </div>
      <BookingRequestItemsParent>
        {(bookingRequestsDataLoading || dataLoading) && (
          <GenericLoadingOverlay
            backgroundColor={'rgba(78, 8, 122, 0.15)'}
            spinnerVariant={'dark'}
          />
        )}
        {bookingRequestsData?.map((item, index) => {
          return (
            <BookingRequestStrip
              key={index}
              bookingRequestId={item?.id}
              cancelClickFunction={cancelBookingRequest}
              bookingRequestData={item}
            />
          )
        })}
      </BookingRequestItemsParent>
    </div>
  )
}

const CustomNoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      Click on Add New Button to add new patient
    </components.NoOptionsMessage>
  )
}

const RecordVitalItemSpan = styled.span`
  min-width: 100px;
`

const RecordVitalItemInput = styled.input`
  width: 60px;
  border: 1px solid #cecece;
  border-radius: 4px;
  margin: 0 4px;
`

const CustomPlaceholder = (props) => (
  <components.Placeholder {...props}>
    <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
      <SearchIcon style={{ fontSize: '16px' }} />
      <span style={{ display: 'flex', alignItems: 'center' }}>Search Patient</span>
    </div>
  </components.Placeholder>
)

const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      <div>
        {props?.label}
        {props?.data?.primaryPatientProfile && (
          <>
            {' '}
            <span
              style={{
                background: '#FE8D59',
                padding: '2px 4px',
                borderRadius: '16px',
                border: '1px solid #000',
                fontSize: '12px',
              }}
            >
              Relative
            </span>
          </>
        )}
      </div>
    </components.Option>
  )
}

export function shouldShowTwoRowDesign(arr) {
  try {
    if (
      arr.some(
        (item) =>
          item?.previousSteps && Array.isArray(item.previousSteps) && item.previousSteps.length > 0,
      ) ||
      arr.some(
        (item) => item?.nextSteps && Array.isArray(item.nextSteps) && item.nextSteps.length > 0,
      )
    ) {
      return true
    }
    return false
  } catch (err) {
    return false
  }
}

export const AvailableScreenerComponent = ({ eventBeingBooked, setScreenerAvailable }) => {
  const [loading, setLoading] = useState(false)
  const [possibleSession, setPossibleSession] = useState()

  async function checkPossibleScreeningSessionForThisBooking() {
    setLoading(true)
    const response = await putCPDataWithoutParams(
      `/api/secure/meeting/screening/find-possible`,
      eventBeingBooked,
    )
    setLoading(false)
    if (
      response?.status === 200 &&
      isCommonResponseSuccessful(response?.data?.code) &&
      response?.data?.data?.screening
    ) {
      setPossibleSession(response?.data?.data?.screening)
      setScreenerAvailable(true)
    } else {
      setScreenerAvailable(false)
      setPossibleSession()
    }
  }

  useEffect(() => {
    checkPossibleScreeningSessionForThisBooking()
  }, [])

  return (
    <div>
      {loading ? (
        <Spinner animation="border" variant="dark" size="sm" />
      ) : (
        <div>
          (
          {possibleSession?.mentorName
            ? `Available: ${possibleSession?.mentorName}`
            : 'No Screener Available right now'}
          )
        </div>
      )}
    </div>
  )
}

export function getCompletedByTotalStringForEventType(bookingsArray, eventTypeData) {
  if (Array.isArray(bookingsArray) && eventTypeData) {
    const eventTypeId = eventTypeData.uid
    const relevantBookings = bookingsArray.filter((booking) => booking.eventTypeId === eventTypeId)
    const totalBookings = relevantBookings.length
    const completedBookings = relevantBookings.filter((booking) => booking?.markedCompleted).length
    return `${completedBookings}/${totalBookings}`
  }
  return ''
}

/// day cal v2:

export const EDVCFilterComponent = ({
  eventTypesVisible,
  thisClinicData,
  selectedEventTypeId,
  setSelectedEventTypeId,
  mentorProfileData,
  bookedSlotsInDay,
}) => {
  return (
    <EDVCEventsContainer>
      {eventTypesVisible?.map((eventTypeItem, eventTypeIndex) => {
        return (
          <NewEventTypeButton
            key={eventTypeIndex}
            selected={selectedEventTypeId === eventTypeItem?.uid}
            onClick={() => setSelectedEventTypeId(eventTypeItem?.uid)}
          >
            {eventTypeItem?.title}
            <span style={{ marginLeft: '8px' }}>
              {eventTypeItem?.meetingSrc === 'aumhum' ? <VideoCameraFrontIcon /> : <ChairIcon />}
            </span>
            {/* {thisEventClinic?.nickName && `(${thisEventClinic?.nickName})`}{' '} */}
            {getCompletedByTotalStringForEventType(bookedSlotsInDay, eventTypeItem) &&
              `(${getCompletedByTotalStringForEventType(bookedSlotsInDay, eventTypeItem)})`}
          </NewEventTypeButton>
        )
      })}
    </EDVCEventsContainer>
  )
}

export const EDVCSessionsCompletedDetailsComponent = ({ bookedSlotsInDay }) => {
  const countInPerson = (bookedSlotsInDay || [])?.filter(
    (item) => item?.meetingSrc === SESSION_PLATFORM_OFFLINE,
  )?.length
  const finishedCountInPerson = (bookedSlotsInDay || [])?.filter(
    (item) => item?.meetingSrc === SESSION_PLATFORM_OFFLINE && item?.markedCompleted,
  )?.length
  const countVideo = (bookedSlotsInDay || [])?.filter(
    (item) => item?.meetingSrc === SESSION_PLATFORM_ONLINE,
  )?.length
  const finishedCountVideo = (bookedSlotsInDay || [])?.filter(
    (item) => item?.meetingSrc === SESSION_PLATFORM_ONLINE && item?.markedCompleted,
  )?.length

  return (
    <EDVCEventsContainer>
      <NewEventTypeButton style={{ cursor: 'default' }}>
        All Patients ({finishedCountInPerson + finishedCountVideo}/{countInPerson + countVideo})
      </NewEventTypeButton>
      <NewEventTypeButton style={{ cursor: 'default' }}>
        In Person <ChairIcon /> ({finishedCountInPerson}/{countInPerson})
      </NewEventTypeButton>
      <NewEventTypeButton style={{ cursor: 'default' }}>
        Video Call <VideoCameraFrontIcon /> ({finishedCountVideo}/{countVideo})
      </NewEventTypeButton>
    </EDVCEventsContainer>
  )
}

export const EventSelectorForBooking = ({
  selectedEventTypeId,
  eventTypesVisible,
  setSelectedEventTypeId,
  clinicLocationId,
  mentorProfileData,
}) => {
  const CHOSEN_BLOCK_VIDEO = 'video-block'
  const CHOSEN_BLOCK_IN_PERSON = 'in-person-block'
  const CHOSEN_BLOCK_OTHER = 'other-block'

  const [chosenBlockId, setChosenBlockId] = useState()
  const [inPersonBlock, setInPersonBlock] = useState()
  const [videoBlock, setVideoBlock] = useState()
  const [otherOptionsArray, setOtherOptionsArray] = useState([])

  console.log(eventTypesVisible)

  useEffect(() => {
    const defaultEventTypeData = getInitiallySelectedEventTypeData(
      mentorProfileData,
      clinicLocationId,
    )
    if (defaultEventTypeData) {
      let eventTypesForOther = [...eventTypesVisible]
      let eventTypesForInPerson = eventTypesForOther?.filter(
        (item) => item?.meetingSrc === SESSION_PLATFORM_OFFLINE,
      )
      let eventTypesForVideo = eventTypesForOther?.filter(
        (item) => item?.meetingSrc === SESSION_PLATFORM_ONLINE,
      )

      let defaultOrFirstInPersonEventData =
        eventTypesForInPerson?.find((item) => item.uid === defaultEventTypeData?.uid) ||
        eventTypesForInPerson[0]
      let defaultOrFirstVideoEventData =
        eventTypesForVideo?.find((item) => item.uid === defaultEventTypeData?.uid) ||
        eventTypesForVideo[0]

      if (defaultOrFirstInPersonEventData) {
        setInPersonBlock(defaultOrFirstInPersonEventData)
      }
      if (defaultOrFirstVideoEventData) {
        setVideoBlock(defaultOrFirstVideoEventData)
      }
      if (defaultOrFirstInPersonEventData) {
        eventTypesForOther = eventTypesForOther?.filter(
          (item) => item?.uid !== defaultOrFirstInPersonEventData?.uid,
        )
      }
      if (defaultOrFirstVideoEventData) {
        eventTypesForOther = eventTypesForOther?.filter(
          (item) => item?.uid !== defaultOrFirstVideoEventData?.uid,
        )
      }
      setOtherOptionsArray(eventTypesForOther)

      if (defaultOrFirstInPersonEventData && defaultOrFirstVideoEventData) {
        if (defaultEventTypeData?.uid === defaultOrFirstInPersonEventData?.uid) {
          setChosenBlockId(CHOSEN_BLOCK_IN_PERSON)
        } else if (defaultEventTypeData?.uid === defaultOrFirstVideoEventData?.uid) {
          setChosenBlockId(CHOSEN_BLOCK_VIDEO)
        } else {
          setChosenBlockId(CHOSEN_BLOCK_IN_PERSON)
        }
      } else if (defaultOrFirstInPersonEventData) {
        setChosenBlockId(CHOSEN_BLOCK_IN_PERSON)
      } else if (defaultOrFirstVideoEventData) {
        setChosenBlockId(CHOSEN_BLOCK_VIDEO)
      }
    }
  }, [])

  if (!Boolean(eventTypesVisible)) {
    return <div style={{ color: 'red' }}>No events offered in this location by mentor</div>
  }
  if (Array.isArray(eventTypesVisible) && eventTypesVisible.length === 0) {
    return <div style={{ color: 'red' }}>No events offered in this location by mentor</div>
  }

  if (inPersonBlock || videoBlock) {
    return (
      <div>
        <div className="d-flex gap-2">
          {inPersonBlock && (
            <BlockComponent
              selected={chosenBlockId === CHOSEN_BLOCK_IN_PERSON}
              clickFunction={() => {
                setChosenBlockId(CHOSEN_BLOCK_IN_PERSON)
                setSelectedEventTypeId(inPersonBlock?.uid)
              }}
              text={'In Person'}
              text2={`${inPersonBlock?.durationInMins}m`}
              icon={<ChairIcon />}
            />
          )}
          {videoBlock && (
            <BlockComponent
              selected={chosenBlockId === CHOSEN_BLOCK_VIDEO}
              clickFunction={() => {
                setChosenBlockId(CHOSEN_BLOCK_VIDEO)
                setSelectedEventTypeId(videoBlock?.uid)
              }}
              text={'Video'}
              text2={`${videoBlock?.durationInMins}m`}
              icon={<VideoCameraFrontIcon />}
            />
          )}
          {Array.isArray(otherOptionsArray) && otherOptionsArray.length > 0 && (
            <BlockComponent
              selected={chosenBlockId === CHOSEN_BLOCK_OTHER}
              clickFunction={() => {
                setChosenBlockId(CHOSEN_BLOCK_OTHER)
              }}
              text={'Other'}
              text2={'Sessions'}
            />
          )}
        </div>
        {chosenBlockId === CHOSEN_BLOCK_OTHER && (
          <>
            <div style={{ fontSize: '12px', marginTop: '16px' }}>Select session type:</div>
            <FormControl fullWidth>
              <MuiSelect
                labelId="select-event-type-label"
                id="select-event-type"
                value={selectedEventTypeId}
                label="Session Type"
                onChange={(e) => setSelectedEventTypeId(e.target.value)}
                sx={{ height: '32px', marginTop: '8px' }}
              >
                {otherOptionsArray?.map((eventType, eventTypeIndex) => {
                  return (
                    <MenuItem key={eventTypeIndex} value={eventType?.uid}>
                      {eventType?.title}
                      <span style={{ marginLeft: '12px' }}>
                        {'('}
                        {eventType?.durationInMins}
                        {' mins)'}
                      </span>
                      <span style={{ marginLeft: '12px' }}>
                        {eventType?.meetingSrc === SESSION_PLATFORM_OFFLINE && <ChairIcon />}
                        {eventType?.meetingSrc === SESSION_PLATFORM_ONLINE && (
                          <VideoCameraFrontIcon />
                        )}
                      </span>
                    </MenuItem>
                  )
                })}
              </MuiSelect>
            </FormControl>
          </>
        )}
      </div>
    )
  } else {
    return <></>
  }
}

const BlockComponent = ({ selected, text, text2, icon, clickFunction }) => {
  return (
    <div
      style={{
        cursor: 'pointer',
        background: selected ? '#7450aa' : '#fff',
        border: '1px solid #7450aa',
        color: selected ? '#fff' : '#7450aa',
        borderRadius: '4px',
        padding: '8px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '75px',
      }}
      onClick={() => clickFunction()}
    >
      <div>{icon}</div>
      <div
        className="d-flex flex-column align-items-center justify-content-center"
        style={{ marginTop: '4px' }}
      >
        <div style={{ fontSize: '12px' }}>{text}</div>
        <div style={{ fontSize: '10px' }}>{text2}</div>
      </div>
    </div>
  )
}

function cleanAttendantsArray(attendantsArray) {
  if (Array.isArray(attendantsArray)) {
    return attendantsArray.filter((item) => item?.name)
  }
  return []
}

export function giveTodayYesterdayElseFormattedStringPerLocal(
  utcTime,
  format,
  includeTime = false,
) {
  if (!format) {
    format = 'DD MMM YY hh:mm a' // Default format
  }

  const localDate = dayjs.utc(utcTime).local()
  const now = dayjs().local()

  const timeFormat = includeTime ? 'hh:mm a' : ''

  // Check if the date is today or yesterday
  if (localDate.isSame(now, 'day')) {
    return localDate.format(`[Today] ${timeFormat}`).trim()
  } else if (localDate.isSame(now.subtract(1, 'day'), 'day')) {
    return localDate.format(`[Yesterday] ${timeFormat}`).trim()
  } else {
    return localDate.format(format) // Otherwise, return the formatted date
  }
}

export const VitalsComponentInsideNewBooking = ({ patientId, clinicId, withCreateButton }) => {
  const [loadingVitals, setLoadingVitals] = useState(false)
  const [vitalsListToday, setVitalsListToday] = useState([])
  const [createDialogOpen, setCreateDialogOpen] = useState(false)

  async function getAndSetTodayVitals() {
    setLoadingVitals(true)
    const vitals = await getVitalsListForAPatientInAClinic(patientId, clinicId)

    console.log({ vitals })

    setVitalsListToday(vitals)

    setLoadingVitals(false)
  }

  useEffect(() => {
    getAndSetTodayVitals()
  }, [])

  return (
    <div>
      <div>
        <span>
          <b>Vitals today: </b>
        </span>
        {withCreateButton && (
          <span
            style={{ cursor: 'pointer', fontSize: '12px' }}
            onClick={() => setCreateDialogOpen(true)}
          >
            <u>Record Now</u>
          </span>
        )}
      </div>
      <div className="w-100 d-flex gap-2" style={{ overflow: 'auto' }}>
        {loadingVitals ? (
          <Spinner size="sm" variant="dark" />
        ) : (
          <>
            {vitalsListToday?.map((vitalItem, vitalIndex) => {
              return (
                <div
                  key={vitalIndex}
                  style={{
                    flexShrink: 0,
                    fontSize: '12px',
                  }}
                >
                  <VitalShowingComponent vitalItem={vitalItem} />
                </div>
              )
            })}
            <span style={{ fontSize: '12px' }}>
              {vitalsListToday?.length === 0 && 'No Vitals recorded today'}
            </span>
          </>
        )}
      </div>
      {createDialogOpen && (
        <VitalsComponentEntryDialog
          patientId={patientId}
          clinicId={clinicId}
          closeFunction={() => setCreateDialogOpen(false)}
          callbackFunctionAfterSave={() => {
            setCreateDialogOpen(false)
            getAndSetTodayVitals()
          }}
        />
      )}
    </div>
  )
}

export const VitalsComponentEntryDialog = ({
  patientId,
  clinicId,
  closeFunction,
  callbackFunctionAfterSave,
}) => {
  const [saving, setSaving] = useState(false)
  const [vitalData, setVitalData] = useState({
    weightInGrams: null,
    systolicBpInMmHg: null,
    diastolicBpInMmHg: null,
    bpm: null,
    sp02: null,
    tempInCelsius: null,
  })

  async function saveVital() {
    setSaving(true)
    await postCPDataWithParams(
      '/api/secure/patient/vitals/save',
      { ...vitalData, patientId: patientId, clinicId: clinicId },
      {},
    )
    await callbackFunctionAfterSave()
    setSaving(false)
  }

  function handleInputChange(field, value) {
    setVitalData((prev) => ({
      ...prev,
      [field]:
        field === 'weightInGrams'
          ? value && !isNaN(value) // Ensure valid input
            ? parseFloat(value) * 1000 // Convert kg to grams
            : null
          : field === 'tempInCelsius'
          ? value && !isNaN(value) // Handle temperature conversion
            ? parseFloat(value)
            : null
          : value && !isNaN(value) // For other numeric fields
          ? parseFloat(value)
          : null,
    }))
  }
  return (
    <Dialog
      open={true}
      PaperProps={{
        sx: {
          padding: '32px 16px',
          margin: '8px',
          maxWidth: 'none',
          borderRadius: '8px',
          minWidth: '400px',
        },
      }}
    >
      <CloseIcon
        sx={{ position: 'absolute', top: '8px', right: '8px', cursor: 'pointer' }}
        onClick={closeFunction}
      />
      <div className="d-flex flex-column">
        <div className="d-flex">
          <RecordVitalItemSpan>Weight: </RecordVitalItemSpan>
          <RecordVitalItemInput
            value={vitalData.weightInGrams !== null ? vitalData.weightInGrams / 1000 : ''}
            type="number"
            onChange={(e) => handleInputChange('weightInGrams', e.target.value)}
          />
          kgs
        </div>
        <div className="d-flex">
          <RecordVitalItemSpan>SpO2:</RecordVitalItemSpan>
          <RecordVitalItemInput
            value={vitalData.sp02 || ''}
            type="number"
            onChange={(e) => handleInputChange('sp02', e.target.value)}
          />
          <span>{' %'}</span>
        </div>
        <div className="d-flex">
          <RecordVitalItemSpan>Pulse:</RecordVitalItemSpan>
          <RecordVitalItemInput
            value={vitalData.bpm || ''}
            type="number"
            onChange={(e) => handleInputChange('bpm', e.target.value)}
          />
          <span>{' bpm'}</span>
        </div>
        <div className="d-flex">
          <RecordVitalItemSpan>Temp (°F):</RecordVitalItemSpan>
          <RecordVitalItemInput
            value={
              vitalData.tempInCelsius ? convertToFahrenheitFromCelsius(vitalData.tempInCelsius) : ''
            }
            type="number"
            onChange={(e) =>
              handleInputChange(
                'tempInCelsius',
                e.target.value ? convertToCelsiusFromFahrenheit(e.target.value) : null,
              )
            }
          />
        </div>
        <div className="d-flex">
          <RecordVitalItemSpan>Blood Pressure:</RecordVitalItemSpan>
          <RecordVitalItemInput
            value={vitalData.systolicBpInMmHg || ''}
            type="number"
            onChange={(e) => handleInputChange('systolicBpInMmHg', e.target.value)}
          />
          {'/'}
          <RecordVitalItemInput
            value={vitalData.diastolicBpInMmHg || ''}
            type="number"
            onChange={(e) => handleInputChange('diastolicBpInMmHg', e.target.value)}
          />
          <span>(sys/dia mmHg)</span>
        </div>
      </div>
      <Button
        style={{ marginTop: '16px' }}
        color="emrPrimary"
        variant="contained"
        onClick={saveVital}
      >
        {saving ? (
          <span>
            <Spinner variant="light" size="sm" />
          </span>
        ) : (
          <span>
            Record Vitals <DoneIcon />
          </span>
        )}
      </Button>
    </Dialog>
  )
}

function isWithinLast24Hours(startTime) {
  const startDate = new Date(startTime + ' UTC')

  const currentUtcDate = new Date()

  const differenceInMs = currentUtcDate - startDate

  return differenceInMs <= 24 * 60 * 60 * 1000
}
