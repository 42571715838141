import { useEffect, useState } from 'react'
import NumpadComponent from './NumpadComponent'
import { toast } from 'react-toastify'
import { getDataWithParams } from 'src/consumer/services/profileService'
import { encryptPin } from 'src/AESEncryptUtil'
import { Spinner } from 'react-bootstrap'

export const PinEntryVarOne = ({ pin, verifyPinWithApi, onSuccessFunc }) => {
  const [enteredPin, setEnteredPin] = useState('')
  const [verifying, setVerifying] = useState(false)

  const handleNumpadClick = (digit) => {
    if (enteredPin.length < 4) {
      // Restrict PIN length to 4 digits
      setEnteredPin((prev) => prev + digit)
    }
  }

  const handleBackspace = () => {
    setEnteredPin((prev) => prev.slice(0, -1))
  }

  const handleSubmit = async () => {
    if (verifyPinWithApi) {
      try {
        const encryptedPin = encryptPin(enteredPin)
        setVerifying(true)
        const response = await getDataWithParams('/api/secure/user/verify-quick-access-pin', {pin: encryptedPin})
        setVerifying(false)
        if (response?.status === 200) {
          if (response?.data) {
            onSuccessFunc()
          } else {
            toast.error('Wrong PIN')
          }
        } else {
          toast.error('Failed to verify PIN')
        }
      } catch (e) {
        console.error(e)
        toast.error('Failed to verify PIN')
      }
    } else {
      if (enteredPin === pin.toString()) {
        onSuccessFunc()
      } else {
        toast.error('Incorrect PIN')
      }
    }
  }

  useEffect(() => {
    if (enteredPin.length === 4) {
      handleSubmit()
    }
  }, [enteredPin])

  return (
    <div style={{ padding: '16px' }}>
      <div>
        <b>Enter PIN:</b>
      </div>
      {verifying && <div><Spinner variant='dark' size='sm' /></div>}
      <input
        type="text"
        value={enteredPin}
        // readOnly
        onChange={(e) => setEnteredPin(e.target.value)}
        style={{
          margin: '8px auto',
          width: '200px',
          border: '1px solid #cecece',
          padding: '8px',
          fontSize: '20px',
          textAlign: 'center',
        }}
      />
      <NumpadComponent
        clickFunc={handleNumpadClick}
        clickSound={true}
        submitVisible={true}
        submitDisabled={enteredPin.length < 4}
        submitFunction={handleSubmit}
        backVisible={enteredPin.length > 0}
        backDisabled={enteredPin.length === 0}
        backFunction={handleBackspace}
        containerStyles={{ width: '240px' }}
      />
    </div>
  )
}
