import { styled as muiStyled } from '@mui/material'

export function getBackgroundForPMPFirstSectionItems({ debug, debugColor, debugHighlightState }) {
  if (debugHighlightState) {
    return '#ff7c7c'
  }
  if (debug) {
    return debugColor || 'grey'
  }
  return 'unset'
}

export const PMPBodySectionItems = muiStyled('div')(
  ({ theme, debug, debugColor, debugHighlight, noPadding }) => `
    padding: ${noPadding ? '' : '10px 0px 0px 0px'};
    background-color: ${getBackgroundForPMPFirstSectionItems({
      debug,
      debugColor,
      debugHighlightState: debugHighlight,
    })};
`,
)
export const PMPBodyDebugHighlightDiv = muiStyled('div')(
  ({ theme, debug, debugHighlight }) => `
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
    background-color: ${getBackgroundForPMPFirstSectionItems({
      debug,
      debugColor: 'transparent',
      debugHighlightState: debugHighlight,
    })};
`,
)
