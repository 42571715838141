import ChatIcon from '@mui/icons-material/Chat'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import InfoIcon from '@mui/icons-material/Info'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import TelegramIcon from '@mui/icons-material/Telegram'
import AC from 'agora-chat'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AGORA_CHAT_MESSAGE_CUSTOM_EXTS_TYPE } from 'src/consumer/constants'
import { ProfileContext } from 'src/consumer/context/ProfileContext'
import {
  selectAgoraChatId,
  selectAgoraChatServerConnection,
  selectAgoraChatToken,
  setAgoraChatId,
  setAgoraChatServerConnection,
  setAgoraChatToken,
} from 'src/consumer/reducers/personalData/personalDataReducer'
import {
  getAgoraChatToken,
  getConversationWithThisUser,
  informBookingRequestReceivedForMentor,
} from 'src/consumer/services/featureServices'
import { getData } from 'src/consumer/services/profileService'
import { useScreenSize } from 'src/customHooks/customHooks'
import { getImageLink, isCommonResponseSuccessful } from 'src/mentor/helpers/utilityFunctions'
import { getAllRolesInBoolean } from 'src/mentor/layout/utils'
import { addReceivedBookingRequest } from 'src/mentor/reducers/bookingRequest/bookingRequestReducer'
import ConversationListChatUI from '../ChatUIComponents/ConversationListChatUI'
import {
  isOnlineNow,
  replaceTriedSentMessageWithResultantMessage,
  sortMessagesArray,
} from '../ChatUIComponents/helperFunctions'
import TwoPeopleChatUI from '../ChatUIComponents/TwoPeopleChatUI'
import { isSameUserId } from './helperFunctions'
import { FIButtonContainer, FIContentContainer } from './intercomStyles'

export default function FloatingIntercomButton() {
  const { profileData } = useContext(ProfileContext)
  const { width } = useScreenSize()
  const { pathname } = useLocation()
  const agoraAppKey = process.env.REACT_APP_AGORA_CHAT_APPKEY
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const agoraChatServerConnection = useSelector(selectAgoraChatServerConnection)
  const agoraChatId = useSelector(selectAgoraChatId)
  const agoraChatToken = useSelector(selectAgoraChatToken)
  const [selectedTab, setSelectedTab] = useState('chat')

  const [floatingButtonVisible, setFloatingButtonVisible] = useState(false)
  const [open, setOpen] = useState(false)
  const [unreadCount, setUnreadCount] = useState(0)
  const [intercomState, setIntercomState] = useState('conversationList')
  const [chosenPersonChatId, setChosenPersonChatId] = useState()
  const [userOnlineList, setUserOnlineList] = useState([])

  const [contacts, setContacts] = useState([])
  const [conversationsWithContacts, setConversationsWithContacts] = useState({})
  const [activePageNumbersOfConversations, setActivePageNumbersOfConversations] = useState({})
  const [chatsLoading, setChatsLoading] = useState(false)

  const [scrollTriggerBoolean, setScrollTriggerBoolean] = useState(false)

  // chat sound function
  const playRequestIncomingSound = async () => {
    const soundModule = await import('src/assets/audio/therapy_notification.mp3')
    const sound = new Audio(soundModule.default)
    sound.play()
  }

  // 1
  async function getAndSetContacts() {
    // first get and set contacts
    if (profileData) {
      const response = await getData('/api/secure/user-chat/contacts')
      if (response?.status === 200 && Array.isArray(response?.data) && response?.data?.length > 0) {
        setContacts(response?.data?.filter((item) => !isSameUserId(item?.userId, profileData?.id)))
      }
    }
  }
  useEffect(() => {
    getAndSetContacts()
  }, [profileData])

  // 2 : SETTING VISIBILITY
  useEffect(() => {
    const isMentorHotlinePath = pathname.startsWith('/hotline-for-mentor')
    const isMentorPortalPath = pathname.startsWith('/mentor-home')
    const isClinicAssistantPortalPath = pathname.startsWith('/ca-home')
    const isClinicOwnerPortalPath = pathname.startsWith('/clinic-home')
    const isWorkspacePath = pathname.startsWith('/workplace')
    const isCounterScreenPath = pathname.includes('counter-screen')
    const isChamberRoomScreen = pathname.includes('chamber-screen-home')
    const isLiveSessionShortUrl = pathname.startsWith('/l/')
    const isLiveSession = pathname.startsWith('/live/')
    if (
      isMentorHotlinePath ||
      isMentorPortalPath ||
      isClinicAssistantPortalPath ||
      isClinicOwnerPortalPath ||
      isWorkspacePath ||
      isLiveSessionShortUrl ||
      isLiveSession ||
      profileData?.id === 'USR-09290ce5379c49b6a97aed9734068fc4' ||
      profileData?.id === 'USR-d67006790d0d49a4bb43dca582a4eb0d'
    ) {
      if (isCounterScreenPath || isChamberRoomScreen) {
        setFloatingButtonVisible(false)
      } else {
        setFloatingButtonVisible(true)
      }
    } else {
      setFloatingButtonVisible(false)
    }
  }, [pathname])

  // 3 : SETTING CONTACTS MANUALLY - TEMPORARY - SHOULD COME FROM SERVER
  useEffect(() => {
    const isWorkspacePath = pathname.startsWith('/workplace')
    if (isWorkspacePath) {
      setContacts([
        {
          relativeRole: 'Your Success Manager',
          profilePicId: 'MEDIA-4a8eeb80da0842099ad7755f4682d557',
          authorities: ['ROLE_USER'],
          firstName: 'Aditya Verma',
          name: 'Aditya Verma',
          userId: 'USR-09290ce5379c49b6a97aed9734068fc4',
          userName: 'aumhum.successmanager@aumhum.xyz',
          userNumber: 'USR001156080',
        },
        {
          // relativeRole: '',
          profilePicId: 'MEDIA-ed39ae4f4af94272a4bf3e63ed4172a9',
          authorities: ['ROLE_USER'],
          firstName: 'Success Bot',
          name: 'Success Bot',
          userId: 'USR-d67006790d0d49a4bb43dca582a4eb0d',
          userName: 'aumhum.bot@aumhum.xyz',
          userNumber: 'USR001156079',
        },
      ])
    }
    if (
      profileData?.id === 'USR-09290ce5379c49b6a97aed9734068fc4' ||
      profileData?.id === 'USR-d67006790d0d49a4bb43dca582a4eb0d'
    ) {
      setContacts([
        {
          relativeRole: 'Aumhum Business Client',
          profilePicId: 'MEDIA-77327e52622844259b4b014ac10b0f16',
          authorities: ['ROLE_USER'],
          firstName: 'Dhawal Sharma',
          name: 'Dhawal Sharma',
          userId: 'USR-d1ebd7aff0c948a8985114bee64f49d9',
          userName: 'aumhum.customer@outlook.com',
          userNumber: 'USR001142886',
        },
      ])
    }
  }, [pathname])

  // 4 : ALL IMPORTANT CONNECTION MAKING AND REDUX STUFF
  useEffect(() => {
    // make a connection to agora only when needed (when there are contacts) and store the connection in redux
    if (contacts?.length > 0) {
      // 1. check if redux already data - conn, userid, token
      if (agoraChatServerConnection && agoraChatId && agoraChatToken) {
        // console.log('there is data')
      } else {
        // 2. call the function that does this
        makeAndStoreAgoraConnectionInRedux(dispatch, agoraAppKey, profileData?.id)
      }
    }
  }, [contacts, agoraChatServerConnection, agoraChatId, agoraChatToken, profileData])

  // 5 : Put agoraChatServerConnnection in dependency array -- if that changes -- and now exists --
  // --send my presence every N seconds to agora server -- presence sending should be from one place only -- even if connection is made because of video call and not contacts
  function keepSendingPresenceOnline() {
    if (agoraChatServerConnection) {
      agoraChatServerConnection
        .publishPresence({ description: `online@${moment().unix()}` })
        .then((res) => {
          // console.log(res)
        })
        .catch((error) => console.log(error))
    }
  }
  useEffect(() => {
    // Initial update on mount
    keepSendingPresenceOnline()
    // Interval to update presence status every 30 seconds
    const presenceInterval = setInterval(() => {
      keepSendingPresenceOnline()
    }, 30000) // 30 seconds
    // Cleanup function to clear interval on unmount
    return () => {
      clearInterval(presenceInterval)
    }
  }, [agoraChatServerConnection])

  // 6. Check presence of contacts every 30 seconds
  function keepCheckingPresenceOfContacts() {
    if (agoraChatServerConnection && contacts?.length > 0) {
      const userIdsToCheck = []
      contacts?.forEach((contact) => {
        // agora only sends userids in lowercase, so we ask for lowercase userids
        userIdsToCheck.push(contact?.userId?.toLowerCase())
      })
      let option = {
        usernames: userIdsToCheck,
      }
      agoraChatServerConnection.getPresenceStatus(option).then((res) => {
        const tempOnlineOfflineArray = []
        res?.data?.result?.forEach((resultItem) => {
          let preparedObject = {}
          preparedObject.userId = resultItem.uid
          preparedObject.online = resultItem.ext ? isOnlineNow(resultItem.ext?.slice(7)) : false
          tempOnlineOfflineArray.push(preparedObject)
        })
        setUserOnlineList(tempOnlineOfflineArray)
      })
    }
  }
  useEffect(() => {
    // Initial update on mount
    keepCheckingPresenceOfContacts()
    // Interval to update presence status every 30 seconds
    const presenceInterval = setInterval(() => {
      keepCheckingPresenceOfContacts()
    }, 30000) // 30 seconds
    // Cleanup function to clear interval on unmount
    return () => {
      clearInterval(presenceInterval)
    }
  }, [agoraChatServerConnection, contacts])

  const otherChosenUser = contacts?.find((contact) =>
    isSameUserId(contact?.userId, chosenPersonChatId),
  )

  // 7. chats of all contacts
  async function getChatDataOfContacts() {
    if (agoraChatServerConnection && contacts?.length > 0) {
      const chatData = {} // Object to store chat data for each contact
      const pageNumberData = {} // Object to store page number of each
      for (const contact of contacts) {
        const { userId } = contact
        setChatsLoading(true)
        const conversationResponse = await getConversationWithThisUser(userId, 1)
        setChatsLoading(false)
        if (conversationResponse?.status === 200 && Array.isArray(conversationResponse?.data)) {
          chatData[userId] = sortMessagesArray(conversationResponse?.data)
        } else {
          chatData[userId] = []
        }
        pageNumberData[userId] = 1
      }
      setConversationsWithContacts(chatData)
      setActivePageNumbersOfConversations(pageNumberData)
    }
  }
  useEffect(() => {
    getChatDataOfContacts()
  }, [agoraChatServerConnection, contacts])

  // 8. handler to listen to incoming messages
  useEffect(() => {
    if (agoraChatServerConnection && agoraChatId && agoraChatToken) {
      agoraChatServerConnection?.addEventHandler?.('message', {
        // Occurs when a message is received
        onTextMessage: function (message) {
          if (isSameUserId(message?.to, agoraChatId)) {
            const userIdOfConversationToUpdate = Object.keys(conversationsWithContacts)?.find(
              (contactId) => isSameUserId(contactId, message?.from),
            )
            if (userIdOfConversationToUpdate) {
              const existingArray = conversationsWithContacts[userIdOfConversationToUpdate] || []
              setConversationsWithContacts((prev) => ({
                ...prev,
                [userIdOfConversationToUpdate]: [...existingArray, message],
              }))
              setScrollTriggerBoolean((prev) => !prev)
            }
          }
        },
        onCustomMessage: async function (message) {
          if (isSameUserId(message?.to, agoraChatId)) {
            if (
              message?.customExts?.type ===
                AGORA_CHAT_MESSAGE_CUSTOM_EXTS_TYPE.INCOMING_BOOKING_REQUEST ||
              message?.customExts?.type ===
                AGORA_CHAT_MESSAGE_CUSTOM_EXTS_TYPE.BOOKING_REQUEST_CANCELLED ||
              message?.customExts?.type ===
                AGORA_CHAT_MESSAGE_CUSTOM_EXTS_TYPE.BOOKING_REQUEST_ACCEPTED
            ) {
              dispatch(
                addReceivedBookingRequest({
                  ...message?.customExts?.bookingRequest,
                  type: message?.customExts?.type,
                }),
              )
              if (
                message?.customExts?.type ===
                AGORA_CHAT_MESSAGE_CUSTOM_EXTS_TYPE.INCOMING_BOOKING_REQUEST
              ) {
                toast.info('New booking request received')
                await informBookingRequestReceivedForMentor(
                  message?.customExts?.bookingRequest?.id,
                  'chat',
                  'agora',
                )
                try {
                  playRequestIncomingSound()
                } catch (e) {
                  console.log(e)
                }
                navigate('/booking-requests-for-mentors/active')
              }
            }

            // adding to messages array
            const userIdOfConversationToUpdate = Object.keys(conversationsWithContacts)?.find(
              (contactId) => isSameUserId(contactId, message?.from),
            )
            if (userIdOfConversationToUpdate) {
              const existingArray = conversationsWithContacts[userIdOfConversationToUpdate] || []
              setConversationsWithContacts((prev) => ({
                ...prev,
                [userIdOfConversationToUpdate]: [...existingArray, message],
              }))
              setScrollTriggerBoolean((prev) => !prev)
            }
          }
        },
      })
    }
    return () => {
      if (agoraChatServerConnection) {
        agoraChatServerConnection?.removeEventHandler?.('message')
      }
    }
  }, [agoraChatServerConnection, agoraChatId, agoraChatToken, conversationsWithContacts])

  // 9. load more chats of a user
  async function loadMoreChats(userId) {
    if (Array.isArray(conversationsWithContacts?.[userId])) {
      const allOlderMessagesAscTime = conversationsWithContacts?.[userId]
      const pageNumberActiveInThisConversation = activePageNumbersOfConversations?.[userId]
      if (allOlderMessagesAscTime.length > 0) {
        setChatsLoading(true)
        const conversationResponse = await getConversationWithThisUser(
          userId,
          pageNumberActiveInThisConversation + 1,
        )
        setChatsLoading(false)
        if (
          conversationResponse?.status === 200 &&
          Array.isArray(conversationResponse?.data) &&
          conversationResponse?.data?.length > 0
        ) {
          setConversationsWithContacts((prev) => ({
            ...prev,
            [userId]: sortMessagesArray([...conversationResponse?.data, ...prev[userId]]),
          }))
          setActivePageNumbersOfConversations((prev) => ({
            ...prev,
            [userId]: pageNumberActiveInThisConversation + 1,
          }))
        } else {
          console.log('no array found, some problem')
        }
      }
    }
  }

  // 10. replace message in messages array of a user id
  async function replaceSpecificTempMessageWithResultMessage(
    userId,
    tempMsgIdToReplace,
    resultMessage,
  ) {
    setConversationsWithContacts((prev) => {
      const newArrayOfMessagesForThisUser = replaceTriedSentMessageWithResultantMessage(
        prev?.[userId] || [],
        tempMsgIdToReplace,
        resultMessage,
      )
      console.log(prev)
      console.log({ ...prev, [userId]: newArrayOfMessagesForThisUser })
      return { ...prev, [userId]: newArrayOfMessagesForThisUser }
    })
  }

  return (
    <>
      {contacts &&
      contacts?.length > 0 &&
      floatingButtonVisible &&
      agoraChatServerConnection &&
      agoraChatId &&
      agoraChatToken ? (
        <>
          <FIButtonContainer onClick={() => setOpen((prev) => !prev)} rotated={open}>
            <div
              style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {open ? (
                <KeyboardArrowDownIcon style={{ fontSize: '32px', rotate: '-22.5deg' }} />
              ) : (
                <TelegramIcon style={{ fontSize: '28px' }} />
              )}
              {unreadCount > 0 && !open && (
                <div
                  style={{
                    background: 'red',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    width: '16px',
                    height: '16px',
                    fontSize: '12px',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {unreadCount}
                </div>
              )}
            </div>
          </FIButtonContainer>
          <FIContentContainer opened={open}>
            <div
              style={{
                height: intercomState === 'conversationList' ? 'calc(100% - 75px)' : '100%',
                width: '100%',
              }}
            >
              {selectedTab === 'chat' && (
                <>
                  {intercomState === 'conversationList' && (
                    <ConversationListChatUI
                      closeFunction={width < 768 ? () => setOpen(false) : undefined}
                      choseClickedPersonFunc={(data) => {
                        setIntercomState('chosenConversation')
                        setChosenPersonChatId(data)
                      }}
                      listItems={contacts}
                      userPresenceList={userOnlineList}
                      arraysOfMessages={conversationsWithContacts || []}
                      updateUnreadGlobalCountFunc={(data) => setUnreadCount(data)}
                    />
                  )}
                  {intercomState === 'chosenConversation' && (
                    <TwoPeopleChatUI
                      agoraChatServerConnection={agoraChatServerConnection}
                      thisUserChatId={agoraChatId}
                      thisUserChatToken={agoraChatToken}
                      thisUserNickname={profileData?.name}
                      thisUserAvatarUrl={
                        profileData?.profilePicId
                          ? getImageLink(profileData?.profilePicId)
                          : undefined
                      }
                      otherUserChatId={chosenPersonChatId}
                      otherUserName={otherChosenUser?.name || otherChosenUser?.firstName}
                      otherUserPresence={
                        userOnlineList?.find((item) =>
                          isSameUserId(item?.userId, chosenPersonChatId),
                        )?.online || false
                      }
                      otherUserRelativeRole={otherChosenUser?.relativeRole}
                      otherUserAvatarUrl={
                        otherChosenUser?.profilePicId
                          ? getImageLink(otherChosenUser?.profilePicId)
                          : undefined
                      }
                      arrayOfAllMessages={conversationsWithContacts?.[chosenPersonChatId] || []}
                      addLocalMessageToArray={(data) => {
                        setConversationsWithContacts((prev) => ({
                          ...prev,
                          [chosenPersonChatId]: [...(prev[chosenPersonChatId] || []), data],
                        }))
                        setScrollTriggerBoolean((prev) => !prev)
                      }}
                      showNotificationsOnIncomingMessage={false}
                      backFunction={() => {
                        setIntercomState('conversationList')
                        setChosenPersonChatId()
                      }}
                      scrollTriggerBoolean={scrollTriggerBoolean}
                      chatsLoading={chatsLoading}
                      loadOlderChatsWithUserFunc={(otherUserId) => loadMoreChats(otherUserId)}
                      replaceSpecificTempMessageWithResultMessage={(
                        userId,
                        tempMsgIdToReplace,
                        resultMessage,
                      ) =>
                        replaceSpecificTempMessageWithResultMessage(
                          userId,
                          tempMsgIdToReplace,
                          resultMessage,
                        )
                      }
                      canAttachFeatures={
                        localStorage.getItem('authorities') &&
                        (getAllRolesInBoolean().isAdmin || getAllRolesInBoolean().isTeacher)
                      }
                    />
                  )}
                </>
              )}
              <div style={{ display: selectedTab === 'info' ? 'unset' : 'none' }}>
                <iframe
                  id="helpcenter"
                  title="Help Center"
                  src={
                    window.location.protocol + '//' + window.location.host + '/help-center-embedded'
                  }
                  height="100%"
                  width="100%"
                ></iframe>
              </div>
            </div>

            {intercomState === 'conversationList' && (
              <div
                style={{
                  background: '#fff',
                  height: '75px',
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  borderTop: '1px solid rgb(182, 194, 200)',
                }}
              >
                <IntercomButton
                  SelectedIcon={ChatIcon}
                  NotSelectedIcon={ChatOutlinedIcon}
                  text={'Chat'}
                  selected={selectedTab === 'chat'}
                  clickFunc={() => setSelectedTab('chat')}
                />
                <IntercomButton
                  SelectedIcon={InfoIcon}
                  NotSelectedIcon={InfoOutlinedIcon}
                  text={'Help'}
                  selected={selectedTab === 'info'}
                  clickFunc={() => setSelectedTab('info')}
                />
              </div>
            )}
          </FIContentContainer>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

// showing floating button on limited paths --- done
// connection data pass from outside --- done
// ensuring compatibility with video call chat - (that part also makes connection with agora itself) --- done
// showing online even in the list : subscribe to presence and even receive presence events from all people outside twopeoplelist. also publish online status outside twopeoplelist
// new message number on the floating button
// last message - not always - but at least when the message just came
// context base chat - api changes

export async function makeAndStoreAgoraConnectionInRedux(dispatch, appKey, userChatId) {
  console.log('setting redux data of connection, userchatid, userchattoken')

  // we should already have user id, we get and set the token, we make connection
  let chatToken
  const chatTokenResponse = await getAgoraChatToken()
  if (
    chatTokenResponse?.status === 200 &&
    isCommonResponseSuccessful(chatTokenResponse?.data?.code) &&
    chatTokenResponse?.data?.data?.chatToken
  ) {
    chatToken = chatTokenResponse?.data?.data?.chatToken
  }
  if (dispatch && appKey && userChatId && chatToken) {
    const client = new AC.connection({ appKey })
    await client.open({
      user: userChatId,
      agoraToken: chatToken,
    })
    // set connection:
    dispatch(setAgoraChatServerConnection(client))
    // setUserId
    dispatch(setAgoraChatId(userChatId))
    // setChatToken
    dispatch(setAgoraChatToken(chatToken))
  } else {
    console.log('can not make agora connection without all 4 parameters')
  }
}

const IntercomButton = ({ SelectedIcon, NotSelectedIcon, text, selected, clickFunc }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '4px',
        transition: 'all 0.2s ease',
      }}
      onClick={clickFunc}
    >
      {selected ? (
        <SelectedIcon style={{ fontSize: '24px', cursor: 'pointer', color: '#1E323B' }} />
      ) : (
        <NotSelectedIcon style={{ fontSize: '24px', cursor: 'pointer', color: '#1E323B' }} />
      )}
      <div style={{ fontSize: '16px', fontWeight: selected ? '600' : '300' }}>{text}</div>
    </div>
  )
}
