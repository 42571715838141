import { toast } from 'react-toastify'
import { getCPDataWithParams } from 'src/clinic/services'
import { isCommonResponseSuccessfulV2 } from 'src/mentor/helpers/utilityFunctions'

export async function getVitalsListForAPatientInClinic(patientId, clinicId) {
  const response = await getCPDataWithParams(`/api/secure/patient/vitals`, {
    patientId: patientId,
    clinicId: clinicId,
  })

  if (
    response?.status === 200 &&
    isCommonResponseSuccessfulV2(response) &&
    Array.isArray(response?.data?.data?.vital)
  ) {
    const today = new Date()
    const startOfDay = new Date(
      Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate(), 0, 0, 0),
    )
    const endOfDay = new Date(
      Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate(), 23, 59, 59, 999),
    )
    const vitalsToday = response?.data?.data?.vital?.filter((vital) => {
      const createdTime = new Date(vital?.createdTime)
      return createdTime >= startOfDay && createdTime <= endOfDay
    })
    return vitalsToday
  } else {
    toast.error('Error getting vitals')
  }
  return []
}
