import { TextareaAutosize } from '@mui/base'
import { ButtonBase, styled as muiStyled } from '@mui/material'
import { withAsyncPaginate } from 'react-select-async-paginate'
import { default as Creatable } from 'react-select/creatable'
import { MUIThemeConfig } from 'src/context/MUIThemeConfig'
import styled from 'styled-components'
import { PRESCRIPTION_COLORS_MAP } from './inputComponents/common/color-utils'
import { isValidNumber } from 'src/consumer/helpers/utilFunctions'

const getFooterBackground = ({ noColor, accentColor = '#397c73', debug = false }) => {
  if (debug) {
    return 'transparent'
  }
  if (noColor) {
    return '#FFF'
  }
  return accentColor
}

export const PrescriptionTabFinalParent = styled.div`
  height: 100%;
  @media print {
    .hide-on-print {
      display: none;
    }
    .make-all-borders-black-print {
      border: 1px solid #000;
    }
    @page {
      size: A4;
      orientation: portrait;

      /* Enable background graphics */
      -webkit-print-color-adjust: exact; /* For Chrome */
      print-color-adjust: exact; /* For other browsers */
    }
  }
`

//

export const PMPParent = styled.div`
  ${({ hiddenInUI }) => hiddenInUI && 'display: none !important;'}
  @media print {
    display: block !important;
  }
  @page {
    size: A4 portrait; /* Page size and orientation */
    margin-top: 1in; /* Top, right, bottom, left */
  }
  margin: 0px auto;
  border: ${(props) => (props?.noBorder ? '' : '1px solid #000')};
  width: 100%;
  max-width: 794px;
  // min-height: 300px;
  height: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box; /* Ensure borders are included in the width */
  font-size: ${({ biggerFont }) => (biggerFont ? '17px' : '12px')};
  background: #fff;
  color: #000;
`

export const PMPParentVarTwo = styled.div`
  margin: 20px auto;
  border: 1px solid black;
  width: 100%;
  max-width: 210mm;
  height: auto;
  display: flex;
  flex-direction: row;
  font-size: 13px;
  background: #fff;
`

export const PMPHeadingBanner = styled.div`
  width: 100%;
  background: ${({ noColor, debug }) => {
    if (debug) {
      return 'transparent'
    }
    if (noColor) {
      return '#FFF'
    }
    return '#397c73'
  }};
  text-align: center;
  text-transform: uppercase;
  color: ${(props) => (props?.noColor ? '#000' : '#FFF')};
  border-bottom: 1px solid #000;
  letter-spacing: 1.5px;
  font-weight: 700;
  font-size: 18px;
  padding: 2px;
`

export const PMPButtonsContainer = styled.div`
  display: flex;
  flex-direction: ${({ alwaysInRow }) => (alwaysInRow ? 'row' : 'column')};
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 20px 0;
  width: 100%;
  position: sticky;
  top: 0px;
  z-index: 2;
  @media (min-width: 1080px) {
    flex-direction: row;
    gap: unset;
  }
`

export const PMPButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props?.whitebg ? '#fff' : '#0098ff')};
  color: ${(props) => (props?.whitebg ? '#0098ff' : '#fff')};
  padding: 2px 10px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 30px;
  border: 1px solid #0098ff;
`

export const PMPButtonForConsultation = muiStyled(ButtonBase)(
  ({ theme, whitebg }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${whitebg ? '#fff' : theme.palette.emrSecondary.default};
  color: ${whitebg ? theme.palette.emrSecondary.default : '#fff'};
  padding: 2px 10px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 30px;
  border: 1px solid ${theme.palette.emrSecondary.default};
  gap:5px;
`,
)

///

export const PMPHeaderLeftPart = styled.div`
  width: 50%;
  padding: 5px 20px;
`

export const PMPHeaderRightPart = styled.div`
  width: 50%;
  padding: 5px 20px;
  display: flex;
  flex-direction: column;
  align-items: end;
`

export const PMPHeaderDoctorText = styled.div`
  color: ${(props) => (props?.main ? '#000' : '#080808')};
  font-weight: ${(props) => (props?.main ? '600' : '400')};
  font-size: ${(props) => (props?.main ? '14px' : '12px')};
  ${(props) => props?.textRight && `text-align: right`};
`

export const PMPHeaderDetails = styled.div`
  background: ${({ noColor, debug }) => {
    if (debug) {
      return 'transparent'
    }
    if (noColor) {
      return '#FFF'
    }
    return MUIThemeConfig.palette.emrPrescriptionPrimary.default
  }};
  padding: 5px 20px;
  color: #000;
  border-bottom: 1px solid #000;
`

//

export const PMPFooterContainer = styled.div`
  background: ${(props) => getFooterBackground(props)};
  padding: 5px 20px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  border-top: 1px solid #000;
  box-sizing: border-box;
`

export const PMPColumnedFooter = styled.div`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props?.columns || 3}, 1fr)`};
  border-top: 1px solid #000;
  background: ${(props) => getFooterBackground(props)};
  color: ${(props) => (props?.noColor ? '#000' : '#FFF')};
`

//

export const PMPLockedSection = styled.div`
  width: 350px;
  flex-shrink: 0;
  margin-top: 20px;
  border: 1px solid #a2a2a2;
  border-radius: 10px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

// styles for prescription items selection components:

function getColumnsPrecStringCss({ columnsPrecArray }) {
  return columnsPrecArray?.reduce((acc, curr) => {
    return `${acc} ${curr}%`
  }, '')
}

export const PMPSingleRowForDrugs = styled.div`
  width: 100%;
  display: grid;
  border: 1px solid ${({ useGreyBorders }) => (useGreyBorders ? '#c6c6c6' : '#000')};
  ${({ isLastRow }) => (isLastRow ? '' : 'border-bottom: unset;')}
  font-weight: ${({ isHeaderRow }) => (isHeaderRow ? '600' : '400')};
  grid-template-columns: ${({ reduceLastBox, columnsPrecArray }) =>
    reduceLastBox ? '35% 10% 15% 10% 10% 20%' : '2% 30% 10% 15% 10% 10% 20% 3%'};
  // reduceLastBox ? getColumnsPrecStringCss(columnsPrecArray) : '2% 30% 10% 15% 10% 10% 20% 3%'};
`
/* props?.reduceLastBox ? '20% 15% 15% 10% 15% 25%' : '2% 25% 15% 15% 10% 10% 20% 3%'}; */
/* old grid template columns */

export const PMPSingleRowBox = styled.div`
  background: ${(props) => (props?.whiteBG ? '#FFF' : '#cfcfcf')};
  color: #000;
  display: flex;
  align-items: center;
  padding: 3px 0 3px 3px;
  ${(props) => props?.borderRight && 'border-right: 1px solid #b4b7ba'};
  justify-content: ${(props) => props?.centered && 'center'};
  word-break: break-word;
`

// general styles for prescription making page items

export const PMPSectionHeading = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
  text-decoration: underline;
`

export const PMPSectionHeadingTabView = styled.div`
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
  text-decoration: underline;
`

export const PMPSectionInputLabel = styled.div`
  min-width: 150px;
  max-width: 500px;
  flex-shrink: 0;
  font-weight: ${(props) => (props?.main ? '700' : '500')};
  font-size: 16px;
`

export const PMPSectionInputBox = styled.input`
  padding: 3px 3px;
  width: 100%;
  max-width: 700px;
  border: 1px solid #b4b7ba;
  border-radius: 5px;
  &:focus {
    border-color: green;
    outline: none;
  }
`

export const PMPSectionTextAreaBox = styled(TextareaAutosize)`
  padding: 5px 5px;
  width: 100%;
  min-height: 80px;
  max-width: 700px;
  border: 1px solid #b4b7ba;
  border-radius: 5px;
  &:focus {
    border-color: green;
    outline: none;
  }
`

export const SectionSecondaryHeadingAndContent = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    gap: 20px;
  }
`

// styled for prescription dropdowns
const CreatableAsyncPaginate = withAsyncPaginate(Creatable)

export const InstructionsSelect = muiStyled(CreatableAsyncPaginate)(
  ({ theme }) => `
  .instructions_selection__menu{
  position: unset !important;
  box-shadow: unset!important;
  border: 1px solid #c0c0c0;
  }
  .instructions_selection__menu-list{
  max-height: 300px!important;
  height: 100% !important}
`,
)

export const ComplaintCreatableSelect = muiStyled(CreatableAsyncPaginate)(({ theme }) => ``)

export const DiagnosisCreatableSelect = muiStyled(CreatableAsyncPaginate)(({ theme }) => ``)

export const ObservationsCreatableSelect = muiStyled(CreatableAsyncPaginate)(({ theme }) => ``)

export const AdviceCreatableSelect = muiStyled(CreatableAsyncPaginate)(({ theme }) => ``)

export const ObservationsAdvicesComplaintsAndPrescriptionsContainer = muiStyled('div')(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: 20px;
  
`,
)

export const DoconPageItemsContainer = muiStyled('div')(
  ({ theme, singleItem }) => `
  display: grid;
  grid-template-columns: ${singleItem ? '1fr' : '1fr 1fr'};
  flex-grow: 1;
  height: calc(100vh - 133px - 85px);
`,
)

export const DoconPageSingleItemContainer = muiStyled('div')(
  ({ theme, rightBorder }) => `
  padding: 7px;
  border-right: ${rightBorder ? '1px solid #c6c6c6' : 'none'};
  // max-height: calc(100vh - 200px);
  height: 100%;
  overflow-y: scroll;
`,
)

export const PMPCalendarContainer = styled.div`
  width: 220px;
  .react-calendar {
    font-size: 12px;
  }
  .react-calendar__navigation {
    font-size: 12px;
  }
  .react-calendar__navigation__label__labelText {
    font-size: 12px;
  }
  .react-calendar__navigation__label__labelText--from {
    font-size: 12px;
  }
`

export const PMPV2FooterContainer = muiStyled('div')(
  ({ theme, debug, prescriptionWidth = 100 }) => `
    background: ${debug ? PRESCRIPTION_COLORS_MAP.footer.color : 'unset'};
    width: 100%;
    overflow:hidden;
    box-sizing: border-box; 
    flex-shrink: 0;
    // @media print {
    //   position: fixed;
    //   bottom: 0px;
    //   left: 0px;
    //   height: fit-content;
    //   z-index: 2000;
    //   border-bottom: 1px solid #000;
    //   border-left: 1px solid #000;
    //   border-right: 1px solid #000;  
    //   max-width: ${prescriptionWidth}px; /* Adjust width to fit the printable area */
    //   box-sizing: border-box; /* Ensure borders are included in the width */
    // }
  `,
)

export const PmpHeaderContainer = muiStyled('div')(
  ({ theme, debug, headerHeight }) => `
    background: ${debug ? PRESCRIPTION_COLORS_MAP.header.color : '#FFF'};
    flex-shrink: 0;
    overflow: hidden;
    border-bottom: ${isValidNumber(headerHeight) && headerHeight === 0 ? '' : '1px solid #000'};
    // @media print {
    //     position: fixed;
    //     top: 0px;
    //     left: 0px;
    //     border-top: 1px solid #000;
    //     border-bottom: 1px solid #000;
    //     border-left: 1px solid #000;
    //     border-right: 1px solid #000; 
    //     z-index: 1000; /* Make sure it stays above content */
    // }
  `,
)

export const PmpContentContainer = muiStyled('div')(
  ({ theme }) => `
        flex: 1;
        padding: 10px 20px;
        `,
)

export const PmpConsultationContentContainer = muiStyled('div')(
  ({ theme }) => `
  flex: 1;
  padding: 0px 20px;
  `,
)

export const PmpBodyContentContainer = muiStyled('div')(
  ({ theme, debug, headerHeight = 114, footerHeight = 114 }) => `
      background: ${debug ? PRESCRIPTION_COLORS_MAP.bodyColor.color : 'unset'};
      display: flex;
      flex-direction: column;
      flex-grow: 1;
        @media print {
          // padding-top: ${headerHeight}px; /* Adjust this value to match the header height */
          // padding-bottom: ${footerHeight}px;
          margin: 0; /* Remove default margin */
          // page-break-inside: avoid; /* Avoid breaking content inside the body */
          box-sizing: border-box;
        }
  `,
)

export const PmpConsultationBodyContentContainer = muiStyled('div')(
  ({ theme, debug, headerHeight = 114, footerHeight = 114 }) => `
    position: relative;
    background: ${debug ? PRESCRIPTION_COLORS_MAP.bodyColor.color : 'unset'};
    display: flex;
    height: 100%;
    flex-direction: column;
    flex-grow: 1;
      @media print {
        // padding-top: ${headerHeight}px; /* Adjust this value to match the header height */
        // padding-bottom: ${footerHeight}px;
        margin: 0; /* Remove default margin */
        // page-break-inside: avoid; /* Avoid breaking content inside the body */
        box-sizing: border-box;
      }
  `,
)

export const PMPConsultationParent = styled.div`
  @media print {
    ${'' /* display: block !important; */}// border-bottom: unset !important;;;;;;
  }
  @page {
    size: A4 portrait; /* Page size and orientation */
    margin-top: 1in; /* Top, right, bottom, left */
  }
  border: ${(props) => (props?.noBorder ? '' : '1px solid #000')};
  width: 100%;
  max-width: 794px;
  // min-height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box; /* Ensure borders are included in the width */
  font-size: ${({ biggerFont }) => (biggerFont ? '17px' : '12px')};
  background: #fff;
  color: #000;
`
export const PMPPrintWrapper = muiStyled('div')(
  ({ theme, showInUI }) => `
  height: ${showInUI ? 'unset' : '0px'};
  overflow: hidden;
  @media print {
    height: unset;
  }
`,
)
