import styled from 'styled-components'
import DoneIcon from '@mui/icons-material/Done'
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined'

export default function NumpadComponent({
  clickFunc,
  clickSound,
  submitVisible = false,
  submitDisabled = true,
  submitFunction = () => null,
  backVisible,
  backDisabled,
  backFunction = () => null,
  containerStyles = {},
}) {
  async function handleClick(num) {
    if (clickSound) {
      const soundModule = await import('src/assets/audio/chat_tick.mp3')
      const sound = new Audio(soundModule.default)
      sound.play()
    }
    clickFunc(num)
  }

  return (
    <div className="d-flex flex-column" style={{ gap: '16px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-around', ...containerStyles }}>
        <NumPadButton onClick={() => handleClick(1)}>1</NumPadButton>
        <NumPadButton mid onClick={() => handleClick(2)}>
          2
        </NumPadButton>
        <NumPadButton onClick={() => handleClick(3)}>3</NumPadButton>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-around', ...containerStyles }}>
        <NumPadButton onClick={() => handleClick(4)}>4</NumPadButton>
        <NumPadButton mid onClick={() => handleClick(5)}>
          5
        </NumPadButton>
        <NumPadButton onClick={() => handleClick(6)}>6</NumPadButton>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-around', ...containerStyles }}>
        <NumPadButton onClick={() => handleClick(7)}>7</NumPadButton>
        <NumPadButton mid onClick={() => handleClick(8)}>
          8
        </NumPadButton>
        <NumPadButton onClick={() => handleClick(9)}>9</NumPadButton>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-around', ...containerStyles }}>
        <NumPadButton
          style={{ color: backDisabled ? '#F5F6FA' : '#000', fontSize: '16px' }}
          disabled={backDisabled}
          onClick={() => backFunction('DL')}
        >
          <BackspaceOutlinedIcon style={{ fontSize: '16px', margin: 0 }} />
        </NumPadButton>
        <NumPadButton mid onClick={() => handleClick(0)}>
          0
        </NumPadButton>
        {submitVisible && (
          <NumPadButton
            style={{ background: submitDisabled ? '#C3C0C3' : '#6F5FBF' }}
            disabled={submitDisabled}
            onClick={() => submitFunction && submitFunction()}
          >
            <DoneIcon style={{ color: '#fff', margin: '0' }} />
          </NumPadButton>
        )}
      </div>
    </div>
  )
}

const NumPadButton = styled.button`
  width: 24%;
  font-size: 20px;
  aspect-ratio: 1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:active {
    background: #eeebf5;
  }
`
