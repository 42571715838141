import CloseIcon from '@mui/icons-material/Close'
import KeyboardVoiceOutlinedIcon from '@mui/icons-material/KeyboardVoiceOutlined'
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined'
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined'
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined'
import { Box, CircularProgress, Dialog, DialogActions, Grid, Tooltip } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Skeleton from '@mui/material/Skeleton'
import AgoraRTC from 'agora-rtc-sdk-ng'
import { debounce } from 'lodash'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { toast } from 'react-toastify'
import blockedAccessImage from 'src/assets/blocked_permissions.png'
import allowPermissionOnTopLeftImage from 'src/assets/click_allow_at_top_left.svg'
import firstTimePermissionImage from 'src/assets/first_time_permission.svg'
import googleCalendarIcon from 'src/assets/google_calendar_icon.svg'
import { sortArrayMomentBased } from 'src/clinic/components/ClinicManageBookingsPage/ClinicManageBookingsHelper'
import {
  currency_list,
  EXTERNAL_FEATURE_TYPES,
  GENDER_LIST_ONBOARDING,
} from 'src/consumer/constants'
import { getAgeFromDobWithTime } from 'src/consumer/helpers/profilePageHelper'
import AddOrViewInvestigationsForSelectedPerson from 'src/mentor/components/Health/investigations/AddOrViewInvestigations'
import PrintableScreeningReportComponent from 'src/mentor/components/ScreeningReportMakingPage/printableReport/PrintableScreeningReportComponent'
import TherapyReportPreview from 'src/mentor/components/TherapyReportMakingPage/components/TherapyReportPreview'
import { GenericDisplayFlex, GenericDisplayFlexColumn } from 'src/mentor/layout/Styles'
import { isCommonResponseSuccessful } from '../../../mentor/helpers/utilityFunctions'
import { getAllDomains } from '../../../mentor/layout/utils'
import {
  getMPDataWithoutParams,
  getMPDataWithParams,
  postMPDataWithParams,
} from '../../../mentor/services'
import { addToGoogleCalendarLink, checkAndGetTargetValue } from '../../helpers/utilFunctions'
import { mediaURL } from '../../services'
import { handleAccept } from '../ActivityPlansPage/activityPlanHelpers'
import { ViewOnlyPrescription, ViewOnlyPrescriptionVarTwo } from '../MyLiveSessions'
import {
  LiveSessionErrorSign,
  LiveSessionPermissionsButtonVarOne,
  LiveSessionPermissionsImageVarOne,
  LiveSessionPermissionsParentVarOne,
  LiveSessionPermissionsTitleVarOne,
} from '../ServiceProvidersListingPage/ServiceProvidersListingComponentsStyles'
import {
  CustomInput,
  CustomSelect,
  ImageViewComponent,
  OverflowingArrayChips,
  selectTypes,
} from '../helperComponents'
import {
  AddtoCalendarStyledButton,
  AssignButton,
  EllipsisText,
  LSActivityPlanCells,
  LSActivityPlanDescriptionTooltipText,
  LSActivityPlanFilterContainer,
  LSActivityPlanItemsContainer,
  LSActivityPlanTableContainer,
  LSActivityPlanTableRows,
  LSOfflineSidebarContainer,
  LSPlanBeingSentModalContent,
  LSPlanBeingSentModalDescription,
  LSPlanBeingSentModalDialogTitle,
  LSPlanBeingSentModalTitle,
  LSPlanBeingSentModalTotalDays,
  LSPlanOfferAcceptButton,
  LSPlanOfferCancelButton,
  LSVideoPartHeaderHeading,
  MicCameraToggleContainer,
  StartingCircularProgress,
} from './LiveSessionPageStyles'
import ConnectingLines from './components/ConnectingLines'
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')

// Load the plugins
dayjs.extend(utc)
dayjs.extend(timezone)

// helpers :

export function getMmssString(n, hideBrackets = false) {
  let minutes = Math.floor(n / 60)
  let seconds = n % 60
  let minutesString = minutes?.toString().padStart(2, '0')
  let secondsString = seconds?.toString().padStart(2, '0')
  return hideBrackets
    ? `Time Spent: ${minutesString}:${secondsString}`
    : `(Time Spent: ${minutesString}:${secondsString})`
}

export function getHoursMinutesAndSecondsFromSecondsString(seconds) {
  let minutes = Math.floor(seconds / 60)
  let hours = Math.floor(minutes / 60)
  let remainingMinutes = minutes % 60
  let remainingSeconds = seconds % 60
  let finalString = ''
  if (hours > 0) {
    finalString += `${hours}h `
  }
  if (remainingMinutes > 0) {
    finalString += `${remainingMinutes}m `
  }
  if (remainingSeconds > 0) {
    finalString += `${remainingSeconds}s`
  }
  return finalString
}

export function sortEventsInOrder(inputArray, fieldToCompare, ascending = true) {
  if (Array.isArray(inputArray)) {
    const compareDates = (a, b) => {
      const dateA = new Date(a?.[fieldToCompare])
      const dateB = new Date(b?.[fieldToCompare])
      if (ascending) {
        return dateA - dateB
      } else {
        return dateB - dateA
      }
    }

    return inputArray.sort(compareDates)
  } else {
    return []
  }
}

export function getActiveAndUpcomingEvents(inputArray, number = 3) {
  if (Array.isArray(inputArray)) {
    const upcomingBookedEvents = inputArray?.filter((item) =>
      moment?.utc(item?.endTime)?.local()?.isSameOrAfter(moment()),
    )
    return upcomingBookedEvents?.slice(0, number)
  } else {
    return []
  }
}

export async function getAndSetMicrophones({ setAvailableMicrophones, setSelectedMicrophone }) {
  const microphones = await AgoraRTC.getMicrophones()
  setAvailableMicrophones(microphones)
  setSelectedMicrophone(microphones[0].deviceId)
}

export async function getAndSetCameras({ setAvailableCameras, setSelectedCamera }) {
  const cameras = await AgoraRTC.getCameras()
  setAvailableCameras(cameras)
  setSelectedCamera(cameras[0].deviceId)
}

export async function getAndSetSpeakers({ setAvailableSpeakers, setSelectedSpeaker }) {
  const speakers = await AgoraRTC.getPlaybackDevices()
  setAvailableSpeakers(speakers)
  setSelectedSpeaker(speakers[0].deviceId)
}

// components

export default function LiveSessionSidebar({
  sidebarSelectMenu,
  sidebarSelectedMenuItem,
  setSidebarSelectedMenuItem,
}) {
  return (
    <LSOfflineSidebarContainer>
      {sidebarSelectMenu?.map((item, index) => (
        <div
          style={{
            textAlign: 'center',
            cursor: 'pointer',
            fontWeight: item?.id === sidebarSelectedMenuItem ? '600' : '400',
            color: item?.id === sidebarSelectedMenuItem ? '#FFF' : '#EFEFEF',
          }}
          key={index}
          onClick={() => {
            setSidebarSelectedMenuItem(item?.id)
          }}
        >
          {item?.label}
        </div>
      ))}
    </LSOfflineSidebarContainer>
  )
}

export const LiveSessionHeaderDetailsPart = ({
  bookedEventFinalData,
  auxiliaryData,
  asHost,
  timeElapsedInSeconds,
}) => {
  const prescriptionData = auxiliaryData?.metaPrescription
  const therapyReportData = auxiliaryData?.therapyReport
  const screeningReportData = auxiliaryData?.screeningReport

  const [debugModeClick, seDebugModeClick] = useState(0)

  function handleDebugModeClick() {
    seDebugModeClick((prev) => prev + 1)
  }

  useEffect(() => {
    if (debugModeClick > 10) {
      localStorage.setItem('debugMode', 'true')
      window.location.reload()
    }
  }, [debugModeClick])

  return (
    <GenericDisplayFlex style={{ height: '60px' }} gap="20">
      {!(prescriptionData || therapyReportData || screeningReportData) && (
        <img
          src={require('src/assets/logo_brand.png')}
          style={{ height: '50px', width: '55px', borderRadius: '5px' }}
          alt="logo"
        />
      )}
      <GenericDisplayFlexColumn style={{ paddingLeft: '10px', flexShrink: 0 }}>
        {prescriptionData ? (
          <LSVideoPartHeaderHeading onClick={handleDebugModeClick}>
            Patient: {prescriptionData?.patientPrefill?.name} (
            {getAgeFromDobWithTime(prescriptionData?.patientPrefill?.vital?.dateOfBirth)}/
            {
              GENDER_LIST_ONBOARDING?.find(
                (item) => item.value === prescriptionData?.patientPrefill?.vital?.gender,
              )?.label
            }
            ){' '}
            <>
              {prescriptionData?.patientPrefill?.patientNumber
                ? `(${prescriptionData?.patientPrefill?.patientNumber})`
                : ''}
            </>
          </LSVideoPartHeaderHeading>
        ) : (
          <LSVideoPartHeaderHeading>{bookedEventFinalData?.eventName}</LSVideoPartHeaderHeading>
        )}
        <LSVideoPartHeaderHeading style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
          {bookedEventFinalData?.meetingSrc === 'offline' ? (
            <>
              Booked for{' '}
              {moment?.utc(bookedEventFinalData?.startTime).local()?.format('DD MMM YYYY')},{' '}
              {moment?.utc(bookedEventFinalData?.startTime).local()?.format('h:mma')}
            </>
          ) : (
            <>
              {moment?.utc(bookedEventFinalData?.startTime).local()?.format('DD MMM YYYY')},{' '}
              {moment?.utc(bookedEventFinalData?.startTime).local()?.format('h:mma')} -{' '}
              {moment?.utc(bookedEventFinalData?.endTime).local()?.format('h:mma')}
            </>
          )}

          {asHost && (
            <div style={{ width: '180px', textAlign: 'left' }}>
              <span style={{ marginLeft: '10px' }}>{getMmssString(timeElapsedInSeconds)}</span>
            </div>
          )}
        </LSVideoPartHeaderHeading>
      </GenericDisplayFlexColumn>
      {prescriptionData && (
        <div className="d-flex flex-column justify-content-center gap-1">
          <div style={{ opacity: '0' }}>sample</div>
          <LSVideoPartHeaderHeading>
            Fee Paid:{' '}
            {currency_list.find((item) => item.code === bookedEventFinalData?.currency)?.symbol}
            {bookedEventFinalData?.feePaid}
          </LSVideoPartHeaderHeading>
        </div>
      )}
      {therapyReportData && (
        <LSVideoPartHeaderHeading style={{ alignSelf: 'flex-start' }}>
          Patient: {therapyReportData?.patientName}
          {'  '}({getAgeFromDobWithTime(therapyReportData?.patientDateOfBirth)}/
          {
            GENDER_LIST_ONBOARDING?.find((item) => item.value === therapyReportData?.patientGender)
              ?.label
          }
          )
        </LSVideoPartHeaderHeading>
      )}
      {screeningReportData && !prescriptionData && (
        <LSVideoPartHeaderHeading style={{ alignSelf: 'flex-start' }}>
          {screeningReportData?.patientName}
          {'  '}({getAgeFromDobWithTime(screeningReportData?.patientDateOfBirth)}/
          {
            GENDER_LIST_ONBOARDING?.find(
              (item) => item.value === screeningReportData?.patientGender,
            )?.label
          }
          )
        </LSVideoPartHeaderHeading>
      )}
    </GenericDisplayFlex>
  )
}

export function ActivityPlansListing({ mentorId, clientName, clientId }) {
  const [customPlans, setCustomPlans] = useState([])
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [loadingPlans, setLoadingMorePlans] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [filters, setFilters] = useState({
    domainIds: [],
  })
  const [planToSend, setPlanToSend] = useState()
  const [showPlanSendConfirmation, setShowPlanSendConfirmation] = useState(false)
  const [sending, setSending] = useState(false)

  const [allDomains, setAllDomains] = useState([])

  async function getMoreActivityPlans(page, search, funcFilters = filters) {
    const response = await getMPDataWithParams('/api/secure/teacher/activity-plans/summary', {
      page: page,
      size: 10,
      search,
      mentorId: mentorId,
      domainIds: funcFilters?.domainIds?.length > 0 ? funcFilters?.domainIds?.join(',') : null,
    })
    if (response.status === 200) {
      if (response.data && response.data.length > 0) {
        setCustomPlans((prev) => [...(prev || []), ...(response.data || [])])
        setPage((prev) => prev + 1)
      } else {
        setHasMore(false)
      }
    }
  }

  const debouncedSearch = useCallback(
    debounce(async (value) => {
      await getMoreActivityPlans(1, value)
      setLoadingMorePlans(false)
    }, 500),
    [],
  )

  async function getNextPage() {
    setLoadingMorePlans(true)
    await getMoreActivityPlans(page)
    setLoadingMorePlans(false)
  }

  async function searchFunction(e) {
    setLoadingMorePlans(true)
    setPage(1)
    setCustomPlans([])
    setSearch(e.target.value)
    debouncedSearch(e.target.value)
  }

  const [sentryRef] = useInfiniteScroll({
    loading: loadingPlans,
    hasNextPage: hasMore,
    onLoadMore: getNextPage,
    rootMargin: '0px 0px 400px 0px',
  })

  async function getDomains() {
    const response = await getAllDomains()
    if (response.status === 200) {
      if (response.data && response.data.length > 0) {
        setAllDomains(response.data)
      }
    }
  }

  async function changeFilters() {
    setLoadingMorePlans(true)
    setPage(1)
    setCustomPlans([])
    setHasMore(true)
    await getMoreActivityPlans(1, search, filters)
    setLoadingMorePlans(false)
  }

  async function Setup() {
    await Promise.all([getDomains()])
  }

  function handleChangeFilter(e) {
    setFilters((prev) => ({ ...prev, [e.target.id]: checkAndGetTargetValue(e) }))
  }

  function handleCloseAssignPlanDialog() {
    setShowPlanSendConfirmation(false)
  }

  function assignPlanToUser(planItem) {
    setPlanToSend(planItem)
    setShowPlanSendConfirmation(true)
  }

  async function suggestPlanToUser() {
    setSending(true)
    const resp = await postMPDataWithParams(
      '/api/secure/teacher/activity-plans/send-to-user',
      {},
      {
        activityPlanId: planToSend?.id,
        userId: clientId,
      },
    )
    if (resp?.status === 200 && isCommonResponseSuccessful(resp?.data?.code)) {
      toast.success('Plan sent to the user.')
      handleCloseAssignPlanDialog()
    } else {
      toast.error('Something went wrong.')
    }
    setSending(false)
  }

  useEffect(() => {
    changeFilters()
  }, [filters])

  useEffect(() => {
    Setup()
  }, [])

  return (
    <div
      style={{
        padding: '20px 0px',
      }}
    >
      <LSActivityPlanFilterContainer container spacing={2}>
        <Grid item xs={12} md={6}>
          <CustomInput value={search} placeholder="Search..." onChange={searchFunction} />
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <CustomSelect
                isMulti
                id="domainIds"
                value={filters.domainIds.sort((a, b) => {
                  const titleA = a.title.toLowerCase() // Convert titles to lowercase for case-insensitive sorting
                  const titleB = b.title.toLowerCase()

                  if (titleA < titleB) {
                    return -1 // a should come before b
                  } else if (titleA > titleB) {
                    return 1 // a should come after b
                  } else {
                    return 0 // titles are equal
                  }
                })}
                placeholder="Select a domain..."
                onChangeEvent={handleChangeFilter}
                selectType={selectTypes.string}
                options={allDomains}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
              />
            </Grid>
          </Grid>
        </Grid>
      </LSActivityPlanFilterContainer>
      <LSActivityPlanTableContainer>
        <LSActivityPlanTableRows header>
          <LSActivityPlanCells></LSActivityPlanCells>
          <LSActivityPlanCells>Plan Title</LSActivityPlanCells>
          <LSActivityPlanCells>Plan Description</LSActivityPlanCells>
          <LSActivityPlanCells>Total Days</LSActivityPlanCells>
          <LSActivityPlanCells>Domains</LSActivityPlanCells>
          <LSActivityPlanCells></LSActivityPlanCells>
        </LSActivityPlanTableRows>
        <LSActivityPlanItemsContainer>
          {customPlans?.map((plan, idx) => (
            <LSActivityPlanTableRows key={plan.id} index={idx}>
              <LSActivityPlanCells index={idx}>
                <ImageViewComponent
                  imageId={plan.coverIcon}
                  src={`${mediaURL}${plan.coverIcon}`}
                  alt="cover_image"
                  variant="rounded"
                  sx={{
                    width: '50px',
                    height: '50px',
                  }}
                />
              </LSActivityPlanCells>
              <LSActivityPlanCells index={idx}>{plan.title}</LSActivityPlanCells>
              <LSActivityPlanCells index={idx}>
                <Tooltip
                  title={
                    <>
                      <LSActivityPlanDescriptionTooltipText>
                        {plan.description}
                      </LSActivityPlanDescriptionTooltipText>
                    </>
                  }
                >
                  <EllipsisText>{plan.description}</EllipsisText>
                </Tooltip>
              </LSActivityPlanCells>
              <LSActivityPlanCells index={idx}>{plan.totalDays}</LSActivityPlanCells>
              <LSActivityPlanCells index={idx}>
                <OverflowingArrayChips
                  allItems={plan.domains?.map((tag) => {
                    const foundTag = allDomains?.find((rTag) => rTag?.id === tag)
                    return !!foundTag ? foundTag : { id: tag, title: tag }
                  })}
                  getTitle={(item) => item?.title}
                  maxChips={2}
                  key={idx}
                />
              </LSActivityPlanCells>
              <LSActivityPlanCells actionButton index={idx}>
                <AssignButton onClick={() => assignPlanToUser(plan)}>Suggest</AssignButton>
              </LSActivityPlanCells>
            </LSActivityPlanTableRows>
          ))}
          {(loadingPlans || hasMore) && (
            <div ref={sentryRef}>
              <ActivityPlanItemsSkeleton />
            </div>
          )}
          {/*<ActivityPlanItemsSkeleton />*/}
        </LSActivityPlanItemsContainer>
      </LSActivityPlanTableContainer>
      <Dialog open={showPlanSendConfirmation} onClose={handleCloseAssignPlanDialog}>
        <LSPlanBeingSentModalDialogTitle>
          Do you want to suggest {planToSend?.title || 'this plan'} to {clientName}?{' '}
        </LSPlanBeingSentModalDialogTitle>
        <LSPlanBeingSentModalContent>
          {clientName} will receive a prompt to accept this plan.
        </LSPlanBeingSentModalContent>
        <DialogActions>
          <LSPlanOfferCancelButton onClick={handleCloseAssignPlanDialog}>
            Cancel
          </LSPlanOfferCancelButton>
          <AssignButton disabled={sending} onClick={suggestPlanToUser}>
            {sending && (
              <div
                style={{
                  position: 'absolute',
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress size={24} />
              </div>
            )}
            <span>Suggest</span>
          </AssignButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}

function ActivityPlanItemsSkeleton() {
  const tableSkeletonSx = {
    borderBottom: '1px solid #0000003b',
  }
  const tableSkeletonProps = {
    width: '100%',
    height: '70px',
    animation: 'wave',
    variant: 'rectangular',
  }

  function ActivityPlanRowBareBones({ idx }) {
    return (
      <LSActivityPlanTableRows>
        <LSActivityPlanCells index={idx}>
          <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
        </LSActivityPlanCells>
        <LSActivityPlanCells index={idx}>
          <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
        </LSActivityPlanCells>
        <LSActivityPlanCells index={idx}>
          <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
        </LSActivityPlanCells>
        <LSActivityPlanCells index={idx}>
          <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
        </LSActivityPlanCells>
        <LSActivityPlanCells actionButton index={idx}>
          <Skeleton variant="rounded" width={80} height={25} />
        </LSActivityPlanCells>
      </LSActivityPlanTableRows>
    )
  }

  return (
    <>
      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((n, idx) => (
        <ActivityPlanRowBareBones key={idx} idx={idx} />
      ))}
    </>
  )
}

export function PlanBeingSentModal({
  planData,
  closeModal,
  userProfileData,
  togglePrewall,
  mentorName,
  setShowPlanDetailsModal,
}) {
  const [activityPlan, setActivityPlan] = useState(null)
  const [loading, setLoading] = useState(false)
  const [startingPlan, setStartingPlan] = useState()

  async function getActivityPlan() {
    if (planData?.id) {
      const response = await getMPDataWithParams('/external/feature', {
        feature: EXTERNAL_FEATURE_TYPES.activityPlan,
        id: planData?.id,
      })
      if (response.status === 200) {
        setActivityPlan(response?.data?.data?.['activity-plan'])
      }
    } else {
      toast.error('Something went wrong while receiving the activity plan.')
    }
  }

  function afterStartPlanFunction() {
    toast.success('Plan started successfully.')
    closeModal()
  }

  async function handleClickAccept() {
    setStartingPlan(true)
    await handleAccept(activityPlan, userProfileData, togglePrewall, afterStartPlanFunction)
    // let activityPlanSubscriptionPlanResponse
    // if (!!activityPlan?.pricePlan) {
    //   activityPlanSubscriptionPlanResponse = await getMPDataWithParams('/api/subscription-plan', {
    //     id: activityPlan?.pricePlan,
    //   })
    // }
    // if (userSubPlanIsFree) {
    //   if (
    //     activityPlanSubscriptionPlanResponse?.data?.subscriptionType?.id ===
    //     'subscription_normal' ||
    //     activityPlanSubscriptionPlanResponse?.data?.subscriptionType?.id === 'subscription_premium'
    //   ) {
    //     togglePrewall(activityPlanSubscriptionPlanResponse?.data)
    //   }
    // } else {
    //   if (userProfileData?.subscriptionPlanType === 'subscription_normal') {
    //     if (
    //       activityPlanSubscriptionPlanResponse?.data?.subscriptionType?.id ===
    //       'subscription_premium'
    //     ) {
    //       togglePrewall(activityPlanSubscriptionPlanResponse?.data)
    //     }
    //   }
    // }
    // await handleStartPlan()
    setStartingPlan(false)
  }

  async function Setup() {
    setLoading(true)
    await getActivityPlan()
    setLoading(false)
  }

  useEffect(() => {
    if (planData) {
      Setup()
    }
  }, [planData])

  return (
    <>
      <LSPlanBeingSentModalDialogTitle>{planData?.notifTitle}</LSPlanBeingSentModalDialogTitle>
      <LSPlanBeingSentModalContent>
        <LSPlanBeingSentModalTitle>
          <span>
            <strong>Plan Name: </strong>
            {activityPlan?.title}
          </span>
        </LSPlanBeingSentModalTitle>
        {activityPlan?.description && (
          <LSPlanBeingSentModalDescription>
            {activityPlan?.description}
          </LSPlanBeingSentModalDescription>
        )}
        {activityPlan?.totalDays !== undefined && (
          <div className="d-flex justify-content-start align-items-center gap-2">
            <strong>Total Days: </strong>
            <LSPlanBeingSentModalTotalDays>{activityPlan?.totalDays}</LSPlanBeingSentModalTotalDays>
          </div>
        )}
      </LSPlanBeingSentModalContent>
      <DialogActions>
        <LSPlanOfferCancelButton onClick={closeModal}>Dismiss</LSPlanOfferCancelButton>
        <Box sx={{ m: 1, position: 'relative' }}>
          <LSPlanOfferAcceptButton onClick={handleClickAccept}>
            Accept
            {startingPlan && (
              <StartingCircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </LSPlanOfferAcceptButton>
        </Box>
      </DialogActions>
    </>
  )
}

export const LSPastPrescriptionsComponent = ({ patientId }) => {
  const [pastPrescriptionsLoading, setPastPrescriptionsLoading] = useState(false)
  const [pastPrescriptionsData, setPastPrescriptionsData] = useState(null)

  async function setupThings() {
    setPastPrescriptionsLoading(true)
    const response = await getMPDataWithoutParams(
      `api/secure/patient/reports/list?patientId=${patientId}`,
    )
    setPastPrescriptionsLoading(false)
    if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
      const prescriptionDataList = response?.data?.data?.prescriptionDataList || []
      sortArrayMomentBased(prescriptionDataList, 'createdTime', false)
      const resultArray = prescriptionDataList?.map(
        (pastPrecriptionsDataOnly, pastPrecriptionsDataOnlyIndex) => ({
          ...(response?.data?.data?.prescriptionTemplateList?.[0] || {}),
          prescriptionData: pastPrecriptionsDataOnly,
        }),
      )
      setPastPrescriptionsData(resultArray)
    }
  }

  useEffect(() => {
    setupThings()
  }, [])

  return (
    <div style={{ width: '100%' }}>
      {pastPrescriptionsLoading ? (
        <div style={{ padding: '10px' }}>
          <Spinner animation="border" variant="dark" size="md" />
        </div>
      ) : pastPrescriptionsData ? (
        pastPrescriptionsData?.map((pastPrescription, pastPrescriptionIndex) => {
          return (
            <div key={pastPrescriptionIndex} style={{ margin: '20px auto' }}>
              <ViewOnlyPrescriptionVarTwo prescriptionData={pastPrescription} />
            </div>
          )
        })
      ) : (
        <div>No Data Available</div>
      )}
    </div>
  )
}

export const LSPastVisitReportsComponent = ({
  patientId,
  referenceBookedEventId,
  screeningSections,
  setIsPrintingPastHistoryReports,
  addTopMargins = false,
}) => {
  const [loading, setLoading] = useState(false)
  const [dateMap, setDateMap] = useState({})

  function setDatewiseMapData(incomingData) {
    // const prescriptions = incomingData?.['prescription']
    // const therapyReports = incomingData?.['therapy-report']
    // const screeningReports = incomingData?.['screening-report']
    const metaPrescriptions = incomingData?.['meta-prescription'] || []
    const therapyReportResponse =
      incomingData?.['therapy-report-response']?.map((response) => {
        return { ...response, type: 'therapy-report-response' }
      }) || []
    const screeningReportResponse =
      incomingData?.['screening-report-response']?.map((response) => {
        return { ...response, type: 'screening-report-response' }
      }) || []

    const allReports = [
      // ...prescriptions,
      // ...therapyReports,
      // ...screeningReports,
      ...metaPrescriptions,
      ...therapyReportResponse,
      ...screeningReportResponse,
    ]
    const dateMapLocal = {}
    allReports.forEach((item) => {
      let timestamp = item.createdTime || item.modifiedTime || dayjs().toISOString()
      if (item?.apiParam === 'meta-prescription') {
        timestamp =
          item?.prescriptionData?.createdTime ||
          item?.prescriptionData?.modifiedTime ||
          dayjs().toISOString()
      }
      if (item?.type === 'therapy-report-response') {
        timestamp =
          item?.therapyReport?.createdTime ||
          item?.therapyReport?.modifiedTime ||
          dayjs().toISOString()
      }
      if (item?.type === 'screening-report-response') {
        timestamp =
          item?.screeningReport?.createdTime ||
          item?.screeningReport?.modifiedTime ||
          dayjs().toISOString()
      }

      const localDate = dayjs(timestamp).tz(dayjs.tz.guess()).format('DD/MM/YYYY, dddd')
      if (!dateMapLocal[localDate]) {
        dateMapLocal[localDate] = []
      }
      dateMapLocal[localDate].push(item)
    })

    setDateMap(dateMapLocal)
  }

  async function setupThings() {
    setLoading(true)
    const response = await getMPDataWithoutParams(
      `/api/secure/patient/reports/list?patientId=${patientId}&referenceBookedEventId=${referenceBookedEventId}`,
    )
    setLoading(false)
    if (response.status === 200 && isCommonResponseSuccessful(response.data.code)) {
      setDatewiseMapData(response.data.data)
    } else {
      toast.error('Error getting patient reports')
    }
  }

  useEffect(() => {
    setupThings()
  }, [])

  console.log({ dateMap })

  return (
    <>
      {loading ? (
        <>
          <Skeleton variant="rectangular" width={'100%'} height={200} />
          <div style={{ height: '24px' }}></div>
          <Skeleton variant="rectangular" width={'100%'} height={200} />
          <div style={{ height: '24px' }}></div>
          <Skeleton variant="rectangular" width={'100%'} height={200} />
          <div style={{ height: '24px' }}></div>
          <Skeleton variant="rectangular" width={'100%'} height={200} />
          <div style={{ height: '24px' }}></div>
          <Skeleton variant="rectangular" width={'100%'} height={200} />
        </>
      ) : (
        <div
          className="d-flex flex-column w-100"
          style={{ marginTop: addTopMargins ? '20px' : '0px' }}
        >
          {Object.keys(dateMap || {})
            ?.sort((a, b) => {
              const parseDate = (dateStr) => dayjs(dateStr.split(',')[0], 'DD/MM/YYYY')
              return parseDate(b).valueOf() - parseDate(a).valueOf() // Sort by latest first
            })
            ?.map((date, index) => (
              <>
                <VisitReportsComponent
                  key={date}
                  visitNo={Object.keys(dateMap).length - index}
                  date={date}
                  reports={dateMap?.[date]}
                  screeningSections={screeningSections}
                  setIsPrintingPastHistoryReports={setIsPrintingPastHistoryReports}
                />
              </>
            ))}
        </div>
      )}
    </>
  )
}

export const AudioInputToggleComponent = ({ isActive, clickHandler, error }) => {
  return (
    <MicCameraToggleContainer isActive={isActive} onClick={clickHandler}>
      {isActive ? (
        <KeyboardVoiceOutlinedIcon style={{ fontSize: '30px' }} />
      ) : (
        <MicOffOutlinedIcon style={{ fontSize: '30px' }} />
      )}
      {error && <LiveSessionErrorSign>!</LiveSessionErrorSign>}
    </MicCameraToggleContainer>
  )
}

export const VideoInputToggleComponent = ({ isActive, clickHandler, error }) => {
  return (
    <MicCameraToggleContainer isActive={isActive} onClick={clickHandler}>
      {isActive ? (
        <VideocamOutlinedIcon style={{ fontSize: '30px' }} />
      ) : (
        <VideocamOffOutlinedIcon style={{ fontSize: '30px' }} />
      )}
      {error && <LiveSessionErrorSign>!</LiveSessionErrorSign>}
    </MicCameraToggleContainer>
  )
}

export const AddToGoogleCalendarButton = ({
  name,
  description,
  utcStartDateTime,
  utcEndDateTime,
}) => {
  const handleButtonClick = () => {
    const googleCalendarLink = addToGoogleCalendarLink(
      utcStartDateTime,
      utcEndDateTime,
      name,
      description,
      moment,
    )
    // &sf=true&output=xml

    // Open the link in a new tab
    window.open(googleCalendarLink, '_blank')
  }

  return (
    <AddtoCalendarStyledButton onClick={handleButtonClick}>
      Add to Calendar{' '}
      <img style={{ width: '23px' }} src={googleCalendarIcon} alt="google_calendar_icon" />
    </AddtoCalendarStyledButton>
  )
}

export const AskBothCameraAndMicrophonePermissionsDialog = ({
  open,
  onCloseFunction,
  setMicrophoneError,
  setCameraError,
  setAvailableMicrophones,
  setSelectedMicrophone,
  setAvailableSpeakers,
  setSelectedSpeaker,
  setAvailableCameras,
  setSelectedCamera,
}) => {
  return (
    <Dialog
      key="both permission dialog"
      open={open}
      onClose={onCloseFunction}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '750px',
          },
        },
      }}
    >
      <LiveSessionPermissionsParentVarOne>
        <LiveSessionPermissionsImageVarOne
          src={firstTimePermissionImage}
          alt="asking for media access"
        />
        <div>
          <LiveSessionPermissionsTitleVarOne main>
            Do you want people to see and hear you in the meeting?
          </LiveSessionPermissionsTitleVarOne>
          <LiveSessionPermissionsTitleVarOne>
            You can turn off your microphone and camera anytime you want.
          </LiveSessionPermissionsTitleVarOne>
        </div>
        <LiveSessionPermissionsButtonVarOne
          main
          onClick={() => {
            navigator.mediaDevices
              .getUserMedia({ audio: true, video: true })
              .then(() => {
                setMicrophoneError(false)
                getAndSetMicrophones({ setAvailableMicrophones, setSelectedMicrophone })
                getAndSetSpeakers(setAvailableSpeakers, setSelectedSpeaker)

                setCameraError(false)
                getAndSetCameras({ setAvailableCameras, setSelectedCamera })
              })
              .catch((e) => {
                setMicrophoneError(true)
                setCameraError(true)
              })
            onCloseFunction()
          }}
        >
          Allow microphone and camera
        </LiveSessionPermissionsButtonVarOne>
        <LiveSessionPermissionsButtonVarOne
          onClick={() => {
            onCloseFunction()
            setMicrophoneError(true)
            setCameraError(true)
          }}
        >
          Continue without microphone and camera
        </LiveSessionPermissionsButtonVarOne>
      </LiveSessionPermissionsParentVarOne>
    </Dialog>
  )
}

export const AskSinglePermissionDialog = ({
  type,
  open,
  onCloseFunction,
  setMicrophoneError,
  setCameraError,
  setAvailableMicrophones,
  setSelectedMicrophone,
  setAvailableSpeakers,
  setSelectedSpeaker,
  setAvailableCameras,
  setSelectedCamera,
}) => {
  const [promptState, setPromptState] = useState(false)
  const [isBlocked, setIsBlocked] = useState(false)

  async function setThingsUp() {
    if (open) {
      //states: "granted", "prompt", "denied"
      if (type === 'microphone') {
        try {
          const permissionToAccessMicrophone = await navigator.permissions.query({
            name: 'microphone',
          })
          if (permissionToAccessMicrophone.state === 'granted') {
            setMicrophoneError(false)
            getAndSetMicrophones({ setAvailableMicrophones, setSelectedMicrophone })
            getAndSetSpeakers(setAvailableSpeakers, setSelectedSpeaker)
            onCloseFunction()
          } else if (permissionToAccessMicrophone.state === 'prompt') {
            setPromptState(true)
            navigator.mediaDevices
              .getUserMedia({ audio: true })
              .then(() => {
                setMicrophoneError(false)
                getAndSetMicrophones({ setAvailableMicrophones, setSelectedMicrophone })
                getAndSetSpeakers(setAvailableSpeakers, setSelectedSpeaker)
                onCloseFunction()
              })
              .catch((e) => {
                setMicrophoneError(true)
                onCloseFunction()
              })
          } else if (permissionToAccessMicrophone.state === 'denied') {
            setIsBlocked(true)
          }
        } catch (e) {
          setIsBlocked(true)
        }
      } else if (type === 'camera') {
        try {
          const permissionToAccessCamera = await navigator.permissions.query({ name: 'camera' })
          if (permissionToAccessCamera.state === 'granted') {
            setCameraError(false)
            getAndSetCameras({ setAvailableCameras, setSelectedCamera })
            onCloseFunction()
          } else if (permissionToAccessCamera.state === 'prompt') {
            setPromptState(true)
            navigator.mediaDevices
              .getUserMedia({ video: true })
              .then(() => {
                setCameraError(false)
                getAndSetCameras({ setAvailableCameras, setSelectedCamera })
                onCloseFunction()
              })
              .catch((e) => {
                setCameraError(true)
                onCloseFunction()
              })
          } else if (permissionToAccessCamera.state === 'denied') {
            setIsBlocked(true)
          }
        } catch (e) {
          setIsBlocked(true)
        }
      }
    }
  }

  useEffect(() => {
    setThingsUp()

    return () => {
      setPromptState(false)
      setIsBlocked(false)
    }
  }, [open])

  return (
    <Dialog
      key="ask single permission"
      open={open}
      onClose={onCloseFunction}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '750px',
          },
        },
      }}
    >
      <LiveSessionPermissionsParentVarOne>
        <CloseIcon onClick={onCloseFunction} style={{ marginLeft: 'auto', cursor: 'pointer' }} />
        {promptState && (
          <LiveSessionPermissionsImageVarOne
            src={allowPermissionOnTopLeftImage}
            alt="give permission"
          />
        )}
        {isBlocked && (
          <LiveSessionPermissionsImageVarOne
            src={blockedAccessImage}
            alt="media access is blocked"
          />
        )}
        <div>
          {promptState && (
            <>
              <LiveSessionPermissionsTitleVarOne main>
                Click Allow
              </LiveSessionPermissionsTitleVarOne>
              <LiveSessionPermissionsTitleVarOne>
                {type === 'microphone' &&
                  'You can still turn off your microphone at any time during the meeting.'}
                {type === 'camera' &&
                  'You can still turn off your camera at any time during the meeting.'}
              </LiveSessionPermissionsTitleVarOne>
            </>
          )}
          {isBlocked && (
            <>
              <LiveSessionPermissionsTitleVarOne main>
                Aumhum has been blocked from using your{' '}
                {type === 'microphone' ? 'microphone' : 'camera'}
              </LiveSessionPermissionsTitleVarOne>
              <LiveSessionPermissionsTitleVarOne style={{ textAlign: 'left' }}>
                1. Click the page info icon in your browser&apos;s address bar
              </LiveSessionPermissionsTitleVarOne>
              <LiveSessionPermissionsTitleVarOne style={{ textAlign: 'left' }}>
                2. Turn on {type === 'microphone' ? 'microphone' : 'camera'}
              </LiveSessionPermissionsTitleVarOne>
            </>
          )}
        </div>
      </LiveSessionPermissionsParentVarOne>
    </Dialog>
  )
}

export const SelectForMediaDevices = ({ value, handleChange, options }) => {
  return (
    <FormControl sx={{ m: 1, width: 300 }}>
      <Select
        value={value}
        onChange={handleChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
      >
        {options?.map((option) => (
          <MenuItem key={option?.deviceId} value={option?.deviceId}>
            {option?.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export const LSInvestigationsPageComponent = (props) => {
  const { patientId, clinicBrandId, mentorId, name } = props
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        // padding: '80px 16px',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '24px',
        background: '#f0f0f0',
      }}
    >
      <div
        style={{
          border: '1px solid #d3d3d3',
          width: '100%',
          background: '#fff',
        }}
      >
        <AddOrViewInvestigationsForSelectedPerson heightLimit={'calc(100vh - 140px)'} {...props} />
      </div>
    </div>
  )
}

export function VisitReportsComponent({
  visitNo,
  date,
  reports,
  screeningSections = [],
  setIsPrintingPastHistoryReports = () => {},
}) {
  const sortedReports = [...reports].sort((a, b) => {
    let dateA = a.createdTime || a.modifiedTime
    let dateB = b.createdTime || b.modifiedTime

    if (a.apiParam === 'meta-prescription') {
      dateA = a.prescriptionData?.createdTime || a.prescriptionData?.modifiedTime
    }
    if (b.apiParam === 'meta-prescription') {
      dateA = b.prescriptionData?.createdTime || b.prescriptionData?.modifiedTime
    }

    return dayjs(dateB).diff(dayjs(dateA)) // Sort descending (newest first)
  })

  const reportContainerStyles = {
    position: 'relative',
    maxWidth: '794px',
    width: '100%',
    margin: '0 auto',
    padding: '8px 0px 24px',
  }

  return (
    <ConnectingLines date={date} visitNo={visitNo}>
      {sortedReports?.map((report, idx) => {
        if (report?.apiParam === 'meta-prescription') {
          return (
            <div style={reportContainerStyles} key={idx}>
              <div
                className="hide-on-print-only"
                style={{
                  position: 'absolute',
                  left: '-115px',
                  top: '0',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  zIndex: 1,
                }}
              >
                <div
                  style={{ flexGrow: 1, borderLeft: '2px dotted grey', width: '2px', opacity: 0 }}
                ></div>
                <div
                  className="d-flex flex-column align-items-center justify-content-center"
                  style={{
                    background: '#c4b9ff',
                    border: '1px solid #4927ff63',
                    width: '100px',
                  }}
                >
                  <span
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      background: '#7067bb',
                      color: 'white',
                    }}
                  >
                    {/* Consultation with {report?.doctorName} */}
                    {dayjs(
                      report?.prescriptionData?.createdTime ||
                        report?.prescriptionData?.modifiedTime,
                    ).format('h:mm A')}
                  </span>
                  <strong>Consultation</strong>{' '}
                </div>
                <div
                  style={{ flexGrow: 1, borderLeft: '2px dotted grey', width: '2px', opacity: 0 }}
                ></div>
              </div>
              <ViewOnlyPrescription
                prescriptionData={report}
                showHeading
                headingText=""
                printableOnlyWhenPressingOnPrintButton
                setIsPrintingPastHistoryReports={setIsPrintingPastHistoryReports}
              />
            </div>
          )
        }
        if (report?.therapyReport) {
          return (
            <div style={reportContainerStyles} key={idx}>
              <div
                className="hide-on-print-only"
                style={{
                  position: 'absolute',
                  left: '-115px',
                  top: '0',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  zIndex: 1,
                }}
              >
                <div
                  style={{ flexGrow: 1, borderLeft: '2px dotted grey', width: '2px', opacity: 0 }}
                ></div>
                <div
                  className="d-flex flex-column align-items-center justify-content-center"
                  style={{
                    background: '#c4b9ff',
                    border: '1px solid #4927ff63',
                    width: '100px',
                  }}
                >
                  <span
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      background: '#7067bb',
                      color: 'white',
                    }}
                  >
                    {/* Consultation with {report?.doctorName} */}
                    {dayjs(
                      report?.therapyReport?.createdTime || report?.therapyReport?.modifiedTime,
                    ).format('h:mm A')}
                  </span>
                  <strong>Therapy</strong>{' '}
                </div>
                <div
                  style={{ flexGrow: 1, borderLeft: '2px dotted grey', width: '2px', opacity: 0 }}
                ></div>
              </div>
              <TherapyReportPreview
                key={idx}
                report={report?.therapyReport}
                templateData={report?.therapyReportTemplate}
                headingText={''}
                setIsPrintingPastHistoryReports={setIsPrintingPastHistoryReports}
              />
            </div>
          )
        }
        if (report?.screeningReport) {
          return (
            <div style={reportContainerStyles} key={idx}>
              <div
                className="hide-on-print-only"
                style={{
                  position: 'absolute',
                  left: '-115px',
                  top: '0',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  zIndex: 1,
                }}
              >
                <div
                  style={{ flexGrow: 1, borderLeft: '2px dotted grey', width: '2px', opacity: 0 }}
                ></div>
                <div
                  className="d-flex flex-column align-items-center justify-content-center"
                  style={{
                    background: '#c4b9ff',
                    border: '1px solid #4927ff63',
                    width: '100px',
                  }}
                >
                  <span
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      background: '#7067bb',
                      color: 'white',
                    }}
                  >
                    {/* Consultation with {report?.doctorName} */}
                    {dayjs(
                      report?.screeningReport?.createdTime || report?.screeningReport?.modifiedTime,
                    ).format('h:mm A')}
                  </span>
                  <strong>Screening</strong>{' '}
                </div>
                <div
                  style={{ flexGrow: 1, borderLeft: '2px dotted grey', width: '2px', opacity: 0 }}
                ></div>
              </div>
              <PrintableScreeningReportComponent
                key={idx}
                report={report?.screeningReport}
                templateData={report?.screeningReportTemplate}
                headingText={''}
                setIsPrintingPastHistoryReports={setIsPrintingPastHistoryReports}
              />
            </div>
          )
        }
        return null
      })}
      <hr className="hide-on-print-only" />
    </ConnectingLines>
  )
}

// if (report?.screeningReportId) {
//   return (
//     <PrintableScreeningReportComponent
//       key={idx}
//       report={report}
//       headingText={`Screened by ${report?.mentorName} (${dayjs(
//         report?.createdTime || report?.modifiedTime,
//       ).format(MOMENT_FORMATS.onlyTimeDetails)})`}
//       setIsPrintingPastHistoryReports={setIsPrintingPastHistoryReports}
//     />
//   )

/* if (report?.therapyReportId) {
  return (
    <TherapyReportPreview
      key={idx}
      report={report}
      headingText={`Therapy by ${report?.mentorName} (${dayjs(
        report?.createdTime || report?.modifiedTime,
      ).format(MOMENT_FORMATS.onlyTimeDetails)})`}
      setIsPrintingPastHistoryReports={setIsPrintingPastHistoryReports}
    />
  )
} */

// export const TherapyReportViewComponentForDoctor = ({
//   propPatientName,
//   propPatientGender,
//   propPatientDateOfBirth,
// }) => {
//   const therapyReportData = {
//     createdTime: '2024-04-22 07:34:55',
//     modifiedTime: '2024-04-22 07:34:55',
//     patientName: propPatientName || 'Ponpon',
//     patientGender: propPatientGender || 'nonBinary',
//     patientDateOfBirth: propPatientDateOfBirth || '2011-04-02 00:00:00',
//   }
//   const observationAndAdvice = {
//     advice: 'Engage in activities you enjoy and reach out for support from friends, family, or a trusted adult when feeling overwhelmed.',
//     observation: 'Exhibited signs of anxiety, including restlessness and difficulty concentrating.',
//   }
//   const selectedDiagnosis = [{ label: 'DEPRESSION', value: 'DEPRESSION' }]
//   const selectedComplaints = [{ label: 'DECREASED SLEEP', value: 'DECREASED SLEEP' }]
//   const nextVisitInputType = 'days'
//   const nextVisitInputValue = 30

//   return (
//     <div style={{ width: '100%' }}>
//       <PMPParent>
//         <TherapyReportHeader therapyReportData={therapyReportData} therapistName={'Somya Singh'} />
//         <div style={{ flex: 1, padding: '20px' }}>
//           <TherapyReportBodyFirstSection
//             observationAndAdvice={observationAndAdvice}
//             selectedComplaints={selectedComplaints}
//             selectedDiagnosis={selectedDiagnosis}
//           />
//           <TherapyReportBody
//             observationAndAdvice={observationAndAdvice}
//             nextVisitInputType={nextVisitInputType}
//             nextVisitInputValue={nextVisitInputValue}
//           />
//         </div>
//         <TherapyReportFooter therapyReportData={therapyReportData} />
//       </PMPParent>
//     </div>
//   )
// }

// {/* <div
//   style={{
//     border: '1px solid #d3d3d3',
//     width: '100%',
//     background: '#fff',
//   }}
// >
//   <div style={{ padding: '16px', fontSize: '20px', fontWeight: '500', textAlign: 'center' }}>
//     Add Patient Investigation Results
//   </div>
//   <NewInvestigationsForSelectedPerson {...props} />
// </div>
// <div style={{ height: '1px', width: '100%', background: '#d3d3d3' }}></div>
// <div
//   style={{
//     border: '1px solid #d3d3d3',
//     width: '100%',
//     background: '#fff',
//   }}
// >
//   <div style={{ padding: '16px', fontSize: '20px', fontWeight: '500', textAlign: 'center' }}>
//     View Patient Investigation Results
//   </div>
//   <ViewInvestigationsForSelectedPerson {...props} />
// </div>
// <div style={{ height: '1px', width: '100%', background: '#d3d3d3' }}></div> */}

{
  /* <div key={idx}>
                <div>
                  <u>Therapy Report</u>
                </div>
                <PMPParent>
                  <TherapyReportHeader
                    therapyReportData={report}
                    therapistName={report?.mentorName}
                  />
                  <div style={{ flex: 1, padding: '20px' }}>
                    <TherapyReportBodyFirstSection
                      observationAndAdvice={{
                        advice: report?.advice || null,
                        observation: report?.observation || null,
                      }}
                      selectedComplaints={getInitialDataForSettingExistingSelectedComplaints(
                        report?.complaints,
                      )}
                      selectedDiagnosis={getInitialDataForSettingExistingSelectedDiagnosis(
                        report?.diagnosis,
                      )}
                    />
                    <TherapyReportBody
                      observationAndAdvice={{
                        advice: report?.advice || null,
                        observation: report?.observation || null,
                      }}
                      content={report?.content}
                      nextVisitInputType={'days'}
                      nextVisitInputValue={getInitialDataForSettingDaysOfNextVisit(
                        report?.nextVisitDate,
                        report?.createdTime,
                      )}
                    />
                  </div>
                  <TherapyReportFooter therapyReportData={report} />
                </PMPParent>
              </div> */
}

// <div key={idx}>
//   <div>
//     <u>Screening Report</u>
//   </div>
//   <PMPParent>
//     <ScreeningReportHeader
//       screeningReportData={report}
//       screenerName={report?.mentorName}
//     />
//     <div style={{ flex: 1, padding: '20px' }}>
//       <ScreeningReportBodyFirstSection
//         observationAndAdvice={{
//           advice: report?.advice || null,
//           observation: report?.observation || null,
//         }}
//         selectedComplaints={getSRInitialDataForSettingExistingSelectedComplaints(
//           report?.complaints,
//         )}
//         selectedDiagnosis={getSRInitialDataForSettingExistingSelectedDiagnosis(
//           report?.diagnosis,
//         )}
//         // screeningSections={screeningSections}
//         // screeningQuestionAnswers={report?.screeningAnswers}
//         reportData={report}
//       />
//       <ScreeningReportBody
//         observationAndAdvice={{
//           advice: report?.advice || null,
//           observation: report?.observation || null,
//         }}
//         content={report?.content}
//         suggestedBookingTherapy={report?.suggestedBookingTherapy}
//       />
//     </div>
//   </PMPParent>
// </div>
