import { ExpandLess, ExpandMore } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import RefreshIcon from '@mui/icons-material/Refresh'
import {
  Box,
  Button,
  ButtonBase,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  Fab,
  Grid,
  Paper,
  Popover,
} from '@mui/material'
import { styled as muiStyled } from '@mui/system'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { components } from 'react-select'
import { AsyncPaginate } from 'react-select-async-paginate'
import { getCountryCode } from 'src/business/services'
import {
  CustomInput,
  CustomSelect,
  CustomTextArea,
  selectTypes,
} from 'src/consumer/components/helperComponents'
import {
  EVENT_TEMPLATE_DATA,
  VISIT_EVENT_TYPES,
  VISIT_EVENT_TYPES_ARRAY,
  allAvailableEventTypes,
  currency_list,
  platformOptions,
} from 'src/consumer/constants'
import { UnsecureSetupContext } from 'src/consumer/context/UnsecureSetupContext'
import { checkAndGetTargetValue } from 'src/consumer/helpers/utilFunctions'
import { MentorProfileContext } from 'src/mentor/context/MentorProfileContext'
import { GenericDisplayFlex, GenericDisplayFlexColumn } from 'src/mentor/layout/Styles'
import { getAllRolesInBoolean } from 'src/mentor/layout/utils'
import { getMPDataWithoutParams } from 'src/mentor/services'
import styled from 'styled-components'
import {
  GenericModalHeadline,
  GenericModalHeadlineLevelTwo,
  ModalActionButton,
  ModalActionButtonsContainer,
  StyledEventInputContainer,
  StyledEventReactSelect,
  StyledEventTextArea,
  StyledInputBoxModal,
  StyledInputSelect,
  StyledTextAreaBoxModal,
} from '../MentorAvailabilityPage/MentorAvailabilityPageStyles'
import { getCurrencySymbol } from './MentorEventsHelpers'
import BaseAddCustomPriceComponent from './components/BaseAddCustomPriceComponent'
import SelectABundle from './components/bundle/SelectABundle'

export const EventsPageMainItemsContainer = styled.div`
  padding: 16px 20px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  @media (min-width: 768px) {
    padding: 20px 24px;
    flex-direction: row;
  }
`

export const EventsPageItemContainer = styled.div`
  width: 100%;
  padding: 30px 15px;
  border: 1px solid #cccccc;
  ${(props) => props?.enabled && 'border-top: 2px solid #8247f5'};
  ${(props) => !props?.enabled && 'background: #cecece'};
  border-radius: 4px;
  box-shadow: 0 1px 6px 0 rgb(0 0 0/10%);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 2px;
  @media (min-width: 768px) {
    width: 280px;
  }
`

export const NewEventTypeButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  width: calc(100% - 40px);
  border-radius: 40px;
  font-size: 12px;
  font-weight: 500;
  font-family: Outfit;
  border: 1px solid #cccccc;
  padding: 4px 8px;
  background: ${(props) => props?.selected && '#005fb8'};
  color: ${(props) => (props?.selected ? '#fff' : '#000')};
  @media (min-width: 768px) {
    padding: 4px 12px;
    width: auto;
  }
`

export const BookedEventContainer = styled.div`
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  width: 100%;
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const BookedEventTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #000000;
`

export const JoiningLink = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: #3366cc;
  cursor: pointer;
  text-decoration: underline;
`

export const StyledPanelMemberReactSelect = styled(AsyncPaginate)`
  width: 300px;
  @media (min-width: 768px) {
    width: 400px;
  }
`

export const StyledEventsRefreshIcon = styled(RefreshIcon)`
  cursor: pointer;
  margin-right: -3px;
  &:hover {
    transform: rotate(135deg);
  }
  transition: all 0.3s ease !important;
`

export const StyledLocationFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  padding: 5px 10px;
  border: 1px solid #00000033;
  width: 100%;
  border-radius: 7px;
`

export const StyledClinicLocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`
export const StyledRestLocationFieldsContainer = styled.div`
  display: grid;
  width: 100%;
  align-items: flex-start;
  gap: 5px;
  grid-template-columns: repeat(2, 1fr);
`

export const StyledDialogPaper = muiStyled(Paper)(
  ({ theme }) => `
  width: 100%;
  max-width: 900px !important;
  margin: 0px !important;
  max-height: 80vh !important;
  height: 100%;
  @media (min-width: 576px) {
    margin: 10px !important;
  }
`,
)

export const AddPricingFab = muiStyled(Fab)(
  ({ theme }) => `
  background: #ac78ff;
  color: white;
  &:hover {
    background: #8247f5;
  }
`,
)

export const CancelAddCustomPrice = muiStyled(ButtonBase)(
  ({ theme }) => `
  padding: 8px 16px;
  border-radius: 7px;
  font-weight: 500;
`,
)
export const AddCustomPriceButton = muiStyled(ButtonBase)(
  ({ theme }) => `
  padding: 8px 16px;
  border-radius: 7px;
  color: white;
  background: #ac78ff;
  font-weight: 500;
  transition: all 150ms ease-in-out;
  &:disabled {
    background: grey;
  }
  
`,
)
export const ChangesWillNotBeSavedButton = muiStyled(ButtonBase)(
  ({ theme }) => `
  padding: 8px 16px;
  border-radius: 7px;
  color: white;
  background: #d5872f;
  font-weight: 500;
  
`,
)

export const AddCountryPricingPopover = muiStyled(Popover)(
  ({ theme }) => `
  .MuiPopover-paper {
    min-width: 300px;
  }`,
)

export const AddCustomPriceSubHeading = muiStyled('div')(
  ({ theme }) => `
  font-size: 16px;
  font-weight: 400;
`,
)

export const CancelConfirmationButton = muiStyled(ButtonBase)(
  ({ theme }) => `
  padding: 8px 16px;
  border-radius: 7px;
  font-weight: 500;
`,
)
export const DeletePriceButton = muiStyled(ButtonBase)(
  ({ theme }) => `
  padding: 8px 16px;
  border-radius: 7px;
  font-weight: 500;
  background: #d44040;
  color: white;
`,
)
export const SavePlanButton = muiStyled(ButtonBase)(
  ({ theme }) => `
  padding: 8px 16px;
  border-radius: 7px;
  font-weight: 500;
  background: #29a429;
  color: white;
  &:disabled {
    background: grey;
  }
`,
)

export const CountryPricingContainer = muiStyled(Grid)(
  ({ theme }) => `
`,
)

export const ChangeViewPricingHTMLSelect = muiStyled('select')`
  outline: none;
  padding: 6px;
  text-align:center;
background: ${({ invalid }) => (invalid ? '#edbbbb' : '#ebebeb')};
border-radius: 7px;
width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
`
export const ChangeViewPricingHTMLSelectOption = muiStyled('option')`
text-align:left;
`

// components, functions and helpers:

const formatDuration = (minutes) => {
  const durationMoment = moment.duration(minutes, 'minutes')
  const hours = durationMoment.hours()
  const remainingMinutes = durationMoment.minutes()

  if (hours === 0) {
    return `${remainingMinutes} mins`
  } else if (remainingMinutes === 0) {
    return `${hours} hr`
  } else {
    return `${hours} hr ${remainingMinutes} mins`
  }
}

export const getDurationOptions = () => {
  const options = []
  const durations = [5, 10, 15, 20, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180]

  for (const duration of durations) {
    const timeValue = `${duration}`
    const displayText = formatDuration(duration)

    options.push(
      <option key={timeValue} value={timeValue}>
        {displayText}
      </option>,
    )
  }
  return options
}

export const getCurrencyOptions = () => {
  return currency_list?.map((item, index) => {
    return (
      <option key={index} value={item?.code}>
        {item?.name} ({item?.symbol})
      </option>
    )
  })
}

export function SubscriptionOption(props) {
  const currencySymbolToUse = getCurrencySymbol(props)
  const viewPriceCountry = props?.selectProps?.viewPriceCountry

  const selectedPriceToShow =
    props.data?.pricingMatrix?.[viewPriceCountry]?.price || props.data?.price
  const finalPriceToShow =
    props.data?.pricingMatrix?.[viewPriceCountry]?.discountedPrice || props.data?.finalPrice

  const showStrikeThrough = selectedPriceToShow !== finalPriceToShow

  return (
    <components.Option {...props}>
      {props?.data?.id === 'create-plan' ? (
        'Set Custom Price'
      ) : (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {showStrikeThrough ? (
            <div>
              <span>{currencySymbolToUse}</span>
              <span style={{ textDecoration: 'line-through' }}>{selectedPriceToShow}</span> @{' '}
              <span style={{ fontWeight: '700' }}>{currencySymbolToUse}</span>
              <span style={{ fontWeight: '700' }}>{finalPriceToShow}</span>
            </div>
          ) : (
            <div>
              <span>{currencySymbolToUse}</span>
              <span style={{ fontWeight: '700' }}>{finalPriceToShow || 0}</span>
            </div>
          )}
          {!!props.data?.mentorId && <Chip color="default" label="Editable" size="small" />}
        </div>
      )}
    </components.Option>
  )
}

export function SubscriptionValue(props) {
  const currencySymbolToUse = getCurrencySymbol(props)
  const viewPriceCountry = props?.selectProps?.viewPriceCountry

  const selectedPriceToShow =
    props.data?.pricingMatrix?.[viewPriceCountry]?.price || props.data?.price
  const finalPriceToShow =
    props.data?.pricingMatrix?.[viewPriceCountry]?.discountedPrice || props.data?.finalPrice

  const showStrikeThrough = selectedPriceToShow !== finalPriceToShow
  // useEffect(() => {
  //   console.log({ viewPriceCountry, pricingMatrix: props.data?.pricingMatrix })
  // }, [viewPriceCountry])

  return (
    <components.SingleValue {...props}>
      {showStrikeThrough ? (
        <div>
          <span>{currencySymbolToUse}</span>
          <span style={{ textDecoration: 'line-through' }}>{selectedPriceToShow}</span> @{' '}
          <span style={{ fontWeight: '700' }}>{currencySymbolToUse}</span>
          <span style={{ fontWeight: '700' }}>{finalPriceToShow}</span>
        </div>
      ) : (
        <div>
          <span>{currencySymbolToUse}</span>
          <span style={{ fontWeight: '700' }}>{finalPriceToShow || 0}</span>
        </div>
      )}
    </components.SingleValue>
  )
}

export const NewEventPageComponent = ({
  eventFormModalData,
  setEventFormModalOpen,
  displayNamesOfEventTypes,
  handleEventCreation,
  oneOnOnePlans,
  bundlePacks,
  refreshBundlePacks,
  refreshOneOnOnePlans,
  saving,
}) => {
  const { unSecureSetupData } = useContext(UnsecureSetupContext)
  const { mentorProfileData, mentorClinicAssociationData } = useContext(MentorProfileContext)
  const [selectedPanelMembers, setSelectedPanelMembers] = useState([])
  const [loadingPanelMembers, setLoadingPanelMembers] = useState(true)
  const [isCustomTimeChosen, setIsCustomTimeChosen] = useState(false)
  const [viewPriceCountry, setViewPriceCountry] = useState()
  const [showAddCustomPrice, setShowAddCustomPrice] = useState(false)
  const [oneOnOnePlanToEdit, setOneOnOnePlanToEdit] = useState()
  const [bundlePackToEdit, setBundlePackToEdit] = useState()
  const [showBundlePackEditModal, setShowBundlePackEditModal] = useState(false)

  const eventTemplateData = {
    ...EVENT_TEMPLATE_DATA,
    subscriptionPlanId: oneOnOnePlans?.[0]?.id,
  }

  const [basicEventObject, setBasicEventObject] = useState(
    eventFormModalData ? { ...eventFormModalData } : eventTemplateData,
  )

  function saveEvent() {
    let eventObjectToSend = basicEventObject
    handleEventCreation(eventObjectToSend)
  }

  const handleValidation = (data) => {
    if (!data?.title) {
      return false
    }
    if (data?.type !== 'webinar') {
      if (!(parseInt(data?.durationInMins) > 2)) {
        return false
      }
    } else {
      const maxUsers = parseInt(data?.maxUsers)
      const minUsers = parseInt(data?.minUsers)
      if (maxUsers < minUsers) {
        return false
      }
      if (maxUsers < 1) {
        return false
      }
      if (minUsers < 0) {
        return false
      }
    }
    if (!data?.free && !Boolean(data?.subscriptionPlanId)) {
      return false
    }
    return true
  }

  function handleChangeLocation(event) {
    const { id } = event.target
    setBasicEventObject((prev) => ({
      ...prev,
      location: { ...(prev?.location || {}), [id]: checkAndGetTargetValue(event) },
    }))
  }

  function handleEditOneOnOnePlan() {
    const foundPlan = oneOnOnePlans?.find(
      (item) => item?.id === basicEventObject?.subscriptionPlanId,
    )
    setOneOnOnePlanToEdit(foundPlan)
    setShowAddCustomPrice(true)
  }

  function changeCountry(selectValue) {
    setBasicEventObject((prev) => ({
      ...prev,
      location: { ...prev?.location, countryCode: selectValue?.id, country: selectValue?.name },
    }))
  }

  function changePlan(e) {
    console.log(e.target.value)
    if (e.target.value !== 'create-plan') {
      basicEventObject?.type !== allAvailableEventTypes?.webinar
        ? setBasicEventObject((prev) => ({ ...prev, subscriptionPlanId: e.target.value }))
        : setBasicEventObject((prev) => ({ ...prev, pricingPlanId: e.target.value }))
    } else {
      setOneOnOnePlanToEdit()
      setShowAddCustomPrice(true)
    }
  }

  function handleCloseAddCustomPrice() {
    setShowAddCustomPrice(false)
  }

  async function Setup() {
    const currentCountryCode = getCountryCode() || 'IN'
    setViewPriceCountry(currentCountryCode.toLowerCase())
    setLoadingPanelMembers(true)
    const response = await getMPDataWithoutParams(
      `/api/secure/teacher/other-teachers/list?ids=${basicEventObject?.panelIds?.join(',')}`,
    )
    if (response.status === 200) {
      const toSet = basicEventObject?.panelIds?.map((id) => {
        const foundTeacher = response.data.find((teacher) => teacher.id === id) || {
          id: id,
          name: 'Error',
        }
        return foundTeacher
      })
      setSelectedPanelMembers(toSet)
    }
    setLoadingPanelMembers(false)
  }

  function changeLocationBasedOnMentorOrClinicLocation(locationObject, contextId) {
    const contextIdToUse = contextId
    if (contextIdToUse) {
      const foundClinic = mentorClinicAssociationData?.find(
        (clinic) => clinic?.id === contextIdToUse,
      )
      setBasicEventObject((prev) => ({
        ...prev,
        location: locationObject ? locationObject : foundClinic.location,
      }))
    } else {
      setBasicEventObject((prev) => ({
        ...prev,
        location: locationObject ? locationObject : mentorProfileData?.location,
      }))
    }
  }

  function setBundle(value) {
    setBasicEventObject((prev) => {
      if (typeof value === 'function') {
        // If the value is a function, call it with the previous bundles object
        return { ...prev, bundlePlanIds: value(prev?.bundlePlanIds || []) }
      } else {
        // Otherwise, merge the value object into the bundles object
        return { ...prev, bundlePlanIds: { ...(prev.bundlePlanIds || []), ...value } }
      }
    })
  }

  useEffect(() => {
    changeLocationBasedOnMentorOrClinicLocation(
      basicEventObject?.location,
      basicEventObject?.contextId,
    )
  }, [mentorClinicAssociationData, mentorProfileData])

  useEffect(() => {
    Setup()
  }, [])

  useEffect(() => {
    const allDurations = getDurationOptions()
    if (
      !allDurations?.some(
        (item) => parseInt(item?.props?.value) === basicEventObject?.durationInMins,
      )
    ) {
      setIsCustomTimeChosen(true)
    }
  }, [])

  async function loadOptions(search, loadedOptions, { page }) {
    const response = await getMPDataWithoutParams(
      `/api/secure/teacher/other-interviewers/list?search=${search}&page=${page}`,
    )
    var hasMore = true
    var optionsArray
    if (response?.status === 200) {
      optionsArray = response?.data?.map((option, index) => {
        return {
          id: option?.id,
          name: option?.name || option?.firstName,
          profilePicId: option?.profilePicId,
        }
      })
    }
    if (response?.status !== 200 || !optionsArray?.length) {
      hasMore = false
    }

    return {
      options: optionsArray,
      hasMore,
      additional: {
        page: page + 1,
      },
    }
  }

  const handleMultiSelectPanelMembersChange = (value) => {
    setBasicEventObject((prev) => ({
      ...prev,
      panelIds: value.map((val) => val.id),
    }))
    setSelectedPanelMembers(value)
  }

  return (
    <GenericDisplayFlexColumn style={{ gap: '24px', maxWidth: '420px' }}>
      <GenericDisplayFlex sb>
        <GenericModalHeadline>
          {Boolean(eventFormModalData) ? 'Edit event' : 'New event'}
        </GenericModalHeadline>
        <CloseIcon
          sx={{ fontSize: 24, color: 'black', cursor: 'pointer' }}
          onClick={() => {
            setEventFormModalOpen(false)
          }}
        />
      </GenericDisplayFlex>

      <GenericDisplayFlex>
        <div>Enabled</div>
        <Checkbox
          size="small"
          sx={{
            padding: 0,
            marginLeft: 1,
            '&.Mui-checked': {
              color: 'blue',
            },
          }}
          checked={basicEventObject?.enabled}
          onChange={(event) => {
            const { checked } = event.target
            setBasicEventObject((prev) => ({ ...prev, enabled: checked }))
          }}
        />
      </GenericDisplayFlex>

      <div style={{ fontStyle: 'italic' }}>
        Note that Disabled sessions are not listed in the app.
      </div>

      <div>
        <GenericModalHeadlineLevelTwo>Event Title (Publicly Visible)</GenericModalHeadlineLevelTwo>
        <StyledInputBoxModal
          value={basicEventObject?.title}
          onChange={(e) =>
            setBasicEventObject((prev) => ({
              ...prev,
              title: e.target.value,
            }))
          }
        />
      </div>

      <div>
        <GenericModalHeadlineLevelTwo>
          Event Description (Publicly Visible)
        </GenericModalHeadlineLevelTwo>
        <StyledTextAreaBoxModal
          value={basicEventObject?.description}
          onChange={(e) =>
            setBasicEventObject((prev) => ({
              ...prev,
              description: e.target.value,
            }))
          }
        />
      </div>

      {basicEventObject?.type !== allAvailableEventTypes?.webinar && (
        <div>
          <GenericModalHeadlineLevelTwo>Type</GenericModalHeadlineLevelTwo>
          <StyledInputSelect
            value={basicEventObject?.type}
            onChange={(e) =>
              setBasicEventObject((prev) => ({
                ...prev,
                type: e.target.value,
                visitEventType: VISIT_EVENT_TYPES.regular,
              }))
            }
          >
            <option value={allAvailableEventTypes.oneOnOne}>
              {displayNamesOfEventTypes?.[allAvailableEventTypes.oneOnOne]}
            </option>
            <option value={allAvailableEventTypes.screening}>
              {displayNamesOfEventTypes?.[allAvailableEventTypes.screening]}
            </option>
            <option value={allAvailableEventTypes.interview}>
              {displayNamesOfEventTypes?.[allAvailableEventTypes.interview]}
            </option>
            {mentorProfileData?.allowedToPrescribe && getAllRolesInBoolean().isDoctor && (
              <option value={allAvailableEventTypes.consultation}>
                {displayNamesOfEventTypes?.[allAvailableEventTypes.consultation]}
              </option>
            )}
            {mentorProfileData?.services?.live && (
              <option value={allAvailableEventTypes.therapySession}>
                {displayNamesOfEventTypes?.[allAvailableEventTypes.therapySession]}
              </option>
            )}
          </StyledInputSelect>
        </div>
      )}

      {basicEventObject?.type === allAvailableEventTypes.screening && (
        <div>
          Note: Screening events should be offline and associated to a clinic. Patients in clinic
          can opt to go through this step before consulting a psychiatrist.
        </div>
      )}

      {(basicEventObject?.type === allAvailableEventTypes.consultation) && (
        <div>
          <GenericModalHeadlineLevelTwo>Event Visit Type</GenericModalHeadlineLevelTwo>
          <StyledInputSelect
            value={basicEventObject?.visitEventType || ''}
            onChange={(e) => {
              setBasicEventObject((prev) => ({ ...prev, visitEventType: e.target.value }))
            }}
          >
            <option value="" hidden>
              None
            </option>
            {VISIT_EVENT_TYPES_ARRAY?.map((item, index) => {
              return (
                <option key={item?.id} value={item?.id}>
                  {item?.title}
                </option>
              )
            })}
          </StyledInputSelect>
        </div>
      )}

      <div>
        <GenericModalHeadlineLevelTwo>Meeting Source(Platform)</GenericModalHeadlineLevelTwo>
        <StyledInputSelect
          value={basicEventObject?.meetingSrc}
          onChange={(e) => {
            setBasicEventObject((prev) => ({ ...prev, meetingSrc: e.target.value }))
            changeLocationBasedOnMentorOrClinicLocation(
              basicEventObject?.location,
              basicEventObject?.contextId,
            )
          }}
        >
          {platformOptions?.map((item, index) => {
            return (
              <option key={item?.id} value={item?.id}>
                {item?.name}
              </option>
            )
          })}
        </StyledInputSelect>
      </div>

      {basicEventObject?.type === allAvailableEventTypes?.interview && (
        <div>
          <GenericModalHeadlineLevelTwo>Interview Panel Members</GenericModalHeadlineLevelTwo>
          <StyledPanelMemberReactSelect
            isMulti
            // isLoading={loadingPanelMembers}
            value={selectedPanelMembers}
            loadOptions={loadOptions}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.name}
            onChange={handleMultiSelectPanelMembersChange}
            debounceTimeout={500}
            additional={{
              page: 1,
            }}
          />
          {/* <MultipleSelectPanelMembers options={panelMembersData} selected={basicEventObject?.panelIds || []} handleMultiSelectPanelMembersChange={handleMultiSelectPanelMembersChange} /> */}
        </div>
      )}

      {basicEventObject?.type === allAvailableEventTypes?.webinar && (
        <div>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <CustomInput
                id="minUsers"
                label={'Minimum Users'}
                value={basicEventObject?.minUsers || ''}
                inputAndMessageContainer={StyledEventInputContainer}
                placeholder="Enter the minimum users in this webinar..."
                type="number"
                onChange={(e) => {
                  console.log(e.target.value)
                  const inputValue = Math.max(0, parseInt(e.target.value)) // Ensure non-negative number
                  setBasicEventObject((prev) => ({
                    ...prev,
                    minUsers: inputValue,
                  }))
                }}
                disabled={!!basicEventObject?.contextId}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                id="maxUsers"
                label={'Maximum Users'}
                value={basicEventObject?.maxUsers || ''}
                inputAndMessageContainer={StyledEventInputContainer}
                placeholder="Enter the maximum users in this webinar..."
                type="number"
                onChange={(e) => {
                  console.log(e.target.value)
                  const inputValue = Math.max(0, parseInt(e.target.value)) // Ensure non-negative number
                  setBasicEventObject((prev) => ({
                    ...prev,
                    maxUsers: inputValue,
                  }))
                }}
                disabled={!!basicEventObject?.contextId}
                required
              />
            </Grid>
          </Grid>
          {/* <MultipleSelectPanelMembers options={panelMembersData} selected={basicEventObject?.panelIds || []} handleMultiSelectPanelMembersChange={handleMultiSelectPanelMembersChange} /> */}
        </div>
      )}

      {basicEventObject?.type !== allAvailableEventTypes?.webinar && (
        <div>
          <GenericModalHeadlineLevelTwo>
            Duration(Choose from options or enter)
          </GenericModalHeadlineLevelTwo>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '30px' }}>
            <div>
              <input
                type="radio"
                id="selection"
                checked={!isCustomTimeChosen}
                onChange={() => {
                  setIsCustomTimeChosen(false)
                  setBasicEventObject((prev) => ({ ...prev, durationInMins: 30 }))
                }}
              />
              <label style={{ marginLeft: '2px' }} htmlFor="selection">
                Selection
              </label>
            </div>

            <div>
              <input
                type="radio"
                id="customTime"
                checked={isCustomTimeChosen}
                onChange={() => {
                  setIsCustomTimeChosen(true)
                }}
              />
              <label style={{ marginLeft: '5px' }} htmlFor="customTime">
                Custom Time
              </label>
            </div>
          </div>
          <div>
            {!isCustomTimeChosen && (
              <StyledInputSelect
                value={basicEventObject?.durationInMins?.toString()}
                onChange={(e) =>
                  setBasicEventObject((prev) => ({ ...prev, durationInMins: e.target.value }))
                }
              >
                {getDurationOptions()}
              </StyledInputSelect>
            )}
            {isCustomTimeChosen && (
              <div>
                <input
                  style={{ border: '1px solid #B2B2B2', borderRadius: '8px' }}
                  type="number"
                  value={basicEventObject?.durationInMins}
                  onChange={(e) =>
                    setBasicEventObject((prev) => ({ ...prev, durationInMins: e.target.value }))
                  }
                />
                <span>mins</span>
              </div>
            )}
          </div>
        </div>
      )}

      {basicEventObject?.type !== allAvailableEventTypes?.webinar && (
        <>
          <div>
            <GenericModalHeadlineLevelTwo>Buffer Time before event</GenericModalHeadlineLevelTwo>
            <StyledInputSelect
              value={basicEventObject?.preBufferMins}
              onChange={(e) =>
                setBasicEventObject((prev) => ({ ...prev, preBufferMins: e.target.value }))
              }
            >
              <option value={0}>0 mins</option>
              <option value={5}>5 mins</option>
              <option value={10}>10 mins</option>
              <option value={15}>15 mins</option>
            </StyledInputSelect>
          </div>
          <div>
            <GenericModalHeadlineLevelTwo>Buffer Time after event</GenericModalHeadlineLevelTwo>
            <StyledInputSelect
              value={basicEventObject?.postBufferMins}
              onChange={(e) =>
                setBasicEventObject((prev) => ({ ...prev, postBufferMins: e.target.value }))
              }
            >
              <option value={0}>0 mins</option>
              <option value={5}>5 mins</option>
              <option value={10}>10 mins</option>
              <option value={15}>15 mins</option>
            </StyledInputSelect>
          </div>
        </>
      )}

      <GenericDisplayFlex>
        <div>Is the session free?</div>
        <Checkbox
          size="small"
          sx={{
            padding: 0,
            marginLeft: 1,
            '&.Mui-checked': {
              color: 'blue',
            },
          }}
          checked={basicEventObject?.free}
          onChange={(event) => {
            const { checked } = event.target
            setBasicEventObject((prev) => ({ ...prev, free: checked }))
            if (checked) {
              setBasicEventObject((prev) => ({
                ...prev,
                subscriptionPlanId: '',
              }))
            } else {
              setBasicEventObject((prev) => ({
                ...prev,
                subscriptionPlanId: oneOnOnePlans?.[0]?.id,
              }))
            }
          }}
        />
      </GenericDisplayFlex>

      <GenericDisplayFlex>
        <div>Is the session to be listed on AUMHUM Portal?</div>
        <Checkbox
          size="small"
          sx={{
            padding: 0,
            marginLeft: 1,
            '&.Mui-checked': {
              color: 'blue',
            },
          }}
          checked={basicEventObject?.publicEvent}
          onChange={(event) => {
            const { checked } = event.target
            setBasicEventObject((prev) => ({ ...prev, publicEvent: checked }))
          }}
        />
      </GenericDisplayFlex>

      {!basicEventObject?.free && (
        <div>
          <GenericModalHeadlineLevelTwo>Set Session Pricing</GenericModalHeadlineLevelTwo>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <span style={{ flexShrink: '0' }}> Showing prices for </span>
            <CustomSelect
              id="viewPriceCountry"
              value={viewPriceCountry}
              options={[
                ...(unSecureSetupData?.metaCountries || [])?.sort((a, b) =>
                  a?.name?.localeCompare(b.name),
                ),
              ]}
              customComponent={StyledEventReactSelect}
              classNamePrefix="react-select"
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.name}
              placeholder="Change"
              onChangeEvent={(event) => {
                setViewPriceCountry(event.target.value)
              }}
              selectType={selectTypes.string}
            />
          </div>
          <div
            style={{
              padding: '10px 0px',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            {basicEventObject?.type !== allAvailableEventTypes?.webinar ? (
              <CustomSelect
                value={basicEventObject?.subscriptionPlanId}
                options={[
                  { id: 'create-plan', finalPrice: 'Create Plan' },
                  ...(oneOnOnePlans || [])?.filter(
                    (plan) => plan?.mentorId === mentorProfileData?.id || !plan?.mentorId,
                  ),
                ]}
                components={{ Option: SubscriptionOption, SingleValue: SubscriptionValue }}
                metaCountries={unSecureSetupData?.metaCountries}
                viewPriceCountry={viewPriceCountry}
                customComponent={StyledEventReactSelect}
                getOptionLabel={(option) =>
                  option?.id === 'create-plan'
                    ? option?.finalPrice
                    : `${
                        unSecureSetupData?.metaCountries?.find(
                          (countryItem) => countryItem?.currency === option?.currency,
                        )?.currencySymbol
                      } ${option?.finalPrice}`
                }
                getOptionValue={(option) => option?.id}
                onChangeEvent={changePlan}
                selectType={selectTypes.string}
              />
            ) : (
              <CustomSelect
                value={basicEventObject?.pricingPlanId}
                options={[
                  { id: 'create-plan', finalPrice: 'Create Plan' },
                  ...(oneOnOnePlans || [])?.filter(
                    (plan) => plan?.mentorId === mentorProfileData?.id || !plan?.mentorId,
                  ),
                ]}
                components={{ Option: SubscriptionOption, SingleValue: SubscriptionValue }}
                metaCountries={unSecureSetupData?.metaCountries}
                viewPriceCountry={viewPriceCountry}
                customComponent={StyledEventReactSelect}
                getOptionLabel={(option) =>
                  option?.id === 'create-plan'
                    ? option?.finalPrice
                    : `${
                        unSecureSetupData?.metaCountries?.find(
                          (countryItem) => countryItem?.currency === option?.currency,
                        )?.currencySymbol
                      } ${option?.finalPrice}`
                }
                getOptionValue={(option) => option?.id}
                onChangeEvent={changePlan}
                selectType={selectTypes.string}
              />
            )}
            {!!oneOnOnePlans?.find((plan) =>
              basicEventObject?.type !== allAvailableEventTypes?.webinar
                ? plan?.id === basicEventObject?.subscriptionPlanId
                : plan?.id === basicEventObject?.pricingPlanId,
            )?.mentorId && (
              <ChangesWillNotBeSavedButton onClick={handleEditOneOnOnePlan}>
                Edit
              </ChangesWillNotBeSavedButton>
            )}
          </div>
          <ShowDetailsOfSelectedPlan
            planData={oneOnOnePlans?.find((item) =>
              basicEventObject?.type !== allAvailableEventTypes?.webinar
                ? item?.id === basicEventObject?.subscriptionPlanId
                : item?.id === basicEventObject?.pricingPlanId,
            )}
            unSecureSetupData={unSecureSetupData}
          />
          <SelectABundle
            allOneOnOnes={oneOnOnePlans}
            allBundles={bundlePacks}
            refreshBundles={refreshBundlePacks}
            selectedBundleIds={basicEventObject?.bundlePlanIds}
            setSelectedBundleIds={setBundle}
          />
        </div>
      )}

      {basicEventObject?.meetingSrc === 'offline' && (
        <div>
          <GenericModalHeadlineLevelTwo>Meeting Location</GenericModalHeadlineLevelTwo>
          {!!basicEventObject?.contextId ? (
            <StyledClinicLocationContainer>
              <StyledLocationFieldContainer>
                <strong>Address </strong>
                <span>{basicEventObject?.location?.address || 'Not set'}</span>
              </StyledLocationFieldContainer>
              <StyledRestLocationFieldsContainer>
                <StyledLocationFieldContainer>
                  <strong>Country </strong>
                  <span>
                    {unSecureSetupData?.metaCountries?.find(
                      (country) => basicEventObject?.location?.countryCode === country.id,
                    )?.name || 'Not set'}
                  </span>
                </StyledLocationFieldContainer>
                <StyledLocationFieldContainer>
                  <strong>City </strong>
                  <span>{basicEventObject?.location?.city || 'Not set'}</span>
                </StyledLocationFieldContainer>
                <StyledLocationFieldContainer>
                  <strong>State </strong>
                  <span>{basicEventObject?.location?.state || 'Not set'}</span>
                </StyledLocationFieldContainer>
                <StyledLocationFieldContainer>
                  <strong>Pincode </strong>
                  <span>{basicEventObject?.location?.pincode || 'Not set'}</span>
                </StyledLocationFieldContainer>
                <StyledLocationFieldContainer>
                  <strong>Latitude </strong>
                  <span>{basicEventObject?.location?.lat || 'Not set'}</span>
                </StyledLocationFieldContainer>
                <StyledLocationFieldContainer>
                  <strong>Longitude </strong>
                  <span>{basicEventObject?.location?.lng || 'Not set'}</span>
                </StyledLocationFieldContainer>
              </StyledRestLocationFieldsContainer>
            </StyledClinicLocationContainer>
          ) : (
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <CustomTextArea
                  id="address"
                  label={'Address'}
                  minHeight={80}
                  value={basicEventObject?.location?.address}
                  onChange={handleChangeLocation}
                  customTextAreaComponent={StyledEventTextArea}
                  options={unSecureSetupData?.metaCountries}
                  getOptionValue={(option) => option?.id}
                  getOptionLabel={(option) => option.name}
                  selectType={selectTypes.string}
                  disabled={!!basicEventObject?.contextId}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  id="countryCode"
                  label={'Country'}
                  value={basicEventObject?.location?.countryCode}
                  onChange={changeCountry}
                  options={unSecureSetupData?.metaCountries?.sort((a, b) =>
                    a?.name?.localeCompare(b.name),
                  )}
                  customComponent={StyledEventReactSelect}
                  getOptionValue={(option) => option?.id}
                  getOptionLabel={(option) => option.name}
                  selectType={selectTypes.string}
                  isDisabled={!!basicEventObject?.contextId}
                  menuPlacement="auto"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  id="city"
                  label={'City'}
                  value={basicEventObject?.location?.city}
                  inputAndMessageContainer={StyledEventInputContainer}
                  placeholder="Enter your city..."
                  onChange={handleChangeLocation}
                  disabled={!!basicEventObject?.contextId}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  id="state"
                  label={'State'}
                  value={basicEventObject?.location?.state}
                  inputAndMessageContainer={StyledEventInputContainer}
                  placeholder="Enter your state..."
                  onChange={handleChangeLocation}
                  disabled={!!basicEventObject?.contextId}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  id="pincode"
                  label={'Pincode'}
                  value={basicEventObject?.location?.pincode || ''}
                  inputAndMessageContainer={StyledEventInputContainer}
                  placeholder="Enter your pincode..."
                  type="number"
                  onChange={(e) => {
                    console.log(e.target.value)
                    const inputValue = e.target.value.slice(0, 6) // Limit input to 6 characters
                    setBasicEventObject((prev) => ({
                      ...prev,
                      location: {
                        ...prev.location,
                        pincode: inputValue,
                      },
                    }))
                  }}
                  disabled={!!basicEventObject?.contextId}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  id="lat"
                  label={'Latitude'}
                  value={basicEventObject?.location?.lat}
                  inputAndMessageContainer={StyledEventInputContainer}
                  placeholder="Enter latitude..."
                  type="number"
                  onChange={handleChangeLocation}
                  disabled={!!basicEventObject?.contextId}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  id="lng"
                  label={'Longitude'}
                  value={basicEventObject?.location?.lng}
                  inputAndMessageContainer={StyledEventInputContainer}
                  placeholder="Enter Longitude..."
                  type="number"
                  onChange={handleChangeLocation}
                  disabled={!!basicEventObject?.contextId}
                  required
                />
              </Grid>
            </Grid>
          )}
        </div>
      )}

      {Array.isArray(mentorClinicAssociationData) && mentorClinicAssociationData?.length > 0 && (
        <div>
          <GenericModalHeadlineLevelTwo>Associated Clinic</GenericModalHeadlineLevelTwo>
          <StyledInputSelect
            value={basicEventObject?.contextId || ''}
            onChange={(e) => {
              setBasicEventObject((prev) => ({ ...prev, contextId: e.target.value }))
              if (e.target.value !== '') {
                changeLocationBasedOnMentorOrClinicLocation(undefined, e.target.value)
              } else {
                changeLocationBasedOnMentorOrClinicLocation(undefined)
              }
            }}
          >
            <option value="">None</option>
            {mentorClinicAssociationData?.map((item, index) => {
              return (
                <option key={item?.id} value={item?.id}>
                  {item?.name}
                </option>
              )
            })}
          </StyledInputSelect>
        </div>
      )}

      {false && (
        <div>
          <GenericModalHeadlineLevelTwo>Meeting Link</GenericModalHeadlineLevelTwo>
          <StyledInputBoxModal
            value={basicEventObject?.meetingSrcLink}
            onChange={(e) =>
              setBasicEventObject((prev) => ({
                ...prev,
                meetingSrcLink: e.target.value,
              }))
            }
          />
        </div>
      )}

      <ModalActionButtonsContainer>
        <ModalActionButton
          onClick={() => {
            setEventFormModalOpen(false)
          }}
        >
          Cancel
        </ModalActionButton>
        <ModalActionButton
          success={handleValidation(basicEventObject) || !saving}
          onClick={() => {
            if (handleValidation(basicEventObject) || !saving) {
              saveEvent()
            }
          }}
        >
          <Box sx={{ display: 'flex' }}>
            {saving ? (
              <CircularProgress size={24} sx={{ color: '#415dfc' }} />
            ) : (
              <>{Boolean(eventFormModalData) || !!eventFormModalData?.id ? 'Save' : 'Create'}</>
            )}
          </Box>
        </ModalActionButton>
      </ModalActionButtonsContainer>
      <Dialog
        open={showAddCustomPrice}
        onClose={handleCloseAddCustomPrice}
        PaperComponent={StyledDialogPaper}
      >
        <BaseAddCustomPriceComponent
          selectedPlan={oneOnOnePlanToEdit}
          handleChangePlan={changePlan}
          closeFunction={handleCloseAddCustomPrice}
          refreshOneOnOnePlans={refreshOneOnOnePlans}
        />
      </Dialog>
    </GenericDisplayFlexColumn>
  )
}

const ShowDetailsOfSelectedPlan = ({ planData, unSecureSetupData }) => {
  const [showAllPlans, setShowAllPlans] = useState(false)

  const matrixArray = Object.keys(planData?.pricingMatrix || {})?.map((key) => [
    key,
    planData?.pricingMatrix[key],
  ])

  function toggleShowAllPlans() {
    setShowAllPlans((prev) => !prev)
  }

  return (
    <div>
      {/* <span>
        {
          unSecureSetupData?.metaCountries?.find(
            (countryItem) => countryItem?.currency === planData?.currency,
          )?.currencySymbol
        }
      </span>
      <span style={{ textDecoration: 'line-through' }}>{planData?.price}</span>
      <span style={{ marginLeft: '10px', fontWeight: '700' }}>
        {
          unSecureSetupData?.metaCountries?.find(
            (countryItem) => countryItem?.currency === planData?.currency,
          )?.currencySymbol
        }
      </span>
      <span style={{ fontWeight: '700' }}>{planData?.finalPrice}</span> */}
      {!Boolean(planData) && <span style={{ color: 'red' }}>No Plan Selected</span>}
      <Button
        color="primary"
        size="small"
        onClick={toggleShowAllPlans}
        endIcon={showAllPlans ? <ExpandLess /> : <ExpandMore />}
      >
        {!showAllPlans ? 'Show More Details' : 'Hide Details'}
      </Button>
      {showAllPlans &&
        matrixArray?.map((matrixItem, matrixItemIndex) => {
          const countryData = unSecureSetupData?.metaCountries?.find(
            (countryItem) => countryItem?.id === matrixItem?.[0],
          )
          const currencySymbol = unSecureSetupData?.metaCountries?.find(
            (countryItem) => countryItem?.currency === matrixItem?.[1]?.currencyId,
          )?.currencySymbol

          return (
            <div key={matrixItemIndex}>
              <div>
                <b>
                  {countryData?.id === 'row' ? 'Global (Rest of the World)' : countryData?.name}
                </b>
              </div>
              <div>
                <span style={{ textDecoration: 'line-through' }}>
                  {currencySymbol}
                  {matrixItem?.[1]?.price}
                </span>{' '}
                @{' '}
                <span>
                  {currencySymbol}
                  {matrixItem?.[1]?.discountedPrice}
                </span>
              </div>
            </div>
          )
        })}
    </div>
  )
}
