import { PMPColumnedFooter, PMPFooterContainer } from '../../PrescriptionMakingPageStyles'
import RelatedClinicDetails from './relatedClinicDetails/RelatedClinicDetails'

export default function TwoColumnPrescriptionFooter({
  prescriptionData,
  noColor,
  hideQr = true,
  debug = false,
}) {
  return (
    <>
      <PMPColumnedFooter
        id="two-col"
        key="two-col"
        style={{ maxHeight: '133px' }}
        columns={2}
        noColor={noColor}
        debug={debug}
      >
        <RelatedClinicDetails
          footerData={prescriptionData?.relatedClinicDetails?.[0]}
          containerStyle={{ fontSize: '9px' }}
        />
        <RelatedClinicDetails
          footerData={prescriptionData?.relatedClinicDetails?.[1]}
          containerStyle={{ textAlign: 'right', fontSize: '9px' }}
        />
      </PMPColumnedFooter>
      <PMPFooterContainer
        noColor={noColor}
        threeColFooter
        debug={debug}
        style={{
          display: 'flex',
          alignItems: 'flex-center',
          justifyContent: 'space-between',
          fontSize: '9px',
          padding: '2px 30px',
        }}
      >
        <div style={{ textAlign: 'left' }}>{prescriptionData?.footer?.supportTextAlt}</div>
        <div style={{ textAlign: 'right' }}>{prescriptionData?.footer?.subtext}</div>
        <div style={{ textAlign: 'left' }}>{prescriptionData?.footer?.supportText}</div>
      </PMPFooterContainer>
    </>
  )
}
