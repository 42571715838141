import { Print } from '@mui/icons-material'
import { Button, CircularProgress } from '@mui/material'
import { useRef, useState } from 'react'
import generatePDF, { Margin } from 'react-to-pdf'
import { sleep } from 'src/mentor/helpers/utilityFunctions'
import { PMPParent } from '../../PrescriptionMakingPage/PrescriptionMakingPageStyles'
import {
  getDateOfTherapyReport,
  getInitialDataForSettingDaysOfNextVisit,
  getInitialDataForSettingExistingSelectedComplaints,
  getInitialDataForSettingExistingSelectedDiagnosis,
  TherapyReportBody,
  TherapyReportBodyFirstSection,
  TherapyReportFooter,
  TherapyReportHeader,
} from '../TherapyReportMakingPageComponents'

export default function TherapyReportPreview({
  report,
  afterSaveGeneratePdfFunc = () => {},
  hideDownloadButton = false,
  templateData,
  headingText = 'Therapy Report',
  setIsPrintingPastHistoryReports = () => {},
}) {
  const targetRef = useRef()
  const [isPrinting, setIsPrinting] = useState(false)

  const [saving, setSaving] = useState(false)

  async function printTherapy() {
    setIsPrinting(true)
    setIsPrintingPastHistoryReports(true)
    await sleep(1000)
    window.print()
    setIsPrintingPastHistoryReports(false)
    setIsPrinting(false)
  }

  async function saveAndGeneratePDF(type) {
    setSaving(true)
    await afterSaveGeneratePdfFunc()
    const filename = `${report?.patientName && report?.patientName} ${getDateOfTherapyReport(
      report,
    )} Prescription.pdf`
    if (type === 'open') {
      await generatePDF(targetRef, {
        filename: filename,
        method: 'open',
        page: { margin: Margin.MEDIUM },
      })
    } else {
      await generatePDF(targetRef, {
        filename: filename,
        method: 'save',
        page: { margin: Margin.MEDIUM },
      })
    }
    setSaving(false)
  }

  return (
    <div className="w-100">
      <div className="d-flex align-items-center gap-2 mb-2 mt-3 hide-on-print-only">
        <u>{headingText}</u>
        {!hideDownloadButton && (
          <Button
            variant="contained"
            startIcon={<Print />}
            sx={{ padding: '5px 10px', minWidth: '0px' }}
            size="small"
            // onMouseEnter={() => {
            //   setHoverButton(true)
            // }}
            // onMouseDown={() => {
            //   setHoverButton(true)
            // }}
            onClick={() => {
              printTherapy()
            }}
          >
            Print {saving && <CircularProgress size="16px" sx={{ ml: 1 }} color="whiteColor" />}
          </Button>
        )}
      </div>
      <div className={`${!isPrinting ? 'hide-on-print-only' : ''} w-100`}>
        <PMPParent ref={targetRef}>
          <TherapyReportHeader
            therapyReportData={report}
            therapistName={report?.mentorName}
            templateData={templateData}
          />
          <div style={{ flex: 1, padding: '10px' }}>
            <TherapyReportBodyFirstSection
              observationAndAdvice={{
                advice: report?.advice || null,
                observation: report?.observation || null,
              }}
              selectedComplaints={getInitialDataForSettingExistingSelectedComplaints(
                report?.complaints,
              )}
              selectedDiagnosis={getInitialDataForSettingExistingSelectedDiagnosis(
                report?.diagnosis,
              )}
            />
            <TherapyReportBody
              observationAndAdvice={{
                advice: report?.advice || null,
                observation: report?.observation || null,
              }}
              content={report?.content}
              nextVisitInputType={'days'}
              nextVisitInputValue={getInitialDataForSettingDaysOfNextVisit(
                report?.nextVisitDate,
                report?.createdTime,
              )}
            />
          </div>
          <TherapyReportFooter therapyReportData={report} />
        </PMPParent>
      </div>
    </div>
  )
}
