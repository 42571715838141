import { configureStore } from '@reduxjs/toolkit'
import { businessLayoutReducer } from './business/reducers/layoutReducer'
import { prescriptionReducer } from './clinic/reducers/prescription/prescriptionReducer'
import { serviceProvidersDataReducer } from './clinic/reducers/serviceProviders/serviceProvidersReducer'
import { genericDataReducer } from './consumer/reducers/genericData/genericDataReducer'
import { personalDataReducer } from './consumer/reducers/personalData/personalDataReducer'
import { bookingRequestReducer } from './mentor/reducers/bookingRequest/bookingRequestReducer'
import { coursesReducer } from './mentor/reducers/courses/coursesReducer'

export default configureStore({
  reducer: {
    genericDataStore: genericDataReducer,
    personalDataStore: personalDataReducer,
    bookingRequestStore: bookingRequestReducer,
    businessLayoutStore: businessLayoutReducer,
    serviceProvidersDataStore: serviceProvidersDataReducer,
    coursesReducer,
    prescriptionStore: prescriptionReducer,
  },
  devTools: true,
})
