import { JOINT_HOMUNCULUS_DATA } from 'src/consumer/constants'
import { JointCirle, JointHighlighterContainer } from './styles'

export default function JointHomunculusComponent({ editable, data, setData }) {
  // console.log(editable)
  // console.log(data)

  const circleSize1 = {
    height: 17.6,
    width: 17.6,
  }

  const circleSize2 = {
    height: 23.2,
    width: 23.2,
  }

  const circleSize3 = {
    height: 26.4,
    width: 26.4,
  }

  const circleSize4 = {
    height: 13.2,
    width: 12.8,
  }

  const circleSize5 = {
    height: 28.4,
    width: 28.4,
  }

  const circleSize6 = {
    height: 15.6,
    width: 15.6,
  }

  const swollenJointPositions = {
    acromioclavicularright: { size: circleSize1, position: { top: '58', left: '111' } },
    acromioclavicularleft: { size: circleSize1, position: { top: '58', left: '167' } },
    sternoclavicularright: { size: circleSize1, position: { top: '67', left: '130' } },
    sternoclavicularleft: { size: circleSize1, position: { top: '67', left: '149' } },
    shoulderright: { size: circleSize2, position: { top: '70', left: '93' } },
    shoulderleft: { size: circleSize2, position: { top: '70', left: '180' } },
    elbowright: { size: circleSize3, position: { top: '124', left: '91' } },
    elbowleft: { size: circleSize3, position: { top: '124', left: '179' } },
    wristright: { size: circleSize3, position: { top: '179', left: '78' } },
    wristleft: { size: circleSize3, position: { top: '179', left: '192' } },
    pip5right: { size: circleSize4, position: { top: '195', left: '51' } },
    pip5left: { size: circleSize4, position: { top: '195', left: '232' } },
    pip4right: { size: circleSize4, position: { top: '207', left: '60' } },
    pip4left: { size: circleSize4, position: { top: '208', left: '224' } },
    pip3right: { size: circleSize4, position: { top: '218', left: '70' } },
    pip3left: { size: circleSize4, position: { top: '218', left: '213' } },
    pip2right: { size: circleSize4, position: { top: '228', left: '80' } },
    pip2left: { size: circleSize4, position: { top: '228', left: '203' } },
    pip1right: { size: circleSize4, position: { top: '239', left: '90' } },
    pip1left: { size: circleSize4, position: { top: '239', left: '193' } },
    mcp5right: { size: circleSize4, position: { top: '209', left: '33' } },
    mcp5left: { size: circleSize4, position: { top: '209', left: '250' } },
    mcp4right: { size: circleSize4, position: { top: '227', left: '41' } },
    mcp4left: { size: circleSize4, position: { top: '227', left: '242' } },
    mcp3right: { size: circleSize4, position: { top: '239', left: '52' } },
    mcp3left: { size: circleSize4, position: { top: '239', left: '231' } },
    mcp2right: { size: circleSize4, position: { top: '248', left: '65' } },
    mcp2left: { size: circleSize4, position: { top: '248', left: '217' } },
    mcp1right: { size: circleSize4, position: { top: '256', left: '85' } },
    mcp1left: { size: circleSize4, position: { top: '256', left: '198' } },
    kneeright: { size: circleSize5, position: { top: '262', left: '111' } },
    kneeleft: { size: circleSize5, position: { top: '262', left: '157' } },
    ankleright: { size: circleSize2, position: { top: '328', left: '112' } },
    ankleleft: { size: circleSize2, position: { top: '328', left: '161' } },
    mtp5right: { size: circleSize6, position: { top: '379', left: '49' } },
    mtp5left: { size: circleSize6, position: { top: '379', left: '231' } },
    mtp4right: { size: circleSize4, position: { top: '388', left: '65' } },
    mtp4left: { size: circleSize4, position: { top: '388', left: '218' } },
    mtp3right: { size: circleSize4, position: { top: '395', left: '78' } },
    mtp3left: { size: circleSize4, position: { top: '394', left: '205' } },
    mtp2right: { size: circleSize4, position: { top: '398', left: '93' } },
    mtp2left: { size: circleSize4, position: { top: '397', left: '191' } },
    mtp1right: { size: circleSize4, position: { top: '398', left: '107' } },
    mtp1left: { size: circleSize4, position: { top: '396', left: '177' } },
  }

  return (
    <JointHighlighterContainer>
      <img
        src={require('src/assets/swollenJointBase.gif')}
        alt="base_swollen_joint"
        style={{ width: '290px' }}
      />
      {Object.keys(JOINT_HOMUNCULUS_DATA)?.map((item, index) => {
        return (
          <JointCirle
            printType={!Boolean(editable)}
            key={index}
            id={item}
            {...swollenJointPositions?.[item]?.size}
            {...swollenJointPositions?.[item]?.position}
            swollen={data?.[item]}
            style={{ cursor: editable && setData ? 'pointer' : 'default' }}
            onClick={() => {
              if (editable && setData) {
                setData((prev) => ({ ...prev, [item]: !prev?.[item] }))
              }
            }}
          />
        )
      })}
    </JointHighlighterContainer>
  )
}
