import axios from 'axios'
import { useEffect, useState } from 'react'
import { appInstallationId, appVersion, commonHeadersWeb, getApiServerUrlObject, platformWeb } from 'src/consumer/services'

export const clinicApiURL = getApiServerUrlObject().url

export const commonHeadersCPWeb = {
  'ef-app-version': appVersion,
  'ef-app-platform': platformWeb,
  'ef-app-package': 'com.aumhum.web',
  'ef-app-installation-id': appInstallationId,
  'ef-device-model': 'iPad11,6',
  'ef-device-os-version': '16',
  'ef-device-manufacturer': 'apple',
  'ef-session-id': sessionStorage.getItem('sessionId'),
  'ef-user-city': 'moscow',
  'ef-device-os-language': (navigator?.language || navigator?.userLanguage).split('-')[0],
}

export async function getCPDataWithoutParams(url) {
  const token = localStorage.getItem('token')
  try {
    const res = await axios.get(`${clinicApiURL}${url}`, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` }),
        ...commonHeadersWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
    })
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function postCPDataWithoutParams(url, data) {
  const token = localStorage.getItem('token')
  try {
    // const os = `${platform.os.family}`.toLowerCase()
    const res = await axios.post(`${clinicApiURL}${url}`, data, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` }),
        ...commonHeadersWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
    })
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function getCPData(url) {
  const token = localStorage.getItem('token')
  const countryCode = localStorage.getItem('countryCode')
  const languageCode = sessionStorage.getItem('languageCode')
  try {
    const res = await axios.get(
      `${clinicApiURL}${url}?languageCode=${languageCode}&platform=${platformWeb}&countryCode=${countryCode}`,
      {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function getCPDataWithParams(url, apiParams) {
  const token = localStorage.getItem('token')
  const countryCode = localStorage.getItem('countryCode')
  const languageCode = sessionStorage.getItem('languageCode')
  try {
    const res = await axios.get(`${clinicApiURL}${url}`, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` }),
        ...commonHeadersWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
      params: {
        languageCode: languageCode,
        platform: platformWeb,
        countryCode: countryCode,
        ...apiParams,
      },
    })
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}
export async function postCPData(url, data) {
  const token = localStorage.getItem('token')
  const countryCode = localStorage.getItem('countryCode')
  const languageCode = sessionStorage.getItem('languageCode')
  try {
    // const os = `${platform.os.family}`.toLowerCase()
    const res = await axios.post(
      `${clinicApiURL}${url}?languageCode=${languageCode}&platform=${platformWeb}&countryCode=${countryCode}`,
      data,
      {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}
export async function postCPDataWithParams(url, data, params = {}) {
  const token = localStorage.getItem('token')
  const countryCode = localStorage.getItem('countryCode')
  const languageCode = sessionStorage.getItem('languageCode')
  try {
    // const os = `${platform.os.family}`.toLowerCase()
    const res = await axios.post(
      `${clinicApiURL}${url}?languageCode=${languageCode}&platform=${platformWeb}&countryCode=${countryCode}`,
      data,
      {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
        params: params,
      },
    )
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export const useFetchCP = (path, customParams = '', platformType) => {
  const [state, setState] = useState({ loading: true, data: null, error: null })

  const [countryCode, setCountryCode] = useState(localStorage.getItem('countryCode'))

  useEffect(() => {
    function handleChangeStorage() {
      setCountryCode(localStorage.getItem('countryCode'))
    }
    window.addEventListener('storage', handleChangeStorage)
    return () => window.removeEventListener('storage', handleChangeStorage)
  }, [])

  const languageCode = sessionStorage.getItem('languageCode')
  const token = localStorage.getItem('token')

  useEffect(() => {
    setState({ loading: true, data: null, error: null })

    const paramsWeb = `languageCode=${languageCode}&platform=${platformWeb}&countryCode=${countryCode?.toLowerCase()}`

    let params = paramsWeb

    axios
      .get(`${clinicApiURL}${path}?${params}${customParams}`, {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersCPWeb,
          'ef-user-country-code': localStorage.getItem('countryCode')?.toLowerCase(),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      })
      .then((res) => {
        setState({ data: res.data, loading: false, error: null })
      })
      .catch((error) => {
        setState({ data: null, loading: false, error: error })
      })
  }, [path, customParams, token, countryCode])
  return state
}

export async function putCPDataWithoutParams(url, data) {
  const token = localStorage.getItem('token')
  try {
    const res = await axios.put(`${clinicApiURL}${url}`, data, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` }),
        ...commonHeadersCPWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
    })
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function putCPDataWithParams(url, data, params = {}) {
  const token = localStorage.getItem('token')
  const countryCode = localStorage.getItem('countryCode')
  const languageCode = sessionStorage.getItem('languageCode')
  try {
    const res = await axios.put(
      `${clinicApiURL}${url}?languageCode=${languageCode}&platform=${platformWeb}&countryCode=${countryCode}`,
      data,
      {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
        params: params,
      },
    )
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}
