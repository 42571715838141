import styled from 'styled-components'
import { styled as muiStyled } from '@mui/material'

export const FIButtonContainer = muiStyled('div')(
  ({ theme }) =>
    `
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 48px;
  height: 48px;
  transition: all 167ms cubic-bezier(0.33, 0, 0, 1) 0s;
  transform-origin: center center;
  transform: rotate(${(props) => (props.rotated ? '22.5deg' : '0deg')});
  user-select: none;
  background: ${theme.palette.emrPrimary.default};
  border-radius: 50%;
  color: #fff;
  z-index: 2147482999;
  cursor: pointer;
  &:active {
    scale: 0.8;
  }
  @media print {
    display: none !important;
  }
`,
)

export const FIContentContainer = styled.div`
  position: fixed;
  transition: width 207ms cubic-bezier(0.33, 0, 0, 1), height 207ms cubic-bezier(0.33, 0, 0, 1);
  background: #fff;
  width: ${(props) => (props?.opened ? '100vw' : '0')};
  height: ${(props) => (props?.opened ? '100vh' : '0')};
  z-index: 2147483000;
  right: 0;
  bottom: 0;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 40px;
  @media (min-width: 768px) {
    border-radius: 8px;
    bottom: 80px;
    right: 20px;
    width: ${(props) => (props?.opened ? '400px' : '0')};
    height: ${(props) => (props?.opened ? 'min(704px, 100% - 104px)' : '0')};
  }
  @media print {
    display: none !important;
  }
`
