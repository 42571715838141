import { VISIT_EVENT_TYPES } from 'src/consumer/constants'

export const CHOSEN_BLOCK_VIDEO = 'video-block'
export const CHOSEN_BLOCK_IN_PERSON = 'in-person-block'
export const CHOSEN_BLOCK_EMERGENCY_IN_PERSON = 'emergency-block'
export const CHOSEN_BLOCK_FOLLOW_UP_IN_PERSON = 'follow-up-block'
export const CHOSEN_BLOCK_OTHER = 'other-block'

export function getRegularVisitEventsFromListOfEvents(events) {
  return filterEventsBasedOnVisitType(events, VISIT_EVENT_TYPES.regular)
}
export function getEmergencyVisitEventsFromListOfEvents(events) {
  return filterEventsBasedOnVisitType(events, VISIT_EVENT_TYPES.emergency)
}
export function getFollowUpVisitEventsFromListOfEvents(events) {
  return filterEventsBasedOnVisitType(events, VISIT_EVENT_TYPES.followUp)
}

function filterEventsBasedOnVisitType(events, type) {
  if (!events) {
    return []
  }
  return events?.filter((eve) => {
    return eve?.visitEventType === type
  })
}

export function getChosenBlocksBasedOnDefaultSelectedItem({
  defaultSelectedItem,
  defaultInPersonEventData,
  defaultVideoEventData,
  defaultEmergencyEventData,
  defaultFollowUpEventData,
}) {
  if (defaultSelectedItem?.uid === defaultInPersonEventData?.uid) {
    return CHOSEN_BLOCK_IN_PERSON
  }
  if (defaultSelectedItem?.uid === defaultVideoEventData?.uid) {
    return CHOSEN_BLOCK_VIDEO
  }
  if (defaultSelectedItem?.uid === defaultEmergencyEventData?.uid) {
    return CHOSEN_BLOCK_EMERGENCY_IN_PERSON
  }
  if (defaultSelectedItem?.uid === defaultFollowUpEventData?.uid) {
    return CHOSEN_BLOCK_FOLLOW_UP_IN_PERSON
  }

  return CHOSEN_BLOCK_OTHER

  // if (defaultInPersonEventData && defaultOrFirstVideoEventData) {
  //   if (defaultEventTypeData?.uid === defaultOrFirstInPersonEventData?.uid) {
  //     setChosenBlockId(CHOSEN_BLOCK_IN_PERSON)
  //   } else if (defaultEventTypeData?.uid === defaultOrFirstVideoEventData?.uid) {
  //     setChosenBlockId(CHOSEN_BLOCK_VIDEO)
  //   } else {
  //     setChosenBlockId(CHOSEN_BLOCK_IN_PERSON)
  //   }
  // } else if (defaultOrFirstInPersonEventData) {
  //   setChosenBlockId(CHOSEN_BLOCK_IN_PERSON)
  // } else if (defaultOrFirstVideoEventData) {
  //   setChosenBlockId(CHOSEN_BLOCK_VIDEO)
  // }
}
