export const customQualificationsFilter = (option, searchText) => {
  let optionToUse = {
    ...option,
    label: option?.label?.replaceAll('.', ''),
    // data: { ...(option?.data || {}), longTitle:  },
  }

  if (option?.label === 'M.B.B.S.') {
    console.log({ optionToUse })
  }

  if (
    optionToUse?.label?.toLowerCase().includes(searchText.toLowerCase()) ||
    optionToUse?.data?.longTitle?.toLowerCase()?.includes(searchText.toLowerCase())
  ) {
    return true
  } else {
    return false
  }
}
