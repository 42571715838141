import React, { useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import ProtectedRoute from 'src/views/pages/login/ProtectedRoute'
import LiveSessionPageWrapperForWebview from '../components/LiveSessionPage/LiveSessionPageWrapperForWebview'
import { InformationContextProvider } from '../context/InformationContext'
import { SetupContextProvider } from '../context/SetupContext'

// Pages

const MentorPublicPage = React.lazy(() =>
  import('../../mentor/components/MentorPublicPage/MentorPublicPage'),
)

const BusinessPublicPage = React.lazy(() =>
  import('../../business/components/BusinessPublicPage/BusinessPublicPage'),
)

const ClinicPromotionPublicPage = React.lazy(() =>
  import('../../clinic/components/ClinicPromotionPage/ClinicPromotionPublicPage'),
)

const EventBooking = React.lazy(() => import('../components/EventBooking'))
const EventListing = React.lazy(() => import('../components/EventListing'))

const MentorLandingPage = React.lazy(() => import('../components/MentorLandingPage'))

const ClinicBrandLandingPage = React.lazy(() =>
  import('../../clinic/components/ClinicBrandPages/ClinicBrandLandingPage'),
)
const ClinicLandingPage = React.lazy(() =>
  import('../../clinic/components/ClinicBrandPages/ClinicLandingPage'),
)

const ClinicPublicBookingPageOfMentor = React.lazy(() =>
  import('../../clinic/components/ClinicBrandPages/ClinicPublicBookingPageOfMentor'),
)

const EventBookingWrapperForWebview = React.lazy(() =>
  import('../components/EventBooking/EventBookingWrapperForWebview'),
)
const LiveSessionScreen = React.lazy(() => import('../components/LiveSessionPage/index'))
const HotlineChamberPage = React.lazy(() => import('../components/HotlineChamberPage'))

const AssessmentListingPage = React.lazy(() => import('../components/AssessmentsListingPage'))
const AssessmentPlayer = React.lazy(() => import('../components/AssessmentPlayer'))

const JournalsListingPage = React.lazy(() => import('../components/JournalsListingPage'))
const JournalLandingPage = React.lazy(() =>
  import('../components/JournalsListingPage/JournalLandingPage'),
)

const ArticlesListingPage = React.lazy(() => import('../components/ArticlesListingPage'))
const ArticlePage = React.lazy(() => import('../components/ArticlePage'))
const UserActivityListingPage = React.lazy(() =>
  import('../components/UserActivityListingPage/UserActivityListingPageWebWrapper'),
)

const TherapistPsychiatristListingPage = React.lazy(() =>
  import('../components/ServiceProvidersListingPage/TherapistPsychiatristListingPage'),
)

const MyDedicatedPrescriptionPage = React.lazy(() =>
  import('../components/MyDedicatedPrescriptionPage'),
)

const TechniqueListingPage = React.lazy(() => import('../components/TechniqueListingPage'))
const TechniquePage = React.lazy(() => import('../components/TechniqueListingPage/TechniquePage'))

const BreatheListingPage = React.lazy(() => import('../components/BreatheListingPage'))

const CourseCategoryListingPage = React.lazy(() =>
  import('../components/CourseCategoryListingPage/index'),
)

const CourseCategoryLandingPage = React.lazy(() =>
  import('../components/CourseCategoryListingPage/CourseCategoryLandingPage'),
)

const AppHomepage = React.lazy(() => import('../components/AppHomepage'))
const ActivityPlanPage = React.lazy(() =>
  import('../components/ActivityPlansPage/ActivityPlanWebWrapper.jsx'),
)
const ActivityPlanListingPage = React.lazy(() =>
  import('../components/ActivityPlanPublicListing/ActivityPlanPublicListing'),
)

const BehavioralAnxietyPlan = React.lazy(() =>
  import('../components/PersonalizedPlan/BehavioralAddictionPlan'),
)

const AddictionTypesSelectionPage = React.lazy(() =>
  import('../components/AddictionTypesSelectionPage/AddictionTypesSelection'),
)

const WebinarListingPage = React.lazy(() => import('../../business/views/Webinars/WebinarsListing'))
const TimerPage = React.lazy(() => import('../../consumer/components/TimersPage'))

const MentorBookingRequestActivePage = React.lazy(() =>
  import('../../mentor/components/MentorBookingRequestPage/MentorBookingRequestActivePage'),
)
const MentorBookingRequestAllPage = React.lazy(() =>
  import('../../mentor/components/MentorBookingRequestPage'),
)

export default function ConsumerLayout() {
  useEffect(() => {
    if (window.location.pathname !== '/') {
      localStorage.setItem('lastChosenFlow', 'consumer')
      sessionStorage.setItem('chosenSomeFlowInThisSession', true)
    }
  }, [])

  return (
    <SetupContextProvider>
      <InformationContextProvider>
        <Routes>
          {/* todo : remove setup api call and profile api calls in these routes, decide to which routes yet */}

          {/* mentor joining flow: */}
          <Route
            exact
            path="/doctors/join"
            name="Doctor Joining Page"
            element={<Navigate to="/mentors/join?type=doctor" />}
          />
          <Route
            exact
            path="/mentors/join"
            name="Mentor Joining Page"
            element={<MentorPublicPage />}
          />
          <Route
            path="/mentors/apply"
            name="Mentors Join"
            element={
              <ProtectedRoute>
                <MentorPublicPage blankPage={true} />
              </ProtectedRoute>
            }
          />

          {/* workplace joining flow: */}
          <Route
            path="/aumhum-for-workplace"
            name="Work Landing Page"
            element={<BusinessPublicPage />}
          />
          <Route
            path="/aumhum-for-workplace/apply"
            name="Work Landing Page"
            element={
              <ProtectedRoute>
                <BusinessPublicPage blankPage={true} />
              </ProtectedRoute>
            }
          />

          {/* clinic joining flow: */}
          <Route
            path="/aumhum-for-clinics"
            name="Clinic Landing Page"
            element={<ClinicPromotionPublicPage />}
          />
          <Route
            path="/aumhum-for-clinics/apply"
            name="Clinic Landing Page"
            element={
              <ProtectedRoute>
                <ClinicPromotionPublicPage blankPage={true} />
              </ProtectedRoute>
            }
          />
          {/* events booking, listing pages: */}
          <Route
            path="/mentor/:mentorSlug/events/:eventId"
            name="Event Booking Page"
            element={<EventBooking />}
          />
          <Route
            path="/webview/mentor/:mentorSlug/events/:eventId"
            name="Event Booking Page"
            element={<EventBookingWrapperForWebview />}
          />
          <Route
            path="/mentor/:mentorSlug/events"
            name="Event Listing Page"
            element={<EventListing />}
          />

          {/* events joining pages: */}
          <Route
            path="/webview/l/:bookedEventId"
            name="Live Session Page"
            element={<LiveSessionPageWrapperForWebview withSlug={true} />}
          />
          <Route
            path="/l/:bookedEventId"
            name="Live Session Page"
            element={<LiveSessionScreen withSlug={true} />}
          />
          <Route element={<MyDedicatedPrescriptionPage />} path={`session/:eventId/prescription`} />
          <Route
            path="/webview/live/:bookedEventId"
            name="Live Session Page"
            element={<LiveSessionPageWrapperForWebview />}
          />
          <Route
            path="/live/:bookedEventId"
            name="Live Session Page"
            element={<LiveSessionScreen />}
          />
          <Route
            path="/webinar/:bookedEventId"
            name="Webinar Session Page"
            element={<LiveSessionScreen />}
          />

          {/* hotline things: */}
          <Route
            path="/hotline-for-mentor/:clinicId/:satelliteId"
            name="Mentor Side Hotline"
            element={<HotlineChamberPage forMentor={true} />}
          />
          <Route
            path="/hotline-for-clinic-chamber/:clinicId/:satelliteId"
            name="Clinic Sode Hotline"
            element={<HotlineChamberPage forMentor={false} />}
          />

          {/* clinic pages for consumers: */}
          <Route
            path="/clinic/:clinicBrandSlug/id/:clinicId/:mentorSlug/:eventId"
            name="Booking Page with Id"
            element={<ClinicPublicBookingPageOfMentor />}
          />
          <Route
            path="/clinic/:clinicBrandSlug/:clinicSlug/:mentorSlug/:eventId"
            name="Booking Page with Slug"
            element={<ClinicPublicBookingPageOfMentor />}
          />
          <Route
            path="/clinic/:clinicBrandSlug/id/:clinicId"
            name="Clinic Location Marketing Page with Id"
            element={<ClinicLandingPage />}
          />
          <Route
            path="/clinic/:clinicBrandSlug/:clinicSlug"
            name="Clinic Location Marketing Page with Slug"
            element={<ClinicLandingPage showDoctors={true} showTherapists={true} />}
          />
          <Route
            path="/clinic/:clinicBrandSlug/:clinicSlug/doctors"
            name="Clinic Location Marketing Page with Slug"
            element={<ClinicLandingPage showDoctors={true} showTherapists={false} />}
          />
          <Route
            path="/clinic/:clinicBrandSlug/:clinicSlug/therapists"
            name="Clinic Location Marketing Page with Slug"
            element={<ClinicLandingPage showDoctors={false} showTherapists={true} />}
          />
          <Route
            path="/clinic/:clinicBrandSlug/:clinicSlug/therapy"
            name="Clinic Location Marketing Page with Slug"
            element={<ClinicLandingPage showDoctors={false} showTherapists={true} />}
          />
          <Route
            path="/clinic/:clinicBrandSlug"
            name="Clinic Brand Page with slug"
            element={<ClinicBrandLandingPage />}
          />

          {/* mentor page: */}
          <Route
            path="/mentor/:mentorSlug"
            name="Event Listing Page"
            element={<MentorLandingPage />}
          />

          {/* mentors listing pages: */}
          <Route
            path="/mentor"
            name="Mentors Listing"
            element={<TherapistPsychiatristListingPage />}
          />
          <Route
            path="/therapy-psychiatry"
            name="Therapists Listing"
            element={<TherapistPsychiatristListingPage />}
          />

          {/* Assessment Routes : */}
          <Route path="/assessment" name="Assessment Listing" element={<AssessmentListingPage />} />
          <Route path="/assessment/:slug" name="Assessment Player" element={<AssessmentPlayer />} />
          <Route
            path="/assessment/id/:assessmentId"
            name="Assessment Player"
            element={<AssessmentPlayer />}
          />
          <Route
            path="/assessments"
            name="Assessment Listing"
            element={<AssessmentListingPage />}
          />
          <Route
            path="/assessments/:slug"
            name="Assessment Player"
            element={<AssessmentPlayer />}
          />
          <Route
            path="/assessments/id/:assessmentId"
            name="Assessment Player"
            element={<AssessmentPlayer />}
          />

          {/* Article Routes */}
          <Route path="/article" name="Articles Listing" element={<ArticlesListingPage />} />
          <Route path="/article/:slug" name="Article Page" element={<ArticlePage />} />
          <Route path="/article/id/:articleId" name="Article Page" element={<ArticlePage />} />
          <Route path="/articles" name="Articles Listing" element={<ArticlesListingPage />} />
          <Route path="/articles/:slug" name="Article Page" element={<ArticlePage />} />
          <Route path="/articles/id/:articleId" name="Article Page" element={<ArticlePage />} />

          {/* Journal Routes */}
          <Route path="/journal" name="Journals Listing" element={<JournalsListingPage />} />
          <Route
            path="/journal/:slug"
            name="Journal Landing Page"
            element={<JournalLandingPage />}
          />
          <Route
            path="/journal/id/:journalId"
            name="Journal Landing Page"
            element={<JournalLandingPage />}
          />

          {/* Courses Routes */}
          <Route
            path="/c/:courseCategoryId/id/:courseId"
            name="Course Page"
            element={<CourseCategoryLandingPage />}
          />
          <Route
            path="/c/:courseCategoryId/:courseSlug"
            name="Course Page"
            element={<CourseCategoryLandingPage />}
          />
          <Route
            path="/podcast/:courseSlug"
            name="Podcast Page"
            element={<CourseCategoryLandingPage prechosenCategory={'podcast'} />}
          />
          <Route
            path="/c/:categorySlug"
            name="Course Listing Page Page"
            element={<CourseCategoryListingPage />}
          />
          <Route
            path="/podcast"
            name="Podcast Listing Page"
            element={<CourseCategoryListingPage prechosenCategory={'podcast'} />}
          />

          {/* Breathe Routes */}
          <Route path="/breathe" name="Breathe Listing" element={<BreatheListingPage />} />
          <Route path="/breathe/:slug" name="Breathe Player" element={<div />} />

          {/* Technique Routes */}
          <Route path="/technique" name="Technique Listing" element={<TechniqueListingPage />} />
          <Route path="/technique/id/:id" name="Technique Page" element={<TechniquePage />} />
          <Route path="/technique/:slug" name="Technique Page" element={<TechniquePage />} />

          {/* Timer Routes */}
          <Route path="/timer/i/:id" name="Timer Page" element={<TimerPage type="id" />} />
          <Route path="/timer/:id" name="Timer Page" element={<TimerPage type="slug" />} />
          {/* Quote Routes */}
          <Route path="/quotes/:slug" name="Quote Page" element={<div />} />

          {/* Activity Plan Routes */}
          <Route element={<UserActivityListingPage />} path="/my-activity-plans" />
          <Route element={<ActivityPlanListingPage />} path="/plan" />
          <Route element={<ActivityPlanPage />} path="/plan/:planId" />
          <Route element={<ActivityPlanPage useId />} path="/plan/id/:planId" />

          {/* Personalized Plan */}
          <Route
            element={<BehavioralAnxietyPlan />}
            path="/personalized-plan/behavioral-addiction-plan"
          />
          <Route
            path="/personalized-plan/behavioral-addiction-plan/addiction-selection"
            element={<AddictionTypesSelectionPage />}
          />
          <Route
            path="/personalized-plan/behavioral-addiction-plan/assessment-selection"
            element={<AddictionTypesSelectionPage type="assessment-selection" />}
          />

          {/* Others */}
          <Route
            path="/webinars"
            name="Webinars Listing"
            element={<WebinarListingPage header="public" />}
          />
          <Route path="/webinars/:slug" name="Webinar Page" element={<div />} />

          {/* infinite rerendering idk safety hack */}
          <Route
            path="/booking-requests-for-mentors/active"
            name="Booking requests page"
            element={<MentorBookingRequestActivePage />}
          />
          <Route
            path="/booking-requests-for-mentors/all"
            name="Booking requests page all"
            element={<MentorBookingRequestAllPage />}
          />

          {/* homepage and tilespages: */}

          <Route path="/*" name="Application Home" element={<AppHomepage />} />
        </Routes>
      </InformationContextProvider>
    </SetupContextProvider>
  )
}
