import { EventSelectorForBooking } from './EventSelectorForBooking'

export default function EventSelectorWithVisitEventTypesSelection({
  clinicLocationId,
  eventTypesVisible,
  mentorProfileData,
  selectedEventTypeId,
  setSelectedEventTypeId,
  selectedVisitType,
  setSelectedVisitType,
}) {
  //redundant component. Can remove in future if needed.

  console.info({eventTypesVisible})

  return (
    <div className="d-flex flex-column gap-2">
      <EventSelectorForBooking
        clinicLocationId={clinicLocationId}
        // eventTypesVisible={filteredOutEvents}
        eventTypesVisible={eventTypesVisible}
        mentorProfileData={mentorProfileData}
        selectedEventTypeId={selectedEventTypeId}
        setSelectedEventTypeId={setSelectedEventTypeId}
        selectedVisitType={selectedVisitType}
        setSelectedVisitType={setSelectedVisitType}
      />
    </div>
  )
}

// const [distinguishedEventsByVisitTypes, setDistinguishedEventsByVisitTypes] = useState({
//   [VISIT_EVENT_TYPES.regular]: [],
//   [VISIT_EVENT_TYPES.emergency]: [],
//   [VISIT_EVENT_TYPES.followUp]: [],
// })
// const filteredOutEvents = useMemo(() => {
//   return distinguishedEventsByVisitTypes?.[selectedVisitType] || []
// }, [selectedVisitType, distinguishedEventsByVisitTypes])

// // function selectVisitTypeBasedOnTheListingEvents({
// //   regularEvents,
// //   emergencyEvents,
// //   followUpEvents,
// // }) {
// //   if (regularEvents?.length > 0) {
// //     //set here
// //     setSelectedVisitType(VISIT_EVENT_TYPES.regular)
// //     return
// //   }
// //   if (emergencyEvents?.length > 0) {
// //     //set here
// //     setSelectedVisitType(VISIT_EVENT_TYPES.emergency)
// //     return
// //   }
// //   if (followUpEvents?.length > 0) {
// //     //set here
// //     setSelectedVisitType(VISIT_EVENT_TYPES.followUp)
// //     return
// //   }

// //   setSelectedVisitType(VISIT_EVENT_TYPES.regular)
// // }

// function setup() {
//   if (!eventTypesVisible) {
//     return
//   }

//   const regularEvents = getRegularVisitEventsFromListOfEvents(eventTypesVisible)
//   const emergencyEvents = getEmergencyVisitEventsFromListOfEvents(eventTypesVisible)
//   const followUpEvents = getFollowUpVisitEventsFromListOfEvents(eventTypesVisible)

//   // selectVisitTypeBasedOnTheListingEvents({ regularEvents, emergencyEvents, followUpEvents })

//   setDistinguishedEventsByVisitTypes({
//     [VISIT_EVENT_TYPES.regular]: regularEvents,
//     [VISIT_EVENT_TYPES.emergency]: emergencyEvents,
//     [VISIT_EVENT_TYPES.followUp]: followUpEvents,
//   })
// }

// console.log({
//   eventTypesVisible,
//   distinguishedEventsByVisitTypes,
//   selectedVisitType,
//   filteredOutEvents,
// })

// useEffect(() => {
//   setup()
// }, [eventTypesVisible])

// <VisitTypesSelector
//   visitType={selectedVisitType}
//   setSelectedVisitType={setSelectedVisitType}
//   eventsNumberByVisitType={{
//     [VISIT_EVENT_TYPES.regular]:
//       distinguishedEventsByVisitTypes?.[VISIT_EVENT_TYPES.regular]?.length,
//     [VISIT_EVENT_TYPES.emergency]:
//       distinguishedEventsByVisitTypes?.[VISIT_EVENT_TYPES.emergency]?.length,
//     [VISIT_EVENT_TYPES.followUp]:
//       distinguishedEventsByVisitTypes?.[VISIT_EVENT_TYPES.followUp]?.length,
//   }}
// />
