import CryptoJS from "crypto-js";

const SECRET_KEY = 'abcdSuperQwerty1'; // 16-byte key for AES-128
const IV = CryptoJS.enc.Utf8.parse('1234567890123456'); // 16-byte IV for AES-128

// Encrypt function
export const encryptPin = (pin) => {
  const key = CryptoJS.enc.Utf8.parse(SECRET_KEY); // Convert the key to WordArray

  const encrypted = CryptoJS.AES.encrypt(pin, key, {
    iv: IV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  return encrypted.toString(); // Return the base64-encoded encrypted string
};

// Decrypt function
export const decryptPin = (encryptedPin) => {
  const key = CryptoJS.enc.Utf8.parse(SECRET_KEY); // Convert the key to WordArray

  const bytes = CryptoJS.AES.decrypt(encryptedPin, key, {
    iv: IV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  const decrypted = bytes.toString(CryptoJS.enc.Utf8); // Decrypt and convert to string

  if (!decrypted) {
    throw new Error('Failed to decrypt data');
  }

  return decrypted;
}