import AddCircleIcon from '@mui/icons-material/AddCircle'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CloseIcon from '@mui/icons-material/Close'
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp'
import DoneIcon from '@mui/icons-material/Done'
import SearchIcon from '@mui/icons-material/Search'
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight'
import { Dialog, LinearProgress } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import CreatableSelect from 'react-select/creatable'
import { Virtuoso } from 'react-virtuoso'
import { sortArrayMomentBased } from 'src/clinic/components/ClinicManageBookingsPage/ClinicManageBookingsHelper'
import {
  CustomHTMLSelect,
  CustomInput,
  PictureUploadButton,
} from 'src/consumer/components/helperComponents'
import { INVESTIGATION_TYPE_PANEL, INVESTIGATION_TYPE_TEST } from 'src/consumer/constants'
import {
  addOrUpdateInvestigationInIdb,
  findAllPanelsWithGivenTestId,
  getAllInvestigationInIdb,
  getInvestigationByIds,
  searchInvestigationInIdb,
} from 'src/emr/database/indexedDbDao'
import { getImageLink, isCommonResponseSuccessful } from 'src/mentor/helpers/utilityFunctions'
import { getMPDataWithoutParams, postMPDataWithParams } from 'src/mentor/services'
import { v4 as uuidv4 } from 'uuid'
import { MinimalInvestigationSelect } from '../../PrescriptionMakingPage/inputComponents/investigation/InvestigationInputComponent'
import {
  AddOrViewInvsComponentContainer,
  InvestigationHistorySectionButton,
} from './investigationStyles'

const states = {
  HISTORY_PAGE: 'history',
  FORM_PAGE: 'formPage',
}

const historySections = {
  IMAGING: 'imaging',
  PATHOLOGY: 'pathology',
}

export default function AddOrViewInvestigationsForSelectedPerson(props) {
  const { patientId, mentorId, clinicBrandId, name, heightLimit = '400px' } = props

  // v2 states
  const [workingPageState, setWorkingPageState] = useState(states.HISTORY_PAGE)
  const [selectedTopLevelSection, setSelectedTopLevelSection] = useState(historySections.PATHOLOGY)
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedTest, setSelectedTest] = useState()
  const [editingReportId, setEditingReportId] = useState()
  const [allHistoricalData, setAllHistoricalData] = useState({
    [historySections.IMAGING]: [],
    [historySections.PATHOLOGY]: [],
  })
  const [loadingHistoricalData, setLoadingHistorialData] = useState(false)

  function selectSearchItemAndOpenAddNewForIt(chosenTestData) {
    setSelectedTest(chosenTestData)
    setEditingReportId()
    setWorkingPageState(states.FORM_PAGE)
  }

  function closeFormAndResetToBase() {
    setSelectedTest()
    setEditingReportId()
    setWorkingPageState(states.HISTORY_PAGE)
    setSearchQuery('')
  }

  function openFormAndAddNewTestResults(testData) {
    setSelectedTest()
    setEditingReportId()
    setWorkingPageState(states.FORM_PAGE)
    setSearchQuery('')
  }

  function openFormAndEditExistingTestResults(testData, reportId) {
    setSelectedTest(testData)
    setEditingReportId(reportId)
    setWorkingPageState(states.FORM_PAGE)
    setSearchQuery('')
  }

  function switchToHistorySection(wantedSection) {
    setSelectedTopLevelSection(wantedSection)
  }

  async function getAndSetHistorialData() {
    setLoadingHistorialData(true)
    // setting actual saved values
    const responseTwo = await getMPDataWithoutParams(
      `/api/secure/doctor/investigation-and-report-scan/list?patientId=${patientId}&clinicBrandId=${clinicBrandId}&doctorMentorId=${mentorId}`,
    )
    if (responseTwo?.status === 200 && isCommonResponseSuccessful(responseTwo?.data?.code)) {
      setAllHistoricalData({
        [historySections.IMAGING]:
          responseTwo?.data?.data?.patientInvestigationReportScanList || [],
        [historySections.PATHOLOGY]: responseTwo?.data?.data?.patientInvestigationList || [],
      })
    }
    setLoadingHistorialData(false)
  }

  useEffect(() => {
    getAndSetHistorialData()
  }, [])

  // OLD:

  // async function updateHistoryDataValues() {
  //   const response = await getMPDataWithoutParams(
  //     `/api/secure/doctor/investigation-and-report-scan/list?patientId=${patientId}&clinicBrandId=${clinicBrandId}&doctorMentorId=${mentorId}`,
  //   )
  //   console.log(response)
  //   if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
  //     if (Array.isArray(response?.data?.data?.patientInvestigationList)) {
  //       setExistingInvestigations(response?.data?.data?.patientInvestigationList)
  //       console.log(response?.data?.data?.patientInvestigationList)
  //     }
  //     if (Array.isArray(response?.data?.data?.patientInvestigationReportScanList)) {
  //       setExistingInvestigationScans(response?.data?.data?.patientInvestigationReportScanList)
  //       console.log(response?.data?.data?.patientInvestigationReportScanList)
  //     }
  //   }
  // }

  // const [initialDataStripsLoading, setInitialDataStripsLoading] = useState(false)
  // const [visibleInvestigationStrips, setVisibleInvestigationStrips] = useState([])
  // const [existingInvestigations, setExistingInvestigations] = useState([])
  // const [existingInvestigationScans, setExistingInvestigationScans] = useState([])

  // async function setupDataVisibleInvestigations() {
  //   setInitialDataStripsLoading(true)
  //   const response = await getMPDataWithoutParams(
  //     `/api/secure/doctor/prescription/patient/list?patientId=${patientId}`,
  //   )
  //   let investigationIdsSet = new Set()
  //   // setting recommendations from all last prescriptions data:
  //   if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
  //     const prescriptionDataList = response?.data?.data?.prescriptionDataList || []
  //     sortArrayMomentBased(prescriptionDataList, 'modifiedTime', false)
  //     const resultArray = prescriptionDataList?.map((pastPrecriptionsDataOnly) => ({
  //       ...(response?.data?.data?.prescriptionTemplateList?.[0] || {}),
  //       prescriptionData: pastPrecriptionsDataOnly,
  //     }))
  //     if (Array.isArray(resultArray) && resultArray.length > 0) {
  //       resultArray?.forEach((resultArrayItem) => {
  //         if (Array.isArray(resultArrayItem?.prescriptionData?.investigations)) {
  //           resultArrayItem?.prescriptionData?.investigations?.forEach((item) => {
  //             if (item?.id) {
  //               investigationIdsSet.add(item?.id)
  //             }
  //           })
  //         }
  //       })
  //     }
  //   }
  //   // setting actual saved values
  //   const responseTwo = await getMPDataWithoutParams(
  //     `/api/secure/doctor/investigation-and-report-scan/list?patientId=${patientId}&clinicBrandId=${clinicBrandId}&doctorMentorId=${mentorId}`,
  //   )
  //   if (responseTwo?.status === 200 && isCommonResponseSuccessful(responseTwo?.data?.code)) {
  //     setExistingInvestigationScans(
  //       responseTwo?.data?.data?.patientInvestigationReportScanList || [],
  //     )
  //     if (Array.isArray(responseTwo?.data?.data?.patientInvestigationList)) {
  //       responseTwo?.data?.data?.patientInvestigationList?.forEach((item) => {
  //         if (item?.investigationId) {
  //           investigationIdsSet.add(item?.investigationId)
  //         }
  //       })
  //       setExistingInvestigations(responseTwo?.data?.data?.patientInvestigationList || [])
  //     }
  //   }
  //   let investigationIdsArray = [...investigationIdsSet]
  //   // get and set strips of these investigations
  //   const responseThree = await getMPDataWithoutParams(
  //     `/api/secure/doctor/investigation/list?ids=${investigationIdsArray.join(
  //       ',',
  //     )}&page=1&pageSize=10000`,
  //   )
  //   if (responseThree?.status === 200 && Array.isArray(responseThree?.data)) {
  //     const panelInvestigations = responseThree?.data?.filter(
  //       (item) => item?.investigationType === INVESTIGATION_TYPE_PANEL,
  //     )
  //     const combinedTestIdsFromPanels = panelInvestigations.reduce((accumulator, currentItem) => {
  //       if (Array.isArray(currentItem?.testIds)) {
  //         return accumulator.concat(currentItem?.testIds)
  //       }
  //       return accumulator
  //     }, [])
  //     const finalFilteredData = []
  //     if (Array.isArray(responseThree?.data)) {
  //       responseThree?.data?.forEach((item) => {
  //         if (!combinedTestIdsFromPanels?.includes(item?.id)) {
  //           finalFilteredData.push(item)
  //         }
  //       })
  //     }
  //     setVisibleInvestigationStrips(finalFilteredData)
  //   }
  //   setInitialDataStripsLoading(false)
  // }

  // useEffect(() => {
  //   setupDataVisibleInvestigations()
  // }, [])

  return (
    <AddOrViewInvsComponentContainer className="w-100 d-flex flex-column" heightLimit={heightLimit}>
      <div style={{ height: '40px', flexShrink: 0 }}>
        <SearchInputComponentInvV2 searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      </div>
      <div className="d-flex" style={{ height: '100%' }}>
        <div style={{ width: '20%', height: '100%' }}>
          <SearchListComponentInvV2
            searchQuery={searchQuery}
            clinicBrandId={clinicBrandId}
            selectedTest={selectedTest}
            selectSearchItemAndOpenAddNewForIt={selectSearchItemAndOpenAddNewForIt}
          />
        </div>
        <div style={{ width: '80%', height: '100%' }}>
          {workingPageState === states.HISTORY_PAGE && (
            <HistoryComponentInvV2
              selectedTopLevelSection={selectedTopLevelSection}
              allHistoricalData={allHistoricalData}
              loadingHistoricalData={loadingHistoricalData}
              switchToHistorySection={switchToHistorySection}
              patientId={patientId}
              clinicBrandId={clinicBrandId}
              mentorId={mentorId}
              updateExistingDataValues={getAndSetHistorialData}
              selectSearchItemAndOpenAddNewForIt={selectSearchItemAndOpenAddNewForIt}
              openFormAndEditExistingTestResults={openFormAndEditExistingTestResults}
            />
          )}
          {workingPageState === states.FORM_PAGE && (
            <FormPageInvV2
              selectedTest={selectedTest}
              closeFormAndResetToBase={closeFormAndResetToBase}
              mentorId={mentorId}
              clinicBrandId={clinicBrandId}
              patientId={patientId}
              editingReportId={editingReportId} // or its data - handle later
              updateExistingDataValues={getAndSetHistorialData}
              allExistingPathologyData={allHistoricalData[historySections.PATHOLOGY]}
            />
          )}
        </div>
      </div>
    </AddOrViewInvsComponentContainer>
  )
}

const SingleInvestigationComponent = ({
  templateData,
  existingInvestigations,
  updateExistingDataValues,
  reportDate,
  patientId,
  clinicBrandId,
  reportReferenceId,
  panelData,
  mentorId,
}) => {
  const [saveLoading, setSaveLoading] = useState(false)
  const [value, setValue] = useState('')
  const [unit, setUnit] = useState('')

  const historyOfThisInvestigation = existingInvestigations?.filter(
    (item) => item?.investigationId === templateData?.id,
  )
  const normalRange = (templateData?.referenceRange || [])?.find(
    (item) => item?.normalRange || item?.note,
  )
  sortArrayMomentBased(historyOfThisInvestigation, 'reportDate', false)

  async function saveThis() {
    setSaveLoading(true)
    const data = {
      patientId: patientId,
      clinicBrandId: clinicBrandId,
      investigationId: templateData?.id,
      panelId: panelData?.id,
      doctorMentorId: mentorId,
      reportDate: reportDate?.format('YYYY-MM-DD HH:mm:ss'),
      resultValue: value,
      resultUnit: unit,
      reportReferenceId: reportReferenceId,
      apiParam: 'patient-investigation',
    }
    const response = await postMPDataWithParams(
      '/api/secure/doctor/patient-investigation/save',
      data,
      {},
    )
    if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
      updateExistingDataValues()
      setValue('')
    }
    setSaveLoading(false)
  }

  useEffect(() => {
    if (Array.isArray(templateData?.units) && templateData?.units?.length > 0) {
      setUnit(templateData?.units?.[0])
    }
  }, [])

  const options =
    templateData?.units?.map((item) => ({
      value: item,
      label: item,
    })) || []

  const handleChange = (selectedOption) => {
    if (selectedOption) {
      setUnit(selectedOption?.value)
    }
  }

  return (
    <div style={{ display: 'flex', gap: '8px', padding: '8px', minHeight: '96px' }}>
      <div
        style={{
          width: '240px',
          textAlign: 'center',
          borderLeft: '1px solid #d3d3d3',
          borderRight: '1px solid #d3d3d3',
          flexShrink: 0,
          padding: '0px 12px',
        }}
      >
        <div>{templateData?.title}</div>
        <div style={{ fontSize: '12px' }}>
          {(normalRange?.min || normalRange?.max || normalRange?.unit) && (
            <>
              {(normalRange?.min || normalRange?.max || normalRange?.unit) && (
                <span>{'Normal: '}</span>
              )}
              {normalRange?.min && normalRange?.max ? (
                <span>
                  {normalRange?.min}-{normalRange?.max}{' '}
                </span>
              ) : (
                <span>{normalRange?.note}</span>
              )}{' '}
              {normalRange?.unit && <span>{normalRange?.unit}</span>}
            </>
          )}
        </div>
      </div>
      <div
        style={{
          borderTop: '1px solid #d3d3d3',
          borderBottom: '1px solid #d3d3d3',
          minWidth: '320px',
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
          flexShrink: 0,
        }}
      >
        <input
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          style={{ border: '1px solid #d3d3d3', minHeight: '32px', padding: '4px', width: '80px' }}
        />
        {/* <select
          value={unit}
          onChange={(e) => setUnit(e.target.value)}
          style={{
            border: '1px solid #d3d3d3',
            minHeight: '32px',
            minWidth: '80px',
            padding: '4px',
          }}
        >
          {templateData?.units?.map((item, index) => (
            <option key={index} value={item}>
              {item}
            </option>
          ))}
        </select> */}
        <CreatableSelect
          value={options.find((option) => option.value === unit)}
          onChange={handleChange}
          options={options}
          placeholder="Enter Unit"
          styles={{
            container: (provided) => ({
              ...provided,
              // height: '28px',
              minWidth: '80px',
              borderRadius: 0,
            }),
            control: (provided) => ({
              ...provided,
              border: '1px solid #d3d3d3',
              borderRadius: '0 important',
            }),
            menu: (provided, state) => ({
              ...provided,
              borderRadius: 0,
            }),
            indicatorSeparator: (provided) => ({
              ...provided,
              display: 'none', // Hide the default arrow separator
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              display: 'none', // Hide the default dropdown arrow
            }),
          }}
        />
        <button
          style={{
            background: unit && value ? '#014cf7' : '#7f8389',
            color: '#fff',
            padding: '4px 16px',
            minHeight: '32px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: unit && value ? 'pointer' : 'default',
          }}
          disabled={!unit || !value}
          onClick={saveThis}
        >
          {saveLoading ? <Spinner animation="border" variant="light" size="sm" /> : <DoneIcon />}
        </button>
      </div>
      <div
        style={{
          borderTop: '1px solid #d3d3d3',
          borderBottom: '1px solid #d3d3d3',
          flex: '1 0 0',
          display: 'flex',
          gap: '8px',
          alignItems: 'center',
        }}
      >
        {historyOfThisInvestigation?.map((historyItem, historyItemIndex) => {
          return <HistoryItem key={historyItemIndex} data={historyItem} normalRange={normalRange} />
        })}
      </div>
    </div>
  )
}

const PanelInvestigationComponent = ({
  templateData,
  existingInvestigations,
  updateExistingDataValues,
  reportDate,
  patientId,
  clinicBrandId,
  reportReferenceId,
  mentorId,
}) => {
  const [visibleChildStrips, setVisibleChildStrips] = useState([])
  async function setChildrenSingleItems() {
    if (Array.isArray(templateData?.testIds) && templateData?.testIds?.length > 0) {
      const response = await getMPDataWithoutParams(
        `/api/secure/doctor/investigation/list?ids=${(templateData?.testIds || [])?.join(
          ',',
        )}&page=1&pageSize=10000`,
      )
      if (response?.status === 200 && Array.isArray(response?.data)) {
        setVisibleChildStrips(response?.data)
      }
    }
  }

  useEffect(() => {
    setChildrenSingleItems()
  }, [])

  return (
    <div style={{ padding: '16px 0' }}>
      <div>{templateData?.title}</div>
      <div style={{ padding: '24px', paddingLeft: '48px' }}>
        {visibleChildStrips?.map((visibleChildStrip, visibleChildStripIndex) => {
          return (
            <SingleInvestigationComponent
              key={visibleChildStripIndex}
              templateData={visibleChildStrip}
              panelData={templateData}
              existingInvestigations={existingInvestigations}
              updateExistingDataValues={updateExistingDataValues}
              reportDate={reportDate}
              patientId={patientId}
              clinicBrandId={clinicBrandId}
              reportReferenceId={reportReferenceId}
              mentorId={mentorId}
            />
          )
        })}
      </div>
    </div>
  )
}

//
//
//
//
//
//
//
//
//
//

const PlannedReportScanUploadComponent = ({
  patientId,
  mentorId,
  clinicBrandId,
  updateExistingDataValues,
}) => {
  const [title, setTitle] = useState('')
  const [type, setType] = useState('image')
  const [selectedInvestigations, setSelectedInvestigations] = useState([])
  const [imageIds, setImageIds] = useState([])
  const [saveLoading, setSaveLoading] = useState(false)

  const [reportReferenceId, setReportReferenceId] = useState('')
  const [reportDate, setReportDate] = useState(dayjs())

  useEffect(() => {
    let uniqueId = uuidv4()
    uniqueId = uniqueId.replaceAll('-', '')
    setReportReferenceId('RPT-' + uniqueId)
  }, [])

  async function saveReportScan() {
    setSaveLoading(true)
    const data = {
      patientId: patientId,
      clinicBrandId: clinicBrandId,
      doctorMentorId: mentorId,
      investigationIds: selectedInvestigations?.map((item) => item?.id),
      storedFile: {
        type: type,
        fileIds: imageIds,
      },
      title: title,
      reportDate: reportDate?.format('YYYY-MM-DD HH:mm:ss'),
      reportReferenceId: reportReferenceId,
      apiParam: 'patient-investigation-report-scan',
    }
    const response = await postMPDataWithParams(
      '/api/secure/doctor/patient-investigation-report-scan/save',
      data,
      {},
    )
    if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
      setTitle('')
      setType('image')
      setSelectedInvestigations([])
      setImageIds([])
      if (updateExistingDataValues) {
        updateExistingDataValues()
      }
    }
    setSaveLoading(false)
  }

  function deleteImageId(imageIdToRemove) {
    const updatedImageIds = imageIds.filter((imageId) => imageId !== imageIdToRemove)
    setImageIds(updatedImageIds)
  }

  return (
    <>
      <div>
        <div style={{ marginBottom: '8px' }}>Add Scanned Reports:</div>
        <div style={{ marginBottom: '16px' }}>
          <CustomInput
            type="text"
            label={'Title'}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div style={{ marginBottom: '16px' }}>
          <CustomHTMLSelect
            label={'Scan Media Type'}
            onChange={(e) => setType(e.target.value)}
            options={[{ label: 'Image', id: 'image' }]}
            getOptionLabel={(data) => data?.label}
            getOptionValue={(data) => data?.id}
          />
        </div>
        <div style={{ marginBottom: '16px' }}>
          Investigations in this report
          <MinimalInvestigationSelect
            clinicBrandId={clinicBrandId}
            selectedInvestigations={selectedInvestigations}
            setSelectedInvestigations={setSelectedInvestigations}
          />
        </div>
        <div style={{ display: 'flex', gap: '12px', flexWrap: 'wrap' }}>
          {imageIds?.map((imageId) => {
            return (
              <div
                key={imageId}
                onClick={() => deleteImageId(imageId)}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <img
                  alt={'imageId'}
                  src={getImageLink(imageId)}
                  style={{
                    width: '64px',
                    height: '64px',
                    borderRadius: '8px',
                    border: '1px solid #cecece',
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    cursor: 'pointer',
                  }}
                >
                  <DeleteOutlineSharpIcon
                    style={{
                      color: 'red',
                      fontSize: '24px',
                    }}
                  />
                </div>
              </div>
            )
          })}
          <PictureUploadButton onChange={(e) => setImageIds((prev) => [...prev, e.target.value])} />
        </div>

        <button
          style={{
            padding: '4px 8px',
            color: '#fff',
            borderRadius: '4px',
            marginTop: '32px',
            background:
              !saveLoading && title && type && Array.isArray(imageIds) && imageIds?.length > 0
                ? '#000'
                : 'gray',
          }}
          disabled={
            !(!saveLoading && title && type && Array.isArray(imageIds) && imageIds?.length > 0)
          }
          onClick={saveReportScan}
        >
          <span>{' Add '}</span>
          {saveLoading && <Spinner animation="border" variant={'light'} size="sm" />}
        </button>
      </div>
    </>
  )
}

const PlannedReportViewComponent = ({
  patientId,
  mentorId,
  clinicBrandId,
  reportReferenceId,
  existingInvestigationScans,
}) => {
  return (
    <>
      <div style={{ height: '1px', width: '100%', background: '#d3d3d3', margin: '24px 0' }}></div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <div style={{ marginBottom: '8px' }}>View Scanned Reports:</div>
        {existingInvestigationScans?.map((scan, scanIndex) => {
          return <ViewScanStrip scanData={scan} key={scanIndex} />
        })}
      </div>
    </>
  )
}

const ViewScanStrip = ({ scanData }) => {
  const [chosenImg, setChosenImg] = useState((scanData?.storedFile?.fileIds || [])?.[0])
  const [arrayImages, setArrayImages] = useState(scanData?.storedFile?.fileIds || [])
  const [dialogOpen, setDialogOpen] = useState(false)
  console.log(dialogOpen)
  return (
    <div style={{ display: 'flex', gap: '12px', flexWrap: 'wrap' }}>
      <div>
        <div>Title: {scanData?.title}</div>
        <div>
          Report Date:{' '}
          {dayjs.utc(scanData?.reportDate, 'YYYY-MM-DD HH:mm:ss')?.format('DD-MMM-YYYY')}
        </div>
      </div>
      <>
        {(scanData?.storedFile?.fileIds || [])?.map((imgId) => {
          return (
            <div
              key={imgId}
              onClick={() => {
                setChosenImg(imgId)
                setDialogOpen(true)
              }}
              style={{
                cursor: 'pointer',
                width: '64px',
                maxWidth: '64px',
                height: '64px',
                maxHeight: '64px',
                borderRadius: '8px',
                border: '1px solid #cecece',
                overflow: 'hidden',
              }}
            >
              <img
                src={getImageLink(imgId)}
                alt="reportpage"
                style={{ height: '100%', width: '100%' }}
              />
            </div>
          )
        })}
      </>
      {dialogOpen && (
        <ImageDialog
          arrayImages={arrayImages}
          chosenImg={chosenImg}
          setChosenImg={setChosenImg}
          closeDialog={() => setDialogOpen(false)}
        />
      )}
    </div>
  )
}

const ImageDialog = ({ arrayImages, chosenImg, setChosenImg, closeDialog }) => {
  console.log(arrayImages)
  return (
    <Dialog open={true} onClose={closeDialog}>
      <div
        style={{
          minWidth: '500px',
          minHeight: '300px',
          position: 'relative',
          background: '#000',
          padding: '16px',
        }}
      >
        <div
          style={{
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'right',
            width: '100%',
            marginBottom: '24px',
          }}
          onClick={closeDialog}
        >
          <CloseIcon style={{ fontSize: '24px', cursor: 'pointer', color: '#fff' }} />
        </div>
        <div style={{ position: 'relative' }}>
          {arrayImages?.length > 1 && arrayImages.indexOf(chosenImg) > 0 && (
            <div
              style={{
                position: 'absolute',
                left: 0,
                margin: 'auto',
                top: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => {
                setChosenImg(arrayImages[arrayImages.indexOf(chosenImg) - 1])
              }}
            >
              <ChevronLeftIcon style={{ fontSize: '32px', cursor: 'pointer' }} />
            </div>
          )}
          <img alt="img" style={{ width: '100%', height: '100%' }} src={getImageLink(chosenImg)} />
          {arrayImages?.length > 1 && arrayImages.indexOf(chosenImg) < arrayImages?.length - 1 && (
            <div
              style={{
                position: 'absolute',
                right: 0,
                margin: 'auto',
                top: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => {
                setChosenImg(arrayImages[arrayImages.indexOf(chosenImg) + 1])
              }}
            >
              <ChevronRightIcon style={{ fontSize: '32px', cursor: 'pointer' }} />
            </div>
          )}
        </div>
      </div>
    </Dialog>
  )
}

const HistoryItem = ({ data, normalRange }) => {
  const [resultState, setResultState] = useState('unknown')

  useEffect(() => {
    if (data?.resultUnit === normalRange?.unit) {
      const minNumber = parseFloat(normalRange.min)
      const maxNumber = parseFloat(normalRange.max)
      const actualNumber = parseFloat(data.resultValue)

      if (!isNaN(minNumber) && !isNaN(maxNumber) && !isNaN(actualNumber)) {
        if (actualNumber >= minNumber && actualNumber <= maxNumber) {
          setResultState('normal')
        } else {
          setResultState('abnormal')
        }
      } else {
        setResultState('unknown')
      }
    }
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{ minWidth: '60px', margin: 'auto 32px', display: 'flex', justifyContent: 'center' }}
      >
        <div
          style={{
            color: '#000',
            borderRadius: '4px 0 0 4px',
            padding: '4px 24px',
            borderLeft: '1px solid #d3d3d3',
            borderTop: '1px solid #d3d3d3',
            borderBottom: '1px solid #d3d3d3',
            background:
              resultState !== 'unknown'
                ? resultState === 'normal'
                  ? '#acf7ac'
                  : '#f6b0b1'
                : '#f0f0f0',
          }}
        >
          {data?.resultValue}
        </div>
        <div
          style={{
            background: '#014cf7',
            color: '#fff',
            borderRadius: '0 4px 4px 0',
            padding: '4px 8px',
            width: 'max-content',
          }}
        >
          {data?.resultUnit}
        </div>
      </div>
      {data?.reportDate && (
        <div style={{ fontSize: '12px' }}>
          {dayjs.utc(data?.reportDate, 'YYYY-MM-DD HH:mm:ss')?.format('DD-MMM-YYYY')}
        </div>
      )}
    </div>
  )
}

// V2 things:
//
//
//
//
//
//
//

const SearchInputComponentInvV2 = ({ searchQuery, setSearchQuery }) => {
  const [localQuery, setLocalQuery] = useState(searchQuery)

  const handleDebouncedChange = useCallback(
    debounce((value) => {
      setSearchQuery(value)
    }, 300),
    [],
  )

  const handleChange = (e) => {
    const value = e.target.value
    setLocalQuery(value) // Update local state immediately
    handleDebouncedChange(value) // Debounced update to global state
  }

  return (
    <div
      className="w-100 h-100 d-flex"
      style={{
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#019EFD',
      }}
    >
      <SearchIcon
        style={{ position: 'absolute', left: '8px', color: '#fff', fontSize: '20px', zIndex: 2 }}
      />
      {!Boolean(localQuery) && (
        <div style={{ color: '#fff', position: 'absolute', left: '32px', fontSize: '16px' }}>
          <i>Search Tests</i>
        </div>
      )}
      <input
        type="text"
        value={localQuery}
        onChange={handleChange}
        className="w-100 h-100"
        style={{
          color: '#F6F5F8',
          fontSize: '16px',
          padding: '0 32px',
          zIndex: 2,
        }}
      />
    </div>
  )
}

const SearchListComponentInvV2 = ({
  searchQuery,
  clinicBrandId,
  selectedTest,
  selectSearchItemAndOpenAddNewForIt,
}) => {
  const [listItems, setListItems] = useState([])
  const [loadingItems, setLoadingItems] = useState(false)
  const [mapLocal, setMapLocal] = useState({})

  async function getListItems(searchQuery) {
    setLoadingItems(true)
    let listItems = []

    try {
      if (searchQuery) {
        listItems = await searchInvestigationInIdb(searchQuery)
        if (!Array.isArray(listItems) || listItems.length === 0) {
          const response = await getMPDataWithoutParams(
            `/api/secure/doctor/investigation/list?search=${searchQuery}&clinicBrandId=${clinicBrandId}&page=1&pageSize=15`,
          )
          if (response?.status === 200 && Array.isArray(response?.data)) {
            listItems = response.data
            addOrUpdateInvestigationInIdb(listItems)
          }
        }
      } else {
        listItems = await getAllInvestigationInIdb()
        if (!Array.isArray(listItems) || listItems.length === 0) {
          const response = await getMPDataWithoutParams(
            `/api/secure/doctor/investigation/list?search=&clinicBrandId=${clinicBrandId}&page=1&pageSize=20`,
          )
          if (response?.status === 200 && Array.isArray(response?.data)) {
            listItems = response.data
          }
        }
      }
    } catch (error) {
      console.error('Error fetching investigations:', error)
      listItems = []
    } finally {
      const result = await addParentPanelsToListFromIndexedDb(listItems, {})
      listItems = result[0]
      setMapLocal(result[1])
      setListItems(listItems)
      setLoadingItems(false)
    }
  }

  useEffect(() => {
    getListItems(searchQuery)
  }, [searchQuery])

  const renderItem = (index) => {
    const searchItemData = listItems[index]
    return (
      <SearchListItem
        key={searchItemData.id}
        searchItemData={searchItemData}
        selectedTest={selectedTest}
        selectSearchItemAndOpenAddNewForIt={selectSearchItemAndOpenAddNewForIt}
        mapLocal={mapLocal}
      />
    )
  }

  return (
    <div
      className="bg-white d-flex flex-column gap-1"
      style={{
        height: '100%',
      }}
    >
      {loadingItems && (
        <div style={{ padding: '4px', borderBottom: '1px solid #cecece' }}>
          {loadingItems && '...'}
        </div>
      )}
      <Virtuoso
        totalCount={listItems.length}
        itemContent={(index) => renderItem(index)}
        // style={{ height: 'calc(100vh - 40px)' }} // Adjust height as per your design
      />
    </div>
  )
}

const SearchListItem = ({
  searchItemData,
  selectedTest,
  selectSearchItemAndOpenAddNewForIt,
  mapLocal,
}) => {
  return (
    <div
      className="w-100 bg-yellow"
      style={{
        borderBottom: '1px solid #cecece',
        padding: '4px',
        cursor: 'pointer',
        fontWeight: selectedTest?.id === searchItemData?.id ? 700 : 400,
      }}
      onClick={() => selectSearchItemAndOpenAddNewForIt(searchItemData)}
    >
      <div style={{ fontSize: '16px' }}>{searchItemData?.title}</div>
      {(mapLocal?.[searchItemData?.id] || [])?.map((subItem, subItemIndex) => {
        return (
          <div className="d-flex gap-1" style={{ fontSize: '12px' }} key={subItemIndex}>
            <SubdirectoryArrowRightIcon />
            <div>{subItem?.title || subItem?.name}</div>
          </div>
        )
      })}
    </div>
  )
}

const HistoryComponentInvV2 = ({
  selectedTopLevelSection,
  allHistoricalData,
  loadingHistoricalData,
  switchToHistorySection,
  patientId,
  clinicBrandId,
  mentorId,
  updateExistingDataValues,
  selectSearchItemAndOpenAddNewForIt,
  openFormAndEditExistingTestResults,
}) => {
  return (
    <div className="bg-white h-100">
      <div className="d-flex gap-2">
        <InvestigationHistorySectionButton
          selected={selectedTopLevelSection === historySections.IMAGING}
          onClick={() => switchToHistorySection(historySections.IMAGING)}
        >
          Imaging
        </InvestigationHistorySectionButton>
        <InvestigationHistorySectionButton
          selected={selectedTopLevelSection === historySections.PATHOLOGY}
          onClick={() => switchToHistorySection(historySections.PATHOLOGY)}
        >
          Pathology
        </InvestigationHistorySectionButton>
      </div>
      <div style={{ margin: '8px auto', width: '90%' }}>
        {loadingHistoricalData && <LinearProgress />}
      </div>
      <div style={{ overflowY: 'scroll', height: 'calc(100% - 45px)' }}>
        {selectedTopLevelSection === historySections.IMAGING && (
          <div style={{ padding: '16px' }}>
            <PlannedReportScanUploadComponent
              patientId={patientId}
              mentorId={mentorId}
              clinicBrandId={clinicBrandId}
              updateExistingDataValues={updateExistingDataValues}
            />
            <PlannedReportViewComponent
              patientId={patientId}
              mentorId={mentorId}
              clinicBrandId={clinicBrandId}
              existingInvestigationScans={allHistoricalData[historySections.IMAGING]}
            />
          </div>
        )}
        {selectedTopLevelSection === historySections.PATHOLOGY && (
          <div style={{ padding: '16px' }}>
            <HistoryTablesPathology
              historyPathology={allHistoricalData[historySections.PATHOLOGY]}
              clinicBrandId={clinicBrandId}
              selectSearchItemAndOpenAddNewForIt={selectSearchItemAndOpenAddNewForIt}
              openFormAndEditExistingTestResults={openFormAndEditExistingTestResults}
            />
          </div>
        )}
      </div>
    </div>
  )
}

const FormPageInvV2 = ({
  selectedTest,
  closeFormAndResetToBase,
  mentorId,
  clinicBrandId,
  patientId,
  editingReportId,
  updateExistingDataValues,
  allExistingPathologyData,
}) => {
  const [reportDate, setReportDate] = useState(dayjs())
  const [markersData, setMarkersData] = useState([])
  const [saving, setSaving] = useState(false)

  async function setMarkersDataArray() {
    if (selectedTest) {
      const markersDataLocalArray = []
      let decidedReportId
      if (editingReportId) {
        decidedReportId = editingReportId
      } else {
        let uniqueId = uuidv4()
        uniqueId = uniqueId.replaceAll('-', '')
        decidedReportId = 'RPT-' + uniqueId
      }

      // first add all markers here that are a part of this investigation, both cases when investigation is marker or investigation is panel. do not add the patient investigation and keep that undefined
      if (selectedTest?.investigationType === INVESTIGATION_TYPE_PANEL) {
        const testIdsInPanel = selectedTest?.testIds || []
        for (const testId of testIdsInPanel) {
          let testData
          const testDatasFoundInDB = await getInvestigationByIds([testId])
          if (Array.isArray(testDatasFoundInDB) && testDatasFoundInDB.length > 0) {
            testData = testDatasFoundInDB[0]
          } else {
            const response = await getMPDataWithoutParams(
              `/api/secure/doctor/investigation/list?id=${testId}&clinicBrandId=${clinicBrandId}&page=1&pageSize=15`,
            )
            if (
              response?.status === 200 &&
              Array.isArray(response?.data) &&
              response.data.length > 0
            ) {
              const testDatasFoundInServer = response.data
              addOrUpdateInvestigationInIdb(testDatasFoundInServer)
              testData = testDatasFoundInServer[0]
            }
          }
          if (testData) {
            markersDataLocalArray.push({ investigation: testData, patientInvestigation: undefined })
          }
        }
      } else if (selectedTest?.investigationType === INVESTIGATION_TYPE_TEST) {
        markersDataLocalArray.push({ investigation: selectedTest, patientInvestigation: undefined })
      }

      // if editingReportId exists, filter all the existing saved values for this report and keep them aside
      // also change the date
      let existingDataOfThisReport = []
      if (editingReportId) {
        existingDataOfThisReport = (
          Array.isArray(allExistingPathologyData) ? allExistingPathologyData : []
        )?.filter((item) => item?.reportReferenceId === editingReportId)
        setReportDate(dayjs(existingDataOfThisReport?.[0]?.reportDate))
      }

      // now loop over the markers data again and if there is patient investigation data for that marker, add it, otherwise add sample patient investigation data given below with result value ''.
      // in the same loop, inside patientInvestigation, add decidedReportId also
      markersDataLocalArray?.forEach((markersDataItem) => {
        const invId = markersDataItem?.investigation?.id
        const foundHistory = existingDataOfThisReport?.find(
          (item) => invId === item?.investigationId,
        )
        let patientInvestigationData = {}
        if (foundHistory) {
          patientInvestigationData = { ...foundHistory, ...patientInvestigationData }
        } else {
          patientInvestigationData = {
            patientId: patientId,
            clinicBrandId: clinicBrandId,
            investigationId: invId,
            panelId:
              selectedTest?.investigationType === INVESTIGATION_TYPE_PANEL
                ? selectedTest?.id
                : undefined,
            doctorMentorId: mentorId,
            resultUnit: markersDataItem?.investigation?.units?.[0] || '',
            resultValue: '',
            apiParam: 'patient-investigation',
          }
        }
        patientInvestigationData['reportDate'] = dayjs.utc(reportDate).format('YYYY-MM-DD HH:mm:ss')
        patientInvestigationData['reportReferenceId'] = decidedReportId

        markersDataItem['patientInvestigation'] = patientInvestigationData
      })

      setMarkersData(markersDataLocalArray)
    }
  }

  async function handleSave() {
    setSaving(true)
    for (const renderedItem of markersData) {
      if (renderedItem?.patientInvestigation?.resultValue) {
        const response = await postMPDataWithParams(
          '/api/secure/doctor/patient-investigation/save',
          {
            ...renderedItem?.patientInvestigation,
            reportDate: dayjs.utc(reportDate).format('YYYY-MM-DD HH:mm:ss'),
          },
          {},
        )
        if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
        }
      }
    }
    setSaving(false)
  }

  useEffect(() => {
    setMarkersDataArray()
  }, [selectedTest])

  return (
    <div className="bg-white w-100 h-100" style={{ overflowY: 'scroll' }}>
      <div
        className="d-flex justify-content-between bg-white p-2 gap-2"
        style={{
          boxShadow: '-2px 2px 4px rgba(0, 0, 0, 0.1)',
          borderLeft: '1px solid #f4f4f4',
          borderBottom: '1px solid #f4f4f4',
        }}
      >
        <div
          style={{ color: '#039EFD', cursor: 'pointer' }}
          onClick={() => {
            updateExistingDataValues()
            closeFormAndResetToBase()
          }}
        >
          Cancel
        </div>
        <div style={{ fontSize: '18px' }}>{selectedTest?.title}</div>
        <div
          style={{ color: '#039EFD', cursor: 'pointer' }}
          onClick={async () => {
            if (saving) {
            } else {
              await handleSave()
              updateExistingDataValues()
              closeFormAndResetToBase()
            }
          }}
        >
          {saving ? <Spinner variant="dark" size="sm" /> : 'Done'}
        </div>
      </div>
      <div className="d-flex flex-column gap-3 p-3" style={{ background: '#F6F5F8' }}>
        {/* Date */}
        <div
          className="d-flex justify-content-between align-items-center bg-white"
          style={{ borderRadius: '4px', padding: '8px 4px' }}
        >
          <div>Date of Report</div>
          <div>
            <DatePicker value={reportDate} onChange={(newValue) => setReportDate(newValue)} />
          </div>
        </div>
        {/* Impression */}
        {/* <div className="d-flex gap-2 align-items-center">
          <div>Impression</div>
          <div className="w-100 bg-red">ss</div>
        </div> */}
        {markersData?.map((markerDataItem, markerDataItemIndex) => {
          return (
            <div
              key={markerDataItemIndex}
              className="d-flex align-items-center bg-white"
              style={{ borderRadius: '4px', padding: '8px 4px' }}
            >
              <div style={{ width: '300px' }}>{markerDataItem?.investigation?.title}</div>
              <div className="d-flex" style={{ marginLeft: '32px' }}>
                <input
                  value={markerDataItem?.patientInvestigation?.resultValue}
                  onChange={(e) => {
                    setMarkersData((prev) => {
                      const newArr = [...prev]
                      newArr[markerDataItemIndex] = {
                        investigation: newArr[markerDataItemIndex]?.investigation,
                        patientInvestigation: {
                          ...newArr[markerDataItemIndex]?.patientInvestigation,
                          resultValue: e.target.value,
                        },
                      }
                      return newArr
                    })
                  }}
                  style={{
                    border: '1px solid #cecece',
                    borderRadius: '4px 0 0 4px',
                    width: '120px',
                  }}
                />
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ background: '#cecece', minWidth: '64px', borderRadius: '0 4px 4px 0' }}
                >
                  {markerDataItem?.patientInvestigation?.resultUnit}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

function getInvestigationIdFromPatientInvestigationsList(patientInvestigationsList) {
  if (Array.isArray(patientInvestigationsList) && patientInvestigationsList?.length > 0) {
    const someItemWithPanelId = patientInvestigationsList.find((item) => item?.panelId)
    if (someItemWithPanelId) {
      return someItemWithPanelId?.panelId
    }
    return patientInvestigationsList?.[0]?.investigationId
  }
}

const HistoryTablesPathology = ({
  historyPathology,
  clinicBrandId,
  selectSearchItemAndOpenAddNewForIt,
  openFormAndEditExistingTestResults,
}) => {
  const [reportIdToTestsMap, setReportIdsToTestsMap] = useState({})
  const [visibleTablesWithInvestigationData, setVisibleTablesWithInvestigationData] = useState([])

  async function setUpTables() {
    const localMap = {}
    ;(historyPathology || []).forEach((item) => {
      if (!localMap[item?.reportReferenceId]) {
        localMap[item?.reportReferenceId] = [] // Initialize the array if the key does not exist
      }
      localMap[item?.reportReferenceId].push(item) // Add the item to the array
    })
    setReportIdsToTestsMap(localMap)
    const investigationDatasOfTablesLocal = []
    for (const arrayItem of Object.values(localMap)) {
      const invId = await getInvestigationIdFromPatientInvestigationsList(arrayItem)
      if (invId) {
        const invDatas = await getInvestigationByIds([invId])
        if (invDatas?.length === 0) {
          const response = await getMPDataWithoutParams(
            `/api/secure/doctor/investigation/list?id=${invId}&clinicBrandId=${clinicBrandId}&page=1&pageSize=15`,
          )
          if (
            response?.status === 200 &&
            Array.isArray(response?.data) &&
            response.data.length > 0
          ) {
            const invDatas = response.data
            addOrUpdateInvestigationInIdb(invDatas)
          }
        }
        if (invDatas.length > 0) {
          const invData = invDatas[0]
          if (!investigationDatasOfTablesLocal.find((item) => item?.id === invId)) {
            investigationDatasOfTablesLocal.push(invData)
          }
        }
      }
    }
    setVisibleTablesWithInvestigationData(investigationDatasOfTablesLocal)
  }

  useEffect(() => {
    setUpTables()
  }, [historyPathology])

  console.log(reportIdToTestsMap)
  console.log(visibleTablesWithInvestigationData)

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      {visibleTablesWithInvestigationData?.map((item, idx) => (
        <HistoryTable
          key={idx}
          historyTablePrimaryInvestigationData={item}
          reportIdToTestsMap={reportIdToTestsMap}
          clinicBrandId={clinicBrandId}
          selectSearchItemAndOpenAddNewForIt={selectSearchItemAndOpenAddNewForIt}
          openFormAndEditExistingTestResults={openFormAndEditExistingTestResults}
        />
      ))}
    </div>
  )
}

const HistoryTable = ({
  historyTablePrimaryInvestigationData,
  reportIdToTestsMap,
  clinicBrandId,
  selectSearchItemAndOpenAddNewForIt,
  openFormAndEditExistingTestResults,
}) => {
  const [rows, setRows] = useState([])
  const [toRenderReports, setToRenderReports] = useState([])

  async function fillDataIntoRows() {
    if (historyTablePrimaryInvestigationData?.investigationType === INVESTIGATION_TYPE_PANEL) {
      const childTests = historyTablePrimaryInvestigationData?.testIds || []
      for (const testId of childTests) {
        let tests = await getInvestigationByIds([testId])
        if (tests?.length === 0) {
          const response = await getMPDataWithoutParams(
            `/api/secure/doctor/investigation/list?id=${testId}&clinicBrandId=${clinicBrandId}&page=1&pageSize=15`,
          )
          if (response?.status === 200 && Array.isArray(response?.data)) {
            tests = response.data
            addOrUpdateInvestigationInIdb(tests)
          }
        }
        if (tests.length > 0) {
          setRows((prev) => [...prev, tests[0]])
        }
      }
    } else if (
      historyTablePrimaryInvestigationData?.investigationType === INVESTIGATION_TYPE_TEST
    ) {
      setRows([historyTablePrimaryInvestigationData])
    }
  }

  useEffect(() => {
    fillDataIntoRows()
  }, [])

  useEffect(() => {
    const reportsToRender = {}
    Object.keys(reportIdToTestsMap).forEach((reportId) => {
      if (
        getInvestigationIdFromPatientInvestigationsList(reportIdToTestsMap?.[reportId]) ===
        historyTablePrimaryInvestigationData?.id
      ) {
        reportsToRender[reportId] = reportIdToTestsMap?.[reportId]
      }
    })
    setToRenderReports(reportsToRender)
  }, [])

  console.log(toRenderReports)

  return (
    <div style={{ border: '1px solid #cecece', borderRadius: '4px' }}>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ borderBottom: '1px solid #cecece', padding: '8px' }}
      >
        <div>
          <b>{historyTablePrimaryInvestigationData?.title}</b>
        </div>
        <div
          style={{ color: '#039EFD', cursor: 'pointer' }}
          onClick={() => {
            selectSearchItemAndOpenAddNewForIt(historyTablePrimaryInvestigationData)
          }}
        >
          <AddCircleIcon style={{ fontSize: '16px', marginRight: '4px' }} />
          Add
        </div>
      </div>
      <div className="d-flex w-100" style={{ overflowX: 'scroll' }}>
        <div style={{ minWidth: '200px' }}>
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{
              background: '#F4F7FD',
              padding: '4px',
              textAlign: 'center',
              minHeight: '48px',
            }}
          >
            <b>Date</b>
          </div>
          {rows?.map((row, rowInd) => (
            <div
              key={rowInd}
              style={{
                padding: '4px',
                borderTop: '1px solid #cecece',
                minHeight: '48px',
                textAlign: 'center',
              }}
              className="d-flex flex-column justify-content-center align-items-center"
            >
              {row?.title}
            </div>
          ))}
        </div>
        {Object.keys(toRenderReports)?.map((reportId, reportIndex) => (
          <ColumnOfHistoryTable
            key={reportIndex}
            reportId={reportId}
            reportData={toRenderReports?.[reportId]}
            rows={rows}
            historyTablePrimaryInvestigationData={historyTablePrimaryInvestigationData}
            clinicBrandId={clinicBrandId}
            lastInRow={Object.keys(toRenderReports)?.length - 1 === reportIndex}
            openFormAndEditExistingTestResults={openFormAndEditExistingTestResults}
          />
        ))}
      </div>
    </div>
  )
}

const ColumnOfHistoryTable = ({
  rows,
  reportId,
  reportData,
  historyTablePrimaryInvestigationData,
  clinicBrandId,
  lastInRow,
  openFormAndEditExistingTestResults,
}) => {
  return (
    <div
      style={{ minWidth: '150px', cursor: 'pointer' }}
      onClick={() =>
        openFormAndEditExistingTestResults(historyTablePrimaryInvestigationData, reportId)
      }
    >
      <div
        style={{
          background: '#F4F7FD',
          padding: '4px',
          textAlign: 'center',
          borderLeft: '1px solid #cecece',
          borderRight: lastInRow ? '1px solid #cecece' : null,
          minHeight: '48px',
        }}
      >
        <b>
          {dayjs.utc(reportData?.[0]?.reportDate, 'YYYY-MM-DD HH:mm:ss').local().format('DD MMM')}
        </b>
      </div>
      {rows?.map((defaultRow, defaultRowIndex) => {
        return (
          <div
            key={defaultRowIndex}
            style={{
              padding: '4px',
              borderTop: '1px solid #cecece',
              borderLeft: '1px solid #cecece',
              borderRight: lastInRow ? '1px solid #cecece' : null,
              minHeight: '48px',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <div>
              {reportData?.find((item) => item?.investigationId === defaultRow?.id)?.resultValue}
            </div>
            <div style={{ fontSize: '10px' }}>
              {reportData?.find((item) => item?.investigationId === defaultRow?.id)?.resultUnit}
            </div>
          </div>
        )
      })}
    </div>
  )
}

async function addParentPanelsToListFromIndexedDb(list, map) {
  if (list.length > 10) {
    return [list, map]
  }

  const newItems = []

  for (let item of list) {
    const panels = await findAllPanelsWithGivenTestId(item?.id)

    if (panels && panels.length > 0) {
      panels.forEach((panel) => {
        if (!map[panel.id]) {
          map[panel.id] = []
        }
        map[panel.id].push(item)
      })

      panels.forEach((panel) => {
        if (!list.some((existingItem) => existingItem?.id === panel?.id)) {
          newItems.push(panel)
        }
      })
    }
  }
  return [list.concat(newItems), map]
}

function isPanel(data) {
  return data?.investigationType === 'panel'
}

function isTest(data) {
  return data?.investigationType === 'test'
}

async function getHistoryTableTitle(reportId, reportIdToTestsMap, clinicBrandId) {}

// check the custom keyboard

{
  /* <div>
{initialDataStripsLoading && <Spinner animation="border" variant="dark" size="sm" />}
{visibleInvestigationStrips?.map((visibleStripItem, visibleStripItemIndex) => {
  if (visibleStripItem?.investigationType === INVESTIGATION_TYPE_TEST) {
    return (
      <div
        style={{
          borderTop: '1px solid #d3d3d3',
          borderBottom: '1px solid #d3d3d3',
          margin: '12px 0',
        }}
        key={visibleStripItemIndex}
      >
        <SingleInvestigationComponent
          templateData={visibleStripItem}
          existingInvestigations={existingInvestigations}
          updateExistingDataValues={updateExistingDataValues}
          reportDate={reportDate}
          patientId={patientId}
          clinicBrandId={clinicBrandId}
          reportReferenceId={reportReferenceId}
          mentorId={mentorId}
        />
      </div>
    )
  }
  if (visibleStripItem?.investigationType === INVESTIGATION_TYPE_PANEL) {
    return (
      <div
        style={{
          borderTop: '1px solid #d3d3d3',
          borderBottom: '1px solid #d3d3d3',
          margin: '12px 0',
        }}
        key={visibleStripItemIndex}
      >
        <PanelInvestigationComponent
          templateData={visibleStripItem}
          existingInvestigations={existingInvestigations}
          updateExistingDataValues={updateExistingDataValues}
          reportDate={reportDate}
          patientId={patientId}
          clinicBrandId={clinicBrandId}
          reportReferenceId={reportReferenceId}
          mentorId={mentorId}
        />
      </div>
    )
  }
  return null
})}
</div> */
}
