import {
  PMPHeaderDoctorText,
  PMPHeaderLeftPart,
  PMPHeaderRightPart,
  PMPHeadingBanner,
} from '../../PrescriptionMakingPageStyles'

export default function TwoColumnFooterWithDisclaimerHeaderWithDegreesInSeperateLines({
  prescriptionData,
  leaveSpace,
  debug = false,
}) {
  return (
    <>
      <PMPHeadingBanner debug={debug} noColor={true}>
        {prescriptionData?.header?.title}
      </PMPHeadingBanner>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <PMPHeaderLeftPart>
          <PMPHeaderDoctorText main style={{fontSize: '18px'}}>{prescriptionData?.doctorName}</PMPHeaderDoctorText>
          <PMPHeaderDoctorText main>{prescriptionData?.designation}</PMPHeaderDoctorText>
          {prescriptionData?.listOfDegrees?.map((degree, degreeIndex) => (
            <PMPHeaderDoctorText main key={degreeIndex}>
              {degree}
            </PMPHeaderDoctorText>
          ))}
        </PMPHeaderLeftPart>
        <PMPHeaderRightPart
          style={{
            flexShrink: 0,
            width: 'unset',
          }}
        >
          {prescriptionData?.regId && (
            <PMPHeaderDoctorText>
              <b>Regn No.: {prescriptionData?.regId}</b>
            </PMPHeaderDoctorText>
          )}
          {prescriptionData?.contactEmail && (
            <PMPHeaderDoctorText>
              Email: <b>{prescriptionData?.contactEmail}</b>
            </PMPHeaderDoctorText>
          )}
          {prescriptionData?.website && (
            <PMPHeaderDoctorText>
              Website: <b>{prescriptionData?.website}</b>
            </PMPHeaderDoctorText>
          )}
          {prescriptionData?.cmoRegId && (
            <PMPHeaderDoctorText>
              <b>C.M.O. Regd. No.: {prescriptionData?.cmoRegId}</b>
            </PMPHeaderDoctorText>
          )}
          {prescriptionData?.affiliations?.map((affiliation, affIndex) => (
            <PMPHeaderDoctorText key={affIndex}>
              <b>{affiliation}</b>
            </PMPHeaderDoctorText>
          ))}
        </PMPHeaderRightPart>
      </div>
    </>
  )
}
