import { createContext, useEffect, useState } from 'react'
import { UNSPLASH_API, useFetch } from '../services'

export const UnsecureSetupContext = createContext()

export const UnsecureSetupContextProvider = ({ children }) => {
  const [unsplashImages, setUnsplashImages] = useState({
    results: [],
    total: 0,
    total_pages: 0,
  })
  const [fetchingImages, setFetchingImages] = useState(false)

  const {
    loading: unSecureSetupLoading,
    data: unSecureSetupData,
    error: unSecureSetupError,
  } = useFetch('/api/meta', '', 'web')

  async function getMultipleImagesFromUnsplash() {
    if (unsplashImages?.results?.length > 0) return
    setFetchingImages(true)
    const imageResponses = await UNSPLASH_API.search.getPhotos({
      page: 1,
      perPage: 10,
      orderBy: 'popular',
      contentFilter: 'high',
      orientation: 'landscape',
      query: 'nature',
    })
    if (imageResponses?.status === 200) {
      console.log({ imageResonsesResponse: imageResponses?.response })
      setUnsplashImages(imageResponses?.response)
    }
    setFetchingImages(false)
  }

  useEffect(() => {
    getMultipleImagesFromUnsplash()
  }, [])

  return (
    <UnsecureSetupContext.Provider
      value={{
        unSecureSetupLoading: unSecureSetupLoading,
        unSecureSetupData: unSecureSetupData,
        unSecureSetupError,
        unsplashImages,
        getMultipleImagesFromUnsplash,
      }}
    >
      {children}
    </UnsecureSetupContext.Provider>
  )
}
