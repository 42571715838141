import KeyboardReturnOutlined from '@mui/icons-material/KeyboardReturnOutlined'

export default function ListOptionComponent({
  optionData,
  getOptionValue,
  getOptionLabel,
  isSelected,
  onClickOption,
  isSingleItemAndSupportsSelectOnEnterKeydown,
}) {
  const selected = isSelected(optionData)
  return (
    <div
      onClick={() => {
        onClickOption(optionData)
      }}
      style={{
        borderBottom: '1px solid #cecece',
        cursor: 'pointer',
        padding: '4px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
      selected={selected}
    >
      <span style={{ fontWeight: selected ? 600 : 400, fontSize: '16px' }}>
        {getOptionLabel(optionData)}
      </span>
      <span>{isSingleItemAndSupportsSelectOnEnterKeydown && <KeyboardReturnOutlined />}</span>
    </div>
  )
}
