import { CalendarMonth, Chair, VideoCameraFront } from '@mui/icons-material'
import { FormControl, MenuItem, Select } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import {
  SESSION_PLATFORM_OFFLINE,
  SESSION_PLATFORM_ONLINE,
  SIREN_ICON_ID,
  VISIT_EVENT_TYPES,
} from 'src/consumer/constants'
import { getInitiallySelectedEventTypeData } from '../../ClinicManageBookingsHelper'
import {
  getDefaultOrFirstEmergencyEventData,
  getDefaultOrFirstFollowUpEventData,
  getDefaultOrFirstInPersonEventData as getDefaultOrFirstRegularInPersonEventData,
  getDefaultOrFirstRegularConsultationOrTherapyVideoEventData as getDefaultOrFirstRegularVideoEventData,
} from '../utils'
import { getImageLink } from 'src/mentor/helpers/utilityFunctions'
import {
  CHOSEN_BLOCK_EMERGENCY_IN_PERSON,
  CHOSEN_BLOCK_FOLLOW_UP_IN_PERSON,
  CHOSEN_BLOCK_IN_PERSON,
  CHOSEN_BLOCK_OTHER,
  CHOSEN_BLOCK_VIDEO,
  getChosenBlocksBasedOnDefaultSelectedItem,
} from './utils'

export const EventSelectorForBooking = ({
  selectedEventTypeId,
  eventTypesVisible,
  setSelectedEventTypeId,
  clinicLocationId,
  mentorProfileData,
  selectedVisitType,
  setSelectedVisitType,
}) => {
  const defaultEventTypeData = getInitiallySelectedEventTypeData(
    mentorProfileData,
    clinicLocationId,
  )
  const [chosenBlockId, setChosenBlockId] = useState()
  const inPersonBlock = useMemo(() => {
    return getDefaultOrFirstRegularInPersonEventData({
      defaultEventTypeData: defaultEventTypeData,
      eventTypesForOtherSessionsDropdown: eventTypesVisible,
    })
  }, [defaultEventTypeData, eventTypesVisible])
  const videoBlock = useMemo(() => {
    return getDefaultOrFirstRegularVideoEventData({
      defaultEventTypeData: defaultEventTypeData,
      eventTypesForOtherSessionsDropdown: eventTypesVisible,
    })
  }, [defaultEventTypeData, eventTypesVisible])
  const emergencyBlock = useMemo(() => {
    return getDefaultOrFirstEmergencyEventData({
      defaultEventTypeData: defaultEventTypeData,
      eventTypesForOtherSessionsDropdown: eventTypesVisible,
    })
  }, [defaultEventTypeData, eventTypesVisible])
  const followUpBlock = useMemo(() => {
    return getDefaultOrFirstFollowUpEventData({
      defaultEventTypeData: defaultEventTypeData,
      eventTypesForOtherSessionsDropdown: eventTypesVisible,
    })
  }, [defaultEventTypeData, eventTypesVisible])

  const [otherOptionsArray, setOtherOptionsArray] = useState([])

  const isOtherSelected = (otherOptionsArray?.map((eve) => eve?.uid) || []).includes(
    selectedEventTypeId,
  )

  console.log({ inPersonBlock, videoBlock, emergencyBlock, followUpBlock, selectedEventTypeId })

  useEffect(() => {
    console.log({ defaultEventTypeData })

    if (defaultEventTypeData) {
      let eventTypesForOtherSessionsDropdown = [...(eventTypesVisible || [])]

      let defaultOrFirstInPersonEventData = inPersonBlock
      let defaultOrFirstVideoEventData = videoBlock

      if (defaultOrFirstInPersonEventData) {
        eventTypesForOtherSessionsDropdown = eventTypesForOtherSessionsDropdown?.filter(
          (item) => item?.uid !== defaultOrFirstInPersonEventData?.uid,
        )
      }
      if (defaultOrFirstVideoEventData) {
        eventTypesForOtherSessionsDropdown = eventTypesForOtherSessionsDropdown?.filter(
          (item) => item?.uid !== defaultOrFirstVideoEventData?.uid,
        )
      }
      if (emergencyBlock) {
        eventTypesForOtherSessionsDropdown = eventTypesForOtherSessionsDropdown?.filter(
          (item) => item?.uid !== emergencyBlock?.uid,
        )
      }
      if (followUpBlock) {
        eventTypesForOtherSessionsDropdown = eventTypesForOtherSessionsDropdown?.filter(
          (item) => item?.uid !== followUpBlock?.uid,
        )
      }
      setOtherOptionsArray(eventTypesForOtherSessionsDropdown)

      console.log({ eventTypesForOtherSessionsDropdown, eventTypesVisible })

      setChosenBlockId(
        getChosenBlocksBasedOnDefaultSelectedItem({
          defaultEmergencyEventData: emergencyBlock,
          defaultFollowUpEventData: followUpBlock,
          defaultInPersonEventData: inPersonBlock,
          defaultSelectedItem: defaultEventTypeData,
          defaultVideoEventData: videoBlock,
        }),
      )
    }
  }, [
    selectedVisitType,
    eventTypesVisible,
    clinicLocationId,
    mentorProfileData,
    defaultEventTypeData,
  ])

  useEffect(() => {
    return () => {
      setSelectedEventTypeId(defaultEventTypeData?.uid)
      setSelectedVisitType(defaultEventTypeData?.visitEventType || VISIT_EVENT_TYPES.regular)
    }
  }, [])

  if (!Boolean(eventTypesVisible)) {
    return <div style={{ color: 'red' }}>No events offered in this location by mentor</div>
  }
  if (Array.isArray(eventTypesVisible) && eventTypesVisible.length === 0) {
    return <div style={{ color: 'red' }}>No events offered in this location by mentor</div>
  }

  return (
    <div>
      <div className="d-flex gap-2">
        {inPersonBlock && (
          <BlockComponent
            selected={selectedEventTypeId === inPersonBlock?.uid}
            clickFunction={() => {
              setChosenBlockId(CHOSEN_BLOCK_IN_PERSON)
              setSelectedEventTypeId(inPersonBlock?.uid)
            }}
            text={'In Person'}
            text2={`${inPersonBlock?.durationInMins}m`}
            icon={<Chair />}
          />
        )}
        {videoBlock && (
          <BlockComponent
            selected={selectedEventTypeId === videoBlock?.uid}
            clickFunction={() => {
              setChosenBlockId(CHOSEN_BLOCK_VIDEO)
              setSelectedEventTypeId(videoBlock?.uid)
            }}
            text={'Online'}
            text2={`${videoBlock?.durationInMins}m`}
            icon={<VideoCameraFront />}
          />
        )}
        {emergencyBlock && (
          <BlockComponent
            selected={selectedEventTypeId === emergencyBlock?.uid}
            backgroundColor="#cf7f73"
            borderColor="#cf7f73"
            textColor="#cf7f73"
            clickFunction={() => {
              setChosenBlockId(CHOSEN_BLOCK_EMERGENCY_IN_PERSON)
              setSelectedEventTypeId(emergencyBlock?.uid)
            }}
            text={'Emergency'}
            text2={`${emergencyBlock?.durationInMins}m`}
            icon={
              <img
                src={getImageLink(SIREN_ICON_ID)}
                alt="emergency_icon"
                style={{ height: '25px', width: '25px' }}
              />
            }
          />
        )}
        {followUpBlock && (
          <BlockComponent
            selected={selectedEventTypeId === followUpBlock?.uid}
            clickFunction={() => {
              setChosenBlockId(CHOSEN_BLOCK_FOLLOW_UP_IN_PERSON)
              setSelectedEventTypeId(followUpBlock?.uid)
            }}
            text={'Follow Up'}
            text2={`${followUpBlock?.durationInMins}m`}
            icon={<CalendarMonth sx={{ fontSize: '20px' }} />}
          />
        )}
        {Array.isArray(otherOptionsArray) && otherOptionsArray.length > 0 && (
          <BlockComponent
            selected={isOtherSelected}
            clickFunction={() => {
              setSelectedEventTypeId(otherOptionsArray?.[0]?.uid)
            }}
            text={'Other'}
            text2={'Sessions'}
          />
        )}
      </div>
      {isOtherSelected && (
        <>
          <div style={{ fontSize: '12px', marginTop: '16px' }}>Select session type:</div>
          <FormControl fullWidth>
            <Select
              labelId="select-event-type-label"
              id="select-event-type"
              value={selectedEventTypeId}
              label="Session Type"
              onChange={(e) => setSelectedEventTypeId(e.target.value)}
              sx={{ height: '32px', marginTop: '8px' }}
            >
              {otherOptionsArray?.map((eventType, eventTypeIndex) => {
                return (
                  <MenuItem key={eventTypeIndex} value={eventType?.uid}>
                    {eventType?.title}
                    <span style={{ marginLeft: '12px' }}>
                      {'('}
                      {eventType?.durationInMins}
                      {' mins)'}
                    </span>
                    <span style={{ marginLeft: '12px' }}>
                      {eventType?.meetingSrc === SESSION_PLATFORM_OFFLINE && <Chair />}
                      {eventType?.meetingSrc === SESSION_PLATFORM_ONLINE && <VideoCameraFront />}
                    </span>
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </>
      )}
    </div>
  )
}

const BlockComponent = ({
  selected,
  text,
  text2,
  icon,
  backgroundColor = '#7450aa',
  borderColor = '#7450aa',
  textColor = '#7450aa',
  clickFunction,
}) => {
  return (
    <div
      style={{
        cursor: 'pointer',
        background: selected ? backgroundColor : '#fff',
        border: `1px solid ${borderColor}`,
        color: selected ? '#fff' : textColor,
        borderRadius: '4px',
        padding: '8px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '75px',
      }}
      onClick={() => clickFunction()}
    >
      <div>{icon}</div>
      <div
        className="d-flex flex-column align-items-center justify-content-center"
        style={{ marginTop: '4px' }}
      >
        <div style={{ fontSize: '12px' }}>{text}</div>
        <div style={{ fontSize: '10px' }}>{text2}</div>
      </div>
    </div>
  )
}
