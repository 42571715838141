import { PMPFooterContainer } from '../../PrescriptionMakingPageStyles'

export default function DefaultPrescriptionFooter({
  prescriptionData,
  noColor,
  hideQr = true,
  debug = false,
}) {
  return (
    <PMPFooterContainer noColor={noColor} debug={debug}>
      {(prescriptionData?.footer?.headline ||
        prescriptionData?.footer?.subtext ||
        prescriptionData?.footer?.supportText) && (
        <div
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: '40%' }}
        >
          <div style={{ textAlign: 'right' }}>{prescriptionData?.footer?.headline}</div>
          <div style={{ textAlign: 'right' }}>{prescriptionData?.footer?.subtext}</div>
          <div style={{ textAlign: 'right' }}>{prescriptionData?.footer?.supportText}</div>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          maxWidth: '200px',
        }}
      ></div>
      {(prescriptionData?.footer?.headlineAlt ||
        prescriptionData?.footer?.subtextAlt ||
        prescriptionData?.footer?.supportTextAlt) && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '40%',
          }}
        >
          <div>{prescriptionData?.footer?.headlineAlt}</div>
          <div>{prescriptionData?.footer?.subtextAlt}</div>
          <div>{prescriptionData?.footer?.supportTextAlt}</div>
        </div>
      )}
    </PMPFooterContainer>
  )
}
