import {
  Avatar,
  ButtonBase,
  Checkbox,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Slider,
  TextareaAutosize,
  styled as muiStyled,
} from '@mui/material'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import styled from 'styled-components'
// import { Creatable } from 'react-select'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import ReactPlayer from 'react-player'
import { AsyncPaginate, withAsyncPaginate } from 'react-select-async-paginate'

const CreatableAsyncPaginate = withAsyncPaginate(CreatableSelect)

export const RegisterModalTitle = muiStyled(DialogTitle)(
  ({ theme }) => `
    margin: 0;
      font-family: "Outfit";
      font-weight: 600;
      font-size: 20px;
      line-height: 1.2;
      letter-spacing: 0px;
      padding: 17px 24px 20px 17px;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      text-align: center;
      width: 280px;
      @media (min-width: 768px) {
        width: 380px;
        font-size: 24px;
      }
  `,
)

export const RegisterModalActions = muiStyled('div')(
  ({ theme }) => `
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 5px 20px;
      gap: 5px;
      margin-top: 50px;
      @media (min-width: 768px) {
        margin-top: 150px;
      }
  `,
)

export const RegisterModalContent = muiStyled(DialogContent)(
  ({ theme }) => `
    font-family: 'Outfit';
    font-size: 16px;
    text-align: center;
    width: 280px;
    @media (min-width: 768px) {
      width: 380px;
      font-size: 18px;
    }
  `,
)

export const RegisterButton = muiStyled(ButtonBase)(
  ({ theme }) => `
      font-family: 'Outfit';
      border-radius: 3px;
      padding: 5px 10px;
      font-size: 19px;
      color:#523290;
      font-weight: 500;
  `,
)

export const StyledLabel = styled.label`
  font-family: 'Outfit';
  font-size: 16px;
  text-align: start;
`
export const StyledSubLabel = styled.label`
  font-family: 'Outfit';
  font-size: 15px;
  color: #718096;
  font-weight: 300;
  text-align: start;
`

export const StyledCustomInputContainer = muiStyled('div')(
  ({ theme, inputFocused, invalid }) => `
  display: flex;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
  padding: 10px;
  background: ${invalid ? '#edbbbb' : '#ebebeb'};
  border-radius: 7px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: ${inputFocused ? '1px solid #8b8b8b' : '1px solid #ebebeb'};
  border-box:box-sizing;
  overflow: hidden;
`,
)

export const StyledCustomInput = styled.input`
  outline: none;
  background: transparent;
  width: 100%;
  ::placeholder {
    color: ${({ invalid }) => (invalid ? '#fff' : '#00000050')};
  }
  ::-ms-input-placeholder {
    /* Edge 12-18 */
    color: ${({ invalid }) => (invalid ? '#fff' : '#00000050')};
  }
`
export const StyledCustomTextArea = styled.textarea`
  outline: none;
  padding: 10px;
  background: ${({ invalid }) => (invalid ? '#ffe0e0' : '#ebebeb')};
  border-radius: 7px;
  min-height: ${({ theme, minHeight }) => (!!minHeight ? minHeight : 120)}px;
  border: 1px solid #ebebeb;
  width: 100%;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:focus {
    border: 1px solid #8b8b8b;
  }
  ::placeholder {
    color: ${({ invalid }) => (invalid ? '#fff' : '#00000050')};
  }
  ::-ms-input-placeholder {
    /* Edge 12-18 */
    color: ${({ invalid }) => (invalid ? '#fff' : '#00000050')};
  }
`
export const StyledCustomCheckBox = muiStyled(Checkbox)(
  ({ theme }) => `
  color: #626262;
`,
)

export const StyledCustomSelect = muiStyled(Select)`
    text-align: start;
    font-family: 'Outfit';
    > .react-select__control{
      background:#ebebeb;
        border:none;
        padding: 4px 0px;
        border-radius: 7px;
    };
    > .react-select__control .react-select__value-container .react-select__multi-value{
      background-color: rgb(200 200 200);
      margin: 0px 10px 0px 0px;
      border-radius: 3px;
    };
`
export const StyledCreatableSelect = muiStyled(CreatableSelect)`
    text-align: start;
    > .creatable-react-select__control{
      background:#ebebeb;
        border:none;
        padding: 4px 0px;
        border-radius: 7px;
    };
    > .creatable-react-select__control .creatable-react-select__value-container .creatable-react-select__multi-value{
      background-color: rgb(200 200 200);
      margin: 0px 10px 0px 0px;
      border-radius: 3px;
    };
`
export const StyledAsyncSelect = muiStyled(AsyncPaginate)`
    text-align: start;
    > .async-react-select__control{
      background:#ebebeb;
        border:none;
        padding: 4px 0px;
        border-radius: 7px;
    };
    > .async-react-select__control .async-react-select__value-container .async-react-select__multi-value{
      background-color: rgb(200 200 200);
      margin: 0px 10px 0px 0px;
      border-radius: 3px;
    };
`
export const StyledAsyncCreatableSelect = muiStyled(CreatableAsyncPaginate)`
    text-align: start;
    > .async-creatable-react-select__control{
      background:#ebebeb;
        border:none;
        padding: 4px 0px;
        border-radius: 7px;
    };
    > .async-creatable-react-select__control .async-creatable-react-select__value-container .async-creatable-react-select__multi-value{
      background-color: rgb(200 200 200);
      margin: 0px 10px 0px 0px;
      border-radius: 3px;
    };
`
export const StyledCustomHTMLSelect = muiStyled('select')`
outline: none;
    padding: 10px;
background: ${({ invalid }) => (invalid ? '#ffe0e0' : '#ebebeb')};
border-radius: 7px;
width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
`
export const StyledCustomHTMLOptions = muiStyled('option')`

`
export const InputComponentContainer = muiStyled('div')(
  ({ theme }) => `
  width:100%;
  display: flex;
  flex-direction: column;
  algin-items:flex-start;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
`,
)
export const CustomImageAvatar = muiStyled(Avatar)`
    border-radius: 0px;
    height: 100%;
    width: 100%;
`
export const CustomImageAvatarFallBack = muiStyled('div')`
display: flex;
justify-content:center;
align-items:center;
flex-direction:column;
background: #b6b6b6;
color: #fafafa;
font-size: 13px;
font-weight:400;
    height: 100%;
    width: 100%;
`

export const CustomImageAvatarContainer = muiStyled('div')`
width: 150px;
aspect-ratio: ${({ aspectRatio }) => (aspectRatio ? aspectRatio : 1)};
border-radius: 10px;
overflow: hidden;
@media (min-width: 768px) {
  width: 200px;
}
`
export const CustomImageAvatarButton = muiStyled(ButtonBase)`
border-radius: 10px;
`

export const CustomDocumentUploadButton = muiStyled(ButtonBase)`
    display: flex;
    justify-content: center;
    align-items: center;
    gap:7px;
    background: ${({ theme, disabled }) => (disabled ? '#D9D9D9' : '#A864FF')};
    color: #fafafa;
    border-radius: 30px;
    padding: 7px 25px;
`

export const DocumentUploadActionsContainer = muiStyled('div')(
  ({ theme, justifyContent }) => `
  display: flex;
  flex-direction: column;
  justify-content: ${justifyContent};
  align-items:center;
  gap:10px;
  width: 100%;
  padding: 25px 0px 0px 0px;
`,
)

export const ViewDocumentButton = muiStyled(ButtonBase)(
  ({ theme, disabled }) => `
border-radius:30px;
display: flex;
justify-content: center;
gap: 5px;
  padding: 5px 15px;
background: ${disabled ? '#D9D9D9' : '#A864FF'};
color: #fafafa;
`,
)
export const EditDocumentButton = muiStyled(ButtonBase)(
  ({ theme, disabled }) => `
  border-radius:30px;
  display: flex;
  justify-content: center;
  gap: 5px;
  padding: 7px 20px;
  background: ${disabled ? '#D9D9D9' : '#e7b300'};
color: #fafafa;
`,
)
export const DownloadDocumentButton = muiStyled(ButtonBase)(
  ({ theme, disabled }) => `
  border-radius:30px;
  display: flex;
  justify-content: center;
  gap: 5px;
    padding: 5px 15px;

    background: ${disabled ? '#D9D9D9' : '#A864FF'};
color: #fafafa;
text-decoration: none;
`,
)

export const CircularProgressDocument = muiStyled(CircularProgress)`
  color: #A864FF;
`
export const ConfirmationModalDialogPaper = muiStyled(Paper)(
  ({ theme }) => `
  font-family: 'Outfit';
`,
)

export const ConfirmationModalSecondaryButton = muiStyled(ButtonBase)(
  ({ theme }) => `
  border-radius: 7px;
  padding: 7px 20px;
  color: #000;
  font-weight: 500;
  font-size: 16px;
  background: #fafafa;
  transition: background 0.4s ${theme.transitions.easing.quickInOut};
  &:hover{
    background: #f0f0f0;
  }
  `,
)
export const ConfirmationModalPrimaryButton = muiStyled(ButtonBase)(
  ({ theme }) => `
    border-radius: 7px;
    padding: 7px 20px;
    color: #fafafa;
    font-weight: 500;
    font-size: 16px;
    transition: background 0.4s ${theme.transitions.easing.quickInOut};
    background: #A864FF;
    &:hover{
      background: #8f4fda;
    }`,
)

export const ActivityDetailsContainer = muiStyled('div')(
  ({ theme }) => `
  padding: 10px 20px 0px;
  width: 100%;
`,
)

export const ActivityDetailsPlayer = muiStyled('div')(
  ({ theme }) => `
  width: 100%;
  aspect-ratio: 16/9;
`,
)

export const ActivityDetailsHeading = muiStyled('h6')(
  ({ theme }) => `
  font-size: 18px;
  margin: 5px 0 15px;
`,
)

export const TrackUploadButton = muiStyled(ButtonBase)(
  ({ theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  gap:7px;
  background: #A864FF;
  color: #fafafa;
  border-radius: 7px;
  padding: 10px 16px;
  transition: background 0.4s ${theme.transitions.easing.quickInOut};
  &:hover {
    background: #8f4fda;
  }
`,
)

export const QuillWrapper = muiStyled('div')(
  ({ theme }) => `
height: calc(100% - 67px);
font-family: 'Outfit';
.quill {
  position: relative;
  width: 100%;
  color: #242424;
  font-size: 1rem; /* Equivalent to 16px */
  p {
    font-size: 1rem;
    strong {
      font-weight: 500;
    }
  }
  h1 {
    font-size: 1.375rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    line-height: 1.2;
  }
  h2 {
    font-size: 1.325rem;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
  h3 {
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
  h4 {
    font-size: 1.275rem;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
  h5 {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
  h6 {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
  img {
    margin: auto;
    border: 1px solid #f4f4f4;
  }
  a {
    font-size: 1rem;
  }
  img {
    width: 100%;
  }
  blockquote {
    font-size: 1rem;
    margin-bottom: 1rem;
    padding-left: 5px;
  }
  ol {
    list-style-type: decimal;
    padding-left: 20px;
    margin: 16px 0;
  }
  ul {
    list-style-type: disc;
    padding-left: 20px;
    margin: 16px 0;
  }
  li {
    font-size: 1rem;
  }

  @media (min-width: 1200px) {
    font-size: 1rem;
    p {
      strong {
        font-weight: 500;
      }
    }
    h1 {
      font-size: 2.5rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
      line-height: 1.2;
    }
    h2 {
      font-size: 2rem;
      font-weight: 500;
      line-height: 1.2;
      margin-bottom: 0.5rem;
    }
    h3 {
      font-size: 1.75rem;
      font-weight: 500;
      line-height: 1.2;
      margin-bottom: 0.5rem;
    }
    h4 {
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1.2;
      margin-bottom: 0.5rem;
    }
  }
}
`,
)

export const AnimatedHamburgerIconContainer = styled.div`
  padding: 8px 4px;
  width: 32px;
  height: 32px;
  cursor: pointer;
`

export const AnimatedHamburgerIconHorizontalLine = styled.div`
  width: 100%;
  height: 2px;
  transition: all 0.4s ease-in-out;
  position: relative;
  margin-bottom: 6px;
  background: #705dbb;
  transform: rotate(0);
  opacity: 1;
  transform: ${(props) =>
    props?.centerLine
      ? 'rotate(0)'
      : 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)'};
  transform-style: preserve-3d;
  ${(props) =>
    props?.topLine &&
    props?.openNow &&
    'transform: translate3d(0px, 8px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg);'};
  ${(props) => props?.centerLine && props?.openNow && 'opacity: 0;'};
  ${(props) =>
    props?.bottomLine &&
    props?.openNow &&
    'transform: translate3d(0px, -8px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-45deg) skew(0deg, 0deg)'};
`
export const CustomPlayerContainer = muiStyled('div')(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    position: relative;
    height:100%;
    width:100%;
`,
)

export const CustomPlayerSliderContainer = muiStyled('div')(
  ({ theme }) => `
width: 85%;
color: white;
display: flex;
gap: 17px;
align-items: center;

.duration-text {
  font-size: 14px;
  color: #fff;
  flex-shrink: 0;
  font-family: "Roboto Mono", monospace;
}

@media (min-width: 768px) {
  width: 90%;
  .duration-text {
  font-size: 16px;
}
}
@media (min-width: 1300px) {
    width: 95%;
}
`,
)

export const CustomPlayerControlsContainer = muiStyled('div')(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 2px 14px;
    position:absolute;
    bottom:0;
    width: 100%;
    background: #0000008c;
    backdrop-filter: blur(15px);
    @media(min-width: 576px){
      padding: 5px 14px;
    }
    @media (min-width: 768px) {
      padding: 5px 14px;
    }
    
`,
)

export const ContainerWithBoxShadowBlurred = muiStyled('div')(
  ({ theme }) => `
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    flex-direction: column;
    padding: 80px 30px;
    justify-content: center;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    gap:40px;
    align-items: center;
    text-align: center;
    background:#efcbffa6;
    z-index: 1;
    color: #000;
    box-shadow: 0px 21px 11px 8px #efcbffa6;
    &::before {
      content: "";
      position: absolute;
      z-index:-1;
      inset:-100px;
      -webkit-backdrop-filter: blur(13.1px);  // for safari
      backdrop-filter: blur(13.1px);
      mask: 
      linear-gradient(to top, transparent 0%, red 90px calc(100% - 90px), transparent 100%), 
      linear-gradient(to left, transparent 0%, red 90px calc(100% - 90px), transparent 100%);
    -webkit-mask: 
      linear-gradient(to top, transparent 0%, red 90px calc(100% - 90px), transparent 100%), 
      linear-gradient(to left, transparent 0%, red 90px calc(100% - 90px), transparent 100%);
      mask-composite: intersect;
      -webkit-mask-composite: source-in;
    }



    .card-details-text {
      font-size: 34px;
      width: 100%;
      font-weight: 600;
    }
    .card-details-text-secondary {
      font-size: 16px;
      width: 100%;
      font-weight: 400;
    }
    @media(min-width: 768px){
      width: 50%;
      box-shadow: 14px 0px 24px 42px #efcbffa6;
    }
`,
)

export const StyledCustomReactPlayer = muiStyled(ReactPlayer)(
  ({ theme }) => `
  .react-player__shadow {
    background: radial-gradient(rgb(0 0 0 / 50%), rgb(0 0 0 / 50%) 60%) !important;
    border-radius: 64px;
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .react-player__play-icon {
    border-style: solid;
    border-width: 16px 0px 16px 26px;
    border-color: transparent black transparent #ffffffbf !important;
    margin-left: 7px;
  }
`,
)

export const StyledCustomReactPlayerSlider = muiStyled(Slider)(
  ({ theme }) => `
  color: #fff;
  padding: 13px 0 !important;
  .MuiSlider-thumb.Mui-Active{
    box-shadow: 0px 0px 0px 2px rgb(255 255 255);
  }
`,
)

export const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#000',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#000',
  },
}))

export const GenericLoadingOverlayBase = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: ${(props) =>
    props?.backgroundColor ? props?.backgroundColor : 'rgba(0, 0, 0, 0.2)'};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SectionGridItem = muiStyled(Grid)(
  ({ theme, shadow }) => `
    padding: 15px;
    border-radius: 7px;
    box-shadow: ${shadow ? '0 0 5px -1px #000' : 'unset'};
`,
)

export const AutocompleteHelperStyledButton = styled.button`
  padding: 2px 16px;
  background: rgb(104, 118, 223);
  background: linear-gradient(
    90deg,
    rgba(104, 118, 223, 1) 0%,
    rgba(9, 9, 121, 0.9598214285714286) 83%
  );
  color: #fff;
  border-radius: 24px;
`

export const ButtonSCNContainer = styled.button`
  background-color: white;
  color: #333; /* Primary text color */
  border: 1px solid #e5e5e5; /* Light border color */
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: #f0f0f0; /* Slightly darker background on hover */
    border-color: #dcdcdc; /* Darker border color on hover */
  }

  &:active {
    background-color: #e0e0e0; /* Even darker background on click */
    border-color: #c0c0c0; /* Darker border color on click */
    transform: scale(0.98); /* Subtle scale effect on click */
  }

  &:disabled {
    background-color: #f9f9f9; /* Disabled state background */
    color: #b0b0b0; /* Disabled state text color */
    border-color: #e5e5e5; /* Same as normal state border color */
    cursor: not-allowed;
    pointer-events: none;
  }

  &:focus {
    outline: 2px solid #007bff; /* Focus outline color */
    outline-offset: 2px;
  }
`
export const ExtendableTextArea = styled(TextareaAutosize)(
  ({ theme }) => `
  width: 100%;
  padding: 7px 12px;
  border-radius: var(--cui-border-radius);
  background: #fff;
  color: #000;
  border:var(--cui-border-width) solid var(--cui-input-border-color, var(--cui-border-color));
  box-shadow: 0 0 0 0 rgba(50, 31, 219, 0.25) ;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:focus {
    border: 1px solid #a19ae1;
    border-color:#998fed;
    box-shadow: 0 0 0 0.25rem rgba(50, 31, 219, 0.25) ;
  }
  &:disabled{
    border-color:#b1b7c1;
    background: #c9c9c94d;
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
)
