import {
  allAvailableEventTypes,
  SESSION_PLATFORM_OFFLINE,
  SESSION_PLATFORM_ONLINE,
  VISIT_EVENT_TYPES,
} from 'src/consumer/constants'

export function getDefaultOrFirstInPersonEventData({
  eventTypesForOtherSessionsDropdown,
  defaultEventTypeData,
}) {
  let eventTypesForInPerson = eventTypesForOtherSessionsDropdown?.filter(
    (item) =>
      item?.meetingSrc === SESSION_PLATFORM_OFFLINE &&
      item?.visitEventType === VISIT_EVENT_TYPES.regular,
  )

  let defaultOrFirstInPersonEventData =
    eventTypesForInPerson?.find((item) => item.uid === defaultEventTypeData?.uid) ||
    eventTypesForInPerson?.[0]
  return defaultOrFirstInPersonEventData
}

export function getDefaultOrFirstRegularConsultationOrTherapyVideoEventData({
  eventTypesForOtherSessionsDropdown,
  defaultEventTypeData,
}) {
  let eventTypesForVideo = eventTypesForOtherSessionsDropdown?.filter(
    (item) =>
      item?.meetingSrc === SESSION_PLATFORM_ONLINE &&
      item?.visitEventType === VISIT_EVENT_TYPES.regular,
  )

  let defaultOrFirstVideoEventData =
    eventTypesForVideo?.find((item) => item.uid === defaultEventTypeData?.uid) ||
    eventTypesForVideo?.[0]
  return defaultOrFirstVideoEventData
}
export function getDefaultOrFirstEmergencyEventData({
  eventTypesForOtherSessionsDropdown,
  defaultEventTypeData,
}) {
  let eventTypesForEmergency = eventTypesForOtherSessionsDropdown?.filter(
    (item) =>
      item?.meetingSrc === SESSION_PLATFORM_OFFLINE &&
      item?.visitEventType === VISIT_EVENT_TYPES.emergency,
  )

  let defaultOrFirstEmergencyEventData =
    eventTypesForEmergency?.find((item) => item.uid === defaultEventTypeData?.uid) ||
    eventTypesForEmergency?.[0]
  return defaultOrFirstEmergencyEventData
}
export function getDefaultOrFirstFollowUpEventData({
  eventTypesForOtherSessionsDropdown,
  defaultEventTypeData,
}) {
  let eventTypesForFollowUp = eventTypesForOtherSessionsDropdown?.filter(
    (item) =>
      item?.meetingSrc === SESSION_PLATFORM_OFFLINE &&
      item?.visitEventType === VISIT_EVENT_TYPES.followUp,
  )

  let defaultOrFirstFollowUpEventData =
    eventTypesForFollowUp?.find((item) => item.uid === defaultEventTypeData?.uid) ||
    eventTypesForFollowUp?.[0]
  return defaultOrFirstFollowUpEventData
}

export function getAllClinicRelatedEventTypes(eventTypes, clinicId) {
  let arrToReturn = []
  eventTypes?.forEach((eventTypeItem) => {
    if (!eventTypeItem?.enabled) {
      return
    }
    if (
      eventTypeItem?.contextId === clinicId &&
      (eventTypeItem?.type === allAvailableEventTypes.therapySession ||
        eventTypeItem?.type === allAvailableEventTypes.consultation ||
        eventTypeItem?.type === allAvailableEventTypes.oneOnOne)
    ) {
      arrToReturn.push(eventTypeItem)
    }
  })

  return arrToReturn
}
