import { mediaURL } from 'src/consumer/services'
import { calculateNextVisitDate } from '../../PrescriptionMakingPageComponents'

export function DefaultNextVisitRowComponent({
  nextVisitInputValue,
  nextVisitInputType,
  prescriptionData,
  showPrescriptionQRCode = false,
  children,
}) {
  return (
    <>
      <div
        className="flex-shrink-0"
        style={{
          width: '50%',
        }}
      >
        {nextVisitInputValue && nextVisitInputType && (
          <>
            <strong>Next Visit Date</strong>
            <div>
              {calculateNextVisitDate(nextVisitInputValue, nextVisitInputType, 'DD/MM/YYYY, dddd')}
            </div>
          </>
        )}
      </div>
      {children}
      <div
        style={{
          flexShrink: prescriptionData?.signature ? 0 : 'unset',
        }}
      >
        {prescriptionData?.signature && (
          <div>
            <img
              style={{
                height: '50px',
                width: '100px',
                marginBottom: '10px',
              }}
              src={`${mediaURL}${prescriptionData?.signature}`}
              alt="doctor's signature"
            ></img>
            <div>{prescriptionData?.doctorName}</div>
          </div>
        )}
      </div>
    </>
  )
}
