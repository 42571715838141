import { isValidPhoneNumber } from 'react-phone-number-input'
import { hasDuplicateStrings } from 'src/consumer/helpers/utilFunctions'

export function isValidEmail(email) {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return emailRegex.test(email)
}

export function isProspectEmailValid(emailString) {
  if (!emailString) {
    return false
  }
  if (!isValidEmail(emailString)) {
    return false
  }
  return true
}

export function isProspectMobileValid(emailString) {
  if (!emailString) {
    return false
  }
  if (!isValidPhoneNumber(emailString)) {
    return false
  }
  return true
}

export function isProspectValid(prospects, currentProspect) {
  const allEmailsInProspects = prospects?.map((prospect) => {
    return prospect?.email
  })

  const allMobilesInProspects = prospects?.map((prospect) => {
    return prospect?.mobile
  })

  const prospectEmail = currentProspect?.email
  const prospectMobile = currentProspect?.mobile

  //email validitiy
  if (!isProspectEmailValid(prospectEmail)) {
    return false
  }
  if (hasDuplicateStrings(allEmailsInProspects)) {
    return false
  }

  //mobile validitiy
  if (!isProspectMobileValid(prospectMobile)) {
    return false
  }
  // if (hasDuplicateStrings(allEmailsInProspects)) {
  //   return false
  // }

  return true
}
