import moment from 'moment'
import { useEffect, useState } from 'react'
import { getMPDataWithoutParams } from 'src/mentor/services'
import { VitalsItemContainer, VitalsListingPageContainer } from './VitalsListingPageStyles'
import { Button, LinearProgress } from '@mui/material'
import { VitalsComponentEntryDialog } from 'src/clinic/components/ClinicManageBookingsPage/ClinicManageBookingComponents'
import AddIcon from '@mui/icons-material/Add'

export default function VitalsListingPage({ patientId, clinicId }) {
  const [vitalsData, setVitalsData] = useState([])
  const [loading, setLoading] = useState(false)
  const [createDialogOpen, setCreateDialogOpen] = useState(false)

  async function getVitalsArray() {
    setLoading(true)
    const response = await getMPDataWithoutParams(
      `/api/secure/patient/vitals?patientId=${patientId}&clinicId=${clinicId}`,
    )
    setLoading(false)
    if (response.status === 200) {
      setVitalsData(response?.data?.data?.vital)
    }
  }

  useEffect(() => {
    getVitalsArray()
  }, [])

  return (
    <>
      {createDialogOpen && (
        <VitalsComponentEntryDialog
          patientId={patientId}
          clinicId={clinicId}
          closeFunction={() => setCreateDialogOpen(false)}
          callbackFunctionAfterSave={() => {
            setCreateDialogOpen(false)
            getVitalsArray()
          }}
        />
      )}
      {loading ? (
        <div>
          <LinearProgress color="secondary" />
        </div>
      ) : (
        <>
          {vitalsData?.length === 0 ? (
            <div>No vitals data</div>
          ) : (
            <VitalsListingPageContainer>
              <div className="w-100 d-flex" style={{ justifyContent: 'right' }}>
                <Button
                  color="emrPrimary"
                  variant="contained"
                  onClick={() => setCreateDialogOpen(true)}
                >
                  Record
                  <AddIcon />
                </Button>
              </div>
              {vitalsData?.map((vitalItem, vitalIndex) => (
                <VitalShowingComponent key={vitalIndex} vitalItem={vitalItem} />
              ))}
            </VitalsListingPageContainer>
          )}
        </>
      )}
    </>
  )
}

export const VitalShowingComponent = ({ vitalItem, useEmStyles = false }) => {
  return !!vitalItem ? (
    <VitalsItemContainer style={useEmStyles ? { fontSize: '0.75em' } : {}}>
      <div
        style={{
          fontSize: useEmStyles ? '0.65em' : '12px',
          textAlign: 'right',
          marginBottom: useEmStyles ? '1em' : '16px',
        }}
      >
        Recorded: {moment.utc(vitalItem?.modifiedTime)?.local()?.format('DD-MM-YYYY h:mm A')}
      </div>
      {vitalItem?.weightInGrams && (
        <div>
          Weight: <b>{vitalItem?.weightInGrams ? `${vitalItem?.weightInGrams / 1000}kgs` : '-'}</b>
        </div>
      )}
      {vitalItem?.sp02 && (
        <div>
          spO2: <b>{vitalItem?.sp02 ? `${vitalItem?.sp02}%` : '-'}</b>
        </div>
      )}
      {(vitalItem?.systolicBpInMmHg || vitalItem?.diastolicBpInMmHg) && (
        <div>
          Blood Pressure:{' '}
          <b>
            {vitalItem?.systolicBpInMmHg || '-'}/{vitalItem?.diastolicBpInMmHg || '-'} mmHg
          </b>{' '}
          <span style={{ fontSize: useEmStyles ? '0.65em' : '12px' }}>(systolic/diastolic)</span>
        </div>
      )}
      {vitalItem?.bpm && (
        <div>
          Pulse: <b>{vitalItem?.bpm ? `${vitalItem?.bpm}bpm` : '-'}</b>
        </div>
      )}
      {vitalItem?.tempInCelsius && (
        <div>
          Temp:{' '}
          <b>
            {vitalItem?.tempInCelsius
              ? `${vitalItem?.tempInCelsius}°C / ${convertToFahrenheitFromCelsius(
                  vitalItem?.tempInCelsius,
                )}°F`
              : '-'}
          </b>
        </div>
      )}
    </VitalsItemContainer>
  ) : (
    <></>
  )
}

export function convertToFahrenheitFromCelsius(celsius) {
  return (celsius * 9) / 5 + 32
}

export function convertToCelsiusFromFahrenheit(fahrenheit) {
  return ((fahrenheit - 32) * 5) / 9
}
