import { AddCircleOutlineOutlined } from '@mui/icons-material'
import SearchIcon from '@mui/icons-material/Search'
import { debounce } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Spinner } from 'react-bootstrap'

export default function FullPageSearch({
  searchTextPlaceholder,
  cancelFunction,
  searchFunction,
  defaultOptionsFunction,
  optionContainerStyles = {},
  OptionComponent,
  optionComponentProps = {},
  optionClickFunction,
  OptionContainerComponent,
  addNewFunction,
}) {
  const [searchQuery, setSearchQuery] = useState()
  const [localQuery, setLocalQuery] = useState()
  const [loadingOptions, setLoadingOptions] = useState(false)
  const [options, setOptions] = useState([])
  const inputRef = useRef(null)

  const handleDebouncedChange = useCallback(
    debounce((value) => {
      setSearchQuery(value)
    }, 300),
    [],
  )

  const handleChange = (e) => {
    const value = e.target.value
    setLocalQuery(value) // Update local state immediately
    handleDebouncedChange(value) // Debounced update to global state
  }

  async function getAndSetOptions(searchQuery) {
    setLoadingOptions(true)
    let listItems = []
    try {
      if (searchQuery) {
        let list = await searchFunction(searchQuery)
        if (Array.isArray(list)) {
          list = list.slice(0, 30)
          listItems.push(...list)
        }
      } else {
        let list = await defaultOptionsFunction()
        if (Array.isArray(list)) {
          list = list.slice(0, 10)
          listItems.push(...list)
        }
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    }
    if (listItems?.length === 0) {
      if (addNewFunction) {
        listItems.push({
          type: 'new_addition_suggestion',
          label: `Add ${searchQuery}`,
          suggestion_title: searchQuery,
        })
      }
    }
    setOptions(listItems)
    setLoadingOptions(false)
  }

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        if (cancelFunction) {
          cancelFunction()
        }
      }
      if (event.key === 'Enter') {
        if (optionClickFunction && options?.length === 1) {
          if (options?.[0]?.type !== 'new_addition_suggestion') {
            optionClickFunction(options?.[0])
          } else {
            if (addNewFunction) {
              addNewFunction(options?.[0]?.suggestion_title)
            }
          }
        }
      }
    },
    [options, optionClickFunction, cancelFunction],
  )

  useEffect(() => {
    getAndSetOptions(searchQuery)
  }, [searchQuery])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress)
    return () => window.removeEventListener('keydown', handleKeyPress)
  }, [handleKeyPress])

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault()
      event.returnValue = '' // This triggers the native warning
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [])

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1001,
        background: '#fff',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '48px',
          display: 'flex',
          position: 'relative',
          justifyContent: 'left',
          gap: '32px',
          alignItems: 'center',
          background: '#7450AA',
          padding: '0 16px',
        }}
      >
        <div
          className="d-flex"
          style={{ position: 'relative', alignItems: 'center' }}
          onClick={() => {
            if (inputRef?.current) {
              inputRef.current.focus()
            }
          }}
        >
          <SearchIcon
            style={{
              position: 'absolute',
              left: '12px',
              color: '#454545',
              fontSize: '20px',
              zIndex: 3,
            }}
          />
          {!Boolean(localQuery) && (
            <div
              style={{
                color: '#454545',
                position: 'absolute',
                left: '40px',
                fontSize: '16px',
                zIndex: '3',
              }}
            >
              <i>{searchTextPlaceholder || 'Search here'}</i>
            </div>
          )}
          <input
            type="text"
            ref={inputRef}
            value={localQuery}
            onChange={handleChange}
            style={{
              width: '40%',
              minWidth: '400px',
              background: '#fff',
              color: '#454545',
              fontSize: '18px',
              zIndex: 2,
              borderRadius: '4px',
              paddingLeft: '40px',
            }}
            onFocus={(e) => (e.target.style.outline = 'none')}
            onBlur={(e) => (e.target.style.outline = 'none')}
          />
        </div>
        <div style={{ color: '#fff', cursor: 'pointer' }} onClick={cancelFunction}>
          Cancel
        </div>
      </div>
      <div style={{ height: 'calc(100% - 48px)', overflow: 'auto' }}>
        {loadingOptions ? (
          <div style={{ padding: '16px' }}>
            <Spinner size="sm" variant="dark" />
          </div>
        ) : (
          <OptionContainerComponent style={{ ...optionContainerStyles }}>
            {options?.map((optionData, optionDataIdx, array) => {
              if (optionData?.type !== 'new_addition_suggestion') {
                return (
                  <OptionComponent
                    key={optionDataIdx}
                    optionData={optionData}
                    {...optionComponentProps}
                    onClickOption={optionClickFunction}
                    isSingleItemAndSupportsSelectOnEnterKeydown={array.length === 1}
                  />
                )
              } else {
                return (
                  <div
                    key={optionDataIdx}
                    onClick={() => addNewFunction(optionData?.suggestion_title)}
                    style={{
                      border: `1px solid #7450AA`,
                      padding: '4px 8px',
                      borderRadius: '16px',
                      cursor: 'pointer',
                      width: 'fit-content',
                    }}
                  >
                    <span style={{ color: '#7450AA', marginRight: '8px' }}>
                      {optionData?.label}
                    </span>
                    <AddCircleOutlineOutlined style={{ color: '#7450AA' }} />
                  </div>
                )
              }
            })}
          </OptionContainerComponent>
        )}
      </div>
    </div>
  )
}
