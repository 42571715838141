import React, { useContext, useEffect, useState } from 'react'
import { Dialog } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import {
  CAMCMethodButtonContainer,
  CAMCMethodButtonImage,
  CAMCGeneralButton,
  CAMCParent,
  CAMCSeparator,
  CAMCGeneralInput,
  CAMCHeading,
  CAMCMaxWithRelativeContainer,
  CAMCGeneralInputLabel,
  CAMCErrorText,
  CAMCPhoneInput,
  CAMCCaptcha,
} from './ClubbedAuthStyles'
import { UnsecureSetupContext } from 'src/consumer/context/UnsecureSetupContext'
import {
  handleAppleLogin,
  handleFacebookLogin,
  handleGoogleLogin,
} from 'src/consumer/services/loginLogoutHandlers'
import { toast } from 'react-toastify'
import { DisplayFlex } from '../../ConsumerProfilePage/ProfileLeftPannelStyle'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import ErrorIcon from '@mui/icons-material/Error'
import { prepareDataForRegistration, prepareDataForUpdate } from '../../Form/helpers/dataHelpers'
import { postData } from 'src/consumer/services/profileService'
import { Spinner } from 'react-bootstrap'
import { SignIn } from 'src/services/APIs/Login/Login'
import { validateEmail } from 'src/consumer/helpers/utilFunctions'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { RecaptchaVerifier, getAuth, signInWithPhoneNumber } from 'firebase/auth'
import { verifyLogin } from 'src/consumer/services'
import { isBasicOnboardingDone } from 'src/mentor/helpers/utilityFunctions'
import { RectangleRadioButton } from 'src/clinic/components/ClinicManageBookingsPage/ClinicManageBookingComponents'
import { INITAL_CLUBBED_AUTH_MODAL_BASIC_DETAILS_STATE } from './utils'
import { isValidEmail } from 'src/clinic/views/onboardng/clinicBrand/components/utils'
import { useNavigate } from 'react-router'

//////////////////////////////////////////////// helper components

export function ClubbedAuthModal({
  loginVariant = false,
  noCloseButton,
  onClose,
  nameFieldHeading,
  loginFieldHeading,
  preferredLoginInputType,
  preferredLoginInputValue,
  beforeReloadFunction,
  redirectToURI,
  initialOnboardingDetails,
  ...rest
}) {
  return (
    <Dialog
      {...rest}
      PaperProps={{
        sx: { padding: '35px 10px', margin: '10px', maxWidth: 'none', borderRadius: '10px' },
      }}
    >
      {!Boolean(noCloseButton) && (
        <CloseIcon
          sx={{ position: 'absolute', top: '20px', right: '20px', cursor: 'pointer' }}
          onClick={onClose}
        />
      )}
      <ClubbedAuthMainComponent
        theme="light"
        doBasicOnboardingInside={true}
        loginVariant={loginVariant}
        nameFieldHeading={nameFieldHeading}
        loginFieldHeading={loginFieldHeading}
        preferredLoginInputType={preferredLoginInputType}
        preferredLoginInputValue={preferredLoginInputValue}
        beforeReloadFunction={beforeReloadFunction}
        redirectToURI={redirectToURI}
        initialOnboardingDetails={initialOnboardingDetails}
      />
    </Dialog>
  )
}

export const CAMCSocialLoginComponent = ({ type, clickFunction }) => {
  let image
  let text
  switch (type) {
    case 'email':
      image = 'email-logo.svg'
      text = 'Continue with Email'
      break
    case 'mobile':
      text = 'Continue with mobile number'
      break
    case 'google':
      image = 'google-colored.png'
      text = 'Continue with Google'
      break
    case 'facebook':
      image = 'facebook-colored.png'
      text = 'Continue with Facebook'
      break
    case 'apple':
      image = 'apple-colored.png'
      text = 'Continue with Apple'
      break
    default:
      break
  }
  return (
    <CAMCMethodButtonContainer onClick={clickFunction}>
      {image && <CAMCMethodButtonImage src={image} />}
      {text}
    </CAMCMethodButtonContainer>
  )
}

/////////////////////////////////////////// helper functions

const handleAfterLoginCAMC = async (
  response,
  setErrorText,
  doBasicOnboardingInside,
  setBasicOnboardingFlowVisible,
  setProfileDataForBasicOnboarding,
  beforeReloadFunction,
  redirectToURI,
) => {
  if (response.status === 200) {
    localStorage.setItem('token', response.data.accessToken)
    localStorage.setItem('authorities', JSON.stringify(response.data.authorities))
    setProfileDataForBasicOnboarding(response?.data)
    if (doBasicOnboardingInside) {
      if (isBasicOnboardingDone(response?.data)) {
        beforeReloadFunction && beforeReloadFunction()
        if (redirectToURI) {
          window.location.href = decodeURIComponent(redirectToURI)
        } else {
          window.location.reload()
        }
      } else {
        setBasicOnboardingFlowVisible(true)
      }
    } else {
      beforeReloadFunction && beforeReloadFunction()
      if (redirectToURI) {
        window.location.href = decodeURIComponent(redirectToURI)
      } else {
        window.location.reload()
      }
    }
  } else {
    if (setErrorText) {
      setErrorText(response?.response?.data?.message || 'Error Logging In')
    } else {
      toast.error(response?.response?.data?.message || 'Error Logging In')
    }
  }
}

const sendOtp = async (
  number,
  setShowOtpInput,
  setErrorText,
  setMobileNumberInputDisabled,
  setConfirmationResultMobileLogin,
) => {
  const auth = getAuth()
  const CaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {}, auth)
  CaptchaVerifier.render()
  return signInWithPhoneNumber(auth, number, CaptchaVerifier)
    .then((confirmationResult) => {
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      setShowOtpInput(true)
      setMobileNumberInputDisabled(false)
      setConfirmationResultMobileLogin(confirmationResult)
      return confirmationResult
      //...
    })
    .catch(() => {
      // Error; SMS not sent
      // ...
      setErrorText('Error sending OTP')
    })
}

const resendOtp = async (
  number,
  setShowOtpInput,
  setErrorText,
  setMobileNumberInputDisabled,
  setConfirmationResultMobileLogin,
  resetSecondsTimeout,
  increaseCountResendOtp,
) => {
  setShowOtpInput(false)
  const auth = getAuth()
  const CaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {}, auth)
  CaptchaVerifier.render()
  try {
    const confirmationResult = await signInWithPhoneNumber(auth, number, CaptchaVerifier)
    // SMS sent. Prompt user to type the code from the message, then sign the
    // user in with confirmationResult.confirm(code).
    resetSecondsTimeout()
    increaseCountResendOtp()
    setShowOtpInput(true)
    setMobileNumberInputDisabled(false)
    setConfirmationResultMobileLogin(confirmationResult)
    return confirmationResult
  } catch {
    // Error; SMS not sent
    // ...
    setErrorText('Error sending OTP')
  }
}

const verifyOtp = async (
  otp,
  confirmationResultMobileLogin,
  setErrorText,
  doBasicOnboardingInside,
  setBasicOnboardingFlowVisible,
  setProfileDataForBasicOnboarding,
  beforeReloadFunction,
  redirectToURI,
) => {
  try {
    const response = await confirmationResultMobileLogin.confirm(otp)
    const res = await verifyLogin(response.user.accessToken)
    handleAfterLoginCAMC(
      res,
      setErrorText,
      doBasicOnboardingInside,
      setBasicOnboardingFlowVisible,
      setProfileDataForBasicOnboarding,
      beforeReloadFunction,
      redirectToURI,
    )
  } catch (err) {
    setErrorText('Invalid OTP! Please check again')
  }
}

/////////////////////////////////////////// MAIN THING :

export function ClubbedAuthMainComponent({
  theme,
  doBasicOnboardingInside = false,
  loginVariant,
  nameFieldHeading = 'Name',
  loginFieldHeading = 'Log in',
  preferredLoginInputType = 'mobile',
  preferredLoginInputValue,
  beforeReloadFunction,
  redirectToURI,
  initialOnboardingDetails = INITAL_CLUBBED_AUTH_MODAL_BASIC_DETAILS_STATE,
}) {
  const navigate = useNavigate()
  const { unSecureSetupData } = useContext(UnsecureSetupContext)
  const [basicOnboardingFlowVisible, setBasicOnboardingFlowVisible] = useState(false)
  const [authType, setAuthType] = useState(loginVariant ? 'login' : 'signup')
  const [activeInputType, setActiveInputType] = useState() // email, mobile
  const [inputEmailPassword, setInputEmailPassword] = useState({ email: '', password: '' })
  const [inputMobileNumber, setInputMobileNumber] = useState()
  const [showOtpInput, setShowOtpInput] = useState(false)
  const [inputOtp, setInputOtp] = useState()
  const [resendOtpAttempts, setResendOtpAttempts] = useState(0)
  const [secondsTimeOut, setSecondsTimeOut] = useState(20)
  const [confirmationResultMobileLogin, setConfirmationResultMobileLogin] = useState()
  const [passwordInputType, setPasswordInputType] = useState('password')
  const [errorText, setErrorText] = useState(null)
  const [userInputsDisabled, setUserInputsDisabled] = useState(false)
  const [mobileNumberInputDisabled, setMobileNumberInputDisabled] = useState(false)
  const [useOtherMethods, setUseOtherMethods] = useState(false)

  const [profileDataForBasicOnboarding, setProfileDataForBasicOnboarding] = useState()
  const [basicOnboardingDetails, setBasicOnboardingDetails] = useState(initialOnboardingDetails)
  const [basicOnboardingErrorText, setBasicOnboardingErrorText] = useState()
  const [basicOnboardingLoading, setBasicOnboardingLoading] = useState(false)

  useEffect(() => {
    if (preferredLoginInputType === 'mobile') {
      if (unSecureSetupData.loginPolicy.login.mobile) {
        if (preferredLoginInputValue && typeof preferredLoginInputValue === 'string') {
          if (preferredLoginInputValue.startsWith('+')) {
            setInputMobileNumber(preferredLoginInputValue.slice(1))
          } else {
            setInputMobileNumber(preferredLoginInputValue)
          }
        }
        setActiveInputType('mobile')
      } else if (unSecureSetupData.loginPolicy.login.usernamePassword) {
        setActiveInputType('email')
      }
    } else if (preferredLoginInputType === 'email') {
      if (unSecureSetupData.loginPolicy.login.usernamePassword) {
        console.log(preferredLoginInputValue)
        if (preferredLoginInputValue && isValidEmail(preferredLoginInputValue)) {
          setInputEmailPassword({ ...inputEmailPassword, email: preferredLoginInputValue })
        }
        setActiveInputType('email')
      } else if (unSecureSetupData.loginPolicy.login.mobile) {
        setActiveInputType('mobile')
      }
    }
  }, [])

  console.log(inputMobileNumber)
  console.log(typeof inputMobileNumber)

  function resetTogether() {
    setErrorText(null)
    setInputOtp()
    setShowOtpInput(false)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (secondsTimeOut > 0 && showOtpInput) {
        setSecondsTimeOut((prev) => prev - 1)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [secondsTimeOut, showOtpInput]) //reducing time every second

  async function handleNext(doBasicOnboardingInside) {
    setErrorText(null)
    setUserInputsDisabled(true)
    if (authType === 'signup' && activeInputType === 'email') {
      if (validateEmail(inputEmailPassword.email)) {
        if (inputEmailPassword.password.length >= 6) {
          const payload = prepareDataForRegistration(inputEmailPassword)
          const response = await postData('/api/user/registration', payload)
          handleAfterLoginCAMC(
            response,
            setErrorText,
            doBasicOnboardingInside,
            setBasicOnboardingFlowVisible,
            setProfileDataForBasicOnboarding,
            beforeReloadFunction,
            redirectToURI,
          )
        } else {
          setErrorText('Password should contain at least 6 digits')
          setUserInputsDisabled(false)
          return null
        }
      } else {
        setErrorText('Please enter a valid email address')
        setUserInputsDisabled(false)
        return null
      }
    } else if (authType === 'login' && activeInputType === 'email') {
      const response = await SignIn(inputEmailPassword.email, inputEmailPassword.password)
      await handleAfterLoginCAMC(
        response,
        setErrorText,
        doBasicOnboardingInside,
        setBasicOnboardingFlowVisible,
        setProfileDataForBasicOnboarding,
        beforeReloadFunction,
        redirectToURI,
      )
    } else if (activeInputType === 'mobile' && showOtpInput) {
      if (!inputOtp?.length) {
        setErrorText('Please enter the OTP received on entered number')
        setUserInputsDisabled(false)
        return null
      } else {
        await verifyOtp(
          inputOtp,
          confirmationResultMobileLogin,
          setErrorText,
          doBasicOnboardingInside,
          setBasicOnboardingFlowVisible,
          setProfileDataForBasicOnboarding,
          beforeReloadFunction,
          redirectToURI,
        )
      }
    } else if (activeInputType === 'mobile') {
      if (inputMobileNumber?.length === 0) {
        setErrorText('Please enter your mobile number')
        setUserInputsDisabled(false)
        return null
      } else if (!isValidPhoneNumber(`+${inputMobileNumber}`)) {
        setErrorText('Please enter a valid mobile number')
        setUserInputsDisabled(false)
        return null
      } else {
        await sendOtp(
          `+${inputMobileNumber}`,
          setShowOtpInput,
          setErrorText,
          setMobileNumberInputDisabled,
          setConfirmationResultMobileLogin,
        )
      }
    }
    setUserInputsDisabled(false)
  }

  async function handleNextBasicOnboarding(profileData) {
    setBasicOnboardingErrorText(null)
    setBasicOnboardingLoading(true)
    if (basicOnboardingDetails?.name?.length > 0) {
      if (basicOnboardingDetails.age >= 12 && basicOnboardingDetails.age <= 120) {
        const payload = prepareDataForUpdate(basicOnboardingDetails, profileData)
        const response = await postData('/api/secure/user/update', payload)
        if (response.status === 200) {
          beforeReloadFunction && beforeReloadFunction()
          if (redirectToURI) {
            window.location.href = decodeURIComponent(redirectToURI)
          } else {
            window.location.reload()
          }
        } else {
          beforeReloadFunction && beforeReloadFunction()
          if (redirectToURI) {
            window.location.href = decodeURIComponent(redirectToURI)
          } else {
            window.location.reload()
          }
        }
      } else {
        setBasicOnboardingErrorText('You must be 12-120 to use AUMHUM')
        setBasicOnboardingLoading(false)
      }
    } else {
      setBasicOnboardingErrorText('Please enter your name')
      setBasicOnboardingLoading(false)
    }
    setBasicOnboardingLoading(false)
  }

  const resetSecondsTimeout = () => setSecondsTimeOut(20)
  const increaseCountResendOtp = () => setResendOtpAttempts((prev) => prev + 1)

  return (
    <CAMCParent>
      {!basicOnboardingFlowVisible && (
        <>
          <CAMCHeading>{authType === 'signup' ? 'Sign up' : loginFieldHeading}</CAMCHeading>

          {activeInputType === 'email' && (
            <>
              <CAMCMaxWithRelativeContainer>
                <CAMCGeneralInputLabel htmlFor="email">Email address</CAMCGeneralInputLabel>
                <CAMCGeneralInput
                  id="email"
                  type="text"
                  value={inputEmailPassword?.email}
                  disabled={userInputsDisabled}
                  onChange={(e) =>
                    setInputEmailPassword((prev) => ({ ...prev, email: e.target.value }))
                  }
                />
              </CAMCMaxWithRelativeContainer>
              <CAMCMaxWithRelativeContainer>
                <CAMCGeneralInputLabel htmlFor="password">Password</CAMCGeneralInputLabel>
                <CAMCMaxWithRelativeContainer>
                  <CAMCGeneralInput
                    id="password"
                    type={passwordInputType}
                    value={inputEmailPassword?.password}
                    disabled={userInputsDisabled}
                    onChange={(e) =>
                      setInputEmailPassword((prev) => ({ ...prev, password: e.target.value }))
                    }
                  />
                  {passwordInputType === 'text' ? (
                    <VisibilityOffIcon
                      sx={{
                        fontSize: 20,
                        position: 'absolute',
                        right: 5,
                        top: 11,
                        cursor: 'pointer',
                      }}
                      onClick={() => setPasswordInputType('password')}
                    />
                  ) : (
                    <VisibilityIcon
                      sx={{
                        fontSize: 20,
                        position: 'absolute',
                        right: 5,
                        top: 11,
                        cursor: 'pointer',
                      }}
                      onClick={() => setPasswordInputType('text')}
                    />
                  )}
                </CAMCMaxWithRelativeContainer>
                <div
                  style={{
                    color: '#514387',
                    fontSize: '12px',
                    textAlign: 'right',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (isValidEmail(inputEmailPassword?.email)) {
                      navigate(`/forgot-password?email=${inputEmailPassword?.email}`)
                    } else {
                      navigate(`/forgot-password`)
                    }
                  }}
                >
                  Forgot password?
                </div>
              </CAMCMaxWithRelativeContainer>
            </>
          )}

          {activeInputType === 'mobile' && (
            <CAMCMaxWithRelativeContainer>
              <CAMCGeneralInputLabel>Mobile</CAMCGeneralInputLabel>
              <CAMCPhoneInput
                disabled={mobileNumberInputDisabled}
                specialLabel=""
                placeholder="Mobile Number"
                country={localStorage.getItem('countryCode')?.toLowerCase() || 'in'}
                value={inputMobileNumber}
                onChange={(value) => setInputMobileNumber(value)}
                // enableSearch={true}
                // searchPlaceholder="Search"
                // searchNotFound="No entries to show"
              />
              {!showOtpInput && <CAMCCaptcha id="recaptcha-container"></CAMCCaptcha>}
              {showOtpInput && (
                <CAMCMaxWithRelativeContainer>
                  <CAMCGeneralInputLabel htmlFor="otp">Enter OTP</CAMCGeneralInputLabel>
                  <CAMCGeneralInput
                    id="otp"
                    type="text"
                    onChange={(e) => setInputOtp(e.target.value)}
                  />
                  {resendOtpAttempts > 3 ? (
                    <div>
                      You have reached maximum attempts of sending OTP. Please try again later.
                    </div>
                  ) : (
                    <>
                      <div>Didn&apos;t get OTP?</div>
                      {secondsTimeOut > 0 ? (
                        <span style={{ cursor: 'default' }}>Resend in {secondsTimeOut}</span>
                      ) : (
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            resendOtp(
                              `+${inputMobileNumber}`,
                              setShowOtpInput,
                              setErrorText,
                              setMobileNumberInputDisabled,
                              setConfirmationResultMobileLogin,
                              resetSecondsTimeout,
                              increaseCountResendOtp,
                            )
                          }
                        >
                          Resend now
                        </span>
                      )}
                    </>
                  )}
                </CAMCMaxWithRelativeContainer>
              )}
            </CAMCMaxWithRelativeContainer>
          )}

          {errorText && (
            <CAMCErrorText>
              <ErrorIcon sx={{ fontSize: '16px' }} />
              {errorText}
            </CAMCErrorText>
          )}

          <CAMCGeneralButton
            onClick={() => handleNext(doBasicOnboardingInside)}
            success
            disabled={userInputsDisabled}
          >
            {userInputsDisabled ? <Spinner animation="border" variant="light" size="sm" /> : 'Next'}
          </CAMCGeneralButton>

          {/* generic stuff below this except last some, same for login and signup */}

          <DisplayFlex style={{ width: '100%', gap: '10px', maxWidth: '305px' }}>
            <CAMCSeparator />
            <div>or</div>
            <CAMCSeparator />
          </DisplayFlex>

          {!useOtherMethods && (
            <div style={{ cursor: 'pointer' }} onClick={() => setUseOtherMethods(true)}>
              <u>Use other methods</u>
            </div>
          )}
          {useOtherMethods && (
            <>
              {unSecureSetupData.loginPolicy.login.usernamePassword && activeInputType !== 'email' && (
                <CAMCSocialLoginComponent
                  type="email"
                  clickFunction={() => {
                    resetTogether()
                    setActiveInputType('email')
                  }}
                />
              )}
              {unSecureSetupData.loginPolicy.login.mobile && activeInputType !== 'mobile' && (
                <CAMCSocialLoginComponent
                  type="mobile"
                  clickFunction={() => {
                    resetTogether()
                    setActiveInputType('mobile')
                  }}
                />
              )}
              {unSecureSetupData.loginPolicy.login.google && activeInputType !== 'google' && (
                <CAMCSocialLoginComponent
                  type="google"
                  clickFunction={() => {
                    resetTogether()
                    handleGoogleLogin(
                      handleAfterLoginCAMC,
                      setErrorText,
                      doBasicOnboardingInside,
                      setBasicOnboardingFlowVisible,
                      setProfileDataForBasicOnboarding,
                      beforeReloadFunction,
                    )
                  }}
                />
              )}
              {/* {unSecureSetupData.loginPolicy.login.facebook && activeInputType !== 'facebook' && <CAMCSocialLoginComponent type='facebook' clickFunction={() => { resetTogether(); handleFacebookLogin(handleAfterLoginCAMC, setErrorText, doBasicOnboardingInside, setBasicOnboardingFlowVisible, setProfileDataForBasicOnboarding) }} />} */}
              {unSecureSetupData.loginPolicy.login.apple && activeInputType !== 'apple' && (
                <CAMCSocialLoginComponent
                  type="apple"
                  clickFunction={() => {
                    resetTogether()
                    handleAppleLogin(
                      handleAfterLoginCAMC,
                      setErrorText,
                      doBasicOnboardingInside,
                      setBasicOnboardingFlowVisible,
                      setProfileDataForBasicOnboarding,
                      beforeReloadFunction,
                    )
                  }}
                />
              )}
            </>
          )}

          <CAMCMaxWithRelativeContainer>
            <CAMCSeparator />
          </CAMCMaxWithRelativeContainer>

          {authType === 'signup' && (
            <div>
              Already have an account?{' '}
              <span
                onClick={() => {
                  resetTogether()
                  setAuthType('login')
                }}
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
              >
                Login here
              </span>
            </div>
          )}
          {authType === 'login' && (
            <div>
              Don&apos;t have an account?{' '}
              <span
                onClick={() => {
                  resetTogether()
                  setAuthType('signup')
                }}
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
              >
                Sign up for AUMHUM
              </span>
            </div>
          )}
        </>
      )}

      {basicOnboardingFlowVisible && (
        <>
          <CAMCHeading secondary>Please fill the following details</CAMCHeading>

          <CAMCMaxWithRelativeContainer>
            <CAMCGeneralInputLabel htmlFor="name">
              {nameFieldHeading || 'Name'}
            </CAMCGeneralInputLabel>
            <CAMCGeneralInput
              id="name"
              type="text"
              value={basicOnboardingDetails?.name}
              onChange={(e) =>
                setBasicOnboardingDetails((prev) => ({ ...prev, name: e.target.value }))
              }
            />
          </CAMCMaxWithRelativeContainer>

          <CAMCMaxWithRelativeContainer>
            <CAMCGeneralInputLabel htmlFor="age">Age</CAMCGeneralInputLabel>
            <CAMCGeneralInput
              id="age"
              type="number"
              value={basicOnboardingDetails?.age}
              onChange={(e) =>
                setBasicOnboardingDetails((prev) => ({ ...prev, age: e.target.value }))
              }
            />
          </CAMCMaxWithRelativeContainer>

          <CAMCMaxWithRelativeContainer>
            <CAMCGeneralInputLabel htmlFor="name">Gender</CAMCGeneralInputLabel>
            <RectangleRadioButton
              options={[
                { label: 'Male', id: 'male' },
                { label: 'Female', id: 'female' },
                { label: 'Non-Binary', id: 'nonBinary' },
              ]}
              selectedId={basicOnboardingDetails?.gender}
              selectionFunction={(id) =>
                setBasicOnboardingDetails((prev) => ({ ...prev, gender: id }))
              }
            />
          </CAMCMaxWithRelativeContainer>

          {basicOnboardingErrorText && (
            <CAMCErrorText>
              <ErrorIcon sx={{ fontSize: '16px' }} />
              {basicOnboardingErrorText}
            </CAMCErrorText>
          )}

          <CAMCGeneralButton
            success
            style={{ marginTop: '30px' }}
            onClick={() => handleNextBasicOnboarding(profileDataForBasicOnboarding)}
          >
            {basicOnboardingLoading ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              'Continue'
            )}
          </CAMCGeneralButton>
        </>
      )}
    </CAMCParent>
  )
}

// fix facebook option
// fix mobile option
