import { ButtonBase, FormControlLabel, IconButton, styled as muiStyled } from '@mui/material'

export const PrescriptionTableViewInputFormControlLabel = muiStyled(FormControlLabel)(
  ({ theme }) => `
    .MuiTypography-root{
    font-weight: 600;
    }
`,
)

function getBackgroundColorForDocOnPills({
  theme,
  selected,
  highlighted,
  selectedColor = '#246797',
  highlightedColor = '#003a89',
}) {
  if (highlighted) {
    return highlightedColor
  }
  if (selected) {
    return selectedColor
  }
  return '#fff'
}

export const PrescriptionPillViewInputPillContainer = muiStyled(ButtonBase)(
  ({ theme, selected, highlighted, selectedColor, highlightedColor }) => `
    display: flex;
    gap: 2px;
    text-align: left;
    font-weight: ${selected ? '600' : '400'};
    border: ${selected ? '1px solid #0000000' : '1px solid #e0e0e0'};
    background-color: ${getBackgroundColorForDocOnPills({
      theme,
      selected,
      highlighted,
      selectedColor,
      highlightedColor,
    })};
    color: ${selected ? 'white' : 'black'};
    align-items: center;
    transition: background-color 170ms;
    padding: ${selected || highlighted ? '8px 7px 8px 16px' : '10px 10px'};
    border-radius: 66px;
    gap: 14px;
`,
)

export const PrescriptionRemoveIconButton = muiStyled(IconButton)(
  ({ theme }) => `
    color: #fff;
    padding: 3px;
    background-color: #00000075;
    &:hover {
      background-color: #00000090;
    }
`,
)

export const PrescriptionPillViewInputNewPillContainer = muiStyled(ButtonBase)(
  ({ theme }) => `
    display: flex;
    gap: 2px;
    font-weight: 600;
    color: #246797;
    border: 1px solid #246797;
    align-items: center;
    padding: 7px 30px;
    border-radius: 66px;
    gap: 18px;
`,
)

export const PillOptionsContainer = muiStyled('div')(({theme})=>`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 10px;
  align-items:flex-start;
`)

export const DoconContextualForm = muiStyled('div')(
  ({ theme, side, open, slideTransitionTiming = 200 }) => `
    z-index: 2;
    position:absolute;
    background: #f4f5f9;
    top: 0px;
    height:100%;
    width: ${open ? '50%' : '0px'};
    ${side === 'left' ? 'left:0px' : 'right: 0px'};
    transition: width ${slideTransitionTiming}ms ${theme.transitions.easing.quickInOut};
    // transform: ${open ? 'translateX(0)' : `translateX(${side === 'left' ? '-100%' : '100%'})`};
    box-shadow: ${open ? '0 0 8px 4px rgb(0 0 0 / 42%)' : '0 0 3px 4px rgb(0 0 0 / 0%)'};
    display: flex;
    flex-direction: column;
  `,
)

export const TableHeadingContainer = muiStyled('div')(
  ({ theme }) => `
    position: sticky;
    top: 0px;
    z-index: 1;
    background: white;
    padding: 10px 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 10px;
    margin-bottom: 20px;
`,
)

export const DocOnContextSectionContainer = muiStyled('div')(
  ({ theme }) => `
  display:flex;
  flex-direction: column;
  border-radius: 10px;
  background: #fff;
  overflow: hidden;
`,
)

export const DocOnContextSectionHeader = muiStyled('div')(
  ({ theme }) => `
  padding: 10px 10px;
  background: #dce2f5;
  font-weight: 600;
`,
)
