import { useEffect, useState } from 'react'
import { getDataWithParams } from 'src/consumer/services/profileService'
import { isCommonResponseSuccessful } from 'src/mentor/helpers/utilityFunctions'
import CloseIcon from '@mui/icons-material/Close'
import { Dialog } from '@mui/material'
import { isAppRunningInProduction } from 'src/helper'
import {
  APP_CONSTANTS,
  APP_UPDATE_ACTIONS,
  DISPATCH_EVENT_KEYS,
  STORAGE_KEYS,
} from 'src/consumer/constants'

export default function AppUpdatePopup() {
  const [isSuggestedUpdateToastOpen, setIsSuggestedUpdateToastOpen] = useState(false)
  const [isForceUpdateDialogOpen, setIsForceUpdateDialogOpen] = useState(false)
  const SUGGEST_UPDATE = APP_UPDATE_ACTIONS.SUGGEST_UPDATE
  const FORCE_UPDATE = APP_UPDATE_ACTIONS.FORCE_UPDATE

  const fetchData = async () => {
    const response = await getDataWithParams(`/api/nc/app-update-action`, {
      packageId: 'com.aumhum.web',
      // platform: 'web', // already sent in getDataWithParams
      destination: isAppRunningInProduction()
        ? encodeURIComponent(APP_CONSTANTS.LIVE_APP_URL)
        : encodeURIComponent(APP_CONSTANTS.BETA_APP_URL),
    })
    if (
      response?.status === 200 &&
      isCommonResponseSuccessful(response?.data?.code) &&
      response?.data?.data?.action
    ) {
      const action = response?.data?.data?.action

      // dispatching this : so any child can override(eg. reload) the default behavior
      if (action === SUGGEST_UPDATE || action === FORCE_UPDATE) {
        const updateEvent = new CustomEvent(DISPATCH_EVENT_KEYS.appUpdateActionEvent, {
          detail: { action: action },
        })
        window.dispatchEvent(updateEvent)
      }

      if (action === SUGGEST_UPDATE) {
        setIsSuggestedUpdateToastOpen(true)
        setIsForceUpdateDialogOpen(false)
      } else if (action === FORCE_UPDATE) {
        setIsSuggestedUpdateToastOpen(false)
        setIsForceUpdateDialogOpen(true)
      } else {
        setIsSuggestedUpdateToastOpen(false)
        setIsForceUpdateDialogOpen(false)
      }
      const appReleaseData = response?.data?.data?.['app-releases']
      const presentDefaultApiUrl = localStorage.getItem(STORAGE_KEYS.restApiURL)
      if (appReleaseData && appReleaseData?.defaultRestApiServer && presentDefaultApiUrl) {
        if (appReleaseData?.defaultRestApiServer !== presentDefaultApiUrl) {
          localStorage.setItem(STORAGE_KEYS.restApiURL, appReleaseData?.defaultRestApiServer)
          setIsSuggestedUpdateToastOpen(false)
          setIsForceUpdateDialogOpen(true)
        }
      }
    }
  }

  useEffect(() => {
    fetchData() // Fetch data immediately on mount
    const intervalId = setInterval(fetchData, 600000) // Set interval for 10 minutes
    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  if (isSuggestedUpdateToastOpen && popupAllowedOnThisUrl()) {
    return (
      <div
        style={{
          position: 'fixed',
          bottom: '20px',
          left: '20px',
          width: '200px',
          height: 'auto',
          userSelect: 'none',
          background: '#fff',
          padding: '8px',
          borderRadius: '8px',
          color: '#000',
          zIndex: 2147482999,
          fontSize: '12px',
          border: '1px solid #cecece',
        }}
      >
        <div className="d-flex" style={{ justifyContent: 'space-between', gap: '8px' }}>
          <div onClick={() => window.location.reload()}>
            <div>A new app version is available.</div>
            <button style={{ color: '#fff', background: '#000', borderRadius: '4px' }}>
              Update Now
            </button>
          </div>
          <CloseIcon
            style={{ fontSize: '16px', cursor: 'pointer' }}
            onClick={() => setIsSuggestedUpdateToastOpen(false)}
          />
        </div>
      </div>
    )
  }

  if (isForceUpdateDialogOpen && popupAllowedOnThisUrl()) {
    return (
      <Dialog
        open={isForceUpdateDialogOpen}
        PaperProps={{
          sx: { padding: '32px 8px', margin: '10px', maxWidth: 'none', borderRadius: '10px' },
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '16px',
            maxWidth: '300px',
            textAlign: 'center',
          }}
        >
          <div style={{ fontSize: '24px' }}>
            <b>New Update Released</b>
          </div>
          <div>You need to update the app to continue</div>
          <button
            style={{ color: '#fff', background: '#000', borderRadius: '4px', marginTop: '48px' }}
            onClick={() => window.location.reload()}
          >
            Update Now
          </button>
        </div>
      </Dialog>
    )
  }
}


function popupAllowedOnThisUrl() {
  // List of pages where popups should be blocked
  const blockedPaths = [
    '/legal',
    '/terms',
    '/privacy-policy-1',
    '/cookie-policy',
    '/instructor-terms',
    '/copy-of-privacy-policy'
  ];

  // Get the current page URL path
  const currentPath = window.location.pathname;

  // Check if the current path is in the blocked list or contains "/webview/"
  return !blockedPaths.includes(currentPath) && !currentPath.includes('/webview/');
}