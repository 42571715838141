import React from 'react'
import PdfReportQuestionAnswer from './PdfReportQuestionAnswer'

export default function PdfReportSectionHandler({ section, firstSection }) {
  console.log({ section, firstSection })

  return (
    <>
      {!firstSection && <div className="border-bottom w-100"></div>}
      <div className="mt-1">
        <div style={{ fontWeight: '600', fontSize: '14px' }}>{section?.title}</div>
        <div>
          {section?.questions?.map((question, idx) => (
            <PdfReportQuestionAnswer
              key={question?.id}
              question={question}
              questionNumber={idx + 1}
            />
          ))}
        </div>
      </div>
    </>
  )
}
