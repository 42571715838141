import { openDB } from 'idb'
import { IDB_STORES } from 'src/consumer/constants'

const requiredStores = Object.values(IDB_STORES).map((store) => store.name)

export async function createERMDatabase() {
  return openDB('erm-database', 7, {
    upgrade(db, oldVersion) {
      // delete the stores that are not supported
      const existingStores = Array.from(db.objectStoreNames)
      for (const store of existingStores) {
        if (!requiredStores.includes(store)) {
          db.deleteObjectStore(store) // Remove unwanted stores
        }
      }

      for (const storeConfig of Object.values(IDB_STORES)) {
        const { name, keyPath, indexes } = storeConfig
        let store
        if (!db.objectStoreNames.contains(name)) {
          store = db.createObjectStore(name, { keyPath })
          for (const { name: indexName, field, unique } of indexes) {
            store.createIndex(indexName, field, { unique })
          }
        }
      }
    },
  })
}

// NOT BEING USED
export async function deleteERMDatabase() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.deleteDatabase('erm-database')

    request.onsuccess = () => {
      console.log(`Database ${'erm-database'} deleted successfully`)
      resolve()
    }

    request.onerror = (event) => {
      console.error(`Error deleting database ${'erm-database'}:`, event.target.error)
      reject(event.target.error)
    }

    request.onblocked = () => {
      console.warn(`Database ${'erm-database'} deletion blocked`)
    }
  })
}
