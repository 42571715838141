import { CircularProgress, Dialog, Grid, IconButton, Tooltip } from '@mui/material'
import { useContext, useEffect, useMemo, useState } from 'react'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import {
  CustomAsyncPaginate,
  CustomCheckBox,
  CustomCreatableSelect,
  CustomHTMLSelect,
  CustomInput,
  CustomSelect,
  DocumentUploadComponent,
  selectTypes,
} from 'src/consumer/components/helperComponents'
import { StyledLabel, StyledSubLabel } from 'src/consumer/components/helperComponentsStyles'
import {
  AddACertificateContainer,
  AddCertificationButton,
  CertificateAddIcon,
  CertificationDialogActions,
  CertificationDialogContent,
  CertificationDialogTitle,
  CertificationFormTableItem,
  CertificationHeaderContainer,
  CloseCertificateDialogButton,
  DownloadCertificateTag,
  EditCertificateButton,
  MentorWorkExperienceInputContainer,
  ProfileComponentsContainer,
  SaveCertificateDialogButton,
  SectionHeading,
  SectionSubHeading,
  StrongHeading,
  UploadCertificateDialogPaper,
  WorkExperienceDropDownsContainer,
} from '../../MentorProfilePageStyles'

import { Delete, Edit } from '@mui/icons-material'
import moment from 'moment'
import { toast } from 'react-toastify'
import { mediaURL } from 'src/consumer/services'
import {
  getMPData,
  getMPDataWithParams,
  getMPDataWithoutParams,
  postMPDataWithoutParams,
} from 'src/mentor/services'
import { MentorProfileContext } from '../../../../context/MentorProfileContext'
import {
  getOnboardingType,
  isOnboardedAsDoctor,
  isOnboardedAsTherapist,
} from 'src/mentor/layout/utils'
import { customQualificationsFilter } from './utils'
import dayjs from 'dayjs'
import { MENTOR_REG_ID_TYPE_ARRAY } from 'src/consumer/constants'

export function MentoringDetails({
  formState,
  setFormState,
  setPage,
  handleChange,
  handleNestedChange,
  dropDownContextData,
}) {
  const creatableOptions = formState?.personalize?.qualifications?.map((qual) => ({
    id: qual,
    title: qual,
  }))
  const [openEditCertDialog, setOpenEditCertDialog] = useState(false)
  const [editModalHeading, setEditModalHeading] = useState('Add A Certificate')
  const [selectedCertificate, setSelectedCertificate] = useState({
    title: '',
    fileUrl: '',
    fileType: 'application/pdf', //change this default thing
  })
  const [hasMore, setHasMore] = useState(true)
  const [loadingCertifications, setLoadingCertifications] = useState(false)
  const [certificationPage, setCertificationPage] = useState(0)
  const [allCertificates, setAllCertificates] = useState([])
  const [metaCategories, setMetaCategories] = useState()
  const [metaCategoriesLoading, setMetaCategoriesLoading] = useState(true)
  const [qualifications, setQualifications] = useState([])
  const [qualificationsLoading, setQualificationsLoading] = useState(true)

  const monthsAndYearsSinceStartDate = useMemo(() => {
    return (
      formState?.personalize?.experienceStartMonth &&
      formState?.personalize?.experienceStartYear &&
      calculateYearsAndMonthsSince(
        createMomentDate(
          formState?.personalize?.experienceStartMonth,
          formState?.personalize?.experienceStartYear,
        ),
      )
    )
  }, [formState?.personalize?.experienceStartMonth, formState?.personalize?.experienceStartYear])
  // formState?.personalize?.experienceStartMonth &&
  // formState?.personalize?.experienceStartYear &&

  async function getCategories() {
    const resp = await getMPData('/api/secure/teacher/meta-categories')
    if (resp?.status === 200) {
      if (resp?.data) {
        const filteredMetaCategories = resp.data?.filter((item) => {
          if (item?.hasOwnProperty('appListable')) {
            if (item?.appListable === true) {
              return true
            } else {
              return false
            }
          } else {
            return true
          }
        })
        setMetaCategories(filteredMetaCategories)
      }
    }
    setMetaCategoriesLoading(false)
  }
  async function getQualifications() {
    const resp = await getMPDataWithParams('/api/secure/teacher/professional-qualifications', {
      specializations: (formState?.categories || [])?.join(','),
    })
    if (resp?.status === 200) {
      if (resp?.data) {
        const filteredQualifications = resp.data
        setQualifications(
          filteredQualifications?.map((qual) => ({ ...qual, id: qual?.title, title: qual?.title })),
        )
      }
    }
    setQualificationsLoading(false)
  }

  const [sentryRef] = useInfiniteScroll({
    loading: loadingCertifications,
    hasNextPage: hasMore,
    onLoadMore: getCertificates,
    rootMargin: '0px 0px 400px 0px',
  })

  useEffect(() => {
    getCategories()
    getQualifications()
  }, [])

  function handlePersonalizeChange(event) {
    handleNestedChange(event, 'personalize')
  }

  async function getCertificates(page = certificationPage, reset = false) {
    setLoadingCertifications(true)
    const response = await getMPDataWithoutParams(
      `/api/secure/teacher/certificate-record/list/paginated?page=${page}`,
    )
    if (response.status === 200) {
      reset
        ? setAllCertificates(response.data.content)
        : setAllCertificates((prev) => [...(prev || []), ...response.data.content])
      setHasMore(!response.data.last)
      setCertificationPage(response.data.pageable.pageNumber)
    }
    setLoadingCertifications(false)
  }

  function handleChangeServices(event) {
    handleNestedChange(event, 'services')
  }
  function handleChangeQualifications(event) {
    handlePersonalizeChange({
      ...event,
      target: { ...event.target, value: event.target.value.map((val) => val?.id || val) },
    })
  }

  const handleCreateQualification = (inputValue) => {
    handlePersonalizeChange({
      target: {
        value: [...(formState?.personalize?.qualifications || []), inputValue],
        id: 'qualifications',
      },
    })
  }

  function openCertificationEditModal() {
    setOpenEditCertDialog(true)
  }

  function addCertificate() {
    setSelectedCertificate({
      title: '',
      fileUrl: '',
      fileType: 'image/*', //change this default thing
    })
    setEditModalHeading('Add a Certificate')
    openCertificationEditModal()
  }
  function editCertificate(certificate) {
    setSelectedCertificate(certificate)
    setEditModalHeading('Edit Certificate')
    openCertificationEditModal()
  }

  function closeCertificationEditModal(event, reason) {
    if (reason && reason === 'backdropClick') {
      return
    }
    setOpenEditCertDialog(false)
  }

  function handleExperienceYearChange(event) {
    const yearValue = event.target.value
    const currentYear = parseInt(moment().format('YYYY'))
    const currentMonth = parseInt(moment().format('MM'))
    if (parseInt(yearValue) === currentYear) {
      parseInt(formState?.personalize?.experienceStartMonth) > currentMonth &&
        handlePersonalizeChange({
          target: { id: 'experienceStartMonth', value: `${currentMonth}` },
        })
    }
    handlePersonalizeChange(event)
  }

  function handleCertificationEdit(certification) {
    openCertificationEditModal()
  }

  async function afterSaveRefreshCertifications() {
    await getCertificates(0, true)
    closeCertificationEditModal()
  }

  function addRegId(regTypeArr) {
    const firstRegType = regTypeArr?.[0]?.id

    setFormState((prev) => ({
      ...prev,
      registrationIds: [...(prev?.registrationIds || []), { typeId: firstRegType, id: '' }],
    }))
  }

  function removeRegId(idx) {
    setFormState((prev) => ({
      ...prev,
      registrationIds: prev?.registrationIds?.filter((_, index) => index !== idx),
    }))
  }

  // useEffect(() => {

  // }, [])

  // const handleInputChange = (inputValue, actionMeta) => {
  //   setQualificationInput(inputValue)
  //   if (actionMeta.action === 'input-change' && inputValue.endsWith(',')) {
  //     // Remove the trailing comma before creating the value
  //     const newValue = inputValue.slice(0, -1)
  //     const newOption = { id: newValue, name: newValue }
  //     handleChangeQualifications({
  //       target: {
  //         id: 'qualifications',
  //         value: [...(creatableOptions || []), newOption],
  //       },
  //     })
  //     actionMeta.context.clearValue()
  //     setQualificationInput('')
  //     return
  //   }
  // }

  // useEffect(() => {
  //   if (isOnboardedAsDoctor()) {
  //     setFormState((prev) => ({
  //       ...prev,
  //       personalize: {
  //         ...prev?.personalize,
  //         qualifications:
  //           ((prev?.personalize?.qualifications || []).filter((qual) => qual !== 'MBBS'), 'MBBS'),
  //       },
  //     }))
  //   }
  // }, [])

  return (
    <>
      <ProfileComponentsContainer container spacing={4}>
        {!isOnboardedAsDoctor() && !isOnboardedAsTherapist() && (
          <>
            <Grid id="service-settings-heading" item xs={12}>
              <SectionHeading>Service Settings</SectionHeading>
              <SectionSubHeading>
                This is the information that will let you personalize the services you want to give.
              </SectionSubHeading>
            </Grid>
            <Grid item display={'flex'} flexDirection={'column'} gap="5px" xs={12}>
              <CustomCheckBox
                id="live"
                label={'Conduct 1 - 1 Sessions'}
                checked={formState?.services?.live}
                onChange={handleChangeServices}
                sx={checkBoxSx}
              />
              <CustomCheckBox
                id="groupLive"
                label={'Conduct Group Sessions'}
                checked={formState?.services?.groupLive}
                onChange={handleChangeServices}
                sx={checkBoxSx}
              />
              <CustomCheckBox
                id="recorded"
                label={'Publish Content'}
                checked={formState?.services?.recorded}
                onChange={handleChangeServices}
                sx={checkBoxSx}
              />
              {formState?.services?.recorded && (
                <CustomSelect
                  isMulti
                  id="contentCategories"
                  label="Publishable Content Type"
                  value={formState?.contentCategories}
                  onChangeEvent={handleChange}
                  options={metaCategories
                    ?.filter(
                      (cat) =>
                        cat?.categoryType === 'course' || cat?.categoryType === 'activity-plan',
                    )
                    ?.sort((cat1, cat2) => cat1.name.localeCompare(cat2.name))}
                  isLoading={metaCategoriesLoading}
                  getOptionValue={(option) => option?.id}
                  getOptionLabel={(option) => option.name}
                  selectType={selectTypes.string}
                  required
                />
              )}
            </Grid>
          </>
        )}
        <Grid id="mentoring-info-heading" item xs={12}>
          <SectionHeading>
            {isOnboardedAsDoctor() || isOnboardedAsTherapist()
              ? 'Experience & Affiliations'
              : 'Mentoring Information'}
          </SectionHeading>
          <SectionSubHeading>
            This is all the information regarding your experience as your specializations.
          </SectionSubHeading>
        </Grid>
        <Grid item xs={12}>
          <CustomCreatableSelect
            isMulti
            id="qualifications"
            label={<strong>Qualifications</strong>}
            subLabel="Your educational qualifications."
            value={formState?.personalize?.qualifications}
            options={[...(creatableOptions || []), ...(qualifications || [])]?.sort((a, b) =>
              a.title.localeCompare(b.title),
            )}
            filterOption={customQualificationsFilter}
            onChangeEvent={handleChangeQualifications}
            onCreateOption={handleCreateQualification}
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option.title}
            isLoading={qualificationsLoading}
            // inputvalue={qualificationInput}
            // onInputChange={handleInputChange}
            placeholder={
              isOnboardedAsDoctor()
                ? `Select qualifications (MBBS) and press enter to add them...`
                : 'Select qualifications and press enter to add them...'
            }
            getNewOptionData={(inputValue, optionLabel) => ({
              id: inputValue,
              title: optionLabel,
            })}
            formatCreateLabel={(value) => `Press enter to add: ${value}`}
            selectType={selectTypes.string}
            noOptionsMessage={() => 'Enter your qualification.'}
            styles={{
              option: (provided, state) => {
                if (state.data.__isNew__) {
                  return { ...provided, color: '#6b50aa' }
                } else {
                  return provided
                }
              },
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'stretch',
            flexDirection: 'column',
            alignItems: 'stretch',
            gap: '10px',
          }}
        >
          <StyledLabel>
            <strong>Work Experience *</strong>
          </StyledLabel>
          <MentorWorkExperienceInputContainer>
            <StyledLabel>When did you start working?</StyledLabel>
            {/* <StartWorkingDateSelector
              value={
                formState?.personalize?.experienceStartMonth &&
                formState?.personalize?.experienceStartYear &&
                createMomentDate(
                  formState?.personalize?.experienceStartMonth || '01',
                  formState?.personalize?.experienceStartYear || '1990',
                )
              }
              // renderInput={(params) => <StartWorkingInputField {...params} />}
              components={{
                TextField: StartWorkingInputField,
              }}
              maxDate={moment()}
              onChange={(selectVal) => handleExperienceChange(formatMomentDate(selectVal))}
              views={['month', 'year']}
            /> */}
            <WorkExperienceDropDownsContainer>
              <CustomSelect
                id="experienceStartYear"
                placeholder="Select a year..."
                value={formState?.personalize?.experienceStartYear}
                onChangeEvent={handleExperienceYearChange}
                options={yearOptions()}
                getOptionValue={(option) => option?.id}
                getOptionLabel={(option) => option.label}
                selectType={selectTypes.string}
                required
              />
              <CustomSelect
                id="experienceStartMonth"
                placeholder="Select a month..."
                value={formState?.personalize?.experienceStartMonth}
                onChangeEvent={handlePersonalizeChange}
                options={
                  Number(formState?.personalize?.experienceStartYear) >=
                  Number(dayjs().format('YYYY'))
                    ? monthOptions?.filter(
                        (option) => Number(option?.id) <= Number(dayjs().format('MM')),
                      )
                    : monthOptions
                }
                getOptionValue={(option) => option?.id}
                getOptionLabel={(option) => option.label}
                selectType={selectTypes.string}
              />
            </WorkExperienceDropDownsContainer>
            {monthsAndYearsSinceStartDate ? (
              <StyledSubLabel>
                {monthsAndYearsSinceStartDate?.months === 0 &&
                monthsAndYearsSinceStartDate?.years === 0
                  ? 'You have started to work this month'
                  : 'You have been working for'}{' '}
                {monthsAndYearsSinceStartDate?.years > 0
                  ? `${monthsAndYearsSinceStartDate?.years} Years`
                  : ''}{' '}
                {monthsAndYearsSinceStartDate?.months > 0 && monthsAndYearsSinceStartDate?.years > 0
                  ? 'and '
                  : ''}
                {monthsAndYearsSinceStartDate?.months > 0
                  ? `${monthsAndYearsSinceStartDate?.months} Months`
                  : ''}
              </StyledSubLabel>
            ) : (
              <></>
            )}
          </MentorWorkExperienceInputContainer>
        </Grid>
        {formState?.categories?.includes('therapist') ||
          formState?.categories?.includes('counsellor') ||
          formState?.categories?.includes('counselling_psychologist') ||
          (formState?.categories?.includes('clinical_psychologist') && (
            <Grid item xs={12}>
              <CustomAsyncPaginate
                isMulti
                id="techniquesSpecializedIn"
                label={<strong>Specializations </strong>}
                subLabel="These are all the techniques you specialize in."
                value={formState?.personalize?.techniquesSpecializedIn}
                onChangeEvent={handlePersonalizeChange}
                loadOptions={loadTechniques}
                // options={allTags}
                getOptionValue={(option) => option?.id}
                getOptionLabel={(option) => option.title}
                debounceTimeout={500}
                additional={{
                  page: 1,
                }}
              />
            </Grid>
          ))}
        <Grid id="reg-heading" item paddingBottom={'10px'} xs={12}>
          <CertificationHeaderContainer>
            <div>
              <SectionHeading>Registeration Details</SectionHeading>
              {/* <SectionSubHeading>
              </SectionSubHeading> */}
            </div>
          </CertificationHeaderContainer>
        </Grid>
        <Grid item display={'flex'} flexDirection={'column'} gap="7px" xs={12}>
          {!!formState?.registrationIds &&
            formState?.registrationIds?.length > 0 &&
            formState?.registrationIds?.map((regId, idx) => (
              <div key={idx}>
                {idx !== 0 && <hr></hr>}
                <div className="d-flex align-items-end gap-2">
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <CustomHTMLSelect
                        value={regId?.typeId}
                        options={MENTOR_REG_ID_TYPE_ARRAY?.filter((type) => {
                          return formState?.categories?.some((category) =>
                            type?.specializations?.includes(category),
                          )
                        })}
                        getOptionLabel={(optn) => optn?.title}
                        getOptionValue={(optn) => optn?.id}
                        label={'Registration ID Type'}
                        onChange={(e) => {
                          setFormState((prev) => {
                            const newRegIds = [...prev?.registrationIds]
                            newRegIds[idx].typeId = e.target.value
                            return { ...prev, registrationIds: newRegIds }
                          })
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomInput
                        value={regId?.id}
                        invalid={!regId?.id}
                        onChange={(e) => {
                          setFormState((prev) => {
                            const newRegIds = [...prev?.registrationIds]
                            newRegIds[idx].id = e.target.value
                            return { ...prev, registrationIds: newRegIds }
                          })
                        }}
                        label={'Registration ID'}
                      />
                    </Grid>
                  </Grid>
                  <div className="pb-1">
                    <Tooltip title="Remove Registration ID" placement="top">
                      <IconButton
                        onClick={() => {
                          removeRegId(idx)
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </div>
            ))}
          <AddACertificateContainer style={{ padding: '10px 0 0 0' }}>
            <AddCertificationButton
              onClick={() =>
                addRegId(
                  MENTOR_REG_ID_TYPE_ARRAY?.filter((type) => {
                    return formState?.categories?.some((category) =>
                      type?.specializations?.includes(category),
                    )
                  }),
                )
              }
            >
              <CertificateAddIcon />
              Add a Registration ID
            </AddCertificationButton>
          </AddACertificateContainer>
        </Grid>
        <Grid id="certification-heading" item paddingBottom={'30px'} xs={12}>
          <CertificationHeaderContainer>
            <div>
              <SectionHeading>
                {isOnboardedAsDoctor() || isOnboardedAsTherapist()
                  ? 'Certifications (Optional)'
                  : 'Certifications'}
              </SectionHeading>
              <SectionSubHeading>
                These are all the certifications you have acquired. We need to ensure the
                credibility of your profile and your expertise on the topics you will be sharing
                with our community.
              </SectionSubHeading>
            </div>
          </CertificationHeaderContainer>
        </Grid>{' '}
        <Grid item display={'flex'} flexDirection={'column'} gap="7px" xs={12}>
          {!!allCertificates &&
            allCertificates.length > 0 &&
            allCertificates?.map((certificate, idx) => (
              <CertificationFormTableItem key={idx} odd={idx % 2 !== 0}>
                <DownloadCertificateTag
                  href={`${mediaURL}${certificate?.fileUrl}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  <StrongHeading>{certificate?.title}</StrongHeading>
                </DownloadCertificateTag>
                <EditCertificateButton onClick={() => editCertificate(certificate)}>
                  <Edit sx={{ fontSize: '23px' }} />
                </EditCertificateButton>
              </CertificationFormTableItem>
            ))}
        </Grid>
        {(loadingCertifications || hasMore) && (
          <div
            ref={sentryRef}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '20px 0px 0px 0px',
              width: '100%',
            }}
          >
            <CircularProgress sx={{ color: '#A864FF' }} size={30} />
          </div>
        )}
        <AddACertificateContainer>
          <AddCertificationButton onClick={addCertificate}>
            <CertificateAddIcon />
            Add a Certificate
          </AddCertificationButton>
        </AddACertificateContainer>
      </ProfileComponentsContainer>
      <Dialog
        open={openEditCertDialog}
        onClose={closeCertificationEditModal}
        PaperComponent={UploadCertificateDialogPaper}
      >
        <UploadCertificate
          formData={selectedCertificate}
          closeModal={closeCertificationEditModal}
          closeAndRefresh={afterSaveRefreshCertifications}
          heading={editModalHeading}
        />
      </Dialog>
    </>
  )
}

function UploadCertificate({ formData, heading, closeModal, closeAndRefresh }) {
  const { mentorProfileData } = useContext(MentorProfileContext)
  const [saving, setSaving] = useState(false)
  const [documentForm, setDocumentForm] = useState({
    title: '',
    fileUrl: '',
    fileType: 'image/*', //change this default thing
  })

  function handleChangeDocument(event) {
    setDocumentForm((prev) => ({ ...prev, [event.target.id]: event.target.value }))
  }

  async function SaveCertificate() {
    setSaving(true)
    const response = await postMPDataWithoutParams('/api/secure/teacher/certificate-record/save', {
      ...documentForm,
      apiParam: 'CertificateRecord',
    })
    if (response.status === 200) {
      toast.success('Certificate saved successfully')
      closeAndRefresh()
    } else {
      toast.error('Something went wrong')
    }
    setSaving(false)
  }

  useEffect(() => {
    console.log(documentForm)
  }, [documentForm])

  useEffect(() => {
    setDocumentForm({ ...formData, userId: mentorProfileData?.userId })
  }, [formData])

  return (
    <>
      <CertificationDialogTitle>{heading}</CertificationDialogTitle>
      <CertificationDialogContent>
        <CustomInput
          label="Document Title"
          id="title"
          value={documentForm?.title}
          placeholder="Enter the title of the document."
          onChange={handleChangeDocument}
          required
        />
        <CustomSelect
          id="fileType"
          value={documentForm?.fileType}
          options={fileTypes}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          onChangeEvent={handleChangeDocument}
          selectType={selectTypes?.string}
          label="Document Type"
          menuPortal={document.body}
          menuPlacement="auto"
        />
        <DocumentUploadComponent
          id="fileUrl"
          value={documentForm?.fileUrl}
          fileType={documentForm?.fileType}
          onChange={handleChangeDocument}
          showDownloadButton
        />
      </CertificationDialogContent>
      <CertificationDialogActions>
        <CloseCertificateDialogButton onClick={closeModal}>Close</CloseCertificateDialogButton>
        <SaveCertificateDialogButton
          key={'certification-submission'}
          onClick={SaveCertificate}
          disabled={!documentForm?.fileUrl || !documentForm?.title || saving}
        >
          Save
        </SaveCertificateDialogButton>
      </CertificationDialogActions>
    </>
  )
}

async function loadTechniques(search, loadedOptions, { page }) {
  const response = await getMPDataWithParams(`/api/secure/technique/paginated/list/searchable`, {
    search,
    page,
    sadhana: true,
  })
  var hasMore = true
  var optionsArray
  if (response?.status === 200) {
    optionsArray = response?.data
  }
  if (response?.status !== 200 || !optionsArray?.length) {
    hasMore = false
  }
  return {
    options: optionsArray,
    hasMore,
    additional: {
      page: page + 1,
    },
  }
}

export function createMomentDate(startMonth, startYear) {
  // Validate startMonth and startYear to ensure they are in the correct format
  if (
    /^(0[1-9]|1[0-2])$/.test(startMonth) &&
    /^(0|1\d{3}|2\d{3}|3\d{3}|4\d{3}|5\d{3}|6\d{3}|7\d{3}|8\d{3}|9\d{3})$/.test(startYear)
  ) {
    // If both values are in the correct format, create a Moment.js date
    const startDate = moment(`${startYear}-${startMonth}-01`, 'YYYY-MM-DD')

    if (startDate.isValid()) {
      return startDate
    } else {
      // throw new Error('Invalid date.')
      return null
    }
  } else {
    // throw new Error('Invalid startMonth or startYear format.')
    const startDate = moment(`${startYear}-${startMonth}-01`, 'YYYY-MM-DD')

    if (startDate.isValid()) {
      return startDate
    } else {
      return null
    }
  }
}

function formatMomentDate(momentDateObject) {
  const currentDate = moment() // Get the current date

  if (momentDateObject.isBefore(currentDate) || momentDateObject.isSame(currentDate, 'day')) {
    const startMonth = momentDateObject.format('MM')
    const startYear = momentDateObject.format('YYYY')
    return { startMonth, startYear }
  } else {
    // Return today's date in the same format
    const startMonth = currentDate.format('MM')
    const startYear = currentDate.format('YYYY')
    return { startMonth, startYear }
  }
}

export function calculateYearsAndMonthsSince(startDate) {
  const currentDate = moment() // Get the current date
  if (startDate.isValid()) {
    const years = currentDate.diff(startDate, 'years')
    startDate.add(years, 'years') // Subtract years from the current date
    const months = currentDate.diff(startDate, 'months')
    return { years, months }
  } else {
    throw new Error('Invalid start date.')
  }
}

const checkBoxSx = {
  '&.Mui-checked': {
    color: '#A864FF',
  },
}

const yearOptions = () => {
  const currentYear = parseInt(moment().format('YYYY'))
  const yearArray = []
  for (let i = currentYear; i >= 1990; i--) {
    yearArray.push({ id: `${i}`, label: `${i}` })
  }
  return yearArray
}

const monthOptions = [
  { id: '01', label: 'January' },
  { id: '02', label: 'February' },
  { id: '03', label: 'March' },
  { id: '04', label: 'April' },
  { id: '05', label: 'May' },
  { id: '06', label: 'June' },
  { id: '07', label: 'July' },
  { id: '08', label: 'August' },
  { id: '09', label: 'September' },
  { id: '10', label: 'October' },
  { id: '11', label: 'November' },
  { id: '12', label: 'December' },
]

const fileTypes = [
  { id: 'application/pdf', name: 'PDF' },
  { id: 'image/*', name: 'Image' },
]
