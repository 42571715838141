import styled from "styled-components";

export const VitalsListingPageContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
`

export const VitalsItemContainer = styled.div`
    width: 100%;
    border: 1px solid #EFEFEF;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    flex-direction: column;
`