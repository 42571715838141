
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { getCPDataWithoutParams } from 'src/clinic/services'
import { displayNamesOfEventTypes } from 'src/consumer/constants'
import { isCommonResponseSuccessful } from 'src/mentor/helpers/utilityFunctions'
import './LoaderComponent.css'

const utcPlugin = require('dayjs/plugin/utc')
dayjs.extend(utcPlugin)


// new stuff components:

export const PastBookingsHorizontalComponent = ({ patientId, clinicId }) => {
  const [loading, setLoading] = useState(false)
  const [bookings, setBookings] = useState([])

  async function getPreviousBooking() {
    setLoading(true)
    const response = await getCPDataWithoutParams(
      `/api/secure/clinic-assistant/booked-slots/patient?patientId=${patientId}&clinicId=${clinicId}&sortDescendingOnStartTime=true`,
    )
    setLoading(false)
    if (
      response?.status === 200 &&
      isCommonResponseSuccessful(response?.data?.code) &&
      response?.data?.data?.['booked-event-info']
    ) {
      setBookings(response?.data?.data?.['booked-event-info'])
    }
  }

  console.log(bookings)

  useEffect(() => {
    getPreviousBooking()
  }, [])

  return (
    <div style={{ position: 'relative', width: '100%', overflowX: 'auto' }}>
      {loading ? (
        <Spinner variant="dark" size="sm" />
      ) : bookings?.length > 0 ? (
        <>
          <div style={{ fontSize: '16px', fontWeight: '700' }}>Booking History:</div>
          <div
            style={{
              padding: '8px',
              display: 'flex',
              direction: 'row-reverse',
              gap: '48px',
            }}
          >
            <hr style={{ width: '100%', position: 'absolute', top: '50%' }} />
            {bookings?.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className="d-flex flex-column justify-content-center align-items-center"
                  style={{ background: 'white', zIndex: 1, padding: '0 8px', flexShrink: 0 }}
                >
                  <div style={{ fontSize: '12px' }}>
                    {displayNamesOfEventTypes[item?.eventType]}
                  </div>
                  <div style={{ fontSize: '12px' }}>
                    {dayjs?.utc(item?.startTime)?.local()?.format('DD MMM YY, h:mm a')}
                  </div>
                  <div style={{ fontSize: '12px' }}>with {item?.mentorName}</div>
                </div>
              )
            })}
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  )
}
