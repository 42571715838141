import { AsyncPaginate } from 'react-select-async-paginate'
import { ButtonSCNContainer } from 'src/consumer/components/helperComponentsStyles'
import { PRESCRIPTION_BUILDER_VIEW_TYPES } from 'src/consumer/constants'
import {
  commonStylesPrescriptionSelect,
  loadOptionsInvestigations,
} from '../../PrescriptionMakingPageComponents'
import PrescriptionTableViewInputComponent from '../common/PrescriptionTableViewInputComponent'

export const InvestigationsSelectMenu = ({
  clinicId,
  clinicBrandId,
  selectedInvestigations,
  setSelectedInvestigations,
  recommendedInvestigationIds,
  onCreateInvestigation,
  prescriptionViewSettings,
}) => {
  switch (prescriptionViewSettings?.prescriptionBuilderViewType) {
    case PRESCRIPTION_BUILDER_VIEW_TYPES.tabletView:
      return (
        <>
          <PrescriptionTableViewInputComponent
            value={selectedInvestigations}
            getOptionValue={(option) => option?.value}
            getOptionLabel={(option) => option?.label}
            getOptionsResponse={loadOptionsInvestigations}
            searchPlaceholder="Search or add new investigations..."
            title="Investigations"
            onChange={(val) => setSelectedInvestigations(val)}
            onCreate={onCreateInvestigation}
            stickyHeader
            prescriptionViewSettings={prescriptionViewSettings}
            debounceTimeout={200}
            additional={{
              page: 1,
              clinicId: clinicId,
              clinicBrandId: clinicBrandId,
              recommendedInvestigationIds: recommendedInvestigationIds,
              prescriptionViewSettings,
            }}
            useFullPageSearch={true}
          />
        </>
      )
    case PRESCRIPTION_BUILDER_VIEW_TYPES.modern:
      return (
        <>
          <PrescriptionTableViewInputComponent
            value={selectedInvestigations}
            getOptionValue={(option) => option?.value}
            getOptionLabel={(option) => option?.label}
            getOptionsResponse={loadOptionsInvestigations}
            searchPlaceholder="Search or add new investigations..."
            onChange={(val) => setSelectedInvestigations(val)}
            onCreate={onCreateInvestigation}
            prescriptionViewSettings={prescriptionViewSettings}
            debounceTimeout={200}
            additional={{
              page: 1,
              clinicId: clinicId,
              clinicBrandId: clinicBrandId,
              recommendedInvestigationIds: recommendedInvestigationIds,
              prescriptionViewSettings,
            }}
          />
        </>
      )
    default:
      return (
        <div className="w-100">
          <MinimalInvestigationSelect
            clinicBrandId={clinicBrandId}
            clinicId={clinicId}
            selectedInvestigations={selectedInvestigations}
            setSelectedInvestigations={setSelectedInvestigations}
            recommendedInvestigationIds={recommendedInvestigationIds}
            prescriptionViewSettings={prescriptionViewSettings}
          />
          <ButtonSCNContainer
            style={{ marginTop: '8px', fontSize: '12px' }}
            onClick={onCreateInvestigation}
          >
            Add New Investigation
          </ButtonSCNContainer>
        </div>
      )
  }
}

export function MinimalInvestigationSelect({
  clinicId,
  clinicBrandId,
  selectedInvestigations,
  setSelectedInvestigations,
  recommendedInvestigationIds = [],
  prescriptionViewSettings,
}) {
  return (
    <>
      <div style={{ width: '100%' }}>
        <AsyncPaginate
          id="InvestigationsSelection"
          placeholder=""
          isMulti={true}
          closeMenuOnSelect={false}
          debounceTimeout={500}
          loadOptions={loadOptionsInvestigations}
          additional={{
            page: 1,
            clinicId: clinicId,
            clinicBrandId: clinicBrandId,
            recommendedInvestigationIds,
            prescriptionViewSettings,
          }}
          styles={commonStylesPrescriptionSelect}
          isClearable={true}
          isSearchable={true}
          defaultOptions={false}
          getOptionValue={(option) => option?.value}
          getOptionLabel={(option) => option?.label}
          value={selectedInvestigations}
          onChange={(value) => {
            setSelectedInvestigations(value)
          }}
        />
      </div>
    </>
  )
}
