export default function PdfReportQuestionAnswer({ question, questionNumber }) {
  return (
    <div className="mt-2">
      {!question?.shortQuestionText ? (
        <>
          <div style={{ fontWeight: '500' }}>
          {question?.translations?.hi?.questionText
              ? `(${question?.translations?.hi?.questionText})`
              : ''}
            <br></br>
            {questionNumber}. {question?.questionText} {question?.questionText}
          </div>
          <div style={{ whiteSpace: 'pre-wrap' }}>{question?.answer?.response}</div>
          <div style={{ whiteSpace: 'pre-wrap' }}>{question?.answer?.additionalResponse}</div>
        </>
      ) : (
        <span>
          <span style={{ fontWeight: '500', whiteSpace: 'pre-wrap' }}>
            {questionNumber}. {question?.shortQuestionText}.{' '}
          </span>
          {question?.answer?.response}
          {question?.answer?.additionalResponse}
        </span>
      )}
    </div>
  )
}
