import { Print } from '@mui/icons-material'
import { Button, CircularProgress } from '@mui/material'
import { useState } from 'react'
import { sleep } from 'src/mentor/helpers/utilityFunctions'
import { PMPParent } from '../../PrescriptionMakingPage/PrescriptionMakingPageStyles'
import {
  getSRInitialDataForSettingExistingSelectedComplaints,
  getSRInitialDataForSettingExistingSelectedDiagnosis,
  ScreeningReportBody,
  ScreeningReportBodyFirstSection,
  ScreeningReportHeader,
} from '../ScreeningReportMakingPageComponents'

// hide-on-print-only
export default function PrintableScreeningReportComponent({
  report,
  hideDownloadButton,
  templateData,
  headingText = 'Screening Report',
  setIsPrintingPastHistoryReports,
}) {
  const [isPrinting, setIsPrinting] = useState(false)

  const [saving, setSaving] = useState(false)

  async function printScreening() {
    setIsPrintingPastHistoryReports(true)
    setIsPrinting(true)
    await sleep(1000)
    window.print()
    setIsPrintingPastHistoryReports(false)
    setIsPrinting(false)
  }

  return (
    <>
      <div className="d-flex align-items-center mb-2 mt-3 gap-2 w-100 hide-on-print-only">
        <u>{headingText}</u>
        {!hideDownloadButton && (
          <Button
            variant="contained"
            startIcon={<Print />}
            sx={{ padding: '5px 10px', minWidth: '0px' }}
            size="small"
            // onMouseEnter={() => {
            //   setHoverButton(true)
            // }}
            // onMouseDown={() => {
            //   setHoverButton(true)
            // }}
            onClick={() => {
              printScreening()
            }}
          >
            Print {saving && <CircularProgress size="16px" sx={{ ml: 1 }} color="whiteColor" />}
          </Button>
        )}
      </div>
      <div className={`${!isPrinting ? 'hide-on-print-only' : ''} w-100`}>
        <PMPParent>
          <ScreeningReportHeader
            screeningReportData={report}
            screenerName={report?.mentorName}
            templateData={templateData}
          />
          <div style={{ flex: 1, padding: '10px' }}>
            <ScreeningReportBodyFirstSection
              observationAndAdvice={{
                advice: report?.advice || null,
                observation: report?.observation || null,
              }}
              selectedComplaints={getSRInitialDataForSettingExistingSelectedComplaints(
                report?.complaints,
              )}
              selectedDiagnosis={getSRInitialDataForSettingExistingSelectedDiagnosis(
                report?.diagnosis,
              )}
              // screeningSections={screeningSections}
              // screeningQuestionAnswers={report?.screeningAnswers}
              reportData={report}
            />
            <ScreeningReportBody
              observationAndAdvice={{
                advice: report?.advice || null,
                observation: report?.observation || null,
              }}
              content={report?.content}
              suggestedBookingTherapy={report?.suggestedBookingTherapy}
            />
          </div>
        </PMPParent>
      </div>
    </>
  )
}
