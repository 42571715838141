import { JOINT_HOMUNCULUS_DATA } from 'src/consumer/constants'
import { getMPDataWithoutParams } from 'src/mentor/services'
import { getDrugItemOptionValue } from './inputComponents/drugAdvice/utils'

export const isHomunculusValid = (jointHomunculusData) => {
  return (
    Object.keys(JOINT_HOMUNCULUS_DATA).filter((key) => {
      return jointHomunculusData?.[key]
    })?.length > 0
  )
}

export function getDrugAdviceItems({ drugAdviceArray, keyOfItem }) {
  let items = drugAdviceArray?.map((item) => {
    return item?.[keyOfItem]
  })

  //keep items that have a label and are unique, label wise
  items = items
    ?.filter((item) => !!item?.label)
    ?.filter((item, index, self) => {
      return index === self.findIndex((t) => t.label === item.label)
    })

  console.log({ keyOfItem, items })

  return items
}

export async function getHealthEntityResponse({ healthEntityParam }) {
  const response = await getMPDataWithoutParams(`/api/secure/doctor/${healthEntityParam}/list`)
  return response
}

function formNewInternalDrugObjectWithSavedDrugItem({
  drug,
  savedWhatTimes,
  savedDurations,
  savedNotes,
  savedFrequencies,
  savedDosages,
  prescriptionViewSettings,
}) {
  // if the drug items have __isNew__ property, then find the corresponding saved item by title to label search
  const foundWhatTime =
    savedWhatTimes.find(
      (item) => getDrugItemOptionValue(item, prescriptionViewSettings) === drug.whatTime?.label,
    ) || {}
  const foundDuration =
    savedDurations.find(
      (item) => getDrugItemOptionValue(item, prescriptionViewSettings) === drug.duration?.label,
    ) || {}
  const foundNote =
    savedNotes.find(
      (item) => getDrugItemOptionValue(item, prescriptionViewSettings) === drug.notes?.label,
    ) || {}
  const foundFrequency =
    savedFrequencies.find(
      (item) => getDrugItemOptionValue(item, prescriptionViewSettings) === drug.frequency?.label,
    ) || {}
  const foundDosage =
    savedDosages.find(
      (item) => getDrugItemOptionValue(item, prescriptionViewSettings) === drug.dosage?.label,
    ) || {}

  return {
    ...drug,
    whatTime: { ...drug?.whatTime, ...foundWhatTime },
    duration: { ...drug?.duration, ...foundDuration },
    notes: { ...drug?.notes, ...foundNote },
    frequency: { ...drug?.frequency, ...foundFrequency },
    dosage: { ...drug?.dosage, ...foundDosage },
  }
}

export function formNewInternalDrugObjectWithDrugAdviceItems({
  allDrugs,
  whatTimes,
  durations,
  notes,
  frequencies,
  dosages,
  prescriptionViewSettings,
}) {
  return (
    allDrugs?.map((drug, index) => {
      return formNewInternalDrugObjectWithSavedDrugItem({
        drug,
        savedWhatTimes: whatTimes,
        savedDurations: durations,
        savedNotes: notes,
        savedFrequencies: frequencies,
        savedDosages: dosages,
        prescriptionViewSettings,
      })
    }) || []
  )
}

function sortFunctionForHealthEntityOrder(a, b) {
  if (a.order === b.order) {
    return a.title.localeCompare(b.title)
  }
  return a.order - b.order
}

export function getOrderedDrugAdviceItemOptions({ drugAdviceItemOptions }) {
  let personalizedItems = drugAdviceItemOptions.filter((item) => item.clinicIds?.length > 0) || []
  let availableForAllItems =
    drugAdviceItemOptions.filter((item) => item.clinicIds?.length === 0 || !item.clinicIds) || []
  //sort items by the key order. if the key order is same for two items, then sort by title
  personalizedItems = personalizedItems.sort(sortFunctionForHealthEntityOrder)
  availableForAllItems = availableForAllItems.sort(sortFunctionForHealthEntityOrder)
  return [...personalizedItems, ...availableForAllItems]
}

export function getAvailableColumnsForDrugAdviceWithPercentageWidth({ drugAdviceArray }) {
  return []
}

export function getPrescriptionBreaksWithWhereInHeight({a4PageHeight, prescriptionHeight}){
  //gets the number of breaks in the prescription based on the height of the a4 page with there its breaking.
  //returns an array of the heights of the breaks
  const breaks = []
  let currentHeight = a4PageHeight
  while(currentHeight < prescriptionHeight){
    breaks.push(currentHeight)
    currentHeight += a4PageHeight
  }
  return breaks
}