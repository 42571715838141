import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  emrSections: {
    state: 'idle',
    data: [],
    error: null,
  },
  prescriptionViewSettings: {
    state: 'idle',
    data: [],
    error: null,
  },
}

const prescriptionSlice = createSlice({
  name: 'prescriptionStore',
  initialState: initialState,
  reducers: {
    setEmrSectionsData(state, action) {
      return {
        ...state,
        emrSections: {
          ...(state?.emrSections || {}),
          data: action.payload,
        },
      }
    },
    setEmrSectionsError(state, action) {
      return {
        ...state,
        emrSections: {
          ...(state?.emrSections || {}),
          error: action.payload,
        },
      }
    },
    setEmrSectionsState(state, action) {
      return {
        ...state,
        emrSections: {
          ...(state?.emrSections || {}),
          state: action.payload,
        },
      }
    },
    setPrescriptionViewSettingsData(state, action) {
      return {
        ...state,
        prescriptionViewSettings: {
          ...(state?.prescriptionViewSettings || {}),
          data: action.payload,
        },
      }
    },
    setPrescriptionViewSettingsError(state, action) {
      return {
        ...state,
        prescriptionViewSettings: {
          ...(state?.prescriptionViewSettings || {}),
          error: action.payload,
        },
      }
    },
    setPrescriptionViewSettingsState(state, action) {
      return {
        ...state,
        prescriptionViewSettings: {
          ...(state?.prescriptionViewSettings || {}),
          state: action.payload,
        },
      }
    },
  },
})

function setPrescriptionReducer(state) {
  return state.prescriptionStore
}

export const {
  setEmrSectionsData,
  setEmrSectionsError,
  setEmrSectionsState,
  setPrescriptionViewSettingsState,
  setPrescriptionViewSettingsError,
  setPrescriptionViewSettingsData,
} = prescriptionSlice.actions

export const prescriptionReducer = prescriptionSlice.reducer

export const selectEmrSectionsData = (state) => setPrescriptionReducer(state).emrSections

export const selectPrescriptionViewSettingsData = (state) => setPrescriptionReducer(state).prescriptionViewSettings
