import styled from 'styled-components'

//
//
//
//
//
//
//
// NEW

export const NewInvsComponentContainer = styled.div`
  width: 100%;
  padding: 16px;
`

//
//
//
//
//
//
//
//
//
//
// VIEW

export const ViewInvsComponentContainer = styled.div`
  width: 100%;
  padding: 16px;
`

//
//
//
//
//
//
//
// ADD OR VIEW

export const AddOrViewInvsComponentContainer = styled.div`
  background: #f6f5f8;
  min-height: ${(props) => props?.heightLimit};
  height: ${(props) => props?.heightLimit};
`

//
//
//
//
//
//
//
//

export const InvestigationButtonVar = styled.button`
  background: ${(props) => (props?.valid ? '#000' : '#fff')};
  border-radius: 4px;
  border: ${(props) => (props?.valid ? '1px solid #000' : '1px solid #d3d3d3')};
  color: ${(props) => (props?.valid ? '#fff' : '#000')};
`

export const InvestigationHistorySectionButton = styled.button`
  font-size: 18px;
  padding: 8px 32px;
  font-weight: ${(props) => (props?.selected ? 600 : 400)};
  background: ${(props) => (props?.selected ? 'rgba(0, 0, 255, 0.05)' : 'transparent')};
  border-bottom: ${(props) => (props?.selected ? '2px solid rgba(0, 0, 255, 0.49)' : 'none')};
  box-sizing: border-box;
`
